export const es = {
    "name": "Spanish",
    "flagCode": "es",
    "locale": "es-es",
    "project": {
        "name": "Nuevo sistema central de reservas QUp"
    },
    "Sidebar": {
        "Setting": "Ajustes",
        "Map": "Mapa",
        "Cue": "Señal",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "Trip": "Viaje",
        "New_booking": "Nueva reserva",
        "City": "City",
        "Chauffeur": "Transport",
        "Delivery": "Entrega",
        "Shuttle": "Lanzadera",
        "Intercity": "interurbano",
        "Customer": "Clientes",
        "Driver": "Conductores",
        "REPORTS": "Informes",
        "Corporate": "Corporativo",
        "mDispatcher": "Colaborador",
        "Reservation": "Calendario",
        "withdrawalRequest": "Solicitudes de retiro",
        "Driver_Settlement": "Liquidaciones conductor",
        "Dashboard": "Panel de control",
        "SmartData": "Smart data",
        "Smart_Data": "Smart data",
        "Campaign_Builder": "Campaign builder",
        "Company_operator": "Company operator",
        "Queuing_area": "Área de espera",
        "Withdrawal_Requests": "Solicitudes de retiro",
        "pickUp": "Pick up",
        "dropOff": "Drop off",
        "SignUpNotifications": "Sign Up Notifications",
        "Payout": "Payout",
        "Payout_label": "Payout",
        "payout": {
            "Pay_To_Driver": "Pagar a los conductores",
            "Payout_History_For_Driver": "Historial de pagos del conductor",
            "Pay_To_Merchant": "Pagar a los Comerciantes",
            "Payout_History_For_Merchant": "Historial de pago del comerciante"
        },
        "number_of_records_to_export": "Number of records to export",
        "export": "Export",
        "Airline": "Airline",
        "driverPayout": {
            "Pay_to_driver": "Pay to Drivers",
            "Payout_history": "Payout History"
        },
        "Reports": {
            "Driver_Referral_history": "Driver refers customer",
            "Driver_refers_Pax": "Conductor refiere Pax",
            "Driver_refers_Pax_Customers": "Customers",
            "Driver_refers_Pax_Bookings": "Bookings",
            "Driver_refers_customer_Drivers": "Driver refers customer (Drivers)",
            "Driver_refers_customer_Customers": "Driver refers customer (Customers)",
            "Driver_refers_customer_Bookings": "Driver refers customer (Bookings)",
            "Passenger_refers_Passengers": "Customer refers customer",
            "Driver_refers_drivers_Referrer": "Driver refers drivers (Referrer)",
            "Deletion_request": "Deletion request",
            "Driver_refers_drivers_Referee": "Driver refers drivers (Referee)",
            "Driver_refers_Driver": "Driver refers driver",
            "Driver_refers_driver_Referrer": "Referrer",
            "Driver_refers_driver_Referee": "Referee",
            "Referral_history": "Historial de referencias",
            "Booking_details": "Detalles de la reserva",
            "Financial": "Financiero",
            "Financial_driver": "Conductor",
            "Financial_fleet": "Flota",
            "Financial_company": "Compañía",
            "Financial_Driver": "Ingresos (Conductor)",
            "Financial_Fleet": "Ingresos (Flota)",
            "Financial_Company": "Ingresos (Empresa)",
            "Financial_fleet_profit": "Beneficio de flota",
            "Financial_Company_Tooltip": "Informe de ingresos netos de conductores, agrupados por empresa",
            "Financial_Driver_ToolTip": "Informe de ganancias netas de cada conductor",
            "Financial_Fleet_Profit_ToolTip": "Beneficio de flota",
            "Driver_activity": "actividad del conductor",
            "Revenue_Summary": "Revenue summary",
            "Revenue_fleet": "Flota",
            "Revenue_driver": "Conductor",
            "Revenue_monthly": "Mensual",
            "Revenue_Summary_Fleet": "Resumen de ingresos (Flota)",
            "Revenue_Summary_Driver": "Resumen de ingresos (Conductor)",
            "Revenue_Summary_Monthly": "Resumen de ingresos (Mensual)",
            "Total_adjustment": "Ajuste total",
            "Promotion": "Promociones",
            "Incident_Cancellation": "Incidente y Cancelación",
            "Booking_logs": "Registros de reservas",
            "Rating": "Calificación de pulgares",
            "Thumbs_rating": "Calificación de pulgares",
            "Stars_rating": "Clasificación de estrellas",
            "Operator_logs": "Registros del operador",
            "Daily": "A diario",
            "Daily_Driver": "Conductor diario",
            "Daily_Car": "Coche diario",
            "Daily_Driver_Car": "Coche de conductor diario",
            "mDispatcher": "Compañero",
            "Corporate": "Corporativo",
            "Driver_login_status": "Estado de inicio de sesión del conductor",
            "Car_activity": "actividad del coche",
            "Partner": "Colaborador",
            "Prepaid_top_up": "Prepago",
            "Affiliation": "Afiliación",
            "Affiliation_penalty_compensation": "Sanción/Compensación",
            "Affiliation_payout_history": "Settlement history",
            "Affiliation_bookings": "Reservaciones",
            "Booking_summary": "Datos de reservas",
            "Cash_Wallet": "billetera en efectivo",
            "Credit_Wallet": "Crédito monedero",
            "Driver_wallet": "Conductor monedero",
            "Driver_Deposit": "Depósito del conductor",
            "Driver_cash_wallet": "Monedero en efectivo",
            "Driver_Deposit_old": "Depósito conductores",
            "Document_expiry": "Vigencia documentos",
            "Settlement": "Asentamiento",
            "Unapproved_Driver": "Conductor no aprobado",
            "Approved_Driver": "conductor aprobado",
            "Pay_to_driver": "Pagar al conductor",
            "Settlement_history": "Historial de liquidaciones",
            "Withdrawal_History": "Retiro del conductor",
            "creditWalletTooltip": "Depósitos del conductor a la billetera de crédito",
            "Incomplete_payment": "Pago incompleto",
            "Driver_withdrawal": "Retiro del conductor",
            "Incident_&_Cancellation": "Incidente y Cancelación",
            "Passenger_wallet": "Incidente y Cancelación",
            "Accept_Cancel_rate": "Acceptance/Cancellation rate",
            "Acceptance/Cancellation_rate": "Acceptance/Cancellation rate",
            "driverDepositTooltip": "Depósitos del conductor a la billetera de crédito",
            "Customer_quest": "Quest",
            "First_will_win": "First will win",
            "Largest_will_win": "Largest will win",
            "redeemed": "Código #",
            "Code_#": "Código #",
            "Quests": "Búsqueda",
            "Merchant_Report": "Merchant",
            "Merchant_Financial_Report": "Financiero",
            "Merchant_Cash_Report": "Cash wallet",
            "Merchant_Credit_Report": "Credit wallet",
            "Deletion_Request": "Deletion request",
            "Driver_Rating": "Customer rates driver",
            "Passenger_Rating": "Driver rates customer",
            "Company_settlement": "Company settlement",
            "Export": "Export History",
            "Third_Party_Booking": "3rd party Booking",
            "BookingDotCom": "Booking.com",
            "Third_Party_Booking_Bookingcom": "Third Party Booking (Booking.com)",
            "Third_Party_Booking_HolidayTaxis": "Third Party Booking (HolidayTaxis)",
            "Third_Party_Booking_Karhoo": "Third Party Booking (Karhoo)",
            "Third_Party_Booking_Hoppa": "Third Party Booking (Hoppa)"
        },
        "Settings": {
            "Fleet_info": "información de la flota",
            "Stripe_connect": "Stripe Connect",
            "stripe_title_connect": "Activate your Stripe Connect account to receive your payout automatically.",
            "Payment_info": "Información de facturación",
            "Permission": "Permiso",
            "Driver_refer_driver": "Driver refer driver",
            "Show_referal_history": "Show/hide referral history on driver app",
            "companyTooltip": "Supplier company",
            "User": "Usuario",
            "General": "General",
            "Regular": "Regular",
            "Sharing": "Street Sharing",
            "Flat": "Plano",
            "Hourly": "Por Hora - Día",
            "Company": "Compañía",
            "Dispatch": "Asignar",
            "Voice_SMS": "Voz & SMS",
            "Rate": "Tarifas",
            "Car": "Vehículo",
            "Car_type": "Tipo de carro",
            "Car_mgmt": "Gestión de coche",
            "Car_Make": "Haz un modelo",
            "Stripe_Connect": "Stripe connect",
            "Voice_&_SMS": "Voz y SMS",
            "Street-sharing_rate": "Street sharing rate",
            "Partner_type": "tipo de socio",
            "Shift_template": "plantilla de turno",
            "Driver_App": "Driver App",
            "Shift_settings": "Ajustes de turno",
            "Operation": "Operación",
            "Driver_Note": "Driver note",
            "Promotion_code": "El código de promoción",
            "Point_config": "Point config",
            "Code_#": "Code #",
            "Voucher_code": "Code #",
            "Campaign": "Campaña",
            "Queuing_area": "zona de cola",
            "Referral_code": "Código de referencia",
            "City": "Ciudad",
            "category": "Categoría",
            "3rd_party_location": "ubicación de terceros",
            "Zone": "Zona",
            "App_banner": "Banner de App",
            "App_banner_pegasus": "App banner",
            "Flight_integration": "Integración de vuelos",
            "SMS_integration": "SMS Integration",
            "Invoice_Setting": "Invoice",
            "Driver_refers_customer": "Driver refers customer",
            "Pax_refers_Pax": "Customer refers customer",
            "Customer_refers_customer": "Customer refers customer",
            "Dynamic_Surcharge": "Recargo dinámico",
            "Dynamic_Fare": "Tarifa dinámica",
            "FlatZone": "Flat rate",
            "IntercityZone": "Intercity",
            "Intercity_Zone": "Intercity Zone",
            "Intercity_Rate": "Intercity Rate",
            "Car_Color": "Car color",
            "third_party_integration": "3rd party integration",
            "bookingCom": "Booking.com",
            "HolidayTaxis": "HolidayTaxis",
            "Karhoo": "Karhoo",
            "Corporate": "Corporate",
            "KarhooIntegration": "Karhoo Integration",
            "holidaytaxis": "HolidayTaxis",
            "karhoo": "Karhoo",
            "hoppa": "Hoppa",
            "Hoppa": "Hoppa",
            "HoppaIntegration": "Hoppa Integration",
            "Third_Party_Integration_Hoppa": "3rd party Integration (Hoppa.com)",
            "titleHoppa": "Hoppa API Credentials",
            "noteHoppa": "Communicate with Hoppa’s technical team to provide you with client, and secret key to connect to their system.",
            "titleHoppaWebhook": "Webhook Configuration",
            "noteHoppaWebhook": "Provide the parameters below for Hoppa to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
            "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
            "Third_Party_Integration_Karhoo": "3rd party Integration (Karhoo.com)",
            "Third_Party_Integration_Mozio": "3rd party Integration (Mozio.com)",
            "invoiceBranding": "Invoice Branding",
            "titleInvoice": "Customize your company header and footer information.",
            "titleheader": "Header Title",
            "descriptionHeader": "Header Description",
            "placehodelHeader": "For example add company details like email, phone number, address, tax number, etc.",
            "titleFooter": "Footer Title",
            "placehodelFooter": "For example, add bank wire information, or any policy etc",
            "descriptionFooter": "Footer Description ",
            "titleBookingCom": "Booking.com API Credentials",
            "noteBookingCom": "Communicate with booking.com’s technical team to provide you with client, and secret key to connect to their system.",
            "clientKey": "Client Key",
            "placeHolderClientKey": "Enter client key",
            "secretKey": "Secret Key",
            "placeHolderSecretKey": "Enter client secret",
            "titleBookingComWebhook": "Webhook Configuration",
            "noteBookingComWebhook": "Provide the parameters below for booking.com to configure. These credentials are used to communicate between both platforms. For any issues please contact your tech provider.",
            "webhook": "Fleet Webhook URL",
            "clientId": "Fleet Client Key",
            "clientSecret": "Fleet Secret Key",
            "driver_app": "Driver App",
            "Jobs_tab": "Jobs Tab",
            "Email_config": "Email config",
            "Driver_fields": "Driver fields",
            "Driver_document": "Driver document",
            "Selected": "Selected",
            "API_Management": "API management"
        }
    },
    "APIKeySetting": {
        "APIKey": "API Keys",
        "APIKey_title": "Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Good Journey may also automatically disable any API key that we've found has leaked publicly.",
        "newKeyBtn": "Create new key",
        "updateApplication": "Update Application",
        "RegisterApp": "Register a new application",
        "RegisterBtn": "Register Application",
        "appDescription": "App Description",
        "appName": "App Name",
        "clientKey": "Client Key",
        "secretKey": "Secret Key",
        "hostedEndPoint": "Hosted endpoints",
        "maxEndpoints": "You can have a max of 5 endpoints",
        "addEndpoint": "Add Endpoint",
        "updateEndpoint": "Update Endpoint",
        "ManageWebhooks": "Manage Webhooks",
        "CreateWebhook": "Create webhooks",
        "subscriptionID": "Subscription ID",
        "webhookURL": "Webhook URL",
        "signingSecret": "Signing secret",
        "eventsToSend": "Events to send",
        "listeningFor": "Listening For",
        "assignedDriver": "Assigned Driver",
        "bookingStatus": "Booking Status",
        "driverLocation": "Driver Location",
        "titleSigningSecret": "This secret is provided by your partner. If for any reason the secret has been compromised, you can update the secret, or delete the web hook.",
        "notUsingHTTPS": "Webhook URL is not using HTTPS.",
        "invalidURL": "Webhook URL is invalid.",
        "clientType": "Client Type",
        "individual": "Individual",
        "corporate": "Corporate",
        "signingSecretLength": "Signing secret must be at least 30 characters long.",
        "bookingCreated": "Booking Created",
        "partner": "Partner",
        "fleetManager": "Fleet Manager"
    },
    "messages": {
        "credits": {
            "406": "Accion: Fallida",
            "407": "Accion: Fallida",
            "416": "El cupón ha sido usado",
            "417": "Su cuenta ha estado inactiva.  Por favor contacte a nuestro administrador",
            "418": "Hubo errores al realizar el pago",
            "419": "¡El código de promoción no es válido!",
            "420": "Este código de promoción se aplicó para otro viaje.  Utilice otro.",
            "421": "El número de tarjeta no es válido.",
            "422": "La fecha de caducidad no es válida.",
            "429": "El código postal no es válido.",
            "430": "El código postal de AVS no coincide",
            "431": "AVS Código postal no verificado",
            "432": "CVV no es válido.",
            "433": "CVV no coincide",
            "434": "CVV no está verificado",
            "435": "El emisor de CVV no participa",
            "436": "Tu tarjeta fue rechazada",
            "437": "Fondos insuficientes.  Por favor, consulte su saldo!",
            "439": "CVV no procesado",
            "441": "No hay datos de CVV del emisor",
            "443": "No hay datos del emisor/cambio de banknet",
            "445": "El sistema AVS no puede procesar",
            "446": "El banco emisor no admite AVS",
            "447": "AVS no es compatible con su negocio",
            "448": "Esta tarjeta ha sido bloqueada debido a repetidos intentos de pago fallidos.  Cambia tu método de pago o vuelve a intentarlo más tarde",
            "452": "Su tarjeta ha sido rechazada.  Ingrese otra tarjeta de crédito que se registró en la misma área que su número de teléfono",
            "453": "No se admite esta pasarela de pago",
            "454": "La dirección AVS no coincide",
            "455": "AVS Tanto el código postal como la dirección no coinciden",
            "458": "Autenticación fallida",
            "459": "Tarjeta no admitida",
            "461": "Esta tarjeta no ha sido admitida",
            "462": "Titular de la tarjeta no válido",
            "463": "El código de verificación no es válido",
            "464": "El banco no admite el envío del código de verificación",
            "470": "Por favor, introduzca el mail del pasajero antes de crear una reserva con la tarjeta de crédito",
            "474": "Por favor, introduzca el nombre del pasajero antes de crear una reserva con la tarjeta de crédito",
            "475": "Se requiere el número de teléfono",
            "476": "Amount is invalid",
            "524": "Crédito insuficiente. La cuenta no tiene suficiente crédito para realizar la transacción",
            "911": "No podemos autenticar su método de pago.  Elija otro método de pago y vuelva a intentarlo.",
            "2000": "No honrar",
            "2001": "Crédito insuficiente. La cuenta no tiene suficiente crédito para realizar la transacción",
            "2002": "Límite excedido",
            "2003": "Límite de actividad excedido",
            "2004": "Tarjeta expirada",
            "2005": "Número de tarjeta de crédito no válido",
            "2006": "fecha de caducidad no válida",
            "2007": "sin cuenta",
            "2008": "longitud de cuenta errónea",
            "2009": "No hay tal emisor",
            "2010": "La entidad emisora rechazó CVV",
            "2011": "Se requiere autorización de voz. El banco del titular de la tarjeta está solicitando que el comerciante llame para obtener un código de autorización especial para completar esta transacción",
            "2012": "Proceso rechazado - posible pérdida de tarjeta",
            "2013": "Proceso rechazado - posible pérdida de tarjeta",
            "2014": "Proceso rechazado - posible proceso de fraude",
            "2015": "Transacción no permitida",
            "2016": "transacción duplicada",
            "2017": "Propietario de tarjeta canceló facturación",
            "2018": "Propietario de tarjeta canceló facturación",
            "2019": "transacción no válida",
            "2020": "Violación",
            "2021": "Violación de seguridad",
            "2022": "Rechazado - actualización de propietario de tarjeta",
            "2023": "Proceso no admite esta configuración",
            "2024": "tipo de tarjeta no permitida",
            "2025": "Error de configuración - Comercio",
            "2026": "Código de comercio no válido",
            "2027": "Error de configuración - Importe",
            "2028": "Error de configuración - Estructura",
            "2029": "Error de configuración - Tarjeta",
            "2030": "Error de configuración - Terminal",
            "2031": "Error encriptado",
            "2032": "cargo no permitido",
            "2033": "datos no consistentes",
            "2034": "no se ha realizado acción",
            "2035": "Aprobación Parcial Por Monto En Versión Grupo III",
            "2036": "No se pudo encontrar la autorización para revertir",
            "2037": "Correción realizada",
            "2038": "Proceso rechazado. El banco del cliente ha rechazado la transacción, contacte con su entidad para más detalles",
            "2039": "Código de autorización no válido",
            "2040": "Comercio no válido",
            "2041": "Rechazado - Llamar para la aprobación",
            "2042": "Código de cliente no válido",
            "2043": "Error: no reintentar, llame a la entidad emisora",
            "2044": "Declinada - llame a la entidad emisora",
            "2045": "Número comercio no válido",
            "2046": "Rechazado.  El banco del cliente no está dispuesto a aceptar la transacción, debe comunicarse con su banco para obtener más detalles.",
            "2047": "Emisor de llamadas.  Selecciona carta.  Este error indica que la tarjeta ha sido reportada como extraviada o robada por el titular de la tarjeta.",
            "2048": "Importe no válido",
            "2049": "Número SKU no válido",
            "2050": "Plan de crédito no válido",
            "2051": "El número de tarjeta de crédito no coincide con el método de pago",
            "2052": "Compra de nivel III no válida",
            "2053": "Tarjeta reportada como extraviada o robada",
            "2054": "El monto de la reversión no coincide con el monto de la autorización",
            "2055": "Número de división de transacción no válido",
            "2056": "El monto de la transacción excede el límite de división de la transacción",
            "2057": "El titular o la entidad emisora han puesto un límite a la tarjeta",
            "2058": "Comerciante sin MasterCard SecureCode habilitado.",
            "2060": "Fallo en la verificación de la dirección y el código de seguridad de la tarjeta",
            "2061": "Datos de transacción no válidos",
            "2062": "Cantidad de impuestos no válida",
            "2064": "Código de moneda no válido",
            "2078": "Código de pago Seguro no válido",
            "2080": "credenciales de usuario no válidas",
            "3000": "La red del procesador no está disponible: inténtelo de nuevo",
            "4001": "Liquidación declinada",
            "4006": "La cantidad de captura excedió el límite permitido",
            "81703": "Esta cuenta de comerciante no acepta el tipo de tarjeta de crédito.",
            "81706": "Se requiere CVV",
            "81707": "El CVV debe tener 3 o 4 dígitos.",
            "81709": "Se requiere la fecha de caducidad.",
            "81710": "La fecha de caducidad no es válida.",
            "81714": "Se requiere número de tarjeta de crédito.",
            "81715": "El número de la tarjeta de crédito es inválido.",
            "81716": "El número de la tarjeta de crédito debe tener entre 12 y 19 dígitos.",
            "81717": "El número de tarjeta de crédito no es un número de prueba aceptado.",
            "81718": "El número de tarjeta de crédito no se puede actualizar a un tipo de tarjeta no admitido cuando está asociado a suscripciones.",
            "81723": "El nombre del titular de la tarjeta es demasiado largo.  Máximo 175 caracteres.",
            "81809": "El código postal no puede contener más de 9 caracteres de letras o números",
            "81813": "El código postal solo puede contener letras, números, espacios y guiones",
            "91722": "Se requiere token de método de pago.",
            "91723": "Actualizar token existente no es válido",
            "91730": "La verificación no es compatible con esta cuenta de comerciante.",
            "91734": "Esta cuenta de comerciante no acepta el tipo de tarjeta de crédito.",
            "91738": "El método de pago especificado no es una tarjeta de crédito.",
            "91745": "Parámetros no válidos para la actualización de la tarjeta de crédito.",
            "91826": "Código postal invalido.  El código postal debe ser una cadena de 5 o 9 dígitos, opcionalmente separados por un guión o un espacio.",
            "-1": "Esta cuenta de cliente ha sido desactivada.  Por favor, póngase en contacto con nuestro administrador."
        },
        "reBookingMsg": {
            "303": "¡La hora que ha seleccionado debe ser posterior a la hora actual!",
            "304": "Esta reserva no fue encontrada",
            "500": "Parte de la información de la reserva no es válida o falta.  ¡Por favor revisa e intenta de nuevo!"
        },
        "promoMsg": {
            "304": "El código promocional no es válido",
            "306": "Código de promoción no válido.  Parece que la promo se aplica a otras zonas.",
            "413": "Lo sentimos, este código de promoción no está disponible en este momento",
            "414": "El código promocional no es válido",
            "415": "El código promocional no es válido",
            "416": "Este código de promoción ya se ha utilizado.",
            "419": "Lo sentimos, este código de promoción no está disponible en este momento",
            "420": "Lo sentimos, ya has llegado al límite de usos de este código promocional",
            "421": "Lo sentimos, este código de promoción ha alcanzado su límite de uso publicado",
            "422": "Este código de promoción no se puede utilizar en su cuenta",
            "423": "Lo sentimos, ha alcanzado su presupuesto promocional limitado",
            "424": "Código promocional no válido. Código aplicable a otras zonas",
            "425": "Código de promoción no válido.  Parece que la promo solo se aplica a clientes referidos",
            "426": "Has alcanzado tus usos promocionales limitados por día.",
            "427": "No se puede aplicar el código de promoción.  El perfil de este cliente está incompleto.",
            "428": "Este cliente ha agotado el código de promoción mensual.",
            "430": "You have used up your yearly promotion codes.",
            "431": "The promotion code can't be used for this payment method.",
            "432": "This fare amount isn't eligible for the promotion code.",
            "433": "The promotion code isn't eligible to use at selected time.",
            "434": "Sorry, you have reached your limited promo budget.",
            "phoneInvalid": "Ingrese el número de teléfono del pasajero antes de aplicar el código de promoción"
        },
        "updateBookingMsg": {
            "303": "Error al actualizar la reserva.  ¡Inténtalo de nuevo!",
            "304": "Esta reserva no fue encontrada",
            "pickUpTime": "¡La hora que ha seleccionado debe ser posterior a la hora actual!",
            "pickup": "No hay servicios de reserva disponibles en esta área",
            "destination": "Parte de la información de la reserva no es válida o falta.  ¡Por favor revisa e intenta de nuevo!",
            "dispatchType": "Parte de la información de la reserva no es válida o falta.  ¡Por favor revisa e intenta de nuevo!"
        },
        "sessionExpired": {
            "403": "Se ha cerrado la sesión por haberse iniciado desde otro dispositivo"
        },
        "not_found": {
            "404": "la página solicitad no se ha encontrado"
        },
        "booking": {
            "303": "¡La hora que ha seleccionado debe ser posterior a la hora actual!",
            "304": "Esta reserva no fue encontrada",
            "305": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue.",
            "306": "No rate assign to selected car type. To apply a rate for any car type, please go to Settings > Car > Car Type.",
            "307": "Can not update booking. Please try again later.",
            "407": "Type rate ko support extra location",
            "500": "Parte de la información de la reserva no es válida o falta.  ¡Por favor revisa e intenta de nuevo!",
            "upgroup_success": "Booking has been ungrouped successfully",
            "Please_Select_driver": "Please select driver",
            "assign_driver_vehicle_success": "Driver/Vehicle has been assigned successfully",
            "assign_driver_success": "Driver has been assigned successfully",
            "detach_driver_success": "Driver has been detach successfully",
            "detach_vehicle_success": "Vehicle has been detach successfully",
            "Group_success": "Booking has been grouped successfully",
            "change_supplier_success": "Booking has been changed suppliers successfully",
            "change_supplier_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "detach_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "assign_driver_partial": "Partial Success: Batch assignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
            "update_dispatch": "Update & Dispatch",
            "remove_booking_false": "Failed to remove booking",
            "remove_booking_success": "Booking has been removed successfully",
            "GROUP_NOT_FOUND": "Group Id is not found",
            "NO_SHARING_BOOKINGS": "You can only group bookings if the customer’s booking allows ride-sharing.",
            "DIFFERENT_CAR_TYPES": "The booking you are trying to group has a different car-type",
            "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Booking cannot be grouped: Seat or luggage limit exceeded.",
            "INVALID_CAR_TYPE": "Cannot assign driver, check driver’s vehicle seat & luggage capacity.",
            "VALIDATION_ERROR": "Validation error",
            "INTERNAL": "Internal server error",
            "fare_gt_zero": "¡La tarifa debe ser mayor que 0!",
            "edit_total_fail": "¡Error al editar el valor total!",
            "complete_fail": "La reserva no ha podido ser completada",
            "cancle_fail": "Ha ocurrido un error al cancelar la reserva. Por favor inténtelo de nuevo",
            "incident_fail": "Incidente fallido",
            "phone_invalid": "¡Por favor ingrese un número de teléfono válido!",
            "add_psg_fail": "No se puede agregar un nuevo pasajero",
            "REMOVE_RECIPIENT_DELIVERED": "Cannot remove recipient has been delivered",
            "REMOVE_LAST_ACTIVE_RECIPIENT": "Cannot remove the last active recipient",
            "ORDER_HAS_BEEN_FINISHED": "Order has been finished already",
            "end_booking_leave": "Si abandona esta página, finalizará su reserva.",
            "update_booking_fail": "Error al actualizar la reserva",
            "EXTERNAL_ID_EXISTED": "External ID already exist on the system.",
            "area_service_unavailable": "No hay servicios de reserva disponibles en esta área",
            "pickupSameDestination": "La dirección de recogida debe ser distinta a la dirección de destino.",
            "time_invalid": "¡La hora que ha seleccionado debe ser posterior a la hora actual!",
            "psg_inactivate": "Esta cuenta de cliente ha sido desactivada.  Por favor contacte a nuestro administrador",
            "data_incorect": "Something went wrong. Please try again later.",
            "preAuthorize": "Por favor revise su cuenta, se necesita un importe de __ para garantizar la reserva",
            "booking_limited": "Lo sentimos, has llegado al máximo de reservas por minuto/día. Por favor inténtelo más tarde",
            "minimumBookAhead": "La hora de recogida debe ser al menos __ hora(s) desde la hora actual",
            "exDoDiffDo": "El destino adicional debe ser diferente del destino.",
            "recurringTimeRange": "Date out of range. You can only create bookings {0} months in advance. Please contact admin for any issues.",
            "recipientsLimit": "Maximum number of recipients is {0}.",
            "maximumBookAhead": "La hora de recogida debe ser al menos __ día(s) desde la hora actual",
            "Please_select_or_add_a_card": "¡Seleccione o agregue una tarjeta!",
            "Unable_to_process_by_fare_zero": "No hemos podido procesar tu petición, por favor modifica el tipo de coche o tipo de reserva e inténtalo de nuevo",
            "Can_not_connect_to_server": "No ha sido posible conectar al servidor",
            "Check_connection_or_contact_system_support": "Por favor revisa tu conexión o contacta con el equipo de soporte",
            "Create_new_booking_success": "Tu reserva se ha creado satisfactoriamente!",
            "Create_new_recurring_booking_success": "New booking(s) has been created successfully",
            "Update_booking_success": "La reserva se ha actualizado satisfactoriamente",
            "Booking_drop_of_success": "La reserva ha sido finalizada satisfactoriamente",
            "Cancel_booking_success": "La reserva ha sido cancelada satisfactoriamente",
            "Booking_detail_not_found": "No se han encontrado los detalles de la reserva!",
            "Check_promo_error": "Revise el error del código promocional",
            "Invalid_format": "Formato no válido",
            "Flight_not_found": "Vuelo no encontrado!",
            "Add_new_credit_error": "Añadir nuevo descuento",
            "Socket_server_disconnected": "Conector del servidor desconectado",
            "Please_reload_page_or_check_your_connection": "Lo sentimos, su petición no ha podido ser procesada. Por favor inténtelo de nuevo",
            "request_timeout": "Sorry. Your request timeout. Please try again.",
            "Check_customer_error": "Revise el error en el cliente",
            "Please_select_car_type": "Por favor seleccione el tipo de vehículo",
            "DropOff_country": "Lo sentimos, ¡el destino solicitado está fuera de nuestra área de servicio!",
            "Different_Country": "Los lugares de recogida y devolución deben estar en 2 países diferentes.",
            "Please_enter_destination_for_ridesharing_booking": "Por favor, introduzca destino para la reserva de viaje compartido",
            "Extra_detination_must_be_difference_from_destination": "El destino adicional debe ser diferente del destino actual",
            "Pickup_address_must_be_different_from_destination": "La dirección de recogida debe ser diferente a la dirección de destino",
            "Best_price": "Mejor precio",
            "You_may_be_charged_extra_fee_if_your_trip_excceds": "Es posible que se le cobre una tarifa adicional si su viaje excede",
            "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "Es posible que se le cobre una tarifa adicional si su viaje excede {0} {1}, {2} {3}",
            "Reject_booking_success": "La reserva ha sido rechazada satisfactoriamente",
            "Reject_fail": "Ocurrió un error al rechazar la reserva. Por favor inténtelo de nuevo",
            "Please_select_payment_method": "Por favor seleccione el método de pago",
            "Driver_signed_out_or_deactivated": "Este controlador acaba de cerrar sesión o ha sido desactivado",
            "reservation_booking_overlaps": "Lo sentimos, esta reserva de reserva se superpone con otra que Driver ha aceptado.",
            "driver_is_not_available": "Sorry, this driver isn’t available at the requested time.",
            "seats_luggage_limit": "This booking can’t be assigned to the driver because the seats/luggage limit has been exceeded.",
            "full_capacity": "This booking can’t be assigned to the driver because the trip has reached its maximum capacity.",
            "marked_failed_ok": "The order has been marked as failed.",
            "warning_markFail_lastRecipient": "This is the last recipient of this booking. If you proceed with marking this order as failed you will then need to complete this booking.",
            "BOOKINGS_LIMIT_EXCEEDED": "You can only assign a maximum of 5 orders into each group",
            "bookingCanceled": "Booking canceled or completed by Booking.com; please reject the job to remove it from the queue."
        },
        "item_no_found": "No se encontró ningún artículo",
        "commonMessages": {
            "greater_than_0": "Introduzca un valor superior a 0.",
            "Required_field": "Este campo es obligatorio",
            "bankPhone_is_required": "Phone number is invalid",
            "bankPhone": "Phone number",
            "distance_from_equa_or_smaller": "La distancia \\desde\\\" debe ser inferior o igual a la distancia \\\"hacia\\\"\"",
            "must_be_number": "Por favor ingrese un número válido",
            "equal_or_greater_than_0": "Introduzca un valor superior o igual a cero",
            "must_be_integer": "Por favor ingrese un número entero",
            "invalid_email_format": "Por favor introduzca un correo electrónico válido.",
            "greater_or_equa": "Introduzca un valor superior o igual a {0}.",
            "value_must_between": "Introduzca un número entre {0} y {1}",
            "large_10x_markup_price": "Edited fare should not exceed 10x estimated fare.",
            "greater_than": "Introduzca un valor superior a cero",
            "less_than": "Introduzca un valor inferior a {0}.",
            "less_or_equal": "Introduzca un valor inferior o igual a {0}.",
            "driver_earnings_warning": "Offline payments: Driver earnings must not surpass the collected fare.",
            "limit_amount": "Ingrese un valor menor o igual a la cantidad límite",
            "Integer_from_range": "Ingrese un número entero de {0} - {1}",
            "integer_greater_than_0": "Introduzca un número entero mayor que 0.",
            "Error_message": "¡Error!",
            "callcenter_newbooking_message": "nueva reserva con teléfono",
            "callcenter_existed_booking_message": "existe una reserva activa con teléfono",
            "callcenter_close_newbooking_form_warning": "Cierre o complete su libro actual antes de recibir una nueva solicitud de cliente",
            "Emergency_SOS_Title": "SOS",
            "Please_enter_a_future_date": "Por favor introduzca una fecha futura",
            "Card_holder_is_required": "Titular de la tarjeta requerido",
            "Card_number_is_required": "Número de la tarjeta requerido",
            "Expired_date_is_required": "Fecha de caducidad requerida",
            "Credit_code_is_required": "Por favor ingrese el código de crédito (última validación)",
            "Expired_date_is_invalid": "fecha de caducidad no válida. Introduzca la fecha en formato mm/aa",
            "cvv_is_required": "Se requiere CVV",
            "City_is_required": "Ciudad requerida",
            "State_is_required": "El país es requerido",
            "Address_is_required": "La direección es obligatoria",
            "Zipcode_is_required": "Código postal obligatorio",
            "Country_is_required": "País obligatorio",
            "Over_query_limit": "La aplicación de Google no funciona debido a un error temporal. Pruebe más tarde",
            "outOfInputQueueOrder": "El número de # pedido no puede ser mayor que el número total de conductores en la cola.",
            "Same_pickup_destination": "Pick-up address must be different from destination address.",
            "Max_length": "La longitud máxima es de {0} caracteres.",
            "choose_at_least_one": "Please choose at least one option.",
            "choose_at_least_one_intercity_schedule": "Please add schedule or allow customer to request their preferred time.",
            "startDate_greater_than_endDate": "The start date is greater than the end date"
        },
        "trip": {
            "cancel_trip_success": "This trip has been cancelled successfully!",
            "data_incorect": "No driver has been assigned. Select a driver from the dropdown list. <br/> If no drivers is available, select Auto Dispatch to assign later.",
            "INTERNAL": "Internal server error.",
            "NOT_FOUND": "Trip not found or invalid status.",
            "SAME_DRIVER": "The assignee must be different from the current driver.",
            "OVERLAP": "Sorry, this trip overlaps with another one which is accepted by this driver.",
            "CANNOT_ASSIGN": "This driver is invalid (i.e. driver not found, not online, or not logged in)."
        },
        "car_mgmt": {
            "Search_car_color": "Search car color",
            "Searching": "Searching"
        },
        "driver_note": {
            "Search_driver_note": "Search driver note",
            "Searching": "Searching"
        }
    },
    "errors": {
        "303": "usuario no encontrado",
        "453": "Gateway unsupported",
        "553": "Sorry! Customer has a booking in-progress, please try again later.",
        "1007": "The period between the start date and the end date must be less than 60 days!!",
        "3001": "El número de teléfono existía",
        "3002": "fecha de nacimiento invalida",
        "3003": "Fecha de caducidad de la licencia de conducir inválida",
        "3004": "Controlador no encontrado",
        "3005": "Información de liquidación del conductor faltante (dirección del banco, ciudad, estado, código postal)",
        "3006": "Falta la información de la cuenta bancaria (nombre de la cuenta, tipo de cuenta, número de ruta, número de cuenta, ssn)",
        "3007": "No puede editar este conductor, que tiene un trabajo.",
        "3008": "Este conductor está iniciando sesión actualmente. Pídele que cierre la sesión para editar su información.",
        "3009": "Este conductor ha aceptado una o más reservas.  Asigne la(s) reserva(s) a otros conductores antes de actualizar/cambiar la información del conductor.",
        "3010": "Falló el envío de conexión.",
        "3011": "Este controlador debe estar configurado para funcionar antes de activarse.",
        "3012": "Este controlador debe estar configurado para funcionar antes de activarse.",
        "3013": "Solo se puede eliminar el elemento en estado inactivo.",
        "3014": "Faltan campos obligatorios.  Por favor, compruebe.",
        "3015": "Año de nacimiento inválido.  Por favor, compruebe.",
        "3016": "Código postal no válido.  Por favor, compruebe.",
        "3017": "Estado/provincia no válido.  Por favor, compruebe.",
        "3018": "Nombre de cuenta no válido.  Por favor, compruebe.",
        "3019": "Número de ruta no válido.  Por favor, compruebe.",
        "3020": "Se requiere cumpleaños.  Por favor, compruebe.",
        "3021": "Número de ssn inválido.  Por favor, compruebe.",
        "3022": "La cuenta bancaria del conductor no puede recibir fondos",
        "3023": "Los controladores registrados no pueden ser operados.  Por favor, inténtelo de nuevo más tarde.",
        "3026": "El controlador ya no existe.",
        "3027": "La cuenta bancaria del conductor no está configurada.  Vaya al módulo de Conductores para configurar su cuenta bancaria.",
        "3028": "No se pudo pagar a los conductores seleccionados.  Inténtalo de nuevo.",
        "3029": "Error al limpiar los datos de ganancias de los conductores, inténtelo de nuevo",
        "3032": "Este nombre de usuario ya existe en nuestro sistema.  Por favor ingrese otro.",
        "3033": "Código bancario no válido. El número debe contener 6 dígitos",
        "3039": "Group ID is required.",
        "3040": "This phone number is already in use by a  %{brandName} driver (i.e. phone number can't be duplicated). Please enter another one",
        "3041": "Username existed.",
        "3044": "National ID is duplicated.",
        "3045": "Driver already has a vehicle, Please refresh page",
        "3046": "The company of the vehicle and the company of the driver are different.",
        "4001": "Su número de placa ha existido en el sistema.  Por favor ingrese otro.",
        "4002": "fecha de caducidad no válida",
        "4003": "El vehículo debe estar desactivado antes de la eliminación",
        "4004": "vehículo no encontrado",
        "4005": "Este vehículo ya está inactivo",
        "4006": "Este vehículo está en uso y no se puede eliminar ni desactivar",
        "4007": "número de matrícula obligatorio",
        "4009": "The vehicle is being assigned to a booking.",
        "4010": "The vehicle is being assigned to a driver.",
        "5001": "Empresa existente",
        "5004": "Empresa no encontrada",
        "6001": "Modelo de vehiculo existente",
        "6004": "Modelo de vehiculo no encontrado",
        "6005": "modelo de vehículo en uso",
        "7001": "Marca de vehículo ya existe, introduzca otra",
        "7004": "Marca de vehículo no encontrada",
        "8001": "El nombre de la tipología de vehículo ya existe, introduzca otra.",
        "8004": "tipología de vehículo no encontrada",
        "9001": "Ruta prefijada existente",
        "9004": "Ruta prefijada no encontrada",
        "10001": "Zona existente",
        "10004": "Zona no encontrada",
        "11001": "Zona existente",
        "11002": "Esta zona se superpone con otra.  Por favor vuelve a dibujar.",
        "11003": "la zona debe tener forma poligonal",
        "11004": "Zona no encontrada",
        "11005": "La zona está en uso",
        "12001": "Bandeja de entrada existente",
        "12002": "Por favor, configure SMS Twilio",
        "12004": "Bandeja de entrada no encontrada",
        "12005": "There is no recipient match your filters. Please check the list and try again.",
        "12006": "No customer exists in the file.",
        "13001": "Este código promocional ya existe en el sistema. Por favor introduzca otro.",
        "13002": "Fecha no válida",
        "13003": "Privado",
        "13004": "código promocional no válido",
        "13005": "Sólo nuevos clientes",
        "13006": "Por encima de cantidad límite",
        "13007": "Por encima de límite presupuestado",
        "13008": "Sobre el límite de uso total",
        "13011": "Esta campaña de promoción ha sido desactivada o eliminada",
        "13012": "el rango de datos no es válido",
        "13013": "Código promocional no válido. Código aplicable a otras zonas",
        "13014": "La zona que ha seleccionado ha sido desactivada.",
        "14004": "Reserva no encontrada",
        "15002": "Su flota ha sido desactivada.  Por favor contacte a nuestro administrador",
        "15004": "Flota no encontrada",
        "16001": "El nombre de la tarifa fija existe en el sistema. Por favor introduzca otro",
        "16004": "Tarifa no encontrada",
        "17001": "Tarifa existente",
        "17004": "Tarifa de paquete no encontrada",
        "18001": "Tarifa existente",
        "18002": "Tarifa existente",
        "18004": "Tarifa no encontrada",
        "19001": "El nombre de su paquete ha existido en el sistema.  Por favor ingrese otro.",
        "19002": "Tarifa existente",
        "19004": "Tarifa no encontrada",
        "20001": "El nombre de usuario ya existe en el sistema. Por favor introduzca uno nuevo",
        "20002": "Su cuenta ha estado inactiva.  Por favor contacte a nuestro administrador",
        "20003": "El nombre de usuario o la contraseña son incorrectos.  Inténtalo de nuevo",
        "20004": "El nombre de usuario no está registrado en el sistema.",
        "20005": "the enlace ha caducado",
        "20006": "la contraseña actual no coincide. Inténtelo de nuevo",
        "20007": "usuario existente",
        "20008": "This email already exists on our system. Please enter another one.",
        "21001": "Su nombre de permiso ha existido en el sistema.  Por favor ingrese otro.",
        "21002": "No se puede desactivar este permiso porque actualmente está asignado a los usuarios.",
        "21003": "Rol activo",
        "21004": "Rol no encontrado",
        "22001": "el cliente ya existe",
        "22004": "cliente no encontrado",
        "22005": "formato de archivo no válido",
        "24001": "Número de teléfono no válido",
        "25001": "Nombre existente, introduzca otro",
        "25002": "Intervalo de tiempo no válido",
        "25004": "Turno no encontrado",
        "26001": "Nombre existente, introduzca otro",
        "26002": "Este turno se utiliza actualmente en funcionamiento, desasignarlo antes de eliminarlo",
        "26004": "Plantilla de turno no encontrada",
        "27001": "Campaña ya existe",
        "27002": "Campaña está activada",
        "27004": "campaña no encontrada",
        "28001": "Ciudad existente",
        "28002": "La ciudad se está activando",
        "28003": "ciudad está en uso",
        "28004": "ciudad no encontrada",
        "29002": "este teléfono ya existe",
        "30001": "Área de espera no encontrada",
        "30002": "Área de espera en uso",
        "30003": "Su nombre de área de espera ha existido en el sistema.  Por favor ingrese otro",
        "30004": "El área de espera no debe superponerse entre sí",
        "30005": "Las coordenadas del área de espera no son válidas",
        "30006": "Las zonas de recogida no son válidas",
        "31001": "Su ID de empresa ha existido en el sistema.  Por favor ingrese otro.",
        "31002": "Este nombre de usuario ya existe en nuestro sistema.  Por favor ingrese otro.",
        "31003": "Empresa no encontrada",
        "31004": "Usuario corporativo no encontrado.",
        "31005": "Solo se pueden eliminar las empresas que están inactivas.",
        "31006": "Este teléfono ha estado presente en el viajero de corp.  Por favor ingrese otro.",
        "31007": "viajero corporativo no encontrado",
        "31008": "tarjeta no encontrada",
        "31009": "Este teléfono ha existido en el sistema.  Por favor ingrese otro.",
        "31010": "esta empresa ha sido desactivada, contacte con nuestro equipo para más información",
        "31011": "este viajero no puede ser activado por pertenecer a una empresa inactiva",
        "31013": "A business with this name already exists",
        "32001": "el nombre del servicio ya existe en el sistema, introduzca otro",
        "32004": "servicio no encontrado",
        "33002": "el rango de fechas no debe solaparse con otras",
        "34001": "Code is existed",
        "34013": "Date range is invalid",
        "36001": "Name is existed",
        "39001": "The document is required",
        "71001": "Zone existed",
        "71002": "Esta zona se superpone con otra.  Por favor, dibújalo de nuevo.",
        "71003": "Zone must be a polygon object",
        "71004": "Zone not found",
        "72001": "Existía la tarifa dinámica",
        "72002": "Esta zona se superpone con otra.  Por favor, dibújalo de nuevo.",
        "72003": "La tarifa dinámica debe ser un objeto poligonal",
        "72004": "Dynamic fare not found",
        "74001": "Tarifa dinámica no encontrada",
        "74002": "Esta zona se superpone con otra.  Por favor, dibújalo de nuevo.",
        "74003": "La zona debe ser un objeto poligonal",
        "74004": "Tarifa zona no encontrada",
        "74005": "Tarifa zona no encontrada",
        "80001": "El banner de la aplicación existía.",
        "80002": "No se encontró el banner de la aplicación.",
        "80003": "Solo se puede eliminar el elemento en estado inactivo.",
        "81001": "La carga de archivos está vacía.",
        "81002": "La carga de archivos tiene un tamaño límite.",
        "82002": "Error al configurar Flightaware. Por favor vuelva a intentar más tarde",
        "90001": "Intercity zone existed",
        "90002": "This area overlaps with another one. Please draw it again.",
        "90003": "Intercity zone must be a polygon object",
        "90004": "Intercity zone not found",
        "90005": "Intercity zone is in use",
        "91001": "Intercity rate is existed",
        "91002": "Intercity rate is in use",
        "91003": "Intercity rate not found",
        "92001": "Route name is existed",
        "92002": "Route not found",
        "92003": "This route already exists at other route, please check and try again",
        "92004": "There is an active trip on this route.",
        "93001": "Driver note has been existed on system. Please enter another one.",
        "93002": "Driver note are inactive can be deleted.",
        "93003": "Driver note is in use.",
        "93004": "Driver note not found.",
        "94001": "Your car color name has been existed on system. Please input another one.",
        "94002": "Car color not found",
        "400000": "¡El número de teléfono no es válido!",
        "400002": "A validation error has occurred.",
        "400003": "permiso denegado",
        "400005": "contenido del fichero no válido",
        "500000": "Error en sistema",
        "500001": "Por favor, configure SMS Twilio",
        "500002": "No ha sido posible atender la reserva. Por favor inténtelo de nuevo",
        "600001": "Ubicación no encontrada",
        "600002": "Coordenadas de longitud y latitud existentes en el sistema, introduzca otras",
        "610001": "La categoría existía",
        "610002": "Categoría no encontrada",
        "610003": "La categoría existía en la ubicación de la fiesta",
        "700001": "El tipo está en uso",
        "700002": "Socio no encontrado",
        "700003": "Su nombre de tipo de socio ha existido en el sistema.  Por favor ingrese otro.",
        "800000": "This store has been assigned to another merchant account. Please select another one.",
        "800001": "Merchant's bank account can't receive funds.",
        "800002": "Merchant's bank account can't receive funds.",
        "undefined": "Algo salió mal con nuestro servidor, inténtalo de nuevo.",
        "completeBooking": {
            "113": "No podemos autenticar su método de pago.  Elija otro método de pago y vuelva a intentarlo.",
            "450": "The balance is insufficient to pay. Please select another method",
            "451": "The balance is insufficient to pay. Please select another method",
            "521": "La billetera no tiene fondos suficientes."
        },
        "upload_file_fail": "Invalid file, please check and try again.",
        "upload_photo_fail": "Invalid file, please check and try again.",
        "missing_some_field": "Invalid field, please check and try again."
    },
    "statusDisplay": {
        "supplierAccepted": "Supplier accepted",
        "delivery_supplierAccepted": "Supplier accepted",
        "completedWithoutService": "Completed without driver",
        "confirmed": "Reserva confirmada",
        "pending": "Pendiente",
        "queue": "despacho",
        "offered": "despacho",
        "booked": "Conductor en camino",
        "engaged": "Pasajero a bordo",
        "droppedOff": "dejado",
        "pre": "Pendiente",
        "recurrent": "Recurrente",
        "vacant": "",
        "vacantTime": "",
        "arrived": "Llegado y esperando",
        "completed": "Terminado",
        "canceled": "Cancelado",
        "noShow": "no show",
        "action": "Esperando para procesar",
        "incident": "Incidencia",
        "pickupTimeExceeded": "Se acabó el tiempo",
        "canceledByCC": "Cancelado por CC",
        "canceledByPassenger": "Cancelado por el pasajero",
        "canceledByDriver": "Cancelado por el conductor",
        "canceledByPartner": "Cancelado por socio",
        "canceledBymDispatcher": "Cancelado por socio",
        "canceledByCorporateAdmin": "Cancelado por el administrador corporativo",
        "canceledByAPI": "Cancelado por API",
        "canceledByWebBooking": "Cancelado por reserva web",
        "canceledByTimeout": "Cancelado por time out",
        "accepted": "Aceptado",
        "rejected": "Rechazado",
        "canceledByCorpAd": "Cancelado por el administrador corporativo",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "delivering": "Delivering",
        "delivery_booked": "Driver on the way to sender",
        "delivery_arrived": "Arrived & waiting for sender",
        "shopForYou_booked": "Driver on the way to collect cash",
        "shopForYou_arrived": "Arrived & waiting to collect cash",
        "collecting": "Driver on the way to merchant",
        "confirmed_delivery": "Confirmed",
        "food_booked": "Driver on the way to merchant",
        "food_collecting": "Arrived & waiting to collect item",
        "mart_collecting": "Arrived & waiting to collect item",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "canceledByAirlineAdmin": "Canceled by Airline Admin",
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "allocated": "Allocated"
    },
    "tripTypes": {
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred"
    },
    "login": {
        "Username": "Nombre de usuario",
        "Password": "contraseña",
        "Remember_me": "Recuérdame",
        "Forgot_your_password": "Olvidaste tu contraseña",
        "Login": "Acceso"
    },
    "invoice": {
        "createBtn": "Create invoice",
        "searchPlInvoice": "Search client, contact, invoice number",
        "searchPl": "Search bookId, customer name",
        "createInvoice": "Create Invoice",
        "invoicePeriod": "Invoice Period",
        "invoicePeriodNote": "You can select month to month date ranges.",
        "viewEdit": "View/Edit",
        "billingContact": "Billing Contact",
        "memo": "Memo",
        "memoMessage": "Leave message to customer",
        "dueDate": "Due Date",
        "updateInvoice": "Update Invoice",
        "chargeCustomer": "Charge Customer",
        "invoiceNumber": "Invoice Number",
        "noItem": "No. Items",
        "issuedDate": "Issued Date",
        "refId": "Ref #",
        "pickupTime": "Pick Up Time",
        "pickup": "Pick Up Location",
        "destination": "Drop Off Location",
        "customerName": "Customer Name",
        "totalAmount": "Amount Due",
        "amountDue": "Amount Due",
        "sendInvoice": "Send Invoice",
        "copyInvoice": "Copy Invoice",
        "downloadPDF": "Download as PDF",
        "paymentLink": "Payment link",
        "deleteInvoice": "Delete Invoice",
        "onlySave": "Save Invoice",
        "saveSend": "Save & Send Invoice",
        "sendEmail": "Send Email",
        "addRecipients": "Add Recipients",
        "editInvoice": "Edit Invoice",
        "check": "Check",
        "wire": "Wire",
        "digitalWallet": "Digital Wallet",
        "cash": "Cash",
        "completedTime": "Completed Time",
        "Status": "Status",
        "bookingStatus": "Booking Status",
        "chargeBtn": "Charge {0}",
        "pastDue": "Past due",
        "payCompleted": "This invoice has been paid successfully!",
        "Delete": "Delete invoice?",
        "Delete_content": "This action will permanently remove this invoice. If necessary, you can create a new one for these order(s).",
        "viewInvoice": "View Invoice",
        "pending": "Pending",
        "paid": "Paid",
        "CopyLink": "Copy Link"
    },
    "cue": {
        "jobVisibility": "Job Visibility",
        "jobPubic": "Public",
        "jobPrivate": "Private",
        "jobSetPubic": "Set Public",
        "jobSetPrivate": "Set Private",
        "supplierAccepted": "Supplier accepted",
        "creationTime": "Creation time",
        "Pax": "Pax #",
        "Luggage": "Luggage",
        "allocated": "Allocated",
        "completedWithoutService": "Completed without driver",
        "shuttle": "Shuttle",
        "farmIn": "Farm In",
        "chargeCode": "Charge Code",
        "clientCaseMatter": "Trip Description",
        "operatorNote": "Internal Notes",
        "farmOut": "Farm Out",
        "roaming": "Roaming",
        "FlightUpdate": "Flight Update",
        "networkType": "Network type",
        "InHouse": "In-house",
        "carHailing": "Car Hailing",
        "uploadBookings": "Upload Bookings",
        "moreActions": "More Actions",
        "rideSharing": "trayecto compartido",
        "Rearrange": "Rearrange",
        "GroupId": "Group ID #",
        "Active": "Activo",
        "Finished": "Terminado",
        "Home_affiliate": "Inicio / Afiliado",
        "Booking_type": "Tipo de reserva",
        "From": "Desde",
        "balanceWallet": "{0}: {1} remaining",
        "partialWallet": "Partial payment with Wallet",
        "To": "A",
        "Status": "Estado",
        "Migration_Status": "Migration Status",
        "Migrated": "migrated",
        "Select_Status": "Seleccionar Estatus",
        "Operator": "Operador",
        "Car_types": "Tipos de vehículos",
        "Service_class": "Service class",
        "Booking_from": "reserva desde",
        "All": "Todos",
        "Pending": "Pendiente",
        "Dispatching": "despacho",
        "Waiting_for_process": "Esperando para procesar",
        "Confirmed_reservation": "Reserva confirmada",
        "Driver_on_the_way": "Conductor en camino",
        "Arrived_and_waiting": "Llegado y esperando",
        "Passenger_on_board": "Pasajero a bordo",
        "Dropped_off": "dejado",
        "combobox_select": "Seleccione...",
        "Search_here": "Busca aquí",
        "Bookingid": "Reserva #",
        "Pickup_Drop_Off": "Recoger dejar",
        "Pickup_Drop_Off_Crew": "Pickup/Drop Off time",
        "Pickup_Drop_Off_Local_Time": "Operator Time",
        "Pickup_Location_Destination": "Lugar de recogida/ Destino",
        "Passenger": "Pasajero",
        "Intercity_Route": "Intercity route",
        "Airport_Pickup": "Recogida en el aeropuerto",
        "Airport_Pickup_Crew": "Airport",
        "Flight_Status": "Status",
        "Payment": "Pago",
        "Vehicle": "Vehículo",
        "Car_type": "Tipo de carro",
        "Driver": "Conductor",
        "Estimated_Fare": "Tarifa estimada",
        "Notes": "notas",
        "Note": "Nota",
        "Type": "Tipo",
        "Home": "Casa",
        "Affiliate": "Afiliado",
        "now": "Ahora",
        "reservation": "Reserva",
        "recurring": "Recurring (Transport)",
        "No_show": "no show",
        "Canceled_by_CC": "Cancelado por CC",
        "Canceled_by_passenger": "Cancelado por el pasajero",
        "Canceled_by_partner": "Cancelado por socio",
        "Canceled_by_mDispatcher": "Cancelado por socio",
        "Canceled_by_Corporate_Admin": "Cancelado por el administrador corporativo",
        "Canceled_by_API": "Cancelado por API",
        "canceled_By_Web_Booking": "Cancelado por reserva web",
        "canceled_By_Time_Out": "Cancelado por time out",
        "Canceled": "Cancelado",
        "Completed": "Terminado",
        "Incident": "Incidencia",
        "Payment_Method": "Método de pago",
        "No_Phone": "Sin teléfono",
        "VIP": "VIP",
        "Non_VIP": "no VIP",
        "Passenger_type": "Tipo de pasajero",
        "Error_load_booking": "no se ha podido cargar la reserva, inténtelo de nuevo o actualice la página!",
        "Edit_total_success": "¡Total editado con éxito!",
        "Edit_total_fail": "Error al editar el total",
        "Time_out": "Se acabó el tiempo",
        "Ride_Service": "Servicio de viaje",
        "Regulation": "Regulación",
        "Ride_Sharing": "trayecto compartido",
        "Unmatching": "cancelando asignación",
        "Matching": "asignando",
        "all": "Todos",
        "matching": "asignando",
        "unmatching": "cancelando asignación",
        "regulation": "Regulación",
        "Support_service": "servicio de soporte",
        "SOS": "llamada de socorro",
        "Non_SOS": "non-SOS",
        "Corporate": "Clientes empresas",
        "Individual": "Individual",
        "On_curb": "en la acera",
        "Meet_Greet": "Conocer y saludar",
        "Close": "Cerca",
        "auto_refrest_cue": "Auto-refresh _ segundos",
        "Ride_type": "Tipo de trayecto",
        "Edit_total_note": "Notas: subtotal, cargo por tecnología, cargos por reserva, impuestos y propinas serán actualizados cuando se cargue el total del trayecto",
        "Booking": "Reserva",
        "Edit_fare": "editar la tarifa",
        "Adjust_price": "Adjust price",
        "Total": "Total editado satisfactoriamente",
        "Reason": "Motivo",
        "Please_enter_reason": "Introduzca el motivo",
        "Save": "Guardar",
        "Cancel": "Cancelar",
        "dmc": "tablón de reservas",
        "corp": "Panel de reservas (CTM)",
        "Zone": "zona de recogida",
        "mDispatcher": "Colaborador",
        "streetSharing": "Street-sharing",
        "Driver_No_Job_found": "Waiting for assignments...",
        "expandFilter": "mostrar filtros",
        "collapseFilter": "ocultar filtros",
        "clearSelectedItems": "Borrar datos seleccionados",
        "Accepted": "Aceptado",
        "Yes": "Sí",
        "No": "No",
        "Order_accepted": "Order accepted",
        "Confirmed": "Confirmed",
        "spotTime": "Spot Time",
        "ETA": "ETA",
        "spareTime": "Spare Time",
        "expectedTime": "Expected pickup time",
        "Driver_on_the_way_to_sender": "Driver on the way to sender",
        "Driver_on_the_way_to_recipient": "Driver on the way to recipient",
        "Driver_on_the_way_to_collect_cash": "Driver on the way to collect cash",
        "Driver_on_the_way_to_collect_goods": "Driver on the way to collect goods",
        "Arrived_waiting_for_sender": "Arrived & waiting for sender",
        "Arrived_waiting_for_recipient": "Arrived & waiting for recipient",
        "Arrived_waiting_to_collect_cash": "Arrived & waiting to collect cash",
        "Arrived_waiting_to_deliver_goods": "Arrived & waiting to deliver goods",
        "Delivered": "Delivering",
        "delivery": "Delivery",
        "Service": "Service",
        "intercity": "Intercity",
        "Transport": "Transport",
        "About_Sender": "About Sender",
        "About_Merchant": "About Merchant",
        "About_Recipient": "About Recipient",
        "photos": "Photos",
        "listOfItems": "List of item",
        "cashOnPickUp": "COP",
        "Package_information": "Package information",
        "otwMerchant": "Driver on the way to merchant",
        "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
        "Save_Filter": "Guardar Filtro",
        "add_favorite_filter": "Add filter",
        "favorite_filter_name": "Nombre",
        "list_favorite_filter": "Administrar Filtros",
        "favorite_filter_actions": "Acción",
        "clear_all_filters": "Clear all filters",
        "Delete_Filter": "Delete Filter",
        "CONFIRM_DELETE_FILTER_TEMPLATE": "Are you sure you want to delete this filter?",
        "batchDelivery": "Batch Delivery",
        "editFare": {
            "creditTransactionFee": "Credit card fees",
            "preferredAdjustment": "Preferred adjustment",
            "editFareFee": "Edit fare & fees",
            "markupPrice": "Markup price",
            "quotedPrice": "Quoted price",
            "basicFare": "Basic fare",
            "preferredEarning": "Preferred earning",
            "difference": "Difference",
            "rushHourFee": "Surcharge",
            "serviceFee": "Add'l service",
            "otherFees": "Other fees",
            "subTotal": "Sub total",
            "airportFee": "Airport fee",
            "meetDriverFee": "Meet driver",
            "deliveryFee": "Delivery fee",
            "tollFee": "Toll fee",
            "parkingFee": "Parking fee",
            "gasFee": "Gas fee",
            "techFee": "Tech fee",
            "tip": "Tip",
            "bookingFee": "Booking fee",
            "tax": "Tax",
            "promoAmount": "Promo amt",
            "etaFare": "Total",
            "fleetService": "Fleet service",
            "Note_serviceFee": "(to edit additional service, visit outer list)",
            "startingFee": "Starting",
            "moveFeeInJourney": "Moving",
            "waitFeeInJourney": "Waiting (in-journey)"
        },
        "partialCompleted": "Partial Completed",
        "failed": "Failed",
        "Recurring": "Recurring #",
        "DragAndDrop": "Drag and drop to reorder columns.",
        "bookingCom": "Booking.com",
        "syncingCompleted": "Syncing completed",
        "groupManifest": "Group Manifest",
        "assignSuppliers": "Assign Suppliers",
        "viewActions": "View Actions",
        "holidaytaxis": "HolidayTaxis",
        "karhoo": "Karhoo",
        "pickupTime": "Pick-up time",
        "AssignSupplier": "Assign Supplier",
        "sharedTransfer": "Shared Transfer (%{id})",
        "batchDeliveryGroup": "Batch Delivery (%{id})",
        "foundersFund": "Founders Fund",
        "zeroCommission": "Zero Commission",
        "unassignDriver": "Unassign Driver",
        "notUnassignDriver": "Cannot unassign driver, Please try again",
        "notAssignDriver": "Cannot assign driver, Please try again",
        "selectDriver": "Select Driver",
        "unassignVehicle": "Unassign Vehicle",
        "unassignAll": "Unassign All",
        "notUnassignAll": "Cannot unassign all, Please try again",
        "unassignAllSuccess": "The vehicle and driver have been successfully detached.",
        "unassign_all_partial": "Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.",
        "notUnassignVehicle": "Cannot unassign vehicle, Please try again",
        "notAssignVehicle": "Cannot assign vehicle, Please try again",
        "selectVehicle": "Select Vehicle",
        "confirmUnassign": "Are you sure you want to unassign?"
    },
    "trip": {
        "Driver_vehicle": "Driver's vehicle",
        "tripId": "Trip #",
        "tripTypes": "Trip types",
        "Start_Time": "Start time",
        "Seats_Status": "Seats status",
        "No_Of_Booking": "No. of bookings",
        "Vehicle": "Driver's vehicle",
        "Estimated_Fare": "Fare",
        "capacityStatus": "Seat status",
        "Trip_Types": "Trip types",
        "scheduled": "Scheduled trips",
        "requested": "Request trips",
        "preferred": "Preferred",
        "All": "All",
        "Confirmed": "Confirmed",
        "Started": "Started",
        "In_Transit": "In Transit",
        "Empty": "Empty",
        "Partial_not_live": "Partial - not live",
        "Partial_live": "Partial - live",
        "Full": "Full",
        "Error_load_trip": "Cannot load the trip, Please try again or refresh page!"
    },
    "tripDetails": {
        "Trip_ID": "Intercity trip",
        "DRIVER_JOB": "Driver info",
        "BOOKINGS": "Reservaciones",
        "updateTrip": "Actualizar viaje",
        "cancelTrip": "Cancelar viaje",
        "note_cancel": "Please assign all bookings to another driver before cancel this trip.",
        "TRIP_INFORMATION": "Trip Info",
        "route": "Ruta",
        "startTime": "Start Time",
        "distance": "Distance",
        "duration": "Duration",
        "fare": "Fare",
        "cartype": "Intercity cartype",
        "seatStatus": "Seat status",
        "started": "Started",
        "intransit": "In transit",
        "empty": "Empty",
        "notLive": "Partial - not live",
        "live": "Partial - live",
        "full": "Full",
        "noOfBooking": "No. of booking",
        "luggage": "Luggage",
        "nameDriver": "Name",
        "vehicleType": "Vehicle",
        "assignDriver": "Asignar conductor",
        "pickUpAddress": "Levantar",
        "destinationAddress": "Dejar",
        "customerName": "Nombre",
        "customerPhone": "Teléfono",
        "customerNumber": "Asientos",
        "customerLuggageNumber": "Equipaje",
        "additionalService": "Servicios adicionales",
        "paymentBooking": "Pago",
        "note": "Nota",
        "CONFIRM_UPDATE": "confirmar actualización",
        "CONFIRM_UPDATE_MESSAGE": "¿Seguro que quieres actualizar este viaje?",
        "Yes": "sí",
        "No": "No",
        "Update_info": "Sí, Actualizar información"
    },
    "newbooking": {
        "Search_Vehicle": "Search for vehicle",
        "rideSharing_multi_point": "Only 1 pick-up and 1 drop off point are allowed for ride sharing booking.",
        "Pick_up_time": "Pick up time",
        "Drop_off_time": "Drop off time",
        "All_Suppliers": "All suppliers",
        "Recipient": "Recipient",
        "All": "Todos",
        "New_Booking": "Nueva reserva",
        "GoogleMapRoute": "Google Map",
        "BOOKING_INFORMATION": "Información de reserva",
        "TIP_PROMO": "Sugerencia - Promoción",
        "Pick_up": "Levantar",
        "instructions": "Instrucciones",
        "instructions_placeholder": "Enumere sus instrucciones aquí...",
        "Enter_a_location": "Introduce una ubicación",
        "Enter_a_location_delivery": "Introduce una ubicación",
        "Enter_3rd_party_location": "Introduce la ubicación de un tercero",
        "Enter_a_sender": "Introduce un remitente",
        "Enter_a_recipient": "Ingrese un Destinatario",
        "Vehicle_Selection": "Vehicle Selection",
        "Destination": "Destino",
        "Pickup_time": "Hora de recogida",
        "Note": "Notas",
        "note_for_driver": "Notas al conductor",
        "affiliate_note": "Note for affiliate",
        "affiliate_note_placeholder": "Enter affiliate notes for operators",
        "operator_note": "Enter internal notes for operators",
        "TRAVELER_INFORMATION": "Información del viajero",
        "Traveler_type": "tipo de viajero",
        "Individual": "Individual",
        "Corporation": "Corporación",
        "Phone_number": "Número de teléfono",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "Email_address": "Dirección de correo electrónico",
        "Corp_id": "ID de corporación",
        "Manager_name": "El nombre del gerente",
        "Manager_email": "correo electrónico del gerente",
        "Cost_centre": "centro de costo",
        "Department": "Departamento",
        "Corp_division": "división de corporaciones",
        "Tip": "Propina",
        "Promo_code": "Código promocional",
        "Apply": "Solicitar",
        "Promo": "Promoción",
        "DISPATCH": "Asignar",
        "Auto_dispatch": "Despacho automático",
        "Assign_driver": "Asignar conductor",
        "FarmOut": "Farm-out",
        "Driver": "Conductor",
        "Driver_Supplier": "Supplier/Driver",
        "PAYMENT_METHOD": "Método de pago",
        "OPERATOR_NOTE": "Internal Notes",
        "TRIP_ESTIMATE": "Estimación de viaje",
        "Choose_a_stored_card": "Elegir una tarjeta guardada",
        "Choose_a_payment_method": "Elegir un método de pago",
        "Use_new_card": "Usar tarjeta nueva",
        "Card_holder": "titular de la tarjeta",
        "Card_number": "Número de tarjeta",
        "Expired_date": "Fecha de caducidad (mm/aa)",
        "ccv": "CVV",
        "Distance": "Distancia",
        "Duration": "Duración",
        "Fare": "Tarifa",
        "Route": "Ruta",
        "Create": "Crear",
        "Cancel": "Cancelar",
        "Meet_driver": "Conozca al conductor",
        "Airline": "Aerolínea",
        "Flight": "Vuelo #",
        "FLY_INFO": "Informacion de vuelo",
        "Ride_Sharing": "Compartir viaje",
        "Round_trip": "Viaje ida y vuelta",
        "Car_type": "Tipo de carro",
        "Address": "Habla a",
        "City": "Ciudad",
        "State": "Expresar",
        "Zip_code": "Código postal",
        "Remove": "Eliminar",
        "Client_case_matter": "asunto del caso del cliente",
        "Charge_code": "Código de carga",
        "CANCEL_BOOKING": "Cerrar Reserva Confirmar",
        "CANCEL_BOOKING_CONFIRM": "¿Quieres salir de este formulario?  Perderá todos los datos de la reserva.",
        "Search_here": "Busca aquí",
        "No_support_location": "Desafortunadamente, el servicio no está disponible en esta área.",
        "Closest_driver_message": "El conductor más cercano está aproximadamente a {0} de distancia",
        "No_cars_available": "No hay auto disponible dentro del radio de despacho.",
        "processed_by_affiliate": "Sus solicitudes serán procesadas por nuestros socios afiliados",
        "FlightExample": "Número de vuelo, por ejemplo, AA1097",
        "Yes": "sí",
        "No": "No",
        "dispatch_3rd": "Asignado a un tercero",
        "Company": "Empresa",
        "Card_required": "¡Seleccione o agregue una tarjeta!",
        "Tip_value": "No aplica",
        "Calendar": "Calendario",
        "Extra_Destination": "destino adicional",
        "Driver_credit_limited_balance_warning": "El saldo del conductor es menor o igual que la cantidad limitada",
        "Meet_Greet_option": "Conocer y saludar",
        "On_Curb_option": "en la acera",
        "Extra_Services": "Servicios adicionales",
        "Addl_Services": "Servicios adicionales",
        "Save": "Ahorrar",
        "Regular": "Regular",
        "Please_fill_in_all_the_required_fields": "Por favor complete los campos requeridos",
        "Please_select_weekdays": "Please select the repeat on days you want this booking to occur",
        "Flat_rate": "Tarifa fija",
        "All_company": "toda la compañía",
        "Best_price": "Mejor precio",
        "PromoTitle": "Promoción",
        "Show_more": "Mostrar más",
        "Warning_pending_payment": "This customer has incomplete payment from past bookings: %{fare}",
        "Warning_outstanding_balance": "Outstanding balance will be applied at the end of the trip.",
        "Not_available": "No disponible",
        "Warning_assigned_rate": "Asignar tarifa al tipo de coche elegido para continuar",
        "PaxLuggageNumber": "Número de pasajero/equipaje",
        "paxNumber": "Pasajero",
        "luggageNumber": "Equipaje",
        "Date": "Date",
        "Single": "Single",
        "Recurring": "Periódico",
        "Date_range": "Date Range",
        "WeekDays": "Repeat on days",
        "Create_Route": "Create Route",
        "Pickup_Point": "Levantar",
        "Drag_To_Render": "Drag and drop to reorder",
        "DropOff_Point": "Drop off points",
        "Sender": "Remitente",
        "Recipients": "Recipients",
        "Sender_Infomation": "Sender Information",
        "Recipient_Infomation": "Recipient Information",
        "Recipient_Name": "Recipient name *",
        "Recipient_Phone": "Phone Number *",
        "Room_floor_block": "Room, floor, and block (optional)",
        "Details": "Details",
        "Package_Content": "Package Content *",
        "Order_ID": "Order # (eg. shopify order #)",
        "Instructions_for_driver": "Instructions for driver",
        "Cost_of_Goods": "Cash on Delivery (COD)",
        "Enter_amount": "Enter amount",
        "Additional_Information": "Additional Information",
        "Insurance": "Insurance",
        "Special_Care": "Special Care",
        "Delivery_Fee": "Delivery Fee",
        "Item_fees": "Item fees",
        "Tech_Fee": "Tech Fee",
        "Tax": "Tax",
        "driverNotes": "Driver Notes",
        "rejectModal": "Reject booking",
        "rejectModalBody": "Are you sure you want to reject this booking?",
        "rejectButton": "Reject booking",
        "acceptModal": "Accept booking",
        "acceptModalBody": "Are you sure you want to Accept this booking?",
        "ConfirmedTo": "Confirmed to ({0})",
        "proceedAction": "Are you sure you want to proceed with this action?",
        "acceptButton": "Accept booking",
        "denyModal": "Deny booking",
        "denyModalBody": "Are you sure you want to deny this booking?",
        "denyButton": "Deny booking",
        "Accept_booking_success": "This booking has been accepted successfully!",
        "Deny_booking_success": "This booking has been denied successfully!",
        "adjustFare": "Adjust fare",
        "totalFare": "Total Fare",
        "Profit": "Profit",
        "DriverEarning": "Driver Earning",
        "Payout": "Payout",
        "totalPayout": "Total payout",
        "DriverPayOut": "Driver Payout",
        "confirmReject": "Confirm Rejection",
        "confirmCancelMSG": "Are you sure you want to reject this booking? You may incur a penalty from your provider.",
        "reasonCancel3rd": "Reason for rejection",
        "welcome_psg": "Welcome passenger",
        "AmountDue": "Amount Due",
        "Fleet_Company": "Fleet",
        "Search_driver_hydra": "We will try to find you a driver soon.",
        "ExternalID": "External ID",
        "viewRate": "View Rate",
        "PointToPoint": "Point to point",
        "Offer_driver": "Offer Driver",
        "Assign_driver_vehicle": "Assign Driver / Vehicle",
        "notify_via_email": "Notify Customer via Email"
    },
    "bookingDetailLogs": {
        "recipient": "Recipient",
        "updatedInfo": "Updated info",
        "updatedField": "Updated field",
        "valueBefore": "Value before",
        "valueAfter": "Value after",
        "location": "Location",
        "etaFare": "Fare",
        "dispatchType": "Dispatch",
        "drvInfo": "Driver",
        "vehicleTypeRequest": "Vehicle Request",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "driverEarningType": "Driver Earning Type",
        "editedDriverEarning": "Driver earning",
        "reasonEditFare": "Reason",
        "bookingReference": "External ID",
        "tip": "Tip",
        "type": "Service type",
        "promo": "Promo",
        "dispatch3rd": "Dispatch 3rd",
        "rideSharing": "Ride-Sharing",
        "notes": "Driver notes",
        "operatorNote": "Internal notes",
        "welcomeMessage": "Welcome Message",
        "flightNumber": "Flight Number",
        "fullName": "Full Name",
        "email": "Email",
        "cardMask": "Card mask",
        "paymentType": "Payment method",
        "recipients": "Recipients",
        "sender": "Sender",
        "pickupName": "Pickup Name",
        "pickupPhone": "Pickup Phone",
        "pickupEmail": "Pickup Email",
        "supplierEarningType": "Payout type",
        "editedSupplierEarning": "Payout value",
        "route": "Route name",
        "markupDifference": "Markup Difference",
        "selectedDates": "Selected Dates",
        "services": "Fleet services",
        "destinationAddress": "Destination location",
        "pickupAddress": "Pick-up location",
        "tripType": "Trip Type",
        "pickUpTime": "Pickup time",
        "paxNumber": "Passenger",
        "luggageNumber": "Luggage",
        "vehicle": "Vehicle",
        "minutesToArrive": "Expected driver arrival",
        "affiliateNote": "Affiliate Note",
        "packageRateName": "Package name",
        "supplierCompanies": "Company",
        "puPoints": "Pick-up location",
        "doPoints": "Destination location",
        "promoValue": "Promo value",
        "packageInfo": "Package info",
        "orderId": "Order Id",
        "cashOnDelivery": "Cash on delivery",
        "externalId": "External Id",
        "recurring": "Recurring",
        "batchDelivery": "Batch Delivery",
        "payout": "Payout",
        "locationDetails": "Location Details",
        "name": "Name",
        "phone": "Phone",
        "address": "Address",
        "basicFare": "Basic fare",
        "airportFee": "Airport fee",
        "bookingFee": "Booking fee",
        "creditTransactionFee": "Credit card fees",
        "fleetService": "Fleet service",
        "fleetServices": "Fleet services",
        "gasFee": "Gas fee",
        "meetDriverFee": "Meet driver fee",
        "moveFeeInJourney": "Moving fee",
        "otherFees": "Other fee",
        "parkingFee": "Parking fee",
        "promoAmount": "Promo amt",
        "rushHourFee": "Rush hour fee",
        "serviceFee": "Service fee",
        "startingFee": "Starting fee",
        "subTotal": "Sub total",
        "tax": "Tax",
        "techFee": "Tech fee",
        "tollFee": "Toll fee",
        "waitFeeInJourney": "Waiting (in-journey) fee"
    },
    "bookingdetail": {
        "settings": "Settings",
        "updateSuccessJobVisibilitySingle": "Update visibility successfully",
        "updateSuccessJobVisibilityMulti": "%{number} bookings has been updated successfully",
        "failApi": "Something went wrong!",
        "settingsTooltip": "Driver already assigned or trip is currently in progress.",
        "shareJobPublicly": "Share Job Publicly",
        "groupOder": "Group order",
        "ExistingGroup": "Existing Group",
        "NewGroup": "New Group",
        "Search_trip": "Search trip #",
        "All": "Todos",
        "creditTransactionFee": "Credit card fees",
        "partial_payment": "Partial Payment",
        "Write_off_debt": "Write off debt",
        "Booking_detail": "Reserva",
        "Order_detail": "Pedido",
        "Completed_Booking": "Completed Booking",
        "Late_Cancel": "Late cancel",
        "Cancel_Driver": "Cancel by driver",
        "No_Show": "No show",
        "Without_driver": "Complete without driver",
        "Complete_driver": "Complete with driver",
        "BOOKING_INFORMATION": "Información de la reserva",
        "TIP_PROMO": "Sugerencia - Promoción",
        "Rearrange_Column": "Rearrange Column",
        "Pick_up": "Levantar",
        "Enter_a_location": "Introduce una ubicación",
        "Destination": "Destino",
        "Pickup_time": "Hora de recogida",
        "Notes": "notas",
        "TRAVELER_INFORMATION": "Información del viajero",
        "Traveler_type": "tipo de viajero",
        "Individual": "Individual",
        "Corporation": "Corporación",
        "Phone_number": "Número de teléfono",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "Email_address": "Dirección de correo electrónico",
        "Corp_id": "ID de corporación",
        "Manager_name": "El nombre del gerente",
        "Manager_email": "correo electrónico del gerente",
        "Cost_centre": "centro de costo",
        "Department": "Departamento",
        "Corp_division": "división de corporaciones",
        "Tip": "Propina",
        "Promo_code": "Código promocional",
        "Apply": "Solicitar",
        "Promo": "Promoción",
        "DISPATCH": "Asignar",
        "Auto_dispatch": "Despacho automático",
        "Assign_driver": "Asignar conductor",
        "Driver": "Conductor",
        "PAYMENT_METHOD": "Método de pago",
        "Payment_methods": "Métodos de pago",
        "pending_payment": "Pago pendiente",
        "TRIP_ESTIMATE": "Estimación de viaje",
        "Choose_a_stored_card": "Elegir una tarjeta guardada",
        "Choose_a_payment_method": "Elegir un método de pago",
        "OPERATOR_NOTE": "Internal Notes",
        "Use_new_card": "Usar tarjeta nueva",
        "Card_holder": "titular de la tarjeta",
        "Card_number": "Número de tarjeta",
        "Expired_date": "Fecha de caducidad (mm/aa)",
        "ccv": "CVV",
        "Distance": "Distancia",
        "Duration": "Duración",
        "Fare": "Tarifa",
        "Route": "Ruta",
        "Meet_driver": "Conozca al conductor",
        "Airline": "Aerolínea",
        "Flight": "Vuelo #",
        "FLY_INFO": "Informacion de vuelo",
        "Ride_Sharing": "Compartir viaje",
        "Round_trip": "Viaje ida y vuelta",
        "Car_type": "Tipo de carro",
        "Address": "Habla a",
        "City": "Ciudad",
        "State": "Expresar",
        "Zip_code": "Código postal",
        "Remove": "Eliminar",
        "New_Booking": "Nueva reserva",
        "Clone_Booking": "Clone Booking",
        "New_Order": "New Order",
        "ViewTrackLink": "View track link",
        "driverLink": "Driver link",
        "ClickCopy": "Click to copy link",
        "ViewLog": "View dispatch logs",
        "Message": "Message",
        "Update_Booking": "Actualizar reserva",
        "Cancel_Booking": "Cancelar reserva",
        "Complete_with_payment": "Completar con pago",
        "Incident": "Incidencia",
        "CONFIRM_CANCEL": "confirmar cancelar",
        "CONFIRM_CANCEL_MESSAGE": "Haga clic en 'Aceptar' para cancelar esta reserva.  Si no desea, haga clic en 'Cancelar' para cerrar el cuadro de diálogo",
        "CONFIRM_UPDATE": "confirmar actualización",
        "CONFIRM_UPDATE_MESSAGE": "¿Está seguro de que desea actualizar esta reserva?",
        "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Your edited fare has been reset, please review before continue.",
        "Close": "Cerca",
        "Update_info": "Actualizar información",
        "Update_info_dispatch": "Actualizar información y envío",
        "Update_info_tooltip": "Si va a reasignar esta reserva a un nuevo controlador, elija \"Sí, Actualizar información y envío\"",
        "BOOKING": "Reserva",
        "DROPPED_OFF": "dejado",
        "Payment_Type": "Tipo de pago",
        "Total": "Total editado satisfactoriamente",
        "Next": "próximo",
        "Pay_here": "Pague aquí",
        "Complete_booking": "Reserva completa",
        "Complete_booking_tooltip": "Haga clic en \"Actualizar reserva\" para guardar los cambios antes de continuar.",
        "Cancel": "Cancelar",
        "Back": "atrás",
        "Tax": "Impuesto",
        "Booking_fees": "Costos de reserva",
        "Tech_fees": "Tarifas tecnológicas",
        "Subtotal": "Total parcial",
        "Other_fees": "Otros cargos",
        "Waiting_fee": "Waiting fee",
        "Toll_fees": "Suplemento por peaje",
        "Heavy_traffic": "Tráfico pesado",
        "Rush_hour": "Recargo",
        "Basic_fare": "Tarifa básica",
        "Ok": "OK",
        "Input_reason": "Motivo de entrada",
        "Client_case_matter": "Código interno del cliente",
        "Charge_code": "Código de cargo",
        "Enter_3rd_party_location": "Introduzca la ubicación del otro pasajero",
        "Company": "Empresa",
        "Check_promo_error": "Revise el error del código promocional",
        "Add_new_credit_error": "Añadir nuevo descuento",
        "Can_not_get_fare_setting": "No se ha podido obtener configuración de tarifas",
        "Can_not_get_affilate_fare_setting": "No se ha podido obtener configuración de tarifas de colaborador",
        "Can_not_check_commisson": "No se ha podido comprobar la comisión",
        "No_flight_info": "Información de vuelo no disponible",
        "Socket_server_disconnected": "Conector del servidor desconectado",
        "Please_reload_page_or_check_your_connection": "Lo sentimos, su petición no ha podido ser procesada. Por favor inténtelo de nuevo",
        "View_Logs": "Visualizar registros",
        "Bookingid": "Reserva #",
        "Calendar": "Calendario",
        "Extra_Destination": "destino adicional",
        "Meet_Greet_option": "Recogida con cartel",
        "On_Curb_option": "Recogida a pie de calle",
        "Credit_Card": "Tarjeta de crédito",
        "Charge": "Cargo",
        "No_charge": "Sin cargo",
        "QR_Code_method_not_allow_error_message": "Seleccionar otro método de pago. Código QR no disponible",
        "change_payment_method_note": "Si desea cambiar el método de pago, por favor vuelva atrás al formulario de reserva",
        "Airport_Fee": "Tarifa Aeropuerto",
        "Addi_service": "Servicios adicionales",
        "Additional_Services": "Servicios adicionales",
        "Minimum": "Mínimo",
        "Search_here": "Busca aquí",
        "No_Driver_Yet": "No driver assigned yet",
        "Personal_Card": "Tarjeta personal",
        "Reject_booking": "Rechazar reserva",
        "Confirm_reject": "Confirmar la cancelación",
        "Confirm_reject_message": "¿Está seguro de rechazar la reserva?",
        "Confirm_reject_booking_with_charge": "Cancelar la reserva supone un cargo de _% de la tarifa establecida según nuestra política de cancelaciones. ¿Está seguro de querer cancelar la reserva?",
        "Yes": "Sí",
        "No": "No",
        "shortTrip": "Viaje corto",
        "Add_new_card": "Agregar nueva tarjeta",
        "Require_customer_info": "Introducir la información del cliente para continuar",
        "confirm_force_overlap": "Confirmation",
        "confirm_force_overlap_message": "This booking overlaps with driver's accepted bookings. Are you sure you want to assign it to this driver?",
        "select": "Select",
        "PICKUP_TIME_RANGE": "Estimated pickup at {0}",
        "food": "Food",
        "mart": "Mart",
        "Order_summary": "Order Summary",
        "addOnPrice": "Adjusted price",
        "applicableFee": "Total (applicable)",
        "itemValue": "Item value",
        "promotion_note_paymentMethod": "This order is being applied promotion code with limited payment method: %{method}.",
        "promotion_note_minimum_fare": "This order is being applied promotion code with limited minimum fare: %{fare}.",
        "promotion_note_schedules": "This order is being applied promotion code with limited pickup time.",
        "edited_fare_note": "This is fare adjustment logs for reference only. Please visit reports to check final fare and fee.",
        "view_good_receipt": "View goods receipt",
        "confirmRemoveGroupTransport": "Are you sure you want to remove current booking from this manifest?",
        "confirmRemoveGroupDelivery": "Are you sure you want to remove current booking from this group?",
        "confirmUngroupTransport": "Are you sure you want to ungroup all bookings from this manifest?",
        "confirmUngroupDelivery": "Are you sure you want to ungroup all bookings from this group?",
        "view_delivery_receipt": "View delivery photo",
        "select_time": "Seleccionar hora",
        "InputReason": "Input reason",
        "viewOrder": "View order",
        "markFailed": "Mark as failed",
        "corporateName": "Corporate Name",
        "sendMessage": "Send Message",
        "AmountDueBefore": "Amount Due Before",
        "AmountDueAfter": "Amount Due After",
        "chargeCustomer": "Charge Customer",
        "pending": "Not Paid",
        "refundPayment": "Refund Payment",
        "refundableAmount": "Refundable amount",
        "refundReason": "Add refund reason",
        "refundBtn": "Refund {0}",
        "partial": "Partial",
        "paid": "Paid",
        "ViewPaymentLogs": "View payment logs",
        "pmActivity": "Payments Activity",
        "paidStatus": "Paid status",
        "paymentLog": "Payment Log",
        "extraWaitFee": "Waiting fee Initial",
        "extraWaitTime": "Wait time (Initial)",
        "copied": "Copied",
        "warnningCancelCorporate": "Please note, you may incur a charge of amount in accordance with our cancellation policy."
    },
    "map": {
        "Enter_a_location": "Introduce una ubicación",
        "All_company": "toda la compañía",
        "All_cars": "Todos los carros",
        "Show_all_cars": "Mostrar todos los vehículos",
        "Hide_all_cars": "Ocultar todos los vehículos",
        "Vacant": "Libre",
        "Dispatching": "despacho",
        "Driver_on_the_way": "Conductor en camino",
        "Arrived_and_waiting": "Llegado y esperando",
        "P_O_B": "Pasajero a bordo",
        "Dropped_off": "dejado",
        "Waiting_for_process": "Esperando para procesar",
        "Unavailable": "Indisponible",
        "Total_Drivers": "Total conductores",
        "Estimate": "Estimar",
        "Destination": "Destino",
        "Pickup": "Levantar",
        "Start_point": "Punto de origen",
        "Passenger": "Pasajero",
        "Driver_name": "Nombre del conductor",
        "Search_car_here": "Buscar coche aquí",
        "Error_load_booking": "no se ha podido cargar la reserva, inténtelo de nuevo o actualice la página!",
        "Show_all": "Mostrar todo",
        "driversLiveMap": "Mapa de conductores",
        "heatMap": "Mapa de demanda",
        "Trip": "Viaje",
        "traffic": "Traffic",
        "bicyclingLayers": "Bicycling",
        "layer": "Layer",
        "trafficBicycling": "Traffic,Bicycling",
        "All_airline": "All airline"
    },
    "customer": {
        "Customers": "Clientes",
        "Points": "Puntos",
        "Migration_Status": "Migration Status",
        "ADD_CUSTOMER": "agregar cliente",
        "EDIT_CUSTOMER": "Editar cliente",
        "Add": "Agregar",
        "Activate": "Activar",
        "Deactivate": "Desactivar",
        "Delete": "Borrar",
        "Import": "Importar",
        "Download_template": "Descargar plantilla",
        "View_Message": "Mensaje",
        "Send_Message": "Enviar mensaje",
        "Export_to_excel": "Exportar a Excel",
        "Filter": "Filtro",
        "Apply": "Aplicar",
        "Clear": "Limpiar",
        "Name": "Nombre",
        "Phone_Number": "Número de teléfono",
        "Email": "Correo electrónico",
        "Vip": "VIP",
        "companies": "Empresa",
        "File": "Archivo",
        "Number_successful_imports": "Número de importaciones exitosas",
        "Number_skipped": "Número de omitidos",
        "Number_duplicate_phone_numbers": "Número de números de teléfono duplicados",
        "Number_wrong_phone_numbers": "Número de números de teléfono incorrectos",
        "Corporate_Name": "Empresa",
        "Registered_From": "Registrado desde",
        "Registration_Date": "Fecha de Registro",
        "Registration_date_from": "Fecha de registro desde",
        "Registration_date_to": "Fecha de registro a",
        "IOS_Last_Login_Version": "Versión de último inicio de sesión de IOS",
        "Android_Last_Login_Version": "Versión de último inicio de sesión de Android",
        "Last_Active_Date": "Última fecha activa",
        "Verification": "Verificación",
        "Support_ID": "Support ID",
        "Days_Since_Last_Active": "Días desde la última conexión",
        "Completed_Bookings": "Reservas Completadas",
        "Outstanding_amount": "monto pendiente",
        "Status": "Estado",
        "Actions": "Acciones",
        "Action": "Acción",
        "Inactive": "Inactivo",
        "Active": "Activo",
        "Edit": "Editar",
        "View": "Vista",
        "ALERT_SELECT_ACTIVATE": "Seleccione el cliente para activar.",
        "ALERT_SELECT_DEACTIVATE": "Seleccione el cliente para desactivar.",
        "ALERT_SELECT_DELETE": "Seleccione el cliente para eliminar.",
        "CONFIRM_DELETE": "¿Está seguro de que desea eliminar el cliente seleccionado?",
        "CONFIRM_DELETE_MULTI": "¿Está seguro de que desea eliminar estos clientes?  Solo se pueden eliminar los clientes que están inactivos y que no son usuarios registrados a través de la aplicación.",
        "CONFIRM_DEACTIVATE_MULTI": "¿Quieres desactivar estos clientes?",
        "CONFIRM_ACTIVATE_MULTI": "¿Quieres activar estos clientes?",
        "CONFIRM_EXPORT_TO_EXCEL": "¿Desea exportar toda la información a un archivo .xlsx?",
        "Add_card_top_up_success": "Tarjeta prepago ha sido recargada satisfactoriamente",
        "Add_card_success": "¡La tarjeta ha sido añadida con éxito!",
        "Add_customer_success": "Se ha creado satisfactoriamente el nuevo cliente",
        "Update_customer_success": "El cliente ha sido actualizado satisfactoriamente",
        "Deleted_customer_success": "El cliente ha sido eliminado satisfactoriamente",
        "Create_customer_fail": "Fallo al crear cliente",
        "Update_customer_fail": "No se pudo actualizar el cliente",
        "ERROR_EXISTED_PHONE": "Este teléfono ha existido en el sistema.  Por favor ingrese otro",
        "ERROR_INPUT_FIRSTNAME": "Por favor ingrese el primer nombre.",
        "ERROR_INPUT_PHONE": "Por favor ingrese el número de teléfono.",
        "ERROR_INPUT_VALIDPHONE": "Por favor ingrese un número de teléfono válido.",
        "ERROR_INPUT_VALIDEMAIL": "Por favor, introduce una dirección de correo electrónico válida.",
        "CONFIRM_DELETE_CARD": "¿Está seguro de que desea eliminar estas tarjetas?",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "Phone_number": "Número de teléfono",
        "Email_address": "Dirección de correo electrónico",
        "Notes": "notas",
        "Account": "Cuenta",
        "Card_management": "Gestión de tarjetas",
        "Home": "Casa",
        "Affiliate": "Afiliado",
        "SHOW_PERSONAL_CARD": "Mostrar tarjeta personal",
        "ADD_PERSONAL_CARD": "Añadir tarjeta personal",
        "SHOW_mDISPATCHER_CARD": "Mostrar tarjeta de socio",
        "ADD_mDISPATCHER_CARD": "Agregar tarjeta de socio",
        "Personal_card": "Personal card",
        "mDispatcher_card": "tarjeta de socio",
        "Card_holder": "titular de la tarjeta",
        "Card_number": "Número de tarjeta",
        "Credit_type": "tipo de crédito",
        "Credit_code": "código de crédito",
        "Sms_verification": "verificación de mensajes de texto",
        "Sms_verification_placeholder": "Introduce el código de verificación",
        "Expired_date": "Fecha de vencimiento",
        "CVV": "CVV",
        "Address": "Habla a",
        "Country": "País",
        "City": "Ciudad",
        "State": "Estado",
        "Zip_code": "Código postal",
        "postal_code": "Postal code",
        "Required_fields": "Campos requeridos",
        "Cancel": "Cancelar",
        "Save": "Ahorrar",
        "ERROR_INPUT_CARD_HOLDER": "Ingrese el titular de la tarjeta",
        "ERROR_INPUT_CARD_NUMBER": "Por favor ingrese número de tarjeta",
        "ERROR_INPUT_EXPIRED_DATE": "Ingrese la fecha de caducidad",
        "ERROR_VAILD_EXPIRED_DATE": "Fecha de caducidad no es válida",
        "ERROR_INPUT_CVV": "Por favor ingrese CVV",
        "ERROR_INPUT_ADDRESS": "Por favor ingrese la dirección",
        "ERROR_INPUT_CITY": "Por favor ingrese la ciudad",
        "ERROR_INPUT_ZIPCODE": "Por favor ingrese el código postal",
        "ERROR_INPUT_COUNTRY": "Introducir país",
        "Info_edit_error": "¡Error!",
        "Gender": "Sexo",
        "Select_Gender": "Seleccione",
        "Select_Idtype": "Select",
        "Male": "Masculino",
        "Female": "Mujer",
        "Other": "Otro",
        "Date_of_birth": "Fecha de nacimiento",
        "Street_address": "Calle",
        "Select_Zone": "Zona",
        "ERROR_GET_GATEWAYS": "ERROR !!!  Obtener puertas de enlace",
        "Require_email": "Introduzca un email antes de agregar una nueva tarjeta",
        "Require_lastName": "Introduzca el apellido antes de agregar una nueva tarjeta",
        "Require_phone_number": "Introduzca el teléfono del pasajero antes de realizar una reserva con tarjeta de crédito",
        "IMPORT_CUSTOMER_SUCCESS": "Clientes importados satisfactoriamente",
        "No_card": "No se encontró ninguna tarjeta",
        "No_Support": "La nueva tarjeta introducidad no es válida en esta zona",
        "Card_Management": "Gestión de tarjetas",
        "Emergency_contacts": "Contactos de emergencia",
        "DETAIL_CUSTOMER": "Datos del cliente",
        "Select_Country": "Seleccionar país",
        "Customer_ID": "Documento de Identidad",
        "Credit_balance": "Balance de crédito",
        "required_email_payment": "Se requiere el correo electrónico del cliente.  Por favor, actualice el perfil para continuar.",
        "Yes": "Yes",
        "No": "No",
        "WriteOffDebt": "Cancelar deuda",
        "selectPayment": "Seleccione un método de pago para cerrar todas las reservas que tengan un pago pendiente",
        "writeOffDebtSuccess": "Cancelar la deuda con éxito",
        "writeOffDebtNotes": "Nota: Esta acción de cancelación solo debe confirmarse después de que todo el monto pendiente se haya pagado en su totalidad.",
        "totalCustomer": "Nombre total de clients",
        "Personal_Card": "Tarjeta personal",
        "INFO_ADD_BALANCE_SUCCESS": "Passenger balance has been updated successfully",
        "INFO_UPDATE_POINT_SUCCESS": "Passenger point has been updated successfully",
        "currency": "Currency",
        "balance": "Balance",
        "changeBalance": "Change balance",
        "change": "Change",
        "WALLET_DEPOSIT": "Wallet",
        "AccessDenied": "You don't have permission to access this module!",
        "referral_code": "Referral code"
    },
    "importBookings": {
        "no": "No.",
        "status": "Status",
        "pickupDate": "Pickup Date",
        "pickup": "Pickup Address",
        "dropoff": "Dropoff Address",
        "passenger": "Passenger",
        "phone": "Phone number",
        "seats": "Seats",
        "luggages": "Luggages",
        "rideSharing": "Ride-share",
        "flightNumber": "Flight No.",
        "notes": "Notes",
        "externalId": "External ID",
        "operatorNote": "Internal notes",
        "clientCaseMatter": "Trip description",
        "chargeCode": "Trip code",
        "paymentType": "Payment",
        "vehicleTypeRequest": "Car type",
        "importBookings": "Import bookings",
        "corporation": "Corporation",
        "clickOrDropCsvFile": "Click or drop.csv file",
        "importingFileForReview": "Importing file for review... ",
        "import": "Import",
        "submit": "Submit",
        "downloadTemplate": "Download template",
        "learnMore": "Learn more",
        "total": "Total",
        "valid": "Valid",
        "invalid": "Invalid",
        "failed": "Failed",
        "processing": "Processing",
        "success": "Success",
        "confirm": "Confirm",
        "confirmMessage": "orders will be created. Are you sure you want to proceed ?",
        "create": "Create",
        "cancel": "Cancel",
        "close": "Close",
        "leavingConfirmMessage": "Your work is not saved! Are you sure you want to leave?",
        "resultMessage": "${success} booking(s) has been created successfully, ${failed} failed.",
        "limitExceededBookings": "Maximum import file size: 100 bookings",
        "delivery": {
            "no": "#",
            "pickupDate": "Pickup date",
            "senderName": "Sender name",
            "senderPhone": "Sender phone",
            "senderMail": "Sender email",
            "senderAddress": "Sender address",
            "recipients": "Recipients",
            "senderDistrictOptional": "District",
            "senderLocationDetails": "Location details",
            "recipientName": "Recipient name",
            "recipientPhone": "Recipient phone",
            "recipientAddress": "Recipient address",
            "recipientDistrictOptional": "District",
            "recipientLocationDetailsOptional": "Location Details",
            "packageContent": "Package content",
            "orderNumber": "Order #",
            "instructions": "Instructions for Driver",
            "cod": "COD Item Value",
            "rideShare": "Ride-share",
            "paymentType": "Payment type",
            "carType": "Car type",
            "recipientLocationDetails": "Location details",
            "operatorNote": "Internal notes",
            "rideSharing": "Batch delivery"
        }
    },
    "merchant": {
        "INFO_ADD_BALANCE_SUCCESS": "Merchant balance has been updated successfully",
        "ADD_MERCHANT_USER": "Add merchant account",
        "EDIT_MERCHANT_USER": "Edit merchant account",
        "MERCHANT_USER_DETAILS": "Merchant account details",
        "CASH_WALLET": "Cash wallet",
        "CREDIT_WALLET": "Credit wallet",
        "ADD_MERCHANT": "Add merchant",
        "ACCOUNT_INFO": "Account Info",
        "BILLING_ADDRESS": "Bank Information",
        "user": {
            "fullName": "Merchant user",
            "Phone_Number": "Phone number",
            "Email": "Email",
            "storeName": "Merchant",
            "status": "Status",
            "actions": "Action",
            "First_name": "First name",
            "Last_name": "Last name",
            "Select_store": "Select merchant",
            "accountHolder": "Account holder's name",
            "First_M_Last": "First M. Last",
            "accountNumber": "Account number",
            "ibanNumber": "IBAN number",
            "bankName": "Name of bank",
            "IDtype": "ID Type",
            "IDnumber": "ID# / National IC#",
            "routingNumber": "Routing/Transit/Branch number",
            "checkNumber": "Check number",
            "ssn": "SSN/NI",
            "sortCode": "Sort code",
            "transitNumber": "Transit number",
            "institutionNumber": "Institution Number",
            "birthDay": "Birthday",
            "address": "Address",
            "city": "City",
            "state": "State",
            "postalCode": "Zipcode",
            "beneficiaryIDIC": "Beneficiary ID# / IC#",
            "relationshipOtherName": "Relationship with other?",
            "verificationDocumentFront": "Verification document (front)",
            "verificationDocumentBack": "Verification document (back)",
            "Verification_document_tooltip": "Acceptable forms for identification: \n  - Passport \n  - Driver license \n  - Resident permit ID \n  - Citizen Card \n  - ID card",
            "additionalDocumentFront": "Additional document (front)",
            "additionalDocumentBack": "Additional document (back)",
            "Additional_document_tooltip": "Acceptable forms of address verification: \n  - Driver license \n  - Utility bill \n  - Government issued letter \n  - Statement from a financial institution \n  - Electoral Register entry",
            "please_select": "Please select",
            "commission": "Commission",
            "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
            "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
            "Activate": "Activate",
            "Deactivate": "Deactivate",
            "ALERT_SELECT_ACTIVATE": "Please select user to activate.",
            "ALERT_SELECT_DEACTIVATE": "Please select user to deactivate.",
            "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these users?",
            "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these users?",
            "RESET_PASSWORD": "Do you want to reset password?",
            "CONFIRM_RESET_PASSWORD": "Do you want to reset password?",
            "REMOVE_BANK_INFO": "Confirm remove bank info",
            "CONFIRM_REMOVE_BANK_INFO": "Do you want to remove bank info?",
            "note_account_password_default": "Note: For new Merchant account, the default password is \"12345678\"",
            "Cash_Balance": "Cash balance",
            "Credit_Balance": "Credit balance"
        },
        "Update_merchant_user_success": "The merchant user has been updated successfully",
        "Update_merchant_user_fail": "Failed to update merchant user",
        "Create_merchant_user_success": "New merchant user has been created successfully",
        "Create_merchant_user_fail": "Failed to create merchant user",
        "Update_merchant_user_status_success": "The merchant user has been updated successfully",
        "Update_merchant_user_status_fail": "Failed to update merchant user",
        "Reset_password_merchant_user_success": "Reset password successfully",
        "Reset_password_merchant_user_fail": "Reset password fail",
        "Deleted_merchant_bank_info_success": "Remove bank info successfully",
        "Deleted_merchant_bank_info_fail": "Remove bank info fail"
    },
    "table_header": {
        "Items_per_page": "elementos por página",
        "Page": "Página",
        "Search_here": "Busca aquí",
        "Shown_Active": "%{start} - %{end} de %{total} mostrado",
        "Active": "%{activo} Activo",
        "OF": "de"
    },
    "driver": {
        "Driver": "Driver",
        "Add": "Agregar",
        "addDriver": "Add driver",
        "Detail": "Detail",
        "detailDriver": "Driver Details",
        "addNewVehicle": "Add Vehicle",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "stripeConnect": "Stripe Connect",
        "stripeConnectDes": "Add a stripe connect account for easier payouts",
        "bankAccount": "Banking Info",
        "bankAccountDes": "Drivers bank info to handle payouts",
        "supplierComission": "Supplier Commission",
        "supplierComissionDes": "Set commissions the supplier earns for every complete order.",
        "fleetCommission": "Fleet/Supplier commission",
        "fleetCommissionDes": "Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.",
        "operationZones": "Operation Zones",
        "operationZonesDes": "Zones that suppliers can receive jobs from:",
        "notes": "Notes",
        "notesDes": "Internal notes about this driver:",
        "searchNotes": "Add notes for driver",
        "marketplace": "Marketplace",
        "marketplaceDes": "Empower drivers to accept jobs they want to do.",
        "otherSettings": "Other Settings",
        "topDriver": "TOP Driver",
        "topDriverDes": "Prioritize top performers with the \"Top Driver\" tag, ensuring they get more rides.",
        "walletTransferBetweenDriver": "Wallet Transfers Between Drivers",
        "walletTransferBetweenDriverDes": "Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.",
        "rideSharing": "Ride-sharing",
        "rideSharingDes": "Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.",
        "individual_vehicle": "Individual vehicle",
        "company_vehicle": "Company vehicle",
        "ContactInfo": "Contact Info",
        "name": "Name",
        "expiryDate": "Expiry Date",
        "action": "Action",
        "editDocument": "Edit Document",
        "documentToUpload": "Document to upload",
        "addFiles": "Click or drag file to this area to upload.",
        "fileSize": "Maximum file size 10 MB",
        "fileSizeDes": "The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "removeOldFile": "Please remove the old file before uploading a new one.",
        "maxLengthFiles": "Please upload max length 1 file",
        "maxSizeFiles": "Please upload file size less than 10MBs",
        "typeFiles": "Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip",
        "maxAvatarSizeFiles": "Please upload avatar size less than 8MBs",
        "IFSCCode": "IFSC Code",
        "Drivers": "Conductores",
        "default": "Default",
        "customize": "Customized",
        "Activate": "Activar",
        "Stripe_connect": "STRIPE CONNECT",
        "status_stripe": "Status: {0} ",
        "stripeConnectStatus": "Stripe connect",
        "stripeStatus": "Stripe account status",
        "Deactivate": "Desactivar",
        "Delete": "Borrar",
        "acceptInDay": "Maximum reservations driver can accept in a day",
        "acceptATime": "Total number of reservations a driver can accept at a time",
        "Commission": "Set fleet commission",
        "Title_Commission": "Establecer comisión de flota para todos los conductores",
        "Confirm_Set_Commission": "¿Está seguro de que desea establecer este valor de comisión para todos los conductores?",
        "Commission_Successfully": "La comisión del conductor se ha actualizado con éxito",
        "Apply": "Solicitar",
        "Type": "Tipo",
        "Currency": "Divisa",
        "View_Message": "Mensaje",
        "Send_Message": "Enviar mensaje",
        "Driver_Name": "Nombre del conductor",
        "Driver_ID": "Documento de Identidad",
        "Phone_Number": "número de teléfono",
        "Driver_Type": "Tipo de controlador",
        "TOP": "CIMA",
        "Company": "Compañía",
        "City": "Ciudad",
        "Driver_License_Number": "Número de licencia de conducir",
        "License_Plate": "Placa",
        "Car_Type": "Tipo de carro",
        "Zone": "Zona",
        "Registered_From": "Registrado desde",
        "Registration_Date": "Fecha de Registro",
        "IOS_Last_Login_Version": "Versión de último inicio de sesión de IOS",
        "Android_Last_Login_Version": "Versión de último inicio de sesión de Android",
        "Last_Active_Date": "Útima fecha de conexión",
        "Days_Since_Last_Active": "Días desde la última actividad",
        "Completed_bookings": "Reservas completadas",
        "Status": "Estado",
        "Actions": "Acciones",
        "Action": "Acción",
        "Inactive": "Inactivo",
        "Active": "Activo",
        "Edit": "Editar",
        "View": "Vista",
        "ALERT_SELECT_ACTIVATE": "Seleccione el controlador para activar.",
        "ALERT_SELECT_DEACTIVATE": "Seleccione el controlador para desactivar.",
        "ALERT_SELECT_DELETE": "Seleccione el controlador para eliminar.",
        "CONFIRM_DELETE": "¿Desea eliminar este controlador?",
        "CONFIRM_DEACTIVATE_MULTI": "¿Está seguro de que desea desactivar los controladores seleccionados?  Sus medidores de hardware conectados también se desactivarán.  Solo se pueden desactivar los conductores que no tengan ningún viaje o reserva actual.",
        "CONFIRM_ACTIVATE_MULTI": "¿Quieres activar estos controladores?  Solo se pueden activar los conductores que estén completamente configurados para el funcionamiento.",
        "CONFIRM_DELETE_MULTI": "¿Desea eliminar estos controladores?  Solo se puede eliminar el controlador en estado inactivo.",
        "CONFIRM_REMOVE_BANKING_INFOR": "¿Está seguro de que desea eliminar la información bancaria?",
        "REMOVE_BANKING_INFOR": "Eliminar información bancaria",
        "ADD_DRIVER": "AÑADIR CONDUCTOR",
        "Avatar": "Avatar",
        "Individual_Driver": "Vehículo de la empresa",
        "Owner_Operator": "Coche Individual",
        "deliveryCompany": "Delivery - Company vehicle",
        "deliveryIndividual": "Delivery - Individual vehicle",
        "CONTACT_INFO": "DATOS DE CONTACTO",
        "Home_address": "Direccion de casa",
        "Username": "nombre de usuario",
        "First_name": "Nombre",
        "Last_name": "Apellidos",
        "Phone_number": "número de teléfono",
        "Email_address": "Dirección de correo electrónico",
        "Select_city": "Ciudad selecta",
        "State": "Estado",
        "Zip_code": "Código postal",
        "Date_of_birth": "Fecha de nacimiento",
        "placeholderDate": "mm/dd/yyyy",
        "City_driver_want_to_drive": "Ciudad de trabajo",
        "Driver_license_number": "Número de licencia de conducir",
        "Driver_license_state": "Licencia de conducir estado/provincia",
        "Driver_license_expiry": "Caducidad de la licencia de conducir",
        "FOR_INSPECTOR": "PARA EL INSPECTOR",
        "Document": "Documento",
        "Document_Id": "Document ID",
        "Link": "Enlace",
        "Notes": "notas",
        "UPLOAD_DOCUMENT": "CARGAR DOCUMENTO",
        "Click_here_to_download": "Haga click aquí para descargar.",
        "Click_here_to_view": "Click to view",
        "EXTENSION_REQUIREMENT": "La extensión del archivo debe ser .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip y menos de 5Mb.",
        "Invalid_file": "Archivo inválido",
        "File_size_larger_than_5Mb": "Tamaña de archivo debe ser superior a 5 Mb",
        "BANK_ACCOUNT": "CUENTA BANCARIA",
        "Name_of_bank": "Nombre del banco",
        "Name_of_account": "Nombre de la cuenta",
        "First_M_Last": "Primero M. Apellido",
        "RTBnumber": "Ruta/Tránsito/Número de oficina",
        "bankName": "Nombre del banco",
        "Select_a_bank": "Seleccione un banco",
        "Account_number": "Número de cuenta",
        "IBAN_number": "Número IBAN",
        "Check_number": "Compruebe número",
        "Swift_code_number": "Número de código Swift",
        "SSNNI": "Número de Seguridad Social",
        "sortCode": "Sort code",
        "Verification_document": "Verification document (front)",
        "Additional_document": "Documento adicional",
        "Verification_document_back": "Verification document (back)",
        "Additional_document_back": "Additional document (back)",
        "others": "Otros",
        "signedW9Form": "Formulario W-9 firmado",
        "driverLicense": "Licencia de conducir",
        "Verification_document_tooltip": "Cargue la copia escaneada de uno de los siguientes documentos: \n- Pasaporte \n- Licencia de conducir \n- Tarjeta de identificación",
        "Additional_document_tootip": "Cargue la copia escaneada de uno de los siguientes documentos: \n- Factura de luz o gas \n- Declaración de impuestos o certificado de no tributación \n- Certificado de impuesto a la vivienda \n- Certificado o factura del seguro de vivienda \n- Recibo de alquiler (de una agencia social o de una agencia inmobiliaria) \n- o título de propiedad \n- Factura telefónica \n- Factura de agua \n- Comunicado de CAF mencionando las ayudas a la vivienda",
        "isBankAccountOwner": "Is this your account?",
        "yes": "Yes",
        "no": "No",
        "beneficiary_name": "Beneficiary Name",
        "beneficiary_idic": "Beneficiary ID/IC",
        "relationship": "Relationship",
        "select_relationship": "Select relationship status",
        "spouse": "Spouse",
        "daughterOrSon": "Daughter/Son",
        "brotherOrSister": "Brother/Sister",
        "relative": "Relative",
        "friends": "Friends",
        "other": "Other",
        "relationshipOtherName": "Relationship with other?",
        "OPERATION": "OPERACIÓN",
        "CAR_INSURANCE": "SEGURO DE AUTO",
        "Policy_number": "Número de póliza",
        "Effective_date": "Fecha efectiva",
        "Expired_date": "Fecha de vencimiento",
        "Select_company": "Seleccionar empresa",
        "All_company": "toda la compañía",
        "All_Car_Type": "Todo tipo de coche",
        "Car": "Vehículo",
        "Existing_car": "coche existente",
        "New_car": "Carro nuevo",
        "License_plate": "Placa",
        "Select_license_plate": "Seleccionar matrícula",
        "Select_Car_Type": "Seleccionar tipo de coche",
        "vehicleOwner": "Name of vehicle’s owner",
        "Make": "Fabricar",
        "Select_Car_Make": "Seleccionar marca de coche",
        "Model": "Modelo",
        "Select_Car_Model": "Seleccionar modelo de coche",
        "Year": "Año",
        "Select_Year": "Seleccionar año",
        "Color": "Color",
        "Passengers": "Pasajeros",
        "Luggage": "Equipaje",
        "License_expiry": "Caducidad de la licencia",
        "Shift": "Turno",
        "Select_Shift_Template": "Seleccionar plantilla de turno",
        "Fleet_commission": "Comisión de flota (informe)",
        "Company_commission": "Company commission (report)",
        "rideHailing": "Street Hailing",
        "intercity": "Intercity",
        "food": "Food",
        "mart": "Mart",
        "streetSharing": "Street Sharing",
        "Shuttle": "Shuttle",
        "parcel": "Parcel",
        "driverInsurance": "Driver Insurance",
        "Percentage": "Porcentaje",
        "Amount": "Monto",
        "transaction": "Transacción",
        "Terminal_ID": "ID terminal",
        "Auth_key": "clave de autentificación",
        "CREDIT_DEPOSIT": "Crédito monedero",
        "Credit_Balance": "Balance de crédito",
        "Balance": "Saldo crédito",
        "Change_balance": "Modificar saldo",
        "Select_balance": "Seleccionar saldo",
        "Adjust_amount": "ajustar importe",
        "Adjust_value": "Adjust value",
        "New_balance": "nuevo saldo",
        "New_point": "New point",
        "Enter_reason": "introduzca los motivos",
        "ERROR_INPUT_BALANCE": "Seleccione el saldo.",
        "ERROR_INPUT_AMOUNT": "Ingrese este valor.",
        "ERROR_INPUT_REASON": "Introduce el motivo.",
        "ERROR_INPUT_VALID_AMOUNT": "Por favor ingrese un valor mayor o igual a 0.",
        "INFO_ADD_BALANCE_SUCCESS": "El saldo del conductor se ha actualizado con éxito",
        "INFO_ADD_CASH_BALANCE_SUCCESS": "Driver cash balance has been updated successfully",
        "Force_meter": "medidor de fuerza",
        "Force_meter_tooltip": "La aplicación del conductor debe estar conectada al medidor antes de operar. Aplicado a los medidores Pulsar y Centrodyne.",
        "ERROR_EXISTED_PHONE": "Este teléfono ha existido en el sistema.  Por favor ingrese otro",
        "TOP_driver": "conductor TOP",
        "Ride_sharing": "viaje compartido",
        "Note_password": "Nota: Para una nueva cuenta de conductor, la contraseña predeterminada es \"contraseña\"\"",
        "Required_fields": "Campos requeridos",
        "Cancel": "Cancelar",
        "Save": "Ahorrar",
        "SaveAndInProgress": "Save & In progress",
        "SaveAndDeactivate": "Save & Deactivate",
        "ERROR_REQUIRE_": "Por favor, input",
        "ERROR_INPUT_AVATAR": "Por favor sube el avatar.",
        "ERROR_INPUT_DRIVER_TYPE": "Please select driver type.",
        "ERROR_INPUT_DOCUMENT": "por favor sube",
        "ERROR_INPUT_USERNAME": "Por favor introduzca un usuario",
        "ERROR_INPUT_VALID_USERNAME": "Usuario deben contener sólo minúsculas y números y debe contener entre 3-20 caracteres",
        "ERROR_INPUT_PHONE": "Por favor, especifique un número de teléfono válido.",
        "ERROR_INPUT_VALID_EMAIL": "Por favor introduzca un correo electrónico válido.",
        "ERROR_INPUT_EMAIL": "Por favor ingrese el correo electrónico.",
        "ERROR_INPUT_FIRSTNAME": "Por favor ingrese el primer nombre.",
        "ERROR_INPUT_LASTNAME": "Por favor ingrese el apellido.",
        "ERROR_INPUT_ADDRESS": "Ingrese la dirección de su casa.",
        "ERROR_INPUT_CITY": "Por favor ingrese la ciudad.",
        "ERROR_INPUT_STATE": "Por favor ingrese el estado.",
        "ERROR_INPUT_ZIPCODE": "Por favor ingrese el código postal.",
        "ERROR_INPUT_BIRTHDAY": "Por favor ingrese cumpleaños.",
        "ERROR_INPUT_NAME_OF_BANK": "Ingrese el nombre del banco.",
        "ERROR_INPUT_NAME_OF_ACCOUNT": "Ingrese el nombre de la cuenta.",
        "ERROR_INPUT_ROUTING_NUMBER": "Ingrese el número de ruta.",
        "ERROR_INPUT_BANK_NAME": "Seleccione un banco.",
        "ERROR_INPUT_ACCOUNT_NUMBER": "Por favor ingrese un número de cuenta",
        "ERROR_INPUT_CHECK_NUMBER": "Por favor compruebe número",
        "ERROR_INPUT_SSN": "Por favor introduzca número de seguridad social",
        "ERROR_INPUT_DocumentId": "Please input Document ID.",
        "ERROR_INPUT_SORTCODE": "Please input sort code.",
        "ERROR_INPUT_COMPANY": "Seleccione la empresa para activar.",
        "ERROR_INPUT_LICENSE_PLATE": "Por favor, elija la matrícula.",
        "ERROR_INPUT_ZONE": "Seleccione la zona para activar.",
        "ERROR_INPUT_SHIFT": "Seleccione turno para activar.",
        "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "Esto debe contener solo números",
        "ERROR_SSN_ONLY_NUMBERS": "Esto debe contener solo números",
        "ERROR_SSN_LENGTH": "SSN debe tener al menos 4 caracteres",
        "ERROR_COMMISSION_NUMBERS": "por favor ingrese un número valido",
        "INFO_DEACTIVAED_SUCCESS": "El controlador se ha desactivado con éxito.",
        "INFO_ACTIVAED_SUCCESS": "El controlador se ha activado con éxito.",
        "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "El controlador se ha desactivado pero el medidor de hardware integrado no se puede apagar por algunas razones inesperadas.",
        "INFO_MULTIDEACTIVAED_SUCCESS": "Los conductores seleccionados han sido desactivados satisfactoriamente",
        "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "Los {0} controladores seleccionados se han desactivado correctamente.  Se deshabilitaron {1} medidores de hardware conectados.",
        "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "Los {0} controladores seleccionados se han desactivado correctamente.  Se deshabilitaron {1} medidores de hardware conectados.  {2} no se debieron a razones inesperadas.",
        "INFO_MULTIACTIVAED_SUCCESS": "Los conductores seleccionados han sido activados satisfactoriamente",
        "DRIVER_UPDATE_SUCCESS": "El controlador se ha actualizado con éxito.",
        "DRIVER_CREATE_SUCCESS": "La información del conductor se ha agregado con éxito",
        "Expiry_Date": "Fecha de caducidad",
        "Case_number": "número de expediente",
        "Export_to_csv": "Exportar a CSV",
        "CONFIRM_EXPORT_TO_CSV": "¿Está seguro de que desea exportar todos los datos en formato .csv?",
        "Ride_sharing_tooltip": "El viaje compartido es una función avanzada, comuníquese con nuestro equipo de soporte para obtener más información",
        "In_Review": "En revisión",
        "Reset_password_success": "Contraseña restablecida satisfactoriamente",
        "Topup_note": "(Se permite recargar el saldo luego de activar al conductor)",
        "cash_balance": "Saldo en efectivo",
        "cash_wallet": "Efectivo en monedero",
        "total_balance": "Balance total",
        "total_cash_balance": "Saldo total en efectivo",
        "total_credit_balance": "Saldo de crédito total",
        "banking_status": "estado bancario",
        "bankingStatus": {
            "verified": "Verificado",
            "not_verified": "No verificado"
        },
        "INVALID_FILE_SIZE": "The file size must be less than 8Mb.",
        "totalDrivers": "Total Driver",
        "profileStatus": "Estado del perfil",
        "profile_completed": "Profile completed",
        "profile_incomplete": "Perfil Completado",
        "profile_inProgress": "Profile in progress",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "CASH_WALLET_DEPOSIT": "Cash wallet",
        "payoutSelect": "Payout (an inbox and email will be sent to the driver after payout)",
        "Confirm_update_cash_balance": "Change Cash Balance",
        "Confirm_update_cash_balance_msg": "Are you sure you want to change driver balance?",
        "cashBalance": "Cash balance",
        "cashBalance_unavailable": "0.00",
        "cashBalance_active": "Active",
        "cashBalance_negative": "Negative",
        "creditBalance": "Credit balance",
        "ERROR_INPUT_DOCUMETID": "Please input Document Id.",
        "ERROR_INPUT_EXPIRYDATE": "Please input Expiry Date.",
        "driverType": "Driver Type",
        "tooltip_in_review_completed": "Send inbox to 'In review' drivers who complete their profile.",
        "tooltip_in_review_incomplete": "Send inbox to 'In review' drivers who haven't completed their profile.",
        "tooltip_in_review_inProgress": "Send inbox to 'In review' drivers who complete their profile but need extra verification.",
        "tooltip_active": "Send inbox to 'Active' drivers.",
        "tooltip_inactive": "Send inbox to drivers who have account reactivated.",
        "active": "Activate",
        "inactive": "Deactivate",
        "inReviewInProgress": "In Review",
        "Market_place": "Marketplace",
        "Market_place_tooltip": "Empower drivers to choose on-demand or reservations bookings to accept",
        "selectDriverType": "Select driver type",
        "PlsSelectDriverType": "Please select driver type",
        "emergencyContacts": "Emergency Contacts",
        "switchVehicle": "Switch to any vehicle",
        "switchVehicleNote": "Allow driver to switch to any vehicle of their assigned supplier company.",
        "vehicleModel": "Model",
        "licensePlateNumber": "Vehicle Plate",
        "addVehicle": "Add Vehicle",
        "newVehicle": "New Vehicle",
        "linkVehicle": "Link Vehicle",
        "modal": "Modal",
        "selectVehicle": "Select Vehicle",
        "selectVehicleNote": "Enable driver to select or change vehicle for a booking.",
        "Warning_change_company": "Warning: Reassigning this driver to another company will unlink all associated vehicles."
    },
    "corporate": {
        "User_Name": "User Name",
        "Edit_Balance": "Edit Balance",
        "Corporates": "Empresas",
        "Supplier": "Supplier",
        "CarTypes": "Car Types",
        "Add": "Agregar",
        "Balance": "Balance",
        "Activate": "Activar",
        "Deactivate": "Desactivar",
        "Delete": "Borrar",
        "Company_Name": "nombre de empresa",
        "Admin_Name": "Nombre del administrador",
        "Username": "nombre de usuario",
        "Phone_Number": "Número de teléfono",
        "importBooking": "Able to import bookings",
        "Email": "Correo electrónico",
        "Registered_From": "Registrado desde",
        "Registration_Date": "Fecha de Registro",
        "Status": "Estado",
        "Actions": "Acciones",
        "Action": "Acción",
        "Inactive": "Inactivo",
        "Active": "Activo",
        "In_Review": "En revisión",
        "Edit": "Editar",
        "View": "Vista",
        "ADD_CORPORATE_CARD": "Agregar tarjeta corporativa",
        "selectCountry": "Seleccionar país",
        "CONFIRM_DELETE_CARD": "¿Está seguro de que desea eliminar estas tarjetas?",
        "ALERT_SELECT_ACTIVATE": "Seleccione corporativo para activar.",
        "ALERT_SELECT_DEACTIVATE": "Seleccione corporativo para desactivar.",
        "ALERT_SELECT_DELETE": "Seleccione empresa para eliminar",
        "CONFIRM_DELETE": "¿Quieres eliminar esta empresa?",
        "CONFIRM_DELETE_USER": "¿Desea eliminar este usuario corporativo?",
        "CONFIRM_DEACTIVATE_MULTI": "¿Quieres desactivar estas empresas?",
        "CONFIRM_ACTIVATE_MULTI": "¿Quieres activar estos corporativos?",
        "CONFIRM_DELETE_MULTI": "¿Desea eliminar estas empresas?\n ** Solo se pueden eliminar las empresas que están inactivas.",
        "ERROR_INPUT_PHONE": "Por favor, especifique un número de teléfono válido.",
        "ERROR_INPUT_VALIDPHONE": "Por favor ingrese un número de teléfono válido.",
        "ERROR_EXISTED_PHONE": "Este teléfono ha existido en el sistema.  Por favor ingrese otro",
        "Account": "Cuenta",
        "Corp_traveler": "viajero del cuerpo",
        "Payment_methods": "Métodos de pago",
        "Corp_operators": "operadores de corporaciones",
        "Pricing": "Tarifas",
        "COMPANY_INFORMATION": "Información de la empresa",
        "Company_name": "nombre de empresa",
        "Company_ID": "ID de la empresa (para registro de viajero corporativo)",
        "Company_address": "Dirección de la empresa",
        "Invoice_email": "Correo electrónico de la factura",
        "Confirmation_email": "Email de confirmación",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "cuenta de administrador",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "Commission": "Comisión",
        "commissionBooking": "Apply commission for bookings from app/pwa",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "Reset_password": "Restablecer la contraseña",
        "Employee_Name": "Nombre de empleado",
        "Created_Date": "Fecha de creación",
        "Add_corporate_card": "Agregar tarjeta corporativa",
        "Corp_Users": "Usuarios corporativos",
        "Pricing_Default": "por defecto (mismas tarifas que usuarios particulares)",
        "Pricing_Discount": "Descuento en porcentaje (aplicado sólo a viajeros corporativos)",
        "Markup_By_Percent": "Marcado por porcentaje (aplicado solo para viajeros corporativos)",
        "Pricing_Add_different_rates": "Asignar tarifas personalizadas",
        "ERROR_INPUT_COMPANYNAME": "Ingrese el nombre de la empresa.",
        "ERROR_INPUT_USERNAME": "Por favor ingrese el nombre de usuario.",
        "ERROR_INPUT_FIRSTNAME": "Por favor ingrese el primer nombre.",
        "ERROR_INPUT_EMAIL": "Por favor ingrese el correo electrónico.",
        "ERROR_INPUT_VALID_EMAIL": "Por favor, introduce una dirección de correo electrónico válida.",
        "ERROR_INPUT_VALID_NUMBER": "Por favor ingrese un número válido",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Introduzca un número entre _ y __",
        "INFO_ADD_CORPORATE_SUCCESS": "La cuenta {0} se ha creado correctamente.  Consulte el correo electrónico {1} para obtener los detalles de las credenciales de inicio de sesión.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "La información ha sido actualizada satisfactoriamente",
        "INFO_UPDATE_PRICING_SUCCESS": "Precios actualizados correctamente",
        "INFO_RESET_PASSWORD_SUCCESS": "La cuenta ha sido restablecida satisfactoriamente. Revise su correo electrónico para obtener sus claves de acceso",
        "COMFIRM_RESET_PASSWORD": "¿Quieres restablecer la contraseña?",
        "Traveler_signature": "Firma del viajero",
        "Parcel_Delivery": "Enable parcel delivery",
        "Tracking_log": "registros de seguimiento",
        "Rating": "Clasificación",
        "Email_address": "Dirección de correo electrónico",
        "Assistant_email": "Correo electrónico de asistente",
        "Corp_PO": "Orden de compra de la corporación",
        "Cost_centre": "centro de costo",
        "Corp_division": "División de empresa",
        "Corp_id": "Identificador empresa",
        "Department": "departamento",
        "Manager_email": "correo electrónico del gerente",
        "Manager_name": "El nombre del gerente",
        "Corporate_card": "Tarjeta corporativa",
        "Direct_invoicing": "facturación directa",
        "Corporate_Prepaid": "Prepago Corporativo",
        "Cash": "Dinero en efectivo",
        "Car_Type_ID": "ID tipo de vehículo",
        "App_Display_Name": "nombre App",
        "Normal_Fare": "Tarifa normal",
        "Flat_Fare": "Tarifa Plana",
        "Hourly_Daily_Rate": "Horas/días",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Asignar tarifa",
        "Zone": "Zona",
        "Rate": "Calificar",
        "Zone_Rate": "Zona - tarifa",
        "Please_select_rate": "Por favor seleccione tarifa",
        "Paid_by": "Pagado por",
        "Enter_new_credit_card": "Introducir nueva tarjeta de crédito",
        "TOP_UP": "aumentar",
        "Top_up": "aumentar",
        "Topup_amount": "incrementar importe",
        "Currency": "Divisa",
        "ERROR_INPUT_AMOUNT": "Ingrese una cantidad válida.",
        "VAT_number": "Número de valor agregado",
        "Gender": "Seleccione",
        "Select_Gender": "Seleccionar",
        "Male": "Masculino",
        "Female": "Femenino",
        "Date_of_birth": "Fecha de nacimiento",
        "Street_address": "Calle",
        "City": "Ciudad",
        "State": "Estado",
        "Zip_code": "Código postal",
        "Country": "País",
        "adding_new_card": "Ha agregado una nueva tarjeta para su uso en % área",
        "No_Support": "La nueva tarjeta introducidad no es válida en esta zona",
        "Job_title": "cargo profesional",
        "required_phone_number_payment": "Se requiere el número de teléfono de la empresa.  Por favor, actualice el perfil para continuar.",
        "Yes": "Sí",
        "No": "No",
        "AddCardBeforeTopup": "No se encontró ninguna tarjeta de crédito.  Agregue una nueva tarjeta para continuar.",
        "flightNumber": "Flight number",
        "Company_Type": "Company type",
        "Standard": "Standard corporate",
        "Airline": "Airline",
        "Branding": "Branding",
        "bgImage": "Background image (1600x900)",
        "logoImage": "Logo image",
        "aliasTitle": "Alias (URL) parameter",
        "importChauffeurBooking": "May kakayahang mag-import ng mga booking sa transportasyon",
        "isCorporateVip": "Corporate VIP",
        "isCorporateVipToolTip": "Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.",
        "qrTitle": "QR Web booking link",
        "qrNote": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!"
    },
    "airline": {
        "Airlines": "Arilines",
        "Add": "Add",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Delete": "Delete",
        "Company_Name": "Company Name",
        "Admin_Name": "Admin Name",
        "Username": "Username",
        "Phone_Number": "Phone Number",
        "Email": "Email",
        "Registered_From": "Registered From",
        "Registration_Date": "Registration Date",
        "Status": "Status",
        "Actions": "Actions",
        "Action": "Action",
        "Inactive": "Inactive",
        "Active": "Active",
        "In_Review": "In Review",
        "Edit": "Edit",
        "View": "View",
        "ADD_CORPORATE_CARD": "Add Airline card",
        "selectCountry": "Select country",
        "CONFIRM_DELETE_CARD": "Are you sure you want to delete this cards?",
        "ALERT_SELECT_ACTIVATE": "Please select Airline to activate.",
        "ALERT_SELECT_DEACTIVATE": "Please select Airline to deactivate.",
        "ALERT_SELECT_DELETE": "Please select Airline to delete.",
        "CONFIRM_DELETE": "Do you want to delete this Airline?",
        "CONFIRM_DELETE_USER": "Do you want to delete this Airline user?",
        "CONFIRM_DEACTIVATE_MULTI": "Are you sure want to deactivate these company?",
        "CONFIRM_ACTIVATE_MULTI": "Are you sure you want to active this company?",
        "CONFIRM_DELETE_MULTI": "Are you sure you want to delete this company?\n ** Only company who are inactive can be deleted.",
        "ERROR_INPUT_PHONE": "Please specify a valid phone number.",
        "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
        "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
        "Airline": "Airline",
        "Crew": "Crew",
        "Payment_methods": "Payment methods",
        "Airline_operators": "Airline operators",
        "Pricing": "Pricing",
        "COMPANY_INFORMATION": "Company information",
        "Company_name": "Company name",
        "Company_ID": "Company ID",
        "Company_address": "Company address",
        "Invoice_email": "Invoice email",
        "Confirmation_email": "Confirmation email",
        "colorCodeBooking": "Color code",
        "ADMIN_ACCOUNT": "Admin account",
        "First_name": "First name",
        "Last_name": "Last name",
        "Commission": "Commission",
        "Required_fields": "Required fields",
        "Required_fields_crews": "Note: For new Crew account, the default password is \"12345678\"",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset_password": "Reset password",
        "Crew_Name": "Crew",
        "Created_Date": "\tCreated Date",
        "Add_corporate_card": "Add airline card",
        "Corp_Users": "Operators",
        "Pricing_Default": "Default",
        "Pricing_Discount": "Discount by percentage",
        "Markup_By_Percent": "Mark-up by percentage",
        "Pricing_Add_different_rates": "Add different rates",
        "ERROR_INPUT_COMPANYNAME": "Please input company name.",
        "ERROR_INPUT_USERNAME": "Please input username.",
        "ERROR_INPUT_FIRSTNAME": "Please input first name.",
        "ERROR_INPUT_EMAIL": "Please input email.",
        "ERROR_INPUT_VALID_EMAIL": "Please enter a valid email address.",
        "ERROR_INPUT_VALID_NUMBER": "Please enter valid number",
        "ERROR_INPUT_VALID_RANGE_AMOUNT": "Please enter a value between {0} and {1}.",
        "INFO_ADD_CORPORATE_SUCCESS": "Account {0} has been created successfully.",
        "INFO_UPDATE_CORPORATE_SUCCESS": "The information has been updated successfully.",
        "INFO_UPDATE_PRICING_SUCCESS": "Pricing updated successfully",
        "INFO_RESET_PASSWORD_SUCCESS": "Account {0} has been reset successfully.",
        "COMFIRM_RESET_PASSWORD": "Do you want to reset password?",
        "Traveler_signature": "Traveler signature",
        "Tracking_log": "Tracking log",
        "Rating": "Rating",
        "Email_address": "Email address",
        "Assistant_email": "Assistant email",
        "Corp_PO": "Corp PO",
        "Cost_centre": "Cost centre",
        "Corp_division": "Corp division",
        "Corp_id": "Corp id",
        "Department": "Department",
        "Manager_email": "Manager's email",
        "Manager_name": "Manager's name",
        "Corporate_card": "Airline card",
        "Direct_invoicing": "Direct invoicing",
        "Corporate_Prepaid": "Airline Prepaid",
        "Cash": "Cash",
        "Car_Type_ID": "Car Type ID",
        "App_Display_Name": "App Display Name",
        "Normal_Fare": "Regular",
        "Flat_Fare": "Flat Rate",
        "Hourly_Daily_Rate": "Hourly/ Daily",
        "Delivery_Rate": "Delivery",
        "Assign_rate": "Assign rate",
        "Zone": "Zone",
        "Rate": "Rate",
        "Zone_Rate": "Zone - Rate",
        "Please_select_rate": "Please select rate",
        "Paid_by": "Paid by",
        "Enter_new_credit_card": " Enter new credit card ",
        "TOP_UP": "TOP UP",
        "Top_up": "Top up",
        "Topup_amount": "Topup amount",
        "Currency": "Currency",
        "ERROR_INPUT_AMOUNT": "Please input valid amount.",
        "VAT_number": "VAT number",
        "Gender": "Gender",
        "Select_Gender": "Select",
        "Male": "Male",
        "Female": "Female",
        "Date_of_birth": "Date of birth",
        "Street_address": "Street address",
        "City": "City",
        "State": "State",
        "Zip_code": "Zip code",
        "Country": "Country",
        "adding_new_card": "You are adding a new card to use service in %{zoneName} area",
        "No_Support": "New card adding is currently not supported in this area",
        "Job_title": "Job title",
        "required_phone_number_payment": "The phone number of corporate are required. Please update profile to continue.",
        "Yes": "Yes",
        "No": "No",
        "AddCardBeforeTopup": "No credit card was found. Please add a new card to continue."
    },
    "message": {
        "Add": "Agregar",
        "Delete": "Borrar",
        "Items_per_page": "elementos por página",
        "Page": "Página",
        "Search_here": "Busca aquí",
        "Shown_Active": "{0} - {1} de {2} mostrado",
        "Date": "fecha",
        "Subject": "Sujeto",
        "To": "A",
        "Type": "Tipo",
        "Result": "Resultado",
        "Actions": "Acciones",
        "New_message": "Nuevo mensaje",
        "MESSAGE_DETAILS": "detalles de mensaje",
        "All": "Todo",
        "Company": "Compañía",
        "Active": "Activo",
        "In_Active": "Inactivo",
        "In_Review": "En revisión",
        "Send_to": "Enviar a",
        "All_drivers": "Todos los conductores",
        "All_customers": "Todos los clientes",
        "All_merchants": "All merchants",
        "Customized_driver": "Conductores específicos",
        "Customized_customer": "Clientes específicos",
        "Customized_merchant": "Customized merchant",
        "Receiver_list": "Lista de receptores",
        "Content": "Contenido",
        "Required_fields": "Campos requeridos",
        "Search_driver_name_driver_phone_number": "Buscar nombre del conductor, número de teléfono del conductor",
        "Search_category_name": "Nombre de la categoría de búsqueda",
        "Search_customer_name_customer_phone_number": "Buscar nombre de cliente, número de teléfono del cliente",
        "Search_merchant_name_merchant_phone_number": "Search merchant name, merchant phone number",
        "Search_car_type": "Buscar tipo de vehículo",
        "Searching": "Buscando...",
        "No_results": "No hay resultados",
        "Send": "Enviar",
        "Cancel": "Cancelar",
        "SMS_failed_Inbox_succeeded": "SMS falló, la bandeja de entrada tuvo éxito",
        "Failed": "Fallido",
        "Succeeded": "logrado",
        "CONFIRM_DELETE": "¿Quieres borrar este mensaje?",
        "CONFIRM_DELETE_MULTI": "¿Quieres eliminar estos mensajes?",
        "ALERT_SELECT_DELETE": "Seleccione el mensaje para eliminar.",
        "ERROR_INPUT_RECEIVERLIST": "Elija al menos un conductor para enviar el mensaje",
        "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Elija al menos un cliente para enviar el mensaje",
        "ERROR_INPUT_SUBJECT": "Por favor ingrese asunto",
        "ERROR_INPUT_CONTENT": "Por favor ingrese el contenido",
        "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Please set up at least one language to continue",
        "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Please input subject for {0} language",
        "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Please input content for {0} language",
        "ERROR_INPUT_URL_MULTI_LANGUAGE": "Please input URL for {0} language",
        "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "Please input title for {0} language",
        "ERROR_INPUT_BODY_MULTI_LANGUAGE": "Please input body for {0} language",
        "import_fail_success_driver": "%{success} driver(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_passenger": "%{success} customer(s) has been imported successfully, %{fail} failed.",
        "import_fail_success_merchant": "%{success} merchant(s) has been imported successfully, %{fail} failed.",
        "CONFIRM_SEND_SUCCESS": "Tu mensaje ha sido enviado.  Esto puede tomar unos pocos minutos.  Espere mientras el sistema procesa su solicitud.",
        "Load_message_and_sms_fail": "Los errores ocurrieron al cargar el mensaje y los detalles de SMS",
        "Update_message_and_sms_fail": "Los errores ocurrieron al actualizar el mensaje y los detalles de SMS",
        "Update_message_and_sms_success": "Los detalles de mensajes y SMS se han actualizado con éxito",
        "Driver_List": "Lista de conductores",
        "Car_Type_List": "Lista de tipos de coches",
        "Search_vehicle_type_name": "buscar nombre de tipo de vehículo",
        "ALERT_PROCESSING_DELETE": "Su petición está siendo procesada, puede tardar algunos segundos",
        "Customer": "Cliente",
        "Driver": "Conductor",
        "StatusOfCustomer": "Estatus del cliente",
        "StatusOfDriver": "Estatus del conductor",
        "StatusOfMerchant": "Status of merchant",
        "SendTo": "Enviar a",
        "DownloadReceiverList": "Descargar lista de receptores",
        "warningInputChangeOver": "Hay un cambio relativo de más del 20% entre las 2 entradas recientes.  ¡Por favor considera!",
        "NoteDownloadReveverList": "Después de 1 mes, la descarga de la lista de receptores se desactivará.",
        "Load_voip_fail": "Los errores ocurrieron al cargar los detalles de VOIP.",
        "Update_voip_success": "Los detalles de VOIP se han actualizado con éxito.",
        "Update_voip_fail": "Los errores ocurrieron al actualizar los detalles de VOIP.",
        "expiryDate": "Send to new user until",
        "expiryDate_placeholder": "Expiry Date (mm/dd/yy)",
        "NA": "N/A",
        "PleaseSelectStatusOfDriver": "Please select status of driver",
        "versionApp": "App Version",
        "platform": "Platform",
        "contentType": "Content type",
        "url": "URL",
        "title": "Title",
        "contentId": "ContentId",
        "body": "Body",
        "image_notification": "Image",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "contentTypeItem": {
            "html": "HTML",
            "link": "Link"
        },
        "Please_input": "This field is required"
    },
    "fleetInfo": {
        "Fleet_name": "Nombre de la flota",
        "Phone_number": "Número de teléfono",
        "Email_address": "Dirección de correo electrónico",
        "Country": "País",
        "Time_zone": "Zona horaria",
        "Address": "Habla a",
        "Website": "Sitio web",
        "Currency": "Divisa",
        "Distance_unit": "Unidad de distancia",
        "Load_fleet_info_fail": "Ocurrió un error durante la carga de la información de flota"
    },
    "generalSetting": {
        "alertNotifications": {
            "title": "Alert notifications",
            "emails": "Emails",
            "emailsPlaceholder": "Enter email",
            "slack": "Slack Channel (Instructions)",
            "slackPlaceholder": "Slack POST URL",
            "discord": "Discord Channel (Instructions)",
            "discordPlaceholder": "Enter Discord Channel",
            "telegram": "Telegram Channel (Instructions)",
            "telegramPlaceholder": "Enter Telegram Channel"
        },
        "showFare": {
            "title": "Show fare to driver",
            "totalFare": "Total fare",
            "estimateGross": "Estimate gross earning"
        },
        "carHailing": "Street hailing",
        "regular": "Regular",
        "cancelTimeout": "Cancel the timeout bookings automatically",
        "allowStripePaymentLink": "Allow generate Stripe payment link",
        "flat": "Flat",
        "techFee": "Tech fee per transaction",
        "customizeLogo": "Customize dashboard logo",
        "logo": "Logo",
        "customerApp": "Customer App",
        "hourly": "Hourly",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "freeCancelTime": "Free cancellation times per day",
        "AM": "SOY",
        "PM": "PM",
        "RUSH_HOUR": "Recargo",
        "Name": "Nombre",
        "Date": "fecha",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "casarse",
        "Thu": "Jue",
        "Fri": "Vie",
        "Sat": "Se sentó",
        "Sun": "sol",
        "Time_range": "Intervalo de tiempo",
        "From": "Desde",
        "To": "A",
        "Rush_hour_time_calculation": "Tiempo de cálculo del recargo",
        "Rush_hour_surcharge": "Recargo",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "MDISPATCHER_CANCELLATION": "Cancelaciones usuarios/colaboradores/reservas web",
        "Book_now": "Reservar ahora",
        "MDISPATCHER_CANCELLATION_TOOLTIP": "Pax / Socio / Cancelación de reserva web",
        "Book_now_toolTip": "La tarifa de cancelación se aplica si las reservas bajo demanda se cancelan dentro de",
        "Book_later_toolTip": "La tarifa de cancelación se aplica si una reserva se cancela antes de la hora de recogida",
        "Drv_Book_now_toolTip": "Cancellation fee is applicable if on-demand bookings get canceled within set time, from accept time",
        "Drv_Book_later_toolTip": "Cancellation fee is applicable if in advance bookings get canceled within set time, before expected pick-up time",
        "Free_cancel": "Free cancel duration",
        "Hide_cancel_btn": "Hide cancel button after set times",
        "Driver_cancellation": "Driver cancellation",
        "Time_day": "Times per day",
        "Duration": "Duración",
        "Times": "Veces",
        "Book_later": "Reservar con antelación",
        "Intercity": "Intercity",
        "Intercity_tooltip": "Cancellation fee is applicable if an intercity booking gets canceled within the set time, before pickup time.",
        "MDISPATCHER_NO_SHOW": "no show",
        "AIRPORT_FEE": "Tarifa de aeropuerto",
        "From_airport": "Del aeropuerto",
        "To_airport": "Al aeropuerto",
        "MEET_DRIVER": "Conozca al conductor",
        "On_curb": "en la acera",
        "Meet_Greet": "Conocer y saludar",
        "Add": "Agregar",
        "Edit": "Editar",
        "DELETE_SURCHARGE": "Eliminar recargo",
        "DELETE_SERVICE": "Eliminar servicio",
        "CHANGE_APPLY_SERVICE_FEES": "Cambiar aplicar tarifas de servicio",
        "DELETE_CONFIRM": "¿Está seguro de que desea eliminar este elemento?",
        "DELETE_SURCHARGE_CONFIRM": "¿Está seguro de eliminar el cargo seleccionado?",
        "DELETE_ADDITIONAL_SERVICES_CONFIRM": "¿Está seguro de eliminar el servicio seleccionado?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "¿Está seguro de aplicar tarifas diferentes para zonas separadas?",
        "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "¿Está seguro de aplicar las mismas tarifas para todas las zonas?",
        "EDIT_SERVICE_FEES": "Editar tarifas de servicio",
        "EDIT_SERVICE_FEES_CONFIRM": "¿Está seguro de que desea salir sin guardar la configuración de %{currentZone}?",
        "HEAVY_TRAFFIC": "Tráfico pesado",
        "Heavy_traffic_surcharge": "Recargo por tráfico pesado",
        "TOLL_FEE": "Peaje",
        "parkingFee": "Parking fee",
        "gasFee": "Gas fee",
        "OTHER_FEES": "Otros cargos",
        "Can_edit": "Capaz de editar",
        "Can_add_note": "Capaz de agregar nota",
        "Other_fees_amount": "Importe de otras tasas",
        "TAX": "IMPUESTO",
        "Tax_surcharge": "recargo fiscal",
        "TIP": "Propina",
        "Default_tip": "Consejo predeterminado",
        "RESERVATION_REMINDER": "Recordatorio de reserva",
        "Driver_app_SMS_alert_before": "Aplicación de conductor - SMS y alerta antes",
        "notifyWhenDriverFailStartTrip": "Notify operator if driver fails to start trip before pickup time",
        "notifyWhenDriverFailStartTripTooltip": "Notifications will be displayed in the platform’s notifications section. You can also configure alerts to be sent via email, Slack, Discord, or Telegram for timely updates.",
        "Pax_app_alert_before": "Aplicación Pax - Alerta antes",
        "Confirm_driver_info_via_email_before": "Enviar email/sms de confirmación de reserva al cliente antes",
        "Confirm_driver_info_via_email_before_hint": "The system will send out a booking confirmation to the customer based on your set time, before the pickup time.",
        "Show_request_tab_on_driver_app": "Show 'Request' tab on driver app",
        "Show_preferred_tab_on_driver_app": "Show 'Preferred' tab on driver app",
        "Sound_notification": "Sonido de notificación",
        "Advance_information": "Información adicional viajero corporativo",
        "Load_general_fail": "Ocurrió un error al descargar la configuración general",
        "Update_general_success": "La configuración general se ha actualizado correctamente",
        "Update_general_fail": "Los errores ocurrieron al actualizar la configuración general",
        "Time_over_lap": "Los intervalos de tiempo no deben superponerse entre sí",
        "Allow_passenger_to_register_as_corp_traveler": "Permitir que el pasajero se registre como viajero corporativo",
        "Not_Allow_Make_Booking_Without_Assign_Fare": "No permitir reserva si la tarifa base es = 0.00",
        "Not_Allow_show_car_type_on_cc": "Do not show car type on CC if no fare assign",
        "Disable_Direct_Invoicing_Receipt_Email": "Deshabilitar correo electrónico de recibo de facturación directa",
        "Additional_services": "Servicios adicionales",
        "Edit_additional_services": "Editar servicios adicionales",
        "Service_Fee_By_Currencies": "Tarifa de servicio",
        "Service_type": "Tipo de Servicio",
        "Optional": "Opcional",
        "Compulsory": "Obligatorio",
        "Vehicle_type": "Tipo de vehículo",
        "Adding_additional_service_success": "Nuevo servicio ha sido creado con éxito",
        "Update_additional_service_success": "El servicio ha sido actualizado con éxito.",
        "Delete_additional_service_success": "Servicio eliminado satisfactoriamente",
        "Adding_additional_service_fail": "No se pudo agregar el servicio",
        "Update_additional_service_fail": "No se pudo actualizar el servicio",
        "Other_settings": "Otros ajustes",
        "Operator_assign_error": "Hubo errores al asignar operador",
        "Create_surcharge_success": "Se ha creado correctamente el nuevo recargo",
        "Update_surcharge_success": "El recargo se ha actualizado correctamente",
        "Delete_surcharge_success": "El recargo se ha eliminado correctamente",
        "Create_surcharge_fail": "Error al generar el recargo",
        "Update_surcharge_fail": "Error al actualizar el recargo",
        "Delete_surcharge_fail": "Error al eliminar el recargo",
        "Get_surcharge_detail_fail": "No se pudo obtener el detalle del recargo",
        "Repeat": "Repetir",
        "DayOfWeek": "Día de la semana",
        "SingleDay": "día único",
        "Yearly": "Anual",
        "Backup_phone_number": "Número de respaldo",
        "Backup_phone_number_hint": "Redirigir a este teléfono cuando conductor y pasajero están fuera de zonas operativas",
        "Apply_countdown_clock": "Establecer contador de tiempo",
        "No_show_duration_tooltip": "Configure el tiempo para mostrar el botón No Show después de que el conductor toque el botón Llegado en la aplicación Driver",
        "Limit_radius": "Radio límite",
        "Limit_radius_tooltip": "El conductor puede informar una reserva como no-show dentro de este radio",
        "Limit_radius_arrive_pickup": "Limit radius driver can arrive & pickup for transport booking",
        "Booking_validation": "Booking validations",
        "Invalid_period_of_time": "Periodo de tiempo no válido",
        "hide_Provider_Filter_From_Corporate_Board": "Ocultar el filtro de proveedores de la junta corporativa",
        "customer_email_is_optional": "e-mail del cliente es opcional en App de pasajero",
        "disable_hourly_rate": "Desactivar tarifa por horas cuando coincide con tarifa fija",
        "customer_gender_is_compulsory": "El género del cliente es obligatorio en App de pasajero",
        "SHOW_MAP_STATUS": "Mostrar estados en mapa",
        "Pay_to": "Pagar a",
        "EMERGENCY_SOS": "Emergencias S.O.S.",
        "customer_address_is_compulsory": "Dirección del cliente es obligatorio en App de pasajero",
        "customer_id_is_compulsory": "Documento de identidad es obligatorio en App de pasajero",
        "allowPassengerToUploadDocument": "Permitir que el pasajero cargue el documento ID# / National IC#",
        "compulsoryNationalIcDocumentInPaxApp": "El documento ID# / National IC# del cliente es obligatorio en la aplicación Pax",
        "doNotAllowAddDriverWhenNationalIdDuplicated": "Do not allow operator to add driver when national ID is duplicated",
        "forceCustomerToUseLiveLocation": "Force customer to use Live Location",
        "disableMarketPlace": "Disable Market Place for new drivers",
        "dontShowSecondStageImmediately": "Hide stage 2 signup",
        "compulsoryCarInsurance": "Driver's car insurance is compulsory",
        "marketPlace": "Market Place",
        "Enable": "Enable",
        "Disable": "Disable",
        "showTotalFareWithoutPromotionOnDriverApp": "Show total fare without promotion on Driver app",
        "showTotalFareWithoutPromotionOnDriverApp_hint": "- Transport: apply this setting before driver makes payment. <br/> - Delivery & Intercity: apply this setting before driver start job.",
        "hideFareOnPaxAppForAllCorporateBookings": "Ocultar tarifa en la aplicación Pax para todas las reservas corporativas",
        "hidePaxNameAvatarOnDispatchScreen": "Hide customer's name and avatar on dispatching screen",
        "showFullCustomerNameOnDriverApp": "Show full customer name on Driver app",
        "hideDriverLogoutBtn": "Hide Driver logout button on Driver app",
        "showMeetAndGreetButtonForEveryBooking": "Show Meet & Greet button for every booking",
        "settings": "Ajustes",
        "Default_value": "Valor por defecto",
        "limitTopupAmount": {
            "valueByCurrencies": "Warning amount",
            "minimumByCurrencies": "Minimum top up amount",
            "maximumByCurrencies": "Maximum top up amount",
            "title": "Minimum and Maximum Top up amount"
        },
        "driverApp": "Aplicación de conductor",
        "passengerApp": "Aplicación para pasajeros",
        "Default_Topup_Values": "Importe de recarga predeterminado",
        "service_fees": "Tarifas de servicios",
        "commission_fleet": "Default Fleet Commission",
        "apply_all": "Lo mismo para todas las zonas de operación.",
        "apply_custom_zone": "Separados en cada zona de operación",
        "apply": "Solicitar",
        "tollFeeDriverCanInput": "Cantidad limitada que puede ingresar el conductor",
        "unlimited": "Ilimitado",
        "limited": "Limitado",
        "sameFee": "Misma tarifa",
        "customizedAirportfee": "Tarifa de aeropuerto personalizada",
        "noteAirportFeeModal": "Nota: Cualquier aeropuerto que no esté en la lista no se le aplicará la tarifa",
        "intercity_schedule_trip_time_range": "To ensure the chronological order of booking time, please make sure the time-range of preferred/request trips is larger than the schedule trips",
        "airportFee": {
            "tooltipApply": "La tarifa de aeropuerto predeterminada será aplicada por cada viaje desde y hacia el aeropuerto por defecto. Si la tarifa es personalizada, la nueva tarifa será aplicada.",
            "titleSearchAiportZone": "Buscar el aeropuerto e introducir la tarifa",
            "airport": "Aeropuerto",
            "currency": "Divisa",
            "fromAirportFee": "Desde la tarifa del aeropuerto",
            "toAirportFee": "Tasa de aeropuerto",
            "action": "Comportamiento"
        },
        "Yes": "Sí",
        "No": "No",
        "tipPaymentMethod": "Métodos de pago disponibles para que el cliente le dé propina a su conductor después del viaje",
        "allowPaxUpdateTip": "Allow passenger update tip percent during ride ",
        "tipDefaultValues": "Consejo predeterminado después del viaje",
        "creadit": "Tarjeta de crédito (Tarjeta personal / Tarjeta corporativa)",
        "wallet": "Cartera",
        "tnGeWallet": "Monedero electrónico TnG",
        "vippseWallet": "Vipps",
        "DurationRegularBooking": "Duración (para reservas regulares)",
        "googlePay": "Google Pay",
        "applePay": "Apple Pay",
        "DurationRegularBookingToolTip": "No se mostrará el botón Mostrar después de que el conductor toque el botón \"Llegó\" en la aplicación del conductor.",
        "noShowCountDown": {
            "customizedNoShow": "Configuración para ubicaciones específicas (aeropuerto, etc)",
            "customizedNoShowTitle": "Aplicar antelación No Show para ubicaciones específicas (aeropuerto, etc)"
        },
        "maxWaitTime": "Max extra wait time",
        "maxWaitTimeToolTip": "This is the maximum extra waiting time that will be charged to the customer. You have the flexibility to customize the per-minute fee in your rate settings.",
        "maxWaitTimePlaceholder": "Maximum charge wait time",
        "HEAT_MAP": "Mapa de demanda",
        "heatMap": {
            "defaultMode": "Modo por defecto",
            "radius": "Radio",
            "intensity": "Intensidad",
            "opacity": "Opacidad",
            "requestedBookings": "Reservas solicitadas",
            "activePassengers": "Pasajeros activos",
            "completedBookings": "Reservas completadas",
            "timeoutBookings": "Reservas de tiempo fuera",
            "tooltipRadius": "El radio de influencia de cada punto de datos, en píxeles.",
            "tooltipIntensity": "La intensidad máxima del mapa de calor.",
            "tooltipOpacity": "La opacidad del mapa de calor, expresada como un número entre 0 y 1."
        },
        "schedule_trip": "Schedule trip",
        "driver_starts_trip_before_set_time": "Driver starts trip before set time",
        "request_trip": "Request trip",
        "preferred_trip": "Preferred trip",
        "time_range": "Time range for request and preferred trip",
        "working_time": "Working time",
        "hour": "hour",
        "hours": "hours",
        "minutes": "minutes",
        "indicate_pickup_time_in_advance_to_customer": "Indicate pickup time in advance to customer.",
        "customers_can_select_start_time_and_request_intercity_trip": "Customers can select start time and request intercity trip. Drivers also can create their preferred trip with their own schedule. Example: a time range of 1 hour will allow customers and drivers to create an intercity trip starts at 8:00 am - 9:00 am, 9:00 am - 10:00 am, or 10:00 am - 11:00 am.",
        "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "This setting limits customers to create request trip within working hours only.",
        "face_mask_verify": "Face mask verification",
        "faceMask_allZones": "All zones",
        "faceMask_customized": "Customized zone",
        "turn_on_verify": "Turn on verification",
        "force_to_wear_face_mask": "Force to wear face mask",
        "time_range_to_verify": "Do not verify again within",
        "customizedFaceMaskZone": "Customized face mask verification",
        "zoneOperation": "Operation Zone",
        "FaceMaskCustomized": "Customized",
        "time": "time",
        "day": "day",
        "days": "days",
        "allow_driver_to_skip_verification_after": "Allow driver to skip verification after",
        "driver_need_to_verify": "When driver need to verify",
        "open_app": "Open app",
        "start_reservation": "Start reservation",
        "choose_at_least_one": "You must select at least one action to continue.",
        "face_mask_customized_fail": "You must select at least one action to continue ({0}).",
        "vehicleAgeLimit": "Vehicle age limit",
        "vehicleAgeLimit_years": "years",
        "founderFund_firstBooking": "Founder's Fund: first booking only",
        "distance_diversity": "Distance diversity",
        "Add_Surcharge_Distance": "Add",
        "from": "From",
        "to": "To",
        "surchargeValue": "Surcharge",
        "actions": "Action",
        "please_enter_distance_range_valid": "Distance %{name} is invalid!",
        "please_enter_distance_to": "Please enter distance \"To\"",
        "please_enter_surchargeValue": "Please enter distance \"Surcharge\" for distance range %{name}",
        "please_enter_surchargeValue_is_number": "Please enter valid number \"Surcharge\" for distance range %{name}",
        "Update_service_fee_success": "Service fees has been updated successfully",
        "Update_service_fee_fail": "The errors occurred while updating service fees",
        "Update_fleet_commission_success": "Default fleet commision has been updated successfully",
        "Update_fleet_commission_fail": "The errors occurred while updating default fleet commission",
        "RESERVATION_POLICY": "Reservation policy",
        "default_expected_pickup_time": "Default expected pickup time",
        "default_expected_pickup_time_hint": "The default value you expect a driver to arrive from the pickup time.",
        "same_original_pickup_time": "Same as original pickup time",
        "quarter_before": "15 min before",
        "half_before": "30 min before",
        "quarter_after": "15 min after",
        "half_after": "30 min after",
        "custom": "Custom",
        "min_before": "min before",
        "min_after": "min after",
        "TransactionFee": "Transaction fee",
        "creditCardPercent": "Credit card/ prepaid",
        "directBilling": "Direct billing",
        "externalCard": "External Card"
    },
    "social": {
        "pax_app": "Passenger app",
        "driver_app": "Driver app",
        "default": "Default",
        "customized": "Customized",
        "html": "HTML",
        "about": "About",
        "email": "Email support",
        "enablePhone": "Phone number",
        "phone": "",
        "policy": "Link Privacy Policy",
        "termOfUse": "Link Terms of Use",
        "site": "Website",
        "logoDriverIOS": "Logo - IOS",
        "logoDriverAndroid": "Logo - Android",
        "htmlUrl": "HTML file",
        "Update_fail": "The errors occurred while updating social",
        "Update_success": "The social has been updated successfully"
    },
    "user": {
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "Search_here": "Busca aquí",
        "show_pages": "{0} - {1} de {2} mostrado",
        "Username": "nombre de usuario",
        "Name": "Nombre",
        "Phone_number": "Número de teléfono",
        "Email": "Correo electrónico",
        "Permission": "Permisos",
        "Status": "Estado",
        "Actions": "Acciones",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Edit": "Editar",
        "Deactivate": "Desactivar",
        "Delete": "Borrar",
        "Add": "Agregar",
        "ADD_USER": "Agregar usuario",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "AgendId": "Usuario #",
        "Email_address": "Dirección de correo electrónico",
        "Address": "Habla a",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "EDIT_USER": "Editar usuario",
        "Yes": "Sí",
        "DELETE_USER": "Borrar usuario",
        "DETAIL_USER": "Detalle de usuario",
        "DELETE_CONFIRM": "¿Está seguro de que desea eliminar el usuario seleccionado?",
        "Update_user_fail": "Los errores ocurrieron al actualizar el usuario",
        "Update_user_success": "El usuario ha sido actualizado con éxito",
        "Create_user_fail": "Los errores ocurrieron al crear el usuario.",
        "Create_user_success": "La cuenta {0} se ha creado correctamente.  Consulte el correo electrónico {1} para obtener los detalles de las credenciales de inicio de sesión.",
        "Load_user_fail": "Los errores ocurrieron al cargar el usuario",
        "Update_user_status_fail": "Los errores ocurrieron al actualizar el estado del usuario",
        "Delete_user_fail": "Los errores ocurrieron al eliminar el usuario",
        "Delete_user_success": "El usuario ha sido eliminado con éxito",
        "CONFIRM_RESET_PASSWORD": "¿Quieres restablecer la contraseña?",
        "RESET_PASSWORD": "¿Quieres restablecer la contraseña?",
        "Reset_password": "¿Quieres restablecer la contraseña?",
        "Select_permission": "Seleccionar permiso",
        "Reset_password_success": "Restablecer contraseña con éxito"
    },
    "report": {
        "query": {
            "errorMessage": {
                "bookFrom": "Por favor seleccione reserva de",
                "paymentMethod": "Por favor seleccione el método de pago",
                "bookingType": "Elegir tipo de reserva",
                "ratingType": "Seleccione el tipo de calificación",
                "stars": "Seleccione el tipo de calificación",
                "type": "Tipo",
                "transactionType": "Seleccione el tipo de transacción",
                "serviceType": "Seleccione el tipo de servicio",
                "rideService": "Seleccione tipo de trayecto",
                "invalidEmail": "Por favor, introduce una dirección de correo electrónico válida."
            },
            "search": {
                "driverReferalPax": "Buscar: nombre del conductor, número de teléfono",
                "passengerRefersPassengers": "Search: Customer Name, Phone Number",
                "driverReferalPaxDetail": "Buscar: Nombre del pasajero",
                "bookingDetails": "Buscar: ID reserva, Operador, teléfono cliente, nombre cliente, nombre conductor, DNI, matrícula, área, notas",
                "financial": "Buscar: Nombre conductor, Teléfono",
                "completedTime": "Completed Time",
                "collectedTime": "Collected Time",
                "financialDriver": "Buscar: ID reserva, Nombre conductor, DNI",
                "carActivity": "Buscar: Matrícula",
                "affiliation": "Buscar: ID Reserva",
                "prepaidTopUp": "Buscar: nombre empresa, recarga ID, transacción",
                "operatorLogs": "Buscar: nombre operador, usuario, descripción",
                "corporate": "Buscar: ID reserva, nombre conductor, viajero corporativo, código interno cliente, código cargo",
                "bookingLogs": "Buscar: ID reserva, nombre cliente, nombre conductor, DNI",
                "rating": "Buscar: ID de reserva, teléfono del cliente, nombre del cliente, número de placa",
                "mDispatcher": "Buscar: ID de reserva, nombre del socio",
                "totalAdjustment": "Buscar: ID Reserva",
                "incidentCancellation": "Buscar: ID reserva, teléfono cliente, nombre cliente",
                "acceptanceCancellationRate": "Buscar: nombre del conductor, número de teléfono",
                "revenueDriver": "Buscar: nombre conductor",
                "dailyDriver": "Buscar: teléfono del conductor, nombre del conductor, ID# / IC# nacional",
                "dailyCar": "Buscar: matrícula, permiso",
                "dailyDriverCar": "Buscar: teléfono del conductor, nombre del conductor, ID#/IC# nacional, matrícula",
                "driverLoginStatus": "Buscar: teléfono del conductor, nombre del conductor, ID#/IC# nacional, matrícula",
                "bookingSummary": "Buscar: nombre operador, usuario, ID reserva",
                "creditWallet": "Buscar: nombre empresa, nombre conductor, ID reserva, operador",
                "cashWallet": "Buscar: nombre empresa, nombre conductor, ID reserva, Phone number",
                "settlement": "Buscar: nombre del conductor, ID# / IC# nacional",
                "driverRating": "Buscar: ID de reserva, teléfono del cliente, nombre del cliente, número de placa",
                "driverWithdrawal": "Nombre del conductor, teléfono, empresa, ID de transacción, número de referencia",
                "penaltyCompensation": "Búsqueda: ID de reserva, nombre del conductor, nombre del pasajero",
                "incompletePayment": "Buscar: ID de reserva, nombre del cliente",
                "passengerWallet": "Search: Booking ID, Customer Name",
                "driverDeposit": "Buscar: nombre empresa, nombre conductor, ID reserva, operador",
                "refund": "Search: Booking ID, Trip Id, Customer name",
                "driverRefersCustomerBookings": "Search: Referral code",
                "driverHistory": "Search: Payout ID",
                "merchantHistory": "Search: Payout ID",
                "firstWillWin": "Search: customer name, customer phone, customer ID",
                "firstWillWinDriver": "Search: driver name, driver phone, username",
                "merchant": "Search: store",
                "merchantCashTransactionHistory": "Search: Booking ID, ID de transacción, Merchant name",
                "merchantCreditTransactionHistory": "Search: Booking ID, ID de transacción, Merchant name",
                "deleteAccountRequest": "Search: Phone",
                "affiliationPayoutHistory": "Search: Payout ID",
                "thirdPartyBooking": "Search: Booking Id, External Id"
            },
            "common": {
                "all": "Todo"
            },
            "today": "Hoy dia",
            "sendDownloadLink": "Recipient Email Address",
            "generaltingYourExcel": "Please note, generating your excel file may take some time. We'll email it to you as soon as it's ready. ",
            "messageSuccess": "File in progress. You'll get an email when ready. Track progress or download directly from Reports/Export History",
            "thisMonth": "Este mes",
            "lastMonth": "El mes pasado",
            "past30Days": "últimos 30 días",
            "thisYear": "Este año",
            "custom": "Personalizado",
            "customize": "Personalizar",
            "thisWeek": "Esta semana",
            "past7Days": "Últimos 7 días",
            "weekly": "Semanalmente",
            "monthly": "Mensual",
            "allTime": "All time",
            "tomorrow": "Tomorrow",
            "nextWeek": "Next Week",
            "lastWeek": "Last Week",
            "nextMonth": "Next Month",
            "yesterday": "Yesterday",
            "fromDate": "Partir de la fecha",
            "toDate": "Hasta la fecha",
            "driver": "Conductor: %{name}",
            "customer": "Customer: %{name}",
            "referralCode": "Referral Code: %{name}",
            "referer": "Referer: %{name}",
            "referee": "Referee: %{name}",
            "usedPromoCode": "Promoción: %{name}",
            "voucherCode": "Code: %{name}",
            "city": "City",
            "company": "Compañía",
            "company_report": "Empresa: %{name}",
            "campaign_report": "Campaign: %{name}",
            "operator": "Operador",
            "farmType": "Tipo de comercial",
            "carLicensePlate": "Matrícula del auto: %{name}",
            "settlementType": "Type",
            "bookingService": "Network type",
            "payoutOption": "Payout option",
            "currency": "Divisa",
            "bookingFrom": "reserva desde",
            "thirdPartyAction": "Action",
            "paymentMethod": "Método de pago",
            "paymentStatus": "Estado de pago",
            "bookingType": "Tipo de reserva",
            "adjustPrice": "Adjust price",
            "intercityRoute": "Intercity route",
            "ratingType": "Tipo de evaluación",
            "stars": "Evaluación",
            "driverRating": "Evaluación del conductor",
            "vehicleRating": "Evaluación del vehículo",
            "mDispatcherType": "tipo de socio",
            "mDispatcher": "Nombre del socio",
            "campaign": "Campaña",
            "promocode": "Código promocional",
            "canceledBy": "Cancelado por",
            "timezone": "Zona horaria",
            "month": "Mes",
            "year": "Año",
            "view": "Vista",
            "action": "Acción",
            "status": "Estado",
            "module": "Módulo",
            "driverType": "Tipo",
            "vehicleType": "Tipo de carro",
            "serviceType": "Tipo de servicio",
            "expireDate": "Fecha de caducidad",
            "rideSharing": "Compartir viaje",
            "rideService": "servicio de paseo",
            "transactionType": "Tipo de transacción",
            "completeFromCC": "Completar desde CC",
            "withDrawalTransactionType": "tipo de transacción",
            "bookingStatus": "Estado de la reservación",
            "withdrawalStatus": "Estado",
            "originalStatus": "Estado de pago original",
            "currentStatus": "Estado de pago actual",
            "tip": "Propina",
            "type": "Tipo",
            "userType": "User type",
            "eventType": "Event type",
            "eventState": "Event state",
            "eventName": "Event name",
            "merchantType": "Merchant type",
            "merchantService": "Merchant service",
            "storeName": "Store",
            "merchant": "Merchant",
            "event": "Event name: %{name}",
            "store": "Store name: %{name}",
            "selectedMerchant": "Merchant: %{name}",
            "associatedApps": "Associated apps",
            "settlement": "Settlement",
            "rideServiceItem": {
                "all": "Todo",
                "regulation": "Regulares",
                "rideSharing": "viaje compartido"
            },
            "actionItem": {
                "all": "Todo",
                "login": "Iniciar sesión",
                "logout": "Cerrar sesión",
                "bookIn": "Disponible",
                "bookOff": "No disponible",
                "deny": "Rechazar",
                "ignore": "Ignorar",
                "accept": "Aceptar",
                "startTrip": "En camino",
                "updateBooking": "Actualizar",
                "arrive": "Llegar",
                "pickup": "Levantar",
                "drop": "Dejar",
                "complete": "Completo",
                "noShow": "no show",
                "rqJob": "despacho",
                "cancel": "Cancelar"
            },
            "loginStatusItem": {
                "all": "Todo",
                "online": "Iniciando sesión",
                "offline": "Desconectado"
            },
            "payoutType": "Payout type",
            "affiliationPayoutTypeItem": {
                "auto": "Auto payout",
                "manual": "Manual payout"
            },
            "affiliationSettlementItem": {
                "payable": "Payable",
                "receivable": "Receivable"
            },
            "affiliationPaymentMethod": {
                "online": "Online",
                "directInvoicing": "Direct invoicing"
            },
            "payoutPaymentStatusItem": {
                "paid": "Paid",
                "pending": "Pending"
            },
            "farmTypeItem": {
                "all": "Todo",
                "farmIn": "Finca en",
                "farmOut": "externalizar",
                "roaming": "Roaming",
                "affiliationOwner": "propietario de la afiliación",
                "farmInAndAffiliationOwner": "Propietario de finca y afiliación",
                "farmOutAndAffiliationOwner": "Farm out y propietario de la afiliación"
            },
            "settlementDriverTypeItem": {
                "all": "Todo conductor",
                "individual": "Vehículo de la empresa"
            },
            "shortTrip": {
                "all": "Todo",
                "shortTrip": "Viaje corto",
                "nonShortTrip": "Viaje no corto"
            },
            "paymentMethodItem": {
                "B2BTerminal": "B2BTerminal",
                "webBooking": "reserva web",
                "pendingPayment": "Pago pendiente",
                "all": "Todo",
                "cash": "Dinero en efectivo",
                "cashBySender": "Efectivo por remitente",
                "cashByRecipient": "Efectivo por destinatario",
                "personalCard": "Tarjeta personal",
                "corporateCard": "Tarjeta corporativa",
                "qrCodePayment": "Código QR",
                "directBilling": "facturación directa",
                "externalCard": "tarjeta externa",
                "mDispatcherCard": "tarjeta de socio",
                "corporatePrepaid": "prepago corporativo",
                "canceled": "Cancelado",
                "noShow": "No presentarse",
                "incident": "Incidencia",
                "fleetCard": "Tarjeta externa",
                "applePay": "Apple Pay",
                "creditCardExternal": "Tarjeta de crédito (externa)",
                "paxWallet": "Cartera",
                "tngeWallet": "Monedero electrónico TnG",
                "vippseWallet": "Vipps",
                "zainCash": "ZainCash",
                "xendit": "Xendit",
                "FIB": "FIB",
                "SantimPay": "SantimPay",
                "gCash": "GCash",
                "completedWithoutService": "Completed without driver",
                "BankOfGeorgia": "Bank Of Georgia",
                "partialCompleted": "Partial Completed",
                "failed": "Failed",
                "googlePay": "Google Pay",
                "paymentLink": "Payment Link",
                "easyPaisa": "Easy Paisa",
                "jazzCash": "Jazz Cash"
            },
            "paymentStatusItem": {
                "all": "Todos",
                "fullPayment": "Pago completo",
                "partialPayment": "Pago parcial",
                "pendingPayment": "Pago pendiente",
                "partialRefund": "Partial refund",
                "fullRefund": "Full refund"
            },
            "companySettlementTypeItem": {
                "all": "All",
                "fleetOweCompany": "Fleet owe company",
                "companyOweFleet": "Company owe fleet"
            },
            "bookingStatusItem": {
                "all": "Todo",
                "completed": "Terminado",
                "incident": "Incidente",
                "paxNoShow": "Pax no se presenta",
                "driverNoShow": "Conductor no show",
                "rejected": "Rechazado",
                "canceledByTimeOut": "Cancelado por tiempo de esperal",
                "canceledByTimeOutRejected": "Cancelado por tiempo de espera (rechazado)",
                "canceledByBookingBoard": "Cancelado por la junta de reservas",
                "canceledByCustomer": "Canceled by customer",
                "canceledBySupplier": "Canceled by supplier"
            },
            "bookingFromItem": {
                "all": "Todo",
                "paxApp": "App de pasajero",
                "mDispatcher": "Compañero",
                "kiosk": "terminal",
                "cc": "Centro de comando",
                "webBooking": "Reservas Web",
                "carHailing": "Coche de granizo",
                "streetSharing": "Street-sharing",
                "dashboard": "Panel de reservas",
                "corp": "Tablón de reservas (CTM)",
                "dmc": "Tablero de reservas (DMC)",
                "Boost_web_link": "Boost web link"
            },
            "thirdPartyActionItem": {
                "all": "All",
                "searched": "Searched",
                "allocated": "Allocated",
                "updated": "Updated",
                "cancelled": "Cancelled"
            },
            "bookingTypeItem": {
                "batchDelivery": "Batch Delivery",
                "all": "Todo",
                "now": "Ahora",
                "reservation": "Reserva",
                "intercity": "interurbano",
                "delivery": "Delivery",
                "shuttle": "Lanzadera"
            },
            "adjustPrices": {
                "all": "All",
                "normalPrice": "Normal price",
                "addonPrice": "Addon price"
            },
            "bookingServiceItem": {
                "all": "Todo",
                "roaming": "Roaming",
                "homeFleet": "externalizar",
                "localBooking": "Reserva local",
                "farmIn": "Finca en",
                "farmInAndAffiliationOwner": "Propietario de finca y afiliación",
                "provideService": "Finca en"
            },
            "ratingTypeItem": {
                "all": "Todos",
                "like": "Me gusta",
                "dislike": "Disgusto"
            },
            "starsItem": {
                "all": "Todo",
                "star1": "1 estrella",
                "star2": "2 estrellas",
                "star3": "3 estrellas",
                "star4": "4 estrellas",
                "star5": "5 estrellas"
            },
            "incidentCanceledTypes": {
                "all": "Todo",
                "incident": "Incidente",
                "canceled": "Cancelado"
            },
            "canceledByItem": {
                "all": "Todos",
                "CorpAD": "Administrador corporativo",
                "passenger": "Pasajero",
                "driver": "Conductor",
                "merchant": "Merchant",
                "CC": "Central Reservas",
                "mDispatcher": "Compañero",
                "Partner": "Compañero",
                "webBooking": "Reservas Web",
                "timeout": "Se acabó el tiempo"
            },
            "bookingSummaryActionItem": {
                "all": "Todos",
                "create": "Crear",
                "update": "Actualizar",
                "cancel": "Cancelar",
                "complete": "Completo",
                "incident": "Incidente"
            },
            "transactionTypeItem": {
                "bankAccount": "Transferido a cuenta bancaria",
                "sentToCustomer": "Send to customer",
                "receivedFromDriver": "Received from driver",
                "sentToDriver": "Sent to driver",
                "creditWallet": "Transferido a billetera de crédito",
                "netEarning": "Ganancia neta",
                "referralEarning": "Ganancia de referencia",
                "all": "Todos",
                "topUp": "Completar",
                "editBalance": "Editar saldo",
                "bookingDeduction": "Deducciones en Reservas",
                "bookingPromo": "Booking promotion",
                "topUpGCash": "Recargar por Gcash",
                "cashWallet": "billetera en efectivo",
                "topUpMOLPay": "Recarga con Razer Pay",
                "topUpZainCash": "Top up by ZainCash",
                "topUpXendit": "Top up by Xendit",
                "topUpFIB": "Top up by FIB",
                "topUpSantimPay": "Top up by SantimPay",
                "topUpTelebirr": "Top up by Telebirr",
                "topUpKsher": "Top up by Ksher",
                "topUpWallet": "Top up by {0}",
                "TnG": "Recarga por TnG",
                "credit": "Recarga con tarjeta de crédito",
                "ride": "Montar",
                "tipAfterCompleted": "Consejo después del viaje",
                "debtWriteOff": "Debt write-off",
                "refund": "Reembolso",
                "excessOffsetAmount": "Excess Cash Credit",
                "excessDeductionAmount": "Excess Cash Debit",
                "cashExcess": "Excess Cash Debit",
                "gateway": "Top up from gateway",
                "driverTopUpForPax": "Excess Cash Debit",
                "updatedBalance": "Updated balance",
                "partialEarning": "Earning",
                "payout": "Payout manual",
                "payoutManual": "Payout manual",
                "payoutDriver": "Payout",
                "itemValue": "Item values",
                "redeemVoucher": "Redeem code #",
                "topUpReferrer": "Referral reward",
                "driverInsurance": "Driver Insurance",
                "cancellationPenalty": "Cancellation Penalty",
                "WingBank": "Transfers to Wingbank account",
                "customerDebt": "Customer Debt",
                "googlePay": "Top up by Google Pay",
                "applePay": "Top up by Apple Pay",
                "merchantCommission": "Merchant commission",
                "eWallet": "Transferred to external e-wallet",
                "reversed": "Withdrawal reversed"
            },
            "moduleItem": {
                "all": "Todos",
                "bookings": "Reservas",
                "customers": "Clientes particulares",
                "dispatch": "Asignar",
                "general": "General",
                "promotion": "Promociones",
                "quest": "Quest",
                "rate": "Tarifas",
                "car": "Vehículo",
                "city": "Ciudad",
                "company": "Compañía",
                "drivers": "Conductores",
                "driverSettlement": "Liquidación del conductor",
                "login": "Registro",
                "logout": "Cerrar sesión",
                "mDispatcher": "Compañero",
                "mDispatcherType": "tipo de socio",
                "operation": "Operaciones",
                "partners": "Colaboradores",
                "permission": "Permisos",
                "queuingArea": "zona de cola",
                "report": "Informe",
                "shift": "Turno",
                "user": "Usuario",
                "voiceSms": "Voz & SMS",
                "intercityZone": "Zone (intercity)",
                "Payout": "Payout",
                "code#": "Code #",
                "ApiManagement": "API management"
            },
            "serviceTypeItem": {
                "all": "Todo",
                "oneWay": "Ida",
                "fromAirport": "Desde Aeropuerto",
                "toAirport": "Al Aeropuerto",
                "hourly": "Por horas / días",
                "roundTrip": "Ida y vuelta",
                "fromAirportHourlyDaily": "Desde aeropuerto - por horas/días",
                "fromAirportRoundTrip": "Desde aeropuerto - Ida y vuelta",
                "toAirportHourlyDaily": "Al aeropuerto - Por horas/días",
                "toAirportRoundTrip": "Al aeropuerto - Ida y vuelta"
            },
            "merchantTransactionTypeItem": {
                "all": "All",
                "editBalance": "Edit balance",
                "payout": "Payout",
                "earning": "Earning",
                "itemValue": "Item value",
                "orderDeduction": "Order deduction"
            },
            "withdrawalTransactionType": {
                "WingBank": "Transferred to Wingbank account",
                "bankAccount": "Transferido a cuenta bancaria",
                "cashWallet": "Transferido a monedero en efectivo",
                "creditWallet": "Transferido a la billetera de crédito"
            },
            "payoutDriver": {
                "driversHaveBankAccount": "Drivers have bank account",
                "driversDontHaveBankAccount": "Drivers don't have bank account",
                "payoutEndTime": "Payout end time",
                "driverHaveInvalidBankAccount": "Drivers have invalid bank account"
            },
            "payoutMerchant": {
                "HaveBankAccount": "Merchants have bank account",
                "DontHaveBankAccount": "Merchants don't have bank account",
                "HaveInvalidBankAccount": "Merchants have invalid bank account",
                "payoutEndTime": "Payout end time"
            }
        },
        "error": {
            "23005": "For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range"
        },
        "result": {
            "idPermitType": "Tipo de identificación",
            "noResultFound": "No se han encontrado resultados",
            "page": "Página",
            "itemsPerPage": "elementos por página",
            "showPages": "Mostrados %desde - %a de %",
            "exportToExcel": "Exportar a Excel",
            "exportToCSV": "Export to csv",
            "exportToPaymentFile": "Export to payment file",
            "pay": "Pagar",
            "paySelected": "Pay selected",
            "payAll": "Pay all",
            "invalidBankAccount": "Invalid bank account",
            "validBankAccount": "Valid bank account",
            "bookingDetails": {
                "successSendMessage": "Your message has been sent",
                "packageName": "Package Name",
                "transactionId": "ID de transacción",
                "proofOfFailure": "Proof Of Failure",
                "recipientsNumber": "No. of recipient",
                "recipientsInfo": "Recipient info",
                "creditTransactionFee": "Credit card fees",
                "carTypeRequest": "Requested cartype",
                "bookingId": "Reserva #",
                "bookingStatus": "Booking status",
                "tripId": "Trip #",
                "bookFrom": "reserva desde",
                "bookingService": "Network type",
                "bookingType": "Tipo de reserva",
                "serviceType": "Tipo de servicio",
                "operator": "Operador",
                "customerPhone": "Número de teléfono del pasajero",
                "customerName": "Nombre del pasajero",
                "customerIdNumber": "Pasajero ID# / IC#",
                "corpDivision": "Departamento",
                "corpDept": "Depart.",
                "chargeCode": "Charge code",
                "tripDescription": "Trip description",
                "rideSharing": "viaje compartido",
                "queueName": "zona de cola",
                "bookingTime": "tiempo de reserva",
                "pickupTime": "Hora de recogida",
                "expectedPickupTime": "Expected Pickup Time",
                "dispatchTime": "Tiempo del envío",
                "onBoardTime": "tiempo a bordo",
                "droppedOffTime": "Hora de entrega",
                "onBoardDuration": "Duración usuario a bordo",
                "dispatch": "Asignación",
                "completedTime": "Tiempo completado",
                "driverName": "Nombre del conductor",
                "driverIdPermit": "Documento de Identidad",
                "driverLicenseNumber": "Número de licencia de conducir",
                "companyName": "Compañía",
                "carType": "Tipo de carro",
                "licensePlate": "Placa",
                "intercityRoute": "Intercity route",
                "pickupLocation": "Lugar de recogida",
                "geoPickup": "Lugar de recogida latitud/longitud",
                "extraDestination": "Destinos adicionales",
                "originDestination": "Destino inicial",
                "originEstDistance": "Distancia estimada inicial",
                "destination": "destino real",
                "geoDestination": "Latitud/longitud del destino real",
                "pickupFrom": "Punto de recogida desde",
                "distanceTour": "distancia P.O.B",
                "internalNotes": "Internal notes",
                "notes": "Notas",
                "basicFareCalculator": "Calculadora tarifa base",
                "basicFare": "Tarifa básica",
                "editFare": "Editar tarifa base",
                "oldBasicFare": "Tarifa base anterior",
                "airportFee": "Tarifa de aeropuerto",
                "meetDriver": "Encuentro con conductor",
                "rushHour": "Recargo",
                "surchargeParameter": "Recargo dinámico",
                "dynamicFare": "Tarifa dinámica",
                "heavyTraffic": "Tráfico pesado",
                "tollFee": "Peaje",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "otherFees": "Otros cargos",
                "subtotal": "Total parcial",
                "subTotalFF": "Subtotal FF",
                "techFee": "Tarifa técnica",
                "serviceFee": "Servicios adicionales",
                "fleetServiceFee": "Fleet services",
                "bookingFee": "Tarifa de reserva",
                "salesTax": "Impuesto de venta",
                "tipAmt": "Cantidad de propina",
                "promoCode": "Código promocional",
                "promoAmt": "Promociones",
                "requestedTotalAmount": "Requested total amount",
                "total": "Importe total",
                "totalNotRounded": "Ronda (cantidad total)",
                "actualReceived": "importe recibido",
                "differenceAmt": "Importe de diferencia",
                "customerDebt": "Customer Debt",
                "markupDifference": "Markup difference",
                "receivedWhenDropOff": "Received when drop off",
                "refundAmount": "Refund amt",
                "requestedPaymentMethod": "Método de pago solicitado",
                "paidBy": "Pagado por",
                "splitPayment": "Split payment",
                "paidByWallet": "Paid by wallet",
                "paidByOtherMethod": "Paid by other method",
                "cashReceived": "Cash received",
                "transferredChangeByWallet": "Transfer change by wallet",
                "returnedChangeByCash": "Return change by cash",
                "instructions": "Instrucciones",
                "additionalServiceName": "Nombre",
                "additionalServiceValue": "Valor",
                "sendReceiptHeader": "",
                "sendReceipt": "Enviar recibo",
                "send": "Enviar",
                "cancel": "Cancelar",
                "sentReceiptSuccess": "El recibo ha sido enviado correctamente",
                "sentReceiptFailed": "El recibo no se puede enviar en este momento.  Inténtalo de nuevo.",
                "coRider": "Co-viajero",
                "coriderName": "Nombre",
                "stopAddress": "destino adicional",
                "stopGeo": "Latitud longitud",
                "addonPrice": "Customer adjust price",
                "oldSubTotal": "Subtotal before adjust",
                "view_good_receipt": "Goods receipt",
                "view_delivery_receipt": "Delivery photo",
                "view": "View",
                "tipAfterCompletedDetails": "Transferred: {0}{1}; Transaction fee: {0}{2}",
                "fleetMarkup": "Profit",
                "editedDriverEarning": "Edited driver earning",
                "driverEarning": "Driver earning",
                "pickUpZoneName": "Pickup zone",
                "dynamicFactor": "Dynamic fare (factor)"
            },
            "financial": {
                "driverName": "Nombre del conductor",
                "driverPhone": "Número de teléfono",
                "driverUserName": "nombre de usuario",
                "companyName": "Compañía",
                "bookingList": "Detalle de reservas",
                "totalFare": "Tarifa total sin promoción",
                "netEarning": "Ganancia neta",
                "showBookingList": "Show",
                "referralEarning": "Ganancias derivadas",
                "totalEarningNet": "Ganancia total (neta)",
                "totalEarningNetTooltip": "Money you earned after returning all fees to fleet. Total earning (net) = Net earning + Tip after ride",
                "totalEarningNetTooltipRefferal": "Money you earned after returning all fees to fleet. Total earning (net) = Referal earning + Net earning + Tip after ride",
                "totalEarningGross": "Ganancia total (bruta)",
                "totalEarning": "Total ganancias",
                "totalEarningGrossTooltip": "Money you earned from booking fare and tips. Total earning (gross) = Gross earning + Tip after ride",
                "totalEarningGrossTooltipRefferal": "Money you earned from booking fare and tip. Total earning (gross) = Referal earning + Gross earning + Tip after ride",
                "note": "Nota",
                "shortTrip": "Viaje corto"
            },
            "financialDriver": {
                "supplierAmount": "Supplier commission",
                "payoutType": "Payout type",
                "date": "fecha",
                "driverTipAfterCompleted": "Consejo después del viaje",
                "bookingId": "ID reserva",
                "paidBy": "Pagado por",
                "bookFrom": "reserva desde",
                "bookingType": "Tipo de reserva",
                "companyName": "Compañía",
                "driverName": "Conductor",
                "driverIdPermit": "ID# / IC# Nacional",
                "totalFare": "Tarifa total sin promoción",
                "subtotal": "Total parcial",
                "airportFee": "Cargo aeropuerto",
                "meetDriver": "Encuentro con conductor",
                "tollFee": "Peaje",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "rushHour": "Hora punta",
                "transactionFee": "tarifa por transacción",
                "techFee": "Tarifa técnica",
                "salesTax": "Impuesto de venta",
                "tipAmt": "Cantidad de propina",
                "bookingFee": "Tarifa de reserva",
                "commission": "Comisión",
                "deduction": "Deducción",
                "promoAmt": "Promociones",
                "ridePayment": "Pago del trayecto",
                "driverTax": "Driver tax",
                "grossEarning": "Ganancias brutas",
                "driverDeduction": "Deducciones conductores",
                "netEarning": "Ganancia neta",
                "payToDriver": "Pagar al conductor",
                "payToFleet": "Pago a la flota",
                "totalNotRounded": "Ronda (cantidad total)",
                "fleetServiceFee": "Fleet service fees",
                "editedDriverEarning": "Edited driver earning",
                "stripeFee": "Stripe Fee"
            },
            "financialCompany": {
                "companyName": "Compañía",
                "homeFleetNetEarning": "Ganancia neta de la flota local",
                "affiliateNetEarning": "Ventas netas colaboradores",
                "totalNetEarning": "Total conductor ventas netas",
                "status": "Estado",
                "companyAmount": "Company profit"
            },
            "companySettlement": {
                "companyName": "Company",
                "pendingSettlement": "Pending settlement",
                "viewDetails": "View details",
                "settlementType": "Type"
            },
            "companySettlementDetails": {
                "bookId": "Booking ID",
                "dateTime": "Date time",
                "originalPaymentStatus": "Original payment status",
                "subTotal": "Subtotal",
                "fleetAmount": "Fleet amount",
                "driverAmount": "Driver amount",
                "companyAmount": "Company amount",
                "companyOweFleet": "Company owe fleet",
                "fleetOweCompany": "Fleet owe company",
                "notes": "Notes"
            },
            "financialProfit": {
                "profit": "Lucro",
                "bookingId": "Reserva #",
                "subTotal": "Total parcial",
                "subTotalFF": "Subtotal FF",
                "fleetCommission": "comisión de flota",
                "techFee": "Tarifa técnica",
                "promotion": "Promoción",
                "markupDifference": "Markup difference",
                "Profit": "Profit",
                "currency": "moneda",
                "fleetServiceFee": "Fleet service (fleet take)",
                "fleetServiceFeeTooltip": "Amount fleet takes commission from service fees"
            },
            "promotion": {
                "date": "fecha",
                "promoCode": "Código promocional",
                "users": "Usuarios",
                "uses": "Usos",
                "checked": "Checked",
                "applied": "Applied",
                "totalPromoAmount": "Importe total de la promoción",
                "totalReceipt": "Recibo total",
                "numberOfUsers": "Number of users",
                "numberOfPromoCodeAppliance": "Number of promo code appliance",
                "numberOfSuccessfulBookings": "Number of successful bookings",
                "numberOfCompletedBookings": "Number of completed bookings",
                "numberOfCompletedUsers": "Number of completed users",
                "numberOfUsersApplyPromotion": "Number of users apply promotion",
                "numberOfUsersApplyPromotionAndCompleteBooking": "Number of users apply promotion & complete booking",
                "numberOfPromoCodeApplianceTooltip": "Number of promo code appliance by all users",
                "numberOfSuccessfulBookingsTooltip": "Number of bookings created successfully with promotion code",
                "numberOfCompletedBookingsTooltip": "Number of completed bookings which applied promotion code"
            },
            "export": {
                "createdDate": "Date",
                "sessionName": "Session Name",
                "download": "Download",
                "delete": "Delete",
                "percent": "Percent",
                "status": "Status",
                "name": "Module name"
            },
            "redeemed": {
                "date": "Date",
                "code": "Code",
                "customers": "Customer",
                "phone": "Phone number",
                "value": "Value",
                "currency": "Currency",
                "campaignName": "Campaign"
            },
            "carActivity": {
                "plateNumber": "Matrícula de coche",
                "vehicleType": "Tipo de carro",
                "companyName": "Compañía",
                "day1st": "1º",
                "day2nd": "2º",
                "day3rd": "3º",
                "day4th": "4º",
                "day5th": "5º",
                "day6th": "6º",
                "day7th": "7º",
                "day8th": "8º",
                "day9th": "9º",
                "day10th": "10º",
                "day11th": "11º",
                "day12th": "12º",
                "day13th": "13º",
                "day14th": "14º",
                "day15th": "15º",
                "day16th": "16º",
                "day17th": "17º",
                "day18th": "18º",
                "day19th": "19º",
                "day20th": "20º",
                "day21st": "21º",
                "day22nd": "22º",
                "day23rd": "23º",
                "day24th": "24º",
                "day25th": "25º",
                "day26th": "26º",
                "day27th": "27º",
                "day28th": "28º",
                "day29th": "29º",
                "day30th": "30º",
                "day31st": "31º",
                "operationDays": "Días de operación",
                "activeCars": "Total vehículos activos:"
            },
            "carActivityLogsTitle": "Registros",
            "carActivityLogs": {
                "licensePlate": "Matrícula de coche",
                "companyName": "Compañía",
                "bookingId": "ID reserva",
                "driverName": "Nombre del conductor",
                "driverIDNumber": "ID# / IC# Nacional",
                "date": "hora aceptada",
                "bookFrom": "reserva desde"
            },
            "affiliation": {
                "date": "Hora de recogida",
                "bookingId": "ID reserva",
                "bookingType": "Tipo de reserva",
                "homeFleet": "Home Flota",
                "providerFleet": "flota proveedor",
                "driver": "Conductor",
                "passenger": "Pasajero",
                "paidBy": "Pagado por",
                "airportFee": "Tarifa de aeropuerto",
                "meetDriver": "Conozca al conductor",
                "rushHour": "Hora pico",
                "tollFee": "Peaje",
                "parkingFee": "Parking fee",
                "gasFee": "Gas fee",
                "heavyTraffic": "Tráfico pesado",
                "otherFees": "Otros cargos",
                "provider": "Proveedor",
                "basicFareProvider": "Tarifa base proveedor",
                "subTotalProvider": "Subtotal proveedor",
                "techFeeProvider": "cargo por tecnología proveedor",
                "bookingFeeProvider": "Tarifa por reserva proveedor",
                "taxValueProvider": "Importe impuestos proveedor",
                "tipProvider": "Propina proveedor",
                "totalFareProvider": "Total tarifa proveedor",
                "basicFare": "Tarifa básica",
                "subtotal": "Total parcial",
                "techFee": "Tarifa técnica",
                "bookingFee": "Tarifa de reserva",
                "tax": "Impuesto",
                "tip": "Propina",
                "totalFare": "total tarifa",
                "payoutInLocalCurrency": "Abono en moneda local",
                "payoutInUSD": "Pago en USD",
                "fleetCommInUSD": "comisión flota en USD",
                "bookingFeeInUSD": "Tarifa reserva en USD",
                "totalPayoutInUSD": "Total reembolso en USD",
                "subTotal": "Total parcial",
                "bookingStatus": "Estado de la reservación",
                "vehicle": "Vehicle",
                "customerPhone": "Customer phone number",
                "driverPhone": "Driver phone number",
                "total": "Total",
                "totalInUSD": "Total fare (USD)",
                "supplierPayout": "Supplier payout",
                "supplierPayoutInUSD": "Supplier payout (USD)",
                "buyerPayout": "Buyer commission",
                "buyerPayoutInUSD": "Commission (USD)",
                "buyerCommission": "Commission",
                "buyerCommissionInUSD": "Commission (USD)",
                "receivableInUSD": "Receivable (USD)",
                "payableInUSD": "Payable (USD)",
                "fleetMarkupInUSD": "Profit (USD)",
                "paymentMethod": "Payment method",
                "paymentStatus": "Payment status"
            },
            "prepaidTopUp": {
                "date": "Fecha y hora",
                "topUpId": "ID recarga",
                "transactionId": "ID de transacción",
                "name": "Nombre",
                "company": "Compañía",
                "paidBy": "Pagado por",
                "amount": "Monto",
                "newAmount": "New Balance",
                "transactionType": "Transaction type"
            },
            "operatorLogs": {
                "date": "fecha",
                "operator": "Operador",
                "username": "nombre de usuario",
                "module": "Módulo",
                "description": "Descripción"
            },
            "corporate": {
                "date": "fecha",
                "bookingId": "ID reserva",
                "operatorName": "Corp operator",
                "corporateName": "Compañía",
                "corpTraveler": "viajero del cuerpo",
                "travelerSignature": "Firma del viajero",
                "corpId": "ID de corporación",
                "corpDivision": "división de corporaciones",
                "costCentre": "Centro de coste",
                "corpPO": "PO corporativo",
                "managerName": "Nombre director",
                "managerEmail": "correo electrónico dirección",
                "title": "Cargo",
                "department": "departamento",
                "clientCaseMatter": "Código interno del cliente",
                "chargeCode": "Código de cargo",
                "driverName": "Conductor",
                "pickupLocation": "Lugar de recogida",
                "destination": "Destino",
                "total": "Importe total",
                "subtotal": "Total parcial",
                "subTotalFF": "Subtotal FF",
                "commission": "Comisión",
                "commissionBooking": "Apply commission for bookings from app/pwa",
                "paidBy": "Pagado por",
                "statusDisplay": "Estado",
                "flightNumber": "Flight number"
            },
            "bookingLogs": {
                "bookingId": "ID reserva",
                "bookingService": "Network type",
                "bookingType": "Tipo de reserva",
                "driverName": "Conductor",
                "driverIdPermit": "ID# / IC# Nacional",
                "customerName": "Pasajero",
                "pickupTime": "Hora de recogida",
                "onBoardTime": "tiempo a bordo",
                "droppedOffTime": "Hora de entrega",
                "pickupLocation": "Lugar de recogida",
                "destination": "Destino",
                "dispatchHistory": "Historial de envíos",
                "otwDistance": "Distancia \\En camino\\\"\"",
                "distanceTour": "Distancia pasajero a bordo",
                "duration": "Duración del P.O.B",
                "paidBy": "Pagado por",
                "bookFrom": "Reserva desde",
                "map": "Mapa",
                "showDispatchHistory": "Show",
                "showMap": "Show",
                "paymentActivities": "Payment activities"
            },
            "rating": {
                "date": "fecha",
                "bookingId": "ID reserva",
                "driverName": "Conductor",
                "driverIdPermit": "ID# / IC# Nacional",
                "customerName": "Nombre del cliente",
                "customerPhone": "teléfono cliente",
                "like": "Me gusta",
                "dislike": "Disgusto",
                "notes": "Comentario",
                "stars": "Clasificación",
                "vehicleRating": "Evaluación vehículo",
                "driverRating": "Evaluación conductor",
                "plateNumber": "Matrícula"
            },
            "mDispatcher": {
                "date": "fecha",
                "bookingId": "ID reserva",
                "mDispatcherName": "Nombre del socio",
                "mDispatcherType": "tipo de socio",
                "subtotal": "Total parcial",
                "commission": "Comisión",
                "paidBy": "Pagado por"
            },
            "totalAdjustment": {
                "date": "fecha",
                "bookingId": "ID reserva",
                "oldAmount": "Cantidad anterior",
                "type": "Type",
                "markupDifference": "Markup difference",
                "newAmount": "nueva cantidad",
                "username": "nombre de usuario",
                "reason": "Motivo"
            },
            "incidentCancellation": {
                "date": "fecha",
                "bookingId": "ID reserva",
                "customerName": "Nombre del cliente",
                "customerPhone": "teléfono cliente",
                "bookFrom": "Reserva desde",
                "canceledBy": "cancelado por",
                "type": "Tipo",
                "reason": "Motivo",
                "reasonCode": {
                    "0": "Encontré otra opción de viaje",
                    "1": "Conductor muy lejos",
                    "2": "Entré en una ubicación incorrecta",
                    "3": "Esperé demasiado",
                    "4": "El conductor pidió cancelar",
                    "5": "I changed my plans",
                    "6": "I wanted to change location",
                    "101": "Most or all items are unavailable",
                    "102": "We're too busy right now",
                    "103": "We're closed right now",
                    "104": "We're closing soon"
                },
                "canceller": "Puesto de dirigente"
            },
            "acceptanceCancellationRate": {
                "companyId": "ID de la compañía",
                "driverId": "identificación del conductor",
                "driverName": "Nombre del conductor",
                "phoneNumber": "Número de teléfono",
                "company": "Compañía",
                "acceptanceRate": "Tarifa de aceptación",
                "cancellationRate": "Tarifa de cancelación",
                "rating": "Clasificación"
            },
            "revenueFleet": {
                "date": "fecha",
                "fareRevenue": "Ingresos por tarifa",
                "trip": "Trayecto",
                "avgFareTrip": "Promedio ingresos/trayectos",
                "drivers": "# Conductores",
                "avgTripPerDriver": "Viaje medio/conductor",
                "dispatcher": "# Peticionario"
            },
            "revenueDriver": {
                "date": "fecha",
                "driverName": "Conductor",
                "fareRevenue": "Ingresos por tarifa",
                "trip": "# Viaje",
                "avgFareTrip": "Promedio ingresos/trayectos"
            },
            "revenueMonthly": {
                "month": "Mes",
                "trip": "Trayectos finalizados",
                "subtotal": "Total parcial",
                "tipAmt": "Propina",
                "techFee": "Cargo por tecnología",
                "salesTax": "Impuestos",
                "partnerCommission": "Comisión colaborador",
                "totalFare": "Tarifa total sin promoción",
                "netEarning": "Ganancias netas",
                "fleetServiceFee": "Fleet service fees"
            },
            "dailyDriver": {
                "driverName": "Conductor",
                "driverIDPermit": "ID# / IC# Nacional",
                "driverPhone": "Teléfono",
                "companyName": "Compañía",
                "workingHoursOnline": "En línea (horas)",
                "workingHoursAvailable": "Disponible (hrs)",
                "driverWorkingHours": "Driver working hours",
                "distance": "Driver traveled distance",
                "CC_activity": "CC activity",
                "fare_summary": "CC activity",
                "workingHoursNotAvailable": "No disponible (hrs)",
                "workingHoursPob": "Pasajero a bordo (hrs)",
                "workingHoursPobToolTip": "Duración del pasajero a bordo",
                "workingHoursAccepted": "Aceptado",
                "workingHoursReject": "Rechazar",
                "workingHoursIgnore": "Ignorar",
                "workingHoursCancel": "Cancelar",
                "completedTripsTotal": "Total editado satisfactoriamente",
                "completedTripsCc": "Desde cc",
                "completedTripsCarHailing": "Desde el coche de granizo",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "Desde la aplicación pax",
                "completedTripsWebBooking": "Desde reserva web",
                "completedTripsMDispatcher": "De socio",
                "completedTripsKiosk": "Desde el quiosco",
                "completedTripsApi": "Desde la API",
                "ccActivityDispatchedTrips": "viajes despachados",
                "ccActivityAccepted": "Aceptado",
                "ccActivityReject": "Rechazar",
                "ccActivityIgnore": "Ignorar",
                "ccActivityCancelByPassenger": "Cancelado por el pasajero",
                "ccActivityCancelByDriver": "Cancelado por el conductor",
                "ccActivityCancelByCc": "Cancelado por centro de comando",
                "ccActivityCancelByAPI": "Cancelado por API",
                "ccActivityNoShow": "no show",
                "fareSummaryTotalCash": "Dinero en efectivo",
                "fareSummaryTotalCreditCard": "Tarjeta de crédito",
                "fareSummaryTotalExternalCard": "tarjeta externa",
                "fareSummaryTotalPaxWallet": "Wallet",
                "fareSummaryTotalDirectBilling": "Driect invoicing",
                "fareSummaryTotalQrCode": "QR code",
                "fareSummaryTotalPrePaid": "Corporate Prepaid",
                "fareSummaryTotalApplePay": "Apple pay",
                "fareSummaryTotalTngeWallet": "TNG eWallet",
                "fareSummaryTotalVippseWallet": "Vipps",
                "fareSummaryTotal": "Total editado satisfactoriamente",
                "ratingNotes": "Nº notas",
                "ratingDislike": "Número de \\No me gusta\\\"\"",
                "ratingLike": "Número de \\Me gusta\\\"\"",
                "fareDetailsBasicFare": "Tarifa básica",
                "fareDetailsAirportFee": "Tarifa de aeropuerto",
                "fareDetailsRushHour": "Recargo",
                "fareDetailsHeavyTraffic": "Tráfico intenso",
                "fareDetailsOtherFees": "Otros cargos",
                "fareDetailsSubTotal": "Total parcial",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Tarifa técnica",
                "fareDetailsBookingFee": "Tarifa de reserva",
                "fareDetailsSalesTax": "Impuesto de venta",
                "fareDetailsTip": "Cantidad de propina",
                "fareDetailsPromoAmount": "Promociones",
                "fareDetailsTotalAmt": "Importe total",
                "availableDistance": "Disponible",
                "otwDistance": "En camino",
                "otwDistanceToolTip": "en el camino distancia",
                "pobDistance": "Pasajero a bordo",
                "pobDistanceToolTip": "Pasajero a bordo distancia",
                "ratingStars1": "nº 1 estrellas",
                "ratingStars2": "nº 2 estrellas",
                "ratingStars3": "nº 3 estrellas",
                "ratingStars4": "nº 4 estrellas",
                "ratingStars5": "nº 5 estrellas",
                "ratingAvg": "Promedio",
                "ssnNumber": "Número de Seguridad Social",
                "driverEmail": "Dirección de correo electrónico",
                "driverAddress": "Direccion de casa",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyCar": {
                "carInfo": "información del coche",
                "fareDetails": "Detalles de tarifas",
                "completedTrips": "Viajes completados",
                "carInfoCompanyName": "Compañía",
                "carInfoPermission": "Permiso",
                "carInfoVehiclePlateNumber": "Placa",
                "carInfoVhcId": "Vehículo",
                "carInfoCarType": "Tipo de carro",
                "completedTripsTotal": "Total editado satisfactoriamente",
                "completedTripsCc": "Desde cc",
                "completedTripsCarHailing": "Desde el coche de granizo",
                "completedTripsStreetSharing": "From Street Sharing",
                "completedTripsPaxApp": "Desde la aplicación pax",
                "completedTripsWebBooking": "Desde reserva web",
                "completedTripsMDispatcher": "De socio",
                "completedTripsKiosk": "Desde el quiosco",
                "completedTripsAPI": "Desde la API",
                "fareDetailsBasicFare": "Tarifa básica",
                "fareDetailsAirportFee": "Tarifa de aeropuerto",
                "fareDetailsMeetDriver": "Conozca al conductor",
                "fareDetailsRushHour": "Recargo",
                "fareDetailsHeavyTraffic": "Tráfico pesado",
                "fareDetailsTollFee": "Peaje",
                "fareDetailsParkingFee": "Parking fee",
                "fareDetailsGasFee": "Gas fee",
                "fareDetailsOtherFees": "Otros cargos",
                "fareDetailsSubTotal": "Subtotal",
                "fareDetailsSubTotalFF": "Subtotal FF",
                "fareDetailsTechFee": "Tarifa técnica",
                "fareDetailsBookingFee": "Tarifa de reserva",
                "fareDetailsSalesTax": "Impuesto de venta",
                "fareDetailsTip": "Propina",
                "fareDetailsPromoAmount": "Importe de la promoción",
                "fareDetailsTotalAmt": "Importe total",
                "fareDetailsAddonPrice": "Customer adjust price",
                "fareDetailsOldSubTotal": "Subtotal before adjust"
            },
            "dailyDriverCar": {
                "driverInfo": "información del conductor",
                "driverName": "Nombre del conductor",
                "driverIDPermit": "ID# / IC# Nacional",
                "driverPhone": "Número de teléfono",
                "companyName": "Compañía",
                "actionInfo": "Acciones",
                "date": "fecha",
                "action": "Acción",
                "bookingId": "ID reserva",
                "carInfo": "información del coche",
                "permission": "Permiso",
                "licensePlate": "Placa",
                "carId": "Carro #",
                "carType": "Tipo de carro"
            },
            "driverLoginStatus": {
                "driver": "Conductor",
                "driverIdPermit": "ID# / IC# Nacional",
                "phone": "Número de teléfono",
                "username": "Nombre de usuario",
                "company": "Empresa",
                "carType": "Tipo de carro",
                "licensePlate": "License plate",
                "status": "Estado"
            },
            "bookingSummary": {
                "date": "fecha",
                "operator": "Operador",
                "username": "nombre de usuario",
                "action": "Acción",
                "bookingId": "ID reserva"
            },
            "cashWallet": {
                "bankAccount": "Transferido a cuenta bancaria",
                "creditWallet": "Transferido a billetera de crédito",
                "tipAfterCompleted": "Sugerencia después de completar",
                "netEarning": "Ganancia neta",
                "referralEarning": "Ganancia de referencia",
                "receiverAccount": "Cuenta del receptor",
                "dateAndTime": "Fecha y hora",
                "driverName": "Nombre del conductor",
                "phoneNumber": "número de teléfono",
                "userName": "nombre de usuario",
                "company": "Empresa",
                "type": "Tipo",
                "bookingId": "Reserva #",
                "operator": "Operador",
                "reason": "Motivo",
                "amount": "importe modificado",
                "newAmount": "nuevo saldo",
                "currency": "moneda",
                "topUpGCashReason": "Recargar por Gcash",
                "topUpReason": "Recarga desde tarjeta de crédito *{0}",
                "cashWalletReason": "Recargue de Cash Wallet",
                "topUpMOLPayReason": "Pago de Razer [ {0} ]",
                "chargedAmount": "Precio a cobrar",
                "differentPercent": "Descuento / Cargo adicional (%)",
                "operatorName": "Operator",
                "sendPayout": "Action",
                "sendPayoutTitle": "Resend payout email",
                "payoutReason": "{0} (Payout {1} {2})"
            },
            "creditWallet": {
                "receiverType": "Receiver Type",
                "receiverName": "Receiver Name",
                "receiverPhone": "Receiver phone number",
                "senderPhone": "Sender phone number",
                "senderName": "Sender name",
                "sentToCustomer": "Send to customer",
                "sentToDriver": "Send to driver",
                "dateAndTime": "Fecha y hora",
                "driverName": "Nombre del conductor",
                "phoneNumber": "número de teléfono",
                "userName": "nombre de usuario",
                "company": "Empresa",
                "type": "Tipo",
                "bookingId": "Reserva #",
                "operator": "Operador",
                "reason": "Motivo",
                "amount": "importe modificado",
                "newAmount": "nuevo saldo",
                "currency": "moneda",
                "transactionId": "ID de transacción",
                "topUpGCashReason": "Recargar por Gcash",
                "topUpReason": "Recarga desde tarjeta de crédito *{0}",
                "cashWalletReason": "Recargue de Cash Wallet",
                "topUpMOLPayReason": "Pago de Razer [ {0} ]",
                "topUpWalletReason": "{0} [ {1} ]",
                "chargedAmount": "Precio a cobrar",
                "differentPercent": "Descuento / Cargo adicional (%)",
                "cashExcessReason": "Customer must pay {0}; Cash received {1}",
                "referralEarningReason": "Customer {0} {1} completed first booking {2}",
                "referralEarningReason_2": "Customer {0} {1} completed booking {2}",
                "insurance": "Driver insurance"
            },
            "passengerWallet": {
                "dateTime": "Fecha y hora",
                "customerName": "Nombre del cliente",
                "phoneNumber": "Número de teléfono",
                "type": "Tipo",
                "bookingId": "Reserva #",
                "changedAmount": "Importe modificado",
                "amount": "Importe modificado",
                "newBalance": "Nuevo equilibrio",
                "currency": "Divisa",
                "transactionId": "ID de transacción",
                "Note": "Nota",
                "credit": "Recarga con tarjeta de crédito *{0}",
                "TnG": "Recarga por TnG #{0}",
                "MOLPay": "Top up from [RazerPay - #{0}]",
                "gatewayName": "Top up from [{0} - #{1}]",
                "cashExcess": "Customer must pay {0}, Cash given {1}",
                "editBalance": "Edit Balance",
                "ride": "Ride",
                "topUp": "Top up",
                "topUpReferee": "Referral reward ({0})",
                "topUpReferrer": "New referee (#{0}): {1} ({2})"
            },
            "recipientsInfo": {
                "order": "No.",
                "orderId": "Order #",
                "name": "Recipient name",
                "phone": "Recipient phone #",
                "address": "Recipient address",
                "deliveryFee": "Delivery fee",
                "ItemValue": "Item Value (COD)",
                "status": "Status",
                "Performer": "Performer",
                "Reason": "Reason",
                "proofOfFailure": "Proof of failure",
                "referenceNumber": "Reference Number"
            },
            "dispatchHistory": {
                "performer": "Ejecutante",
                "name": "Nombre",
                "action": "Acción",
                "date": "fecha",
                "location": "ubicación del conductor",
                "estimate": "Estimación del lugar de recogida del vehículo",
                "passenger": "Customer",
                "detachVehicle": "Detach Vehicle",
                "assignVehicle": "Assign Vehicle"
            },
            "payToDriver": {
                "driverName": "Conductor",
                "totalUnsettledTransactions": "Total transacciones sin saldar",
                "totalUnsettledAmount": "total importe sin saldar",
                "actions": "Acciones",
                "pay": "Pagar",
                "remove": "Eliminar",
                "clearSuccess": "Las ganancias de los conductores seleccionados han sido correctamente borradas",
                "clearFailed": "Error al limpiar los datos de ganancias de los conductores, inténtelo de nuevo",
                "selectDriver": {
                    "title": "",
                    "body": "Seleccione el conductor para pagar.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "¿está seguro de querer limpiar los datos de ganancias de los conductores?. Esta acción no se podrá deshacer",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "¿Está seguro de que desea pagar a los conductores seleccionados?",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Pagos liquidados con éxito a los conductores"
            },
            "customerQuest": {
                "name": "Nombre",
                "phoneNumber": "Phone number",
                "supportId": "Support ID",
                "ranking": "Ranking",
                "points": "Points",
                "numberOfReservation": "Number of reservation",
                "driver": "Driver",
                "customer": "Customer",
                "firstWillWin": "First will win",
                "firstWillWinLuckyDraw": "First will win (lucky draw)",
                "largestWillWin": "Largest will win (leaderboard)",
                "qualified": "Qualified",
                "notQualified": "Not qualified",
                "userName": "User name",
                "completedDate": "Completed date",
                "isQualified": "Status",
                "ticket": "Ticket",
                "numOfComplete": "Num of completed",
                "bookingDistance": "P.O.B distance",
                "numberOfIntercity": "Number of intercity",
                "numberOfDelivery": "Number of delivery",
                "pleaseSelectEventName": "Please select event name.",
                "numberOfQualifiedSetting": "Number of qualified (setting): ",
                "numberOfActualQualified": "Number of actual qualified: ",
                "driverRating": "Driver Rating",
                "acceptanceRating": "Acceptance Rating",
                "cancellationRating": "Cancellation Rating",
                "period": "Period: "
            },
            "payoutToDriver": {
                "IFSCCode": "IFSC Code",
                "payoutID": "Payout ID",
                "driverName": "Driver",
                "driverPhone": "Phone number",
                "companyName": "Company",
                "period": "Period",
                "driverId": "ID# / National IC#",
                "cashWalletBalance": "Cash wallet balance",
                "creditBalance": "Credit balance",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "enterMinimumPayoutAmount": "Enter minimum payout amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "actions": "Actions",
                "pay": "Pay",
                "remove": "Remove",
                "payoutEndTime": "Payout end time",
                "clearSuccess": "The earning of selected driver was successfully cleared.",
                "clearFailed": "Failed to clear earning data of the driver. Please try again.",
                "amountKeepInDriverCashWallet": "Amount keep in driver's Cash Wallet",
                "selectDriver": {
                    "title": "",
                    "body": "Please select driver to pay.",
                    "buttonTitle": "OK"
                },
                "confirmClear": {
                    "title": "",
                    "body": "Are you sure you want to clear earning data of this driver? This action cannot be undone.",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected driver(s)? <br> - Number of drivers: <b>%{drivers}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>   ",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "paymentSuccessfully": "Payments settled successfully to driver(s)",
                "paymentCompleted": "Payout completed: <br> - Pay successfully: %{success} <br> - Failed: %{failed} <br> - Total payout successfully: %{currency} %{total}",
                "noteSelectDrivers": "Note: select drivers who have bank account to pay.",
                "sendPayout": "Send payout successful notification to email address",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "accountHoldName": "Account holder's name",
                "bankName": "Bank name",
                "selelctedDriverHasBeenPaid": "Selected driver(s) has been paid",
                "selelctedDriverHasBeenFailed": "Selected driver(s) has been failed",
                "selelctedDriverHasBeenUpdateAsInvalid": "Selected driver(s) has been updated as 'Invalid bank account'",
                "selelctedDriverHasBeenUpdateAsValid": "Selected driver(s) has been updated as 'Valid bank account'",
                "isBankAccountOwner": "Beneficiary same as driver",
                "isBankAccountOwner_true": "Yes",
                "isBankAccountOwner_false": "No",
                "beneficiaryIDIC": "Beneficiary ID# / IC#",
                "bankRelationship": "Relationship",
                "bankRelationship_1": "Spouse",
                "bankRelationship_2": "Daughter/Son",
                "bankRelationship_3": "Brother/Sister",
                "bankRelationship_4": "Relative",
                "bankRelationship_5": "Friends",
                "bankRelationship_6": "Other: {0}"
            },
            "payoutToMerchant": {
                "payoutId": "Payout ID",
                "merchantName": "Merchant Name",
                "phone": "Phone number",
                "accountNumber": "Account number",
                "iBanNumber": "IBAN number",
                "bankAccountHolder": "Account holder's name",
                "bankName": "Bank name",
                "pendingAmount": "Pending amount",
                "payoutAmount": "Payout amount",
                "payoutDate": "Payout date",
                "holdAmount": "Hold amount",
                "minPayout": "Min payout",
                "selelctedMerchantHasBeenPaid": "Selected merchant(s) has been paid",
                "selelctedMerchantHasBeenFailed": "Selected merchant(s) has been failed",
                "pay": "Pay",
                "selectMerchant": {
                    "title": "Warning",
                    "body": "Please select merchant to pay.",
                    "buttonTitle": "OK"
                },
                "confirmPay": {
                    "body": "Are you sure you want to pay to selected merchant(s)? <br> - Number of merchants: <b>%{merchants}</b> <br> - Min payout out for each driver: %{currency} <b>%{min}</b>  <br> - Hold amount: %{currency}<b> %{hold}</b> <br> - Total payout: %{currency} <b>%{total}</b>",
                    "buttonTitle": "Yes",
                    "closeButtonText": "No"
                },
                "sendPayout": "Send payout successful notification to email address",
                "HasBeenUpdateAsInvalid": "Selected merchant(s) has been updated as 'Invalid bank account'",
                "HasBeenUpdateAsValid": "Selected merchant(s) has been updated as 'Valid bank account'"
            },
            "settlementHistory": {
                "date": "fecha",
                "driverName": "Nombre del conductor",
                "dateRange": "rango de fechas",
                "totalTransaction": "total transacciones saldadas",
                "paidAmount": "total importe saldado"
            },
            "settlement": {
                "driverName": "Conductor",
                "driverIdPermit": "ID# / IC# Nacional",
                "totalBookings": "total reservas",
                "total": "Importe total",
                "approved": "Aprobado",
                "acct": "CUENTA",
                "gross": "Bruto",
                "percent": "3,00%",
                "net": "Neto"
            },
            "documentExpiry": {
                "driverName": "Nombre del conductor",
                "driverPhone": "Teléfono del conductor",
                "company": "Compañía",
                "documentName": "Nombre del documento",
                "expiredDate": "Fecha de vencimiento",
                "status": "Estado",
                "statusStr": {
                    "NA": "No aplica",
                    "expired": "activo",
                    "active": "Activo",
                    "expireSoon": "Expire soon"
                }
            },
            "referralDetail": {
                "bookingId": "Reserva #",
                "completedDate": "Hora fin trayecto",
                "paidBy": "Pagado por",
                "bookFrom": "Reserva desde",
                "driverName": "Nombre del conductor",
                "driverIdPermit": "Documento de Identidad",
                "companyName": "Empresa",
                "Passenger": "Pasajero",
                "Customer": "Customer",
                "subtotal": "Total parcial",
                "subTotalFF": "Subtotal FF",
                "subtotalWithPromo": "Subtotal with promotion",
                "referralEarning": "Ganancias derivadas",
                "Amount": "Importe",
                "Note": "Notas",
                "Currency": "moneda",
                "PercentOfSubTotal": "% de total",
                "max": "max",
                "referrerInfo": "Driver refers customer",
                "phoneReferral": "Driver phone #",
                "driverNumber": "ID# / National IC#",
                "referralCode": "Referral code"
            },
            "referralHistory": {
                "driverName": "Nombre del conductor",
                "phoneNumber": "Número de teléfono",
                "companyName": "Compañía",
                "referralCode": "Código de referencia",
                "commission": "Comisión",
                "expectedReferralEarning": "Expect referral earning",
                "activePaxTooltip": "Pasajeros que hayan canjeado el código de referencia del conductor con al menos una reserva realizada con éxito.",
                "referredPaxTooltip": "Pasajeros que hayan canjeado el código de referencia del conductor pero que aún no hayan realizado ninguna reserva.",
                "activePax": "Total de pasajeros activos",
                "referredPax": "Total de pasajeros referidos",
                "detailFields": {
                    "passengerId": "Identificación del pasajero",
                    "passengerName": "Nombre del pasajero",
                    "firstBookingDate": "1a fecha de reserva completa",
                    "firstBookingDateGMT": "1.ª fecha de reserva completa (GMT)",
                    "passengerPhone": "Customer phone number",
                    "appliedDate": "Referred date",
                    "referredByDriver": "Referred by driver",
                    "driverPhone": "Driver phone number",
                    "referralCode": "Referral code"
                },
                "showDetail": "Show",
                "detail": "Detalle"
            },
            "paxReferralHistory": {
                "customerName": "Customer name",
                "phoneNumber": "Phone number",
                "referralCode": "Referral code",
                "commission": "Total commission",
                "activePaxTooltip": "Passengers who have redeemed the referral code with at least one booking successfully made.",
                "referredPaxTooltip": "Passengers who have redeemed the referral code but yet to make any booking.",
                "activePax": "Total active customers",
                "referredPax": "Total referred customers",
                "detailFields": {
                    "passengerId": "Passenger ID",
                    "passengerName": "Passenger name",
                    "firstBookingDate": "1st completed booking date",
                    "firstBookingDateGMT": "1st completed booking date (GMT)"
                },
                "showDetail": "Show",
                "detail": "Detail"
            },
            "driverRefersDriver": {
                "companyName": "Company",
                "phoneNumber": "Phone number",
                "username": "Username",
                "referralCode": "Referral code",
                "referrer": "Referrer",
                "numberOfReferees": "Number of referees",
                "activeReferees": "Active referees",
                "referee": "Referee",
                "refereePhone": "Referee phone number",
                "refereeUsername": "Referee username",
                "referredDate": "Referred date",
                "activatedDate": "Activated date",
                "referrerByDriver": "Referrer",
                "referrerPhone": "Referrer phone number",
                "referralStatus": "Referral status",
                "lifeTimeBookings": "Life time bookings",
                "inReview": "In review",
                "activated": "Activated"
            },
            "driverWithdrawal": {
                "transferToEWallet": "Transferred to external e-wallet *{0}",
                "channelCode": "Channel code",
                "channelName": "Channel name",
                "reversed": "Reversed",
                "currentBalanceTooltip": "El saldo actual de la billetera en efectivo del conductor antes de la solicitud de retiro",
                "paymentTime": "Tiempo de pago",
                "requestTime": "Tiempo requerido",
                "referenceId": "Referencia #",
                "transactionId": "ID de transacción",
                "description": "Descripción",
                "transferAmount": "Monto de la transferencia",
                "currency": "Divisa",
                "approved": "Aprobado",
                "rejected": "Rechazado",
                "failed": "Fallido",
                "reason": "Razón"
            },
            "penaltyCompensation": {
                "penaltyAmount": "Pena",
                "totalCollect": "Necesidad de pagar en moneda local.",
                "totalCollectInUSD": "Necesidad de pagar en USD",
                "compensationAmount": "Remuneración",
                "compensationAmountInUSD": "Compensación (USD)",
                "penaltyReason": "Motivo de la sanción",
                "estimatedPrice": "Estimated price",
                "estimatedPriceInUSD": "Estimated price (USD)",
                "penaltyAmountInUSD": "Pena (USD)",
                "amount": "Amount",
                "amountInUSD": "Amount (USD)"
            },
            "imcompletePayment": {
                "bookingId": "ID de reserva",
                "bookFrom": "reserva desde",
                "bookingTime": "tiempo de reserva",
                "completedTime": "tiempo completado",
                "customerName": "Nombre del cliente",
                "requestedPaymentMethod": "Método de pago solicitado",
                "originalPaymentStatus": "Estado de pago original",
                "currentPaymentStatus": "Estado de pago actual",
                "updatedDateTime": "Fecha / Hora actualizada",
                "totalAmt": "Importe total",
                "originalOutstandingAmount": "Cantidad original pendiente",
                "currentOutstandingAmount": "Cantidad pendiente actual",
                "outstandingAmountPaidBy": "Cantidad pendiente pagada por",
                "writeOffDebtMethod": "Descartar el método deb",
                "reason": "Razón",
                "customerPhone": "Phone number",
                "amountCollected": "Amount collected",
                "partialPaymentAmount": "Partial payment when drop off"
            },
            "driverDeposit": {
                "dateAndTime": "Fecha y hora",
                "userName": "nombre de usuario",
                "type": "Tipo",
                "newAmount": "nuevo saldo",
                "topUpGCashReason": "Recargar por Gcash",
                "topUpReason": "Recarga desde tarjeta de crédito *{0}",
                "cashWalletReason": "Recargue de Cash Wallet",
                "topUpMOLPayReason": "Pago de Razer [ {0} ]"
            },
            "refund": {
                "fullRefund": "Full refund",
                "partialRefund": "Partial refund",
                "tripId": "Trip ID",
                "route": "Route",
                "refundAmount": "Refund amount",
                "refundTime": "Refund time",
                "paymentStatus": "Payment status"
            },
            "merchant": {
                "store": "Store",
                "name": "Name",
                "phoneNumber": "Phone number",
                "bookingId": "Booking #",
                "totalOrders": "Total orders",
                "completedOrders": "Completed orders",
                "canceledOrders": "Canceled orders",
                "incidentOrders": "Incident",
                "revenue": "Revenue",
                "commission": "Commission",
                "earning": "Earning",
                "food": "Food",
                "mart": "Mart",
                "preferredMerchant": "Preferred merchant",
                "regularMerchant": "Regular merchant",
                "companyName": "Company"
            },
            "merchantCashTransactionHistory": {
                "companyName": "Company",
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID de transacción",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "merchantCreditTransactionHistory": {
                "dateTime": "Date & time",
                "merchantUserName": "Merchant user",
                "merchantUserPhone": "Phone number",
                "merchantUserEmail": "Email",
                "merchantName": "Merchant",
                "transactionType": "Type",
                "bookId": "Booking #",
                "transactionId": "ID de transacción",
                "operatorName": "Operator",
                "reason": "Reason",
                "amount": "Amount",
                "newBalance": "New balance",
                "currencyISO": "Currency"
            },
            "deleteAccountRequest": {
                "date": "Date",
                "phone": "Phone",
                "username": "Username",
                "supportId": "Support ID",
                "requestFrom": "Request from",
                "associatedApps": "Associated apps",
                "reason": "Reason"
            },
            "affiliationPayoutHistory": {
                "payoutId": "Payment ID",
                "dateTime": "Date & time",
                "payoutType": "Type",
                "accountHolderName": "Account holder's name",
                "bankName": "Bank name",
                "accountNumber": "Account number",
                "totalPayout": "Total payment (USD)",
                "viewDetails": "View details",
                "notes": "Note",
                "bookId": "Booking #",
                "transactionType": "Transaction type",
                "transactionId": "Transaction Id (auto transfer)",
                "settlement": "Settlement",
                "paymentMethod": "Type",
                "networkType": "Network type"
            },
            "thirdPartyBooking": {
                "date": "Date",
                "bookingId": "Booking ID",
                "externalId": "External ID",
                "psgPhone": "Passenger phone number",
                "psgName": "Passenger name",
                "pickupLocation": "Pickup location",
                "pickupLocationLatLong": "Pickup location lat/long",
                "dropoffLocation": "Dropoff location",
                "dropoffLocationLatLong": "Dropoff location lat/long",
                "pickupTime": "Pickup time",
                "numOfPassenger": "Num of passenger",
                "action": "Action"
            }
        }
    },
    "zoneSetting": {
        "Add": "Agregar",
        "Import": "Importar",
        "Name": "Nombre",
        "Status": "Estado",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Deactivate": "Desactivar",
        "Activate": "Activar",
        "Delete": "Borrar",
        "Cancel": "Cancelar",
        "Input_zone_name": "Introducir nombre de zona",
        "Save_zone": "Guardar zona",
        "Clear_draw": "Limpiar dibujo zona",
        "Edit": "Editar",
        "Delete_zone_success": "La zona ha sido eliminada con éxito",
        "Delete_zone_fail": "Los errores ocurrieron al eliminar la zona",
        "Update_zone_fail": "Los errores ocurrieron al actualizar la zona",
        "Update_zone_success": "La zona ha sido actualizada con éxito",
        "Create_zone_fail": "Los errores ocurrieron al crear la zona.",
        "Create_zone_success": "La nueva zona se ha creado con éxito",
        "Draw_zone": "Por favor dibuje la zona en el mapa",
        "Import_zones_successful": "zona importada correctamente",
        "Import_zones_fail": "Error al importar zonas",
        "DELETE_ZONE": "Eliminar zona",
        "ZONE_CONFIRM": "¿Está seguro de que desea eliminar la zona seleccionada?",
        "Name_zone_required": "¡El nombre y la zona son obligatorios!",
        "Select_file": "seleccionar archivo",
        "No": "No",
        "Yes": "Sí",
        "Import_zones_intercity_successful": "[{0}] zona(s) se ha importado correctamente, [{1}] falló.  {2}",
        "Import_zones_intercity_successful_failed": "[{0}] zone(s) has been imported successfully, [{1}] failed.",
        "Flat_zone": "zona plana",
        "Json_file": "archivo json",
        "Import_zones": "Importar zonas",
        "timezone": "Time Zone"
    },
    "companySetting": {
        "DELETE_COMPANY": "Borrar empresa",
        "DELETE_CONFIRM": "¿Quieres eliminar la empresa!",
        "DETAIL_COMPANY": "Detalles empresa",
        "commissionValue": "Company commission",
        "connectStatus": "Stripe Connect",
        "connectStripe": "Connect",
        "stripeConnectId": "Stripe account ID",
        "loginStripe": "Dashboard",
        "disconnectStripe": "Disconnect",
        "Yes": "sí",
        "No": "No",
        "Cancel": "Cancelar",
        "EDIT_COMPANY": "EDITAR EMPRESA",
        "ADD_COMPANY": "AÑADIR EMPRESA",
        "Name": "Nombre",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Actions": "Acciones",
        "Edit": "Editar",
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "{0} - {1} de {2} mostrado",
        "Create_company_success": "La nueva empresa se ha creado con éxito.",
        "Create_company_fail": "error durante la creación de la empresa",
        "Update_company_success": "La empresa ha sido actualizada con éxito",
        "Update_company_fail": "Los errores ocurrieron al actualizar la empresa",
        "Load_company_fail": "Los errores ocurrieron al cargar la empresa",
        "Delete_company_success": "La empresa ha sido eliminada con éxito",
        "Delete_company_fail": "Los errores ocurrieron al eliminar la empresa",
        "hideFare": "Always hide fare",
        "Broadcast_orders": "Broadcast all orders",
        "paymentMethodsApply": "Support payment method",
        "emailsNotification": "Emails Notification",
        "CopySignUpLink": "Copy sign up link",
        "operationZone": "Operation Zone",
        "delayBroadcast": "Show order after",
        "releaseTime": "Release Time",
        "advanceBookingVisibility": "Advance booking visibility",
        "advanceBookingVisibilityTooltip": "Set the number of days before the pickup date that a supplier can see the booking. This ensures that bookings are released to drivers in a timely manner, based on each supplier."
    },
    "dispatchSetting": {
        "GENERAL": "General",
        "Offer_interval": "Intervalo de oferta",
        "Offer_interval_merchant": "Offer interval (merchant)",
        "Offer_interval_merchant_toolTip": "Maximum time for a merchant to accept a order",
        "Offer_interval_toolTip": "Tiempo máximo para que un conductor acepte una reserva",
        "soundMarketplace": "Sound",
        "sound": "Sound",
        "Auto_re_dispatch_interval_toolTip": "Tiempo de descanso entre 2 rondas de despacho",
        "Maximum_auto_dispatch_duration_toolTip": "Tiempo máximo de envío de una reserva a los conductores",
        "Seconds": "Segundos",
        "Auto_re_dispatch_interval": "Intervalo de reenvío automático",
        "Maximum_auto_dispatch_duration": "Duración máxima del envío automático",
        "Time_out": "Se acabó el tiempo",
        "mins": "minutos",
        "minTimeBeforeReservation": "No permita que el conductor inicie la reserva antes de",
        "IN_ADVANCE": "POR ADELANTADO",
        "Assign_reservation_to_driver": "asignar reserva a conductor",
        "Dispatcher_confirms_job_for_driver": "El despachador confirma el trabajo del conductor",
        "Dispatcher_confirms_job_for_offline_driver": "El despachador confirma la reserva para el conductor fuera de línea",
        "Broadcast_orders": "Broadcast orders to all companies",
        "Broadcast_orders_tooltip_1": "Enable this setting if you just want to broadcast all trips to all company suppliers. \n Keep in mind you need to enable broadcast mode on in each company’s profile.",
        "Minimum_book_ahead": "Reserva mínima por delante",
        "Maximum_book_ahead": "Reserva máxima por delante",
        "Maximum_period_recurring_oders_fleet": "Maximum period for recurring orders (fleet operator)",
        "Maximum_period_recurring_oders_corp": "Maximum period for recurring orders (corporate)",
        "Day": "Day",
        "Week": "Semana",
        "Month": "Mes",
        "hrs": "horas",
        "Driver_cancellation_duration": "Duración de la cancelación del conductor",
        "Mins": "mins",
        "Maximum_Day": "Máximo de reservas que el conductor puede aceptar en un día.",
        "Maximum_Accept_Reservation": "Máximo de reservas que el conductor puede aceptar a la vez.",
        "Local_notification_before": "Notificación local antes",
        "Dispatch_mode": "modo de despacho",
        "Manual": "Manual",
        "Immediate_auto_dispatch": "Envío automático inmediato",
        "Delayed_auto_dispatch": "Envío automático retrasado",
        "Auto_dispatch_before_pickup_time": "Tiempo alarma previo a hora recogida",
        "Required_fields": "Campos requeridos",
        "Shuttle": "SHUTTLE",
        "Shuttle_Dispatch_mode": "Dispatch mode",
        "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.",
        "Save": "Ahorrar",
        "Process_in_advance": "Proceso por adelantado",
        "Save_dispatch_success": "El envío se ha actualizado con éxito",
        "Save_dispatch_fail": "Hubo errores al actualizar despacho",
        "Dispatch_setting_general_tooltip": "Aplicar a la reserva bajo demanda, reserva por adelantado con envío automático retrasado",
        "Dispatch_setting_Assign_reservation_to_driver_tooltip": "La reserva es asignada y aceptada por el conductor automáticamente cuando esta opción está activada",
        "Dispatch_setting_Driver_cancellation_duration_tooltip": "La duración es desde el momento en que la hora de recogida hasta el conductor cancela la reserva.",
        "Dispatch_setting_Dispatch_mode_tooltip": "- Modo manual: la reserva se guardará y permanecerá en Cue de su Centro de Comando en estado Pendiente\" una vez que se crea la reserva. Después de hacer clic en \"Actualizar reserva\" en los detalles de la reserva, \"\"la reserva se enviará a su asignado  controladores o a todos los controladores en caso de que no haya una asignación de controlador. Se integrará en el calendario del dispositivo del controlador y el recordatorio estará disponible. <br>- Modo retrasado\"\": la reserva se guardará y permanecerá en Cue de su Command Center en \"  Pendiente\" una vez que se crea la reserva. La reserva se enviará automáticamente antes del \"valor de tiempo en\" Procesar por adelantado \"\" desde la hora de recogida al conductor asignado o al \"\"disponible más cercano en caso de que no haya asignación de conductor. <br>  - Modo de envío automático inmediato\"\": la reserva se enviará automáticamente a todos los conductores una vez que se cree la reserva. Se integrará en el calendario del dispositivo del conductor y el recordatorio estará disponible.",
        "Notification_nearby_drivers_before_pickup_time": "Notificación a los conductores cercanos antes de la hora de recogida",
        "Dispatch_setting_Maximum_Day_tooltip": "This is to limit drivers from having too many reservations on the same day. Applicable for Marketplace and Intercity.",
        "Maximum_Accept_Reservation_tooltip": "The number of accepted reservations by driver cannot exceed this number. This limits the driver from accepting too many at a time. Applicable for Marketplace and Intercity.",
        "Notification_nearby_drivers_before_pickup_time_tooltip": "If a customer's reservation has not been accepted by any driver before the set time, before pickup time, the system will notify drivers nearby. This will hopefully prompt them to check marketplace to accept the booking.",
        "marketPlace": "MARKET PLACE",
        "howFarDriverSeeBookingMarketplace": "How far out the driver can see bookings in the marketplace",
        "shortNoticeOrder": "Short notice order",
        "shortNoticeOrderTooltip": "Set the time frame for bookings to be considered urgent and visible to all tiers immediately."
    },
    "voiceSMSSetting": {
        "ACCOUNT_SETTINGS": "Configuraciones de la cuenta",
        "Account_SID": "SID de la cuenta",
        "Auth_Token": "Token de autenticación",
        "Application_ID": "ID de aplicación",
        "SMS_number_1": "SMS número 1",
        "SMS_number_2": "SMS número 2",
        "SMS_SETTINGS": "AJUSTES DE SMS",
        "MASKED_PHONE_SETTINGS": "AJUSTES DE TELÉFONO ENMASCARADO",
        "SMS_MARKETING_SETTINGS": "AJUSTES DE MARKETING POR SMS",
        "Phone_number": "Número de teléfono",
        "Save": "Ahorrar",
        "voiceSMS": "TWILIO",
        "DRIVER_APP": "Aplicación de conductor",
        "PASSENGER_APP": "Aplicación para pasajeros",
        "allowDriverToCallCC": "Permita que el conductor llame al Command Center a través de:",
        "allowDriverToCallHelpdesk": "Allow Driver to call Helpdesk via:",
        "allowDriverToCallPassenger": "Permitir que el conductor llame al pasajero a través de:",
        "allowPassengerToCallCC": "Permitir que el Pasajero llame al Centro de Comando a través de:",
        "allowPassengerToCallHelpdesk": "Allow Passenger to call Helpdesk via:",
        "allowPassengerToCallDriver": "Permitir que el Pasajero llame al Conductor a través de:",
        "voip": "VOIP",
        "mobile": "Móvil",
        "bookingSMS": "Message template",
        "bookingSMSTemplate": {
            "name": "Name",
            "content": "Content",
            "action": "Action",
            "DELETE": "Delete Message template",
            "DELETE_CONFIRM": "Are you sure you want to delete the selected Message template?",
            "EDIT": "Edit Message template",
            "ADD": "Add Message template",
            "DETAIL": "Detail Message template",
            "Update_success": "The message template has been updated successfully",
            "Create_success": "The message template has been created successfully",
            "Delete_success": "The message template has been deleted successfully",
            "Delete_fail": "The errors occurred while deleting Message template",
            "Load_fail": "The errors occurred while loading Message template",
            "Update_fail": "The errors occurred while updating Message template",
            "Create_fail": "The errors occurred while creating Message template"
        },
        "bookingSMSModal": {
            "title": "Add SMS template",
            "save": "Save",
            "cancel": "Cancel",
            "titleMessage": "Send Message",
            "template": "Template",
            "phoneNumber": "Phone number",
            "content": "Content",
            "messageType": "Message type",
            "inbox": "Inbox",
            "sms": "SMS",
            "sendTo": "Sent to",
            "passenger": "Passenger",
            "driver": "Driver"
        }
    },
    "regularSetting": {
        "extraWaitTime": "Extra wait time",
        "extraWaitTimeTooltip": "Fee to compensate the driver when he has to wait additional time for the customer till they are onboard.",
        "extraWaitTimeTooltip2": "To set the maximum wait time, go to general settings.",
        "fareWaitTimeLater": "Fare per minute (Reservation)",
        "fareWaitTimeNow": "Fare per minute (Now)",
        "serviceType": "Service Type",
        "transport": "Transport",
        "delivery": "Delivery",
        "Fer_Stop": "Fee fer stop",
        "First_Stop": "Fee for first stop",
        "Each_Stop": "Fee for each extra stop",
        "Name": "Nombre",
        "Pay_to_driver_Cash": "Pay to Supplier/Driver (Cash booking)",
        "Last_Update": "Última actualización",
        "Status": "Estado",
        "Required_fields": "Campos requeridos",
        "Yes": "sí",
        "No": "No",
        "Cancel": "Cancelar",
        "Save": "Ahorrar",
        "Actions": "Acciones",
        "Edit": "Editar",
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "{0} - {1} de {2} mostrado",
        "DELETE_CONFIRM": "¿Quieres borrar?",
        "ADD_REGULAR_RATE": "Añadir tarifa normal",
        "EDIT_REGULAR_RATE": "Editar tarifa normal",
        "DETAIL_REGULAR_RATE": "Detalle tarifa regular",
        "DELETE_REGULAR_RATE": "Eliminar tarifa normal",
        "Delete_confirm_regular_rate": "¿Quieres eliminar este artículo?",
        "STARTING": "A partir de",
        "Starting_Now": "Comenzando (ahora)",
        "Starting_Reservation": "Inicio (Reserva)",
        "FIRST_DISTANCE_FEE": "Tasa de primera distancia",
        "First_distance_fee": "Tasa de primera distancia",
        "From": "Desde",
        "To": "A",
        "Fee_for_first_distance": "Tasa por primera distancia",
        "SECOND_DISTANCE_FEE": "Tasa de segunda distancia",
        "Second_distance_fee": "Tasa de segunda distancia",
        "AFTER_SECOND_DISTANCE": "Después de la segunda distancia",
        "Fee_after_second_distance": "Tarifa después de la segunda distancia",
        "PER_MINUTE": "Por minuto",
        "Fee_per_minute": "Tarifa por minuto",
        "mi": "min",
        "hour": "hora",
        "MINIMUM": "Mínimo",
        "Minimum_Now": "Mínimo (ahora)",
        "Minimum_Reservation": "Mínimo (Reserva)",
        "PAX_MDISPATCHER_CANCELLATION": "Cancelación de pasajero/pareja",
        "On_demand": "Bajo demanda",
        "Penalty": "Multa",
        "In_advance": "Por adelantado",
        "PAX_MDISPATCHER_NO_SHOW": "Pax/pareja no show",
        "Driver_cancel_demand": "Driver cancel policy - on demand",
        "Driver_cancel_advance": "Driver cancel policy - in advance",
        "Assign_car_type": "Asignar tipo de coche",
        "Select_car_type": "Seleccionar tipo de coche",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Deactivate": "Desactivar",
        "DELETE_FLATE_RATE": "Eliminar tarifa plana",
        "Delete_confirm_flat_rate": "¿Quieres eliminar este artículo?",
        "EDIT_FLAT_RATE": "Editar tarifa plana",
        "ADD_FLAT_RATE": "Añadir tarifa plana",
        "DELETE_FLAT_RATE": "Eliminar tarifa fija",
        "DELETE_HOURLY_RATE": "Eliminar tarifa por hora/diaria",
        "Delete_confirm_hour_rate": "¿Quieres eliminar este artículo?",
        "EDIT_HOURLY_RATE": "Editar tarifa por hora/diaria",
        "ADD_HOURLY_RATE": "Agregar tarifa por hora/diaria",
        "DELETE_FLAT_ROUTE": "Eliminar ruta prefijada",
        "DELETE_ROUTE_CONFIRM": "Estás seguro de querer eliminar esta ruta?",
        "EDIT_ROUTE": "Editar ruta",
        "ADD_ROUTE": "Añadir ruta",
        "Route_name": "Nombre de la ruta",
        "Route_type": "Tipo de ruta",
        "Zone_to_zone": "Zona a Zona",
        "Zipcode_to_zipcode": "Codigo postal a código postal",
        "First_location": "Primera ubicación",
        "Second_location": "Segunda ubicación",
        "First_location_zip_note": "*Notas: puedes agregar varios códigos postales a la vez, usando la coma (,). Para códigos consecutivos, puedes usar -> en lugar de introducir uno a uno (por. ej. 41000->41200, 41500, 41600)",
        "Second_location_zip_note": "*Notas: puedes agregar varios códigos postales a la vez, usando la coma (,). Para códigos consecutivos, puedes usar -> en lugar de introducir uno a uno (por. ej. 41000->41200, 41500, 41600)",
        "SINGLE_TRIP_SETUP": "Configuración de un solo viaje",
        "Route_1": "Ruta 1 (Primera ubicación -> Segunda ubicación)",
        "Route_2": "Ruta 2 (Segunda ubicación -> Primera ubicación)",
        "Limitation": "Límites",
        "Unlimited": "Ilimitado",
        "Limited": "Limitado",
        "Time_coverage": "Tiempo incluido",
        "Extra_time": "Tiempo extra",
        "ROUND_TRIP_SETUP": "Configuración de ida y vuelta",
        "Round_trip": "Viaje ida y vuelta",
        "ROUTES": "RUTAS",
        "Route_1_Fare": "Tarifa ruta 1",
        "Route_2_Fare": "Tarifa ruta 2",
        "Round_Trip_Fare": "Tarifa ida y vuelta",
        "Select_a_zone": "Seleccione una zona",
        "minute": "minuto",
        "VIEW_REGULAR_RATE": "Ver detalles tarifa regular",
        "VIEW_FLAT_RATE": "Ver tarifa fija",
        "VIEW_HOURLY_RATE": "ver tarifa por días/horas",
        "HOURLY_PACKAGE_RATE": "Tarifa de paquete por hora",
        "Package_name": "Nombre del Plan",
        "Package_duration": "duración del Plan",
        "PackageId": "Package ID",
        "Base_rate": "Tarifa base",
        "Distance_coverage": "Distancia incluida",
        "Extra_duration": "Duración extra",
        "Extra_distance": "Distancia extra",
        "DELETE_HOURLY_PACKAGE": "Eliminar Plan por días/horas",
        "DELETE_PACKAGE_CONFIRM": "Desea eliminiar la tarifa del Plan?",
        "EDIT_PACKAGE": "Editar paquete",
        "VIEW_PACKAGE": "Ver detalle del paquete",
        "ADD_PACKAGE": "Agregar paquete",
        "Fee_per_extra_duration": "Tarifa por duración extra",
        "Fee_per_extra_distance": "Tarifa por distancia extra",
        "hours": "horas)",
        "days": "días)",
        "Update_flat_rate_success": "La tarifa plana se ha actualizado con éxito",
        "Update_flat_rate_fail": "Los errores ocurrieron al actualizar la tarifa plana",
        "Created_flat_rate_success": "La tarifa plana se ha creado con éxito",
        "Created_flat_rate_fail": "Los errores ocurrieron al crear la tarifa plana",
        "Update_flat_rate_route_success": "La ruta de tarifa plana se ha actualizado con éxito",
        "Update_flat_rate_route_fail": "Los errores ocurrieron al actualizar la ruta de tarifa plana",
        "Created_flat_rate_route_success": "La ruta de tarifa plana se ha creado correctamente",
        "Created_flat_rate_route_fail": "Los errores ocurrieron al crear la ruta de tarifa plana",
        "Loading_flat_rate_fail": "Los errores ocurrieron al cargar el detalle de la tarifa plana",
        "Delete_flat_rate_route_fail": "Los errores ocurrieron al eliminar la ruta de tarifa plana",
        "Delete_flat_rate_success": "La tarifa plana se ha eliminado correctamente",
        "Delete_flat_rate_fail": "Los errores ocurrieron al eliminar la tarifa plana",
        "Update_intercity_rate_success": "La tarifa interurbana se ha actualizado con éxito",
        "Update_intercity_rate_fail": "Los errores ocurrieron al actualizar la tarifa interurbana",
        "Created_intercity_rate_success": "La tarifa interurbana se ha creado con éxito",
        "Created_intercity_rate_fail": "Los errores ocurrieron al crear la tarifa interurbana",
        "Update_intercity_rate_route_success": "La tarifa de ruta interurbana se ha actualizado con éxito",
        "Update_intercity_rate_route_fail": "Los errores ocurrieron al actualizar la ruta de la tarifa interurbana",
        "Created_intercity_rate_route_success": "La ruta de tarifa interurbana se ha creado con éxito",
        "Created_intercity_rate_route_fail": "Los errores ocurrieron al crear la ruta de tarifa interurbana",
        "Loading_intercity_rate_fail": "Los errores ocurrieron al cargar el detalle de la tarifa interurbana",
        "Delete_intercity_rate_route_fail": "Los errores ocurrieron al eliminar la ruta de la tarifa interurbana",
        "Delete_intercity_rate_success": "La tarifa interurbana se ha eliminado correctamente",
        "Delete_intercity_rate_fail": "Los errores ocurrieron al eliminar la tarifa interurbana",
        "Update_regular_rate_success": "La tarifa regular ha sido actualizada con éxito",
        "Update_regular_rate_fail": "Los errores ocurrieron al actualizar la tarifa regular",
        "Create_regular_rate_success": "La tarifa regular se ha creado con éxito",
        "Create_regular_rate_fail": "Los errores ocurrieron al crear la tarifa regular",
        "Update_hourly_rate_success": "Tarifa por horas actualizada correctamente",
        "Update_hourly_rate_fail": "Los errores ocurrieron al actualizar la tarifa por hora",
        "Create_hourly_rate_success": "La tarifa por hora se ha creado con éxito",
        "Create_hourly_rate_fail": "Los errores ocurrieron al crear la tarifa por hora",
        "Update_hourly_rate_package_success": "El paquete de tarifa por hora se ha actualizado con éxito",
        "Update_hourly_rate_package_fail": "Los errores ocurrieron al actualizar el paquete de tarifa por hora",
        "Create_hourly_rate_package_success": "El paquete de tarifa por hora se ha creado correctamente",
        "Create_hourly_rate_package_fail": "Los errores ocurrieron al crear el paquete de tarifa por hora",
        "Load_hourly_rate_fail": "Los errores ocurrieron al cargar los detalles de la tarifa por hora",
        "Update_hourly_status": "Los errores ocurrieron al actualizar el estado de la tarifa por hora",
        "Delete_hourly_fail": "Los errores ocurrieron al eliminar la tarifa por hora",
        "Delete_hourly_success": "La tarifa por hora se ha eliminado correctamente",
        "Loading_regular_rate_fail": "Los errores ocurrieron al cargar la tarifa regular",
        "Update_regular_status_fail": "Los errores ocurrieron al actualizar el estado de la tarifa regular",
        "Delete_regular_rate_success": "La tarifa normal se ha eliminado correctamente",
        "Delete_regular_rate_fail": "Los errores ocurrieron al eliminar la tarifa regular",
        "Please_go_to_Car_Type_settings": "Vaya a la configuración de Tipo de automóvil para asignar esta tarifa a los tipos de automóviles.",
        "Car_type_filter": "Filtro tipo de vehículo",
        "Delete_package_error": "¡Hubo un error al eliminar el paquete!",
        "Pax_mDispatcher_cancellation_tooltip": "Política de cancelación aplicada para pasajeros",
        "Penalty_tooltip": "monto cobrado",
        "Pax_mDispatcher_no_show_tooltip": "Se aplicó la política de no presentarse para los pasajeros. Se cobrará esta tarifa si los conductores presionan \"No presentarse\" cuando no ven pasajeros en el lugar de recogida.",
        "detail_route": "Detalle de ruta",
        "detail_package": "Detalle de Plan",
        "THIRD_DISTANCE_FEE": "Third distance fee",
        "AFTER_DISTANCE_FEE": "After third distance fee",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Starting_fee": "Starting fee",
        "First_distance": "First distance",
        "Second_distance": "Second distance",
        "Third_distance": "Third distance",
        "Fee_for_second_distance": "Fee for second distance",
        "Fee_for_third_distance": "Fee for third distance",
        "Fee_after_third_distance": "Fee after third distance",
        "Assign_rate_to_car_type": "Assign rate to cartype",
        "car_type": "Cartype",
        "PRICE_ADJUSTABLE": "Price adjustable",
        "minimum_Percent_Adjust": "Minimum % from estimated fare",
        "maximum_Percent_Adjust": "Maximum % from estimated fare",
        "Amount_Step": "Price adjustable",
        "Tax": "Tax",
        "TechFee": "Tech fee",
        "Pay_to_driver": "Pay to Supplier/Driver",
        "Pay_to_driver_tooltip": "Options to compensate drivers:\n - Fixed amount: A fixed amount the driver will receive.\n - Commission: Driver’s set commission applied to the penalty amount.",
        "Fix_amount": "Fixed amount",
        "Commission": "Commission",
        "fareFlat": "Fare flat",
        "flatRoute": "Flat route",
        "Mode": "Mode",
        "speedThresholdKM": "Speed threshold (km/h)",
        "speedThresholdMI": "Speed threshold (mi/h)",
        "timeAndMileage": "Time and mileage",
        "timeOrMileage": "Time or mileage",
        "perDistanceKM": "Per kilometer",
        "perDistanceMI": "Per mile",
        "perTime": "Per minute"
    },
    "flatRateSettings": {
        "flatZoneNote": "The zones you create here are only used to setup your flat rates."
    },
    "deliverySetting": {
        "Name": "NaNombreme",
        "Last_Update": "Última actualización",
        "Status": "Estado",
        "Actions": "Comportamiento"
    },
    "sharingSetting": {
        "EDIT_RATE": "Edit street sharing rate",
        "ADD_RATE": "Add street sharing rate",
        "VIEW_RATE": "View street sharing rate",
        "Name": "Name",
        "startingFee": "Starting fare",
        "minimumFee": "Minimum fare",
        "Update_streetSharing_rate_fail": "The errors occurred while updating street sharing rate",
        "Update_streetSharing_rate_success": "Street sharing rate has been updated successfully",
        "Created_streetSharing_rate_success": "Street sharing rate has been created successfully",
        "Created_streetSharing_rate_fail": "The errors occurred while creating street sharing rate",
        "Loading_streetSharing_rate_fail": "The errors occurred while loading street sharing rate detail",
        "Delete_streetSharing_rate_success": "Street sharing rate has been deleted successfully",
        "Delete_streetSharing_rate_fail": "The errors occurred while deleting street sharing rate",
        "Delete_streetSharing_rate": "Delete street sharing rate",
        "Assign_Car": "Assign car type"
    },
    "carTypeSetting": {
        "newService": "New Service",
        "editService": "Edit Service",
        "serviceInfo": "Service Info",
        "name": "Name",
        "namePH": "Car-type name",
        "enterNumber": "Enter a number",
        "appDisplayName": "App display name",
        "shortDescription": "Short description",
        "maxPassengers": "Max passengers",
        "maxLuggage": "Max luggages",
        "vehiclePhoto": "Vehicle Photos",
        "iconShowList": "Icon to show in list",
        "imageShowWeb": "Image to show on web",
        "carPinOnMap": "Car PIN on map",
        "recommendedSize": "Recommended size:",
        "serviceSetting": "Service Settings",
        "serviceType": "Service type",
        "reservation": "Reservation",
        "onDemand": "On-demand",
        "destinationSetting": "Destination setting",
        "requireDestination": "Require destination",
        "extraDestination": "Extra destination",
        "calculatorFareMode": "Calculate fare mode",
        "hideDestinationOnDemand": "On-demand",
        "meteredFare": "Metered fare",
        "quotedFare": "Quoted fare",
        "availableZones": "Available Zones",
        "dispatchAssociation": "Dispatch Association",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "validateName": "Please input name",
        "validateAppDisplayName": "Please input app display name",
        "validateSelectImage": "Please select an image",
        "updateSuccess": "Update successfully",
        "createSuccess": "Create successfully",
        "failApi": "Something went wrong!",
        "validateRequire": "Please specify passenger count and double-check luggage."
    },
    "carSetting": {
        "Details": "Details",
        "vehicleInfo": "Vehicle Info",
        "Documents": "Documents",
        "vehicle": "Vehicle",
        "addVehicle": "Add Vehicle",
        "detailVehicle": "Vehicle Details",
        "additionalVehicleTitle": "Additional Vehicle Information",
        "Phone_Number_Tooltip": "Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.",
        "discardChange": "Discard changes",
        "model_name_is_required": "Modelo de vehículo obligatorio",
        "DELETE_CAR": "Eliminar coche",
        "DELETE_CONFIRM": "¿Quieres eliminar el coche seleccionado?",
        "Yes": "sí",
        "Cancel": "Cancelar",
        "EDIT_CAR": "editar coche",
        "CREATE_CAR": "crear coche",
        "Plate_Number": "Número de placa",
        "Car_Type": "Tipo de carro",
        "Select_car_type": "Seleccionar tipo de coche",
        "Make": "Fabricar",
        "Select_car_make": "Seleccionar marca de coche",
        "Model": "Modelo",
        "Select_a_car_model": "Seleccione un modelo de coche",
        "Year": "Año",
        "Select_year": "Seleccionar año",
        "Hardware_Meter": "Medidor de hardware",
        "VIN": "Número de identificación del vehículo (VIN)",
        "Company": "Compañía",
        "Select_company": "Seleccionar empresa",
        "Max_Passengers": "Pasajeros máximos",
        "Max_Luggage": "Equipaje máximo",
        "Color": "Color",
        "License_expiry": "Caducidad de la licencia",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Add": "Agregar",
        "Car_Model": "Modelo de auto",
        "Created_Date": "Fecha de creación",
        "Status": "Estado",
        "Actions": "Acciones",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "{0} - {1} de {2} mostrado",
        "Active": "Activo",
        "activeVehicle": "Active vehicle",
        "Inactive": "Inactivo",
        "Edit": "Editar",
        "Deactivate": "Desactivar",
        "Activate": "Activar",
        "Delete": "Borrar",
        "Name": "Nombre",
        "MODEL_LIST": "Lista de modelos",
        "Model_Name": "Nombre del modelo",
        "Add_Model": "Añadir modelo",
        "DELETE_CAR_MODEL": "Eliminar modelo de vehículo",
        "DELETE_CONFIRM_MODEL": "Desea eliminar el modelo de vehículo?",
        "DELETE_CAR_MAKE": "Eliminar marca de coche",
        "DELETE_CONFIRM_CAR_MAKE": "¿Quieres eliminar esta marca de coche?",
        "EDIT_CAR_MAKE": "Editar marca de coche",
        "CREATE_CAR_MAKE": "Añadir marca de coche",
        "Assign_Rate": "Asignar tarifa",
        "ASSIGN_RATE": "Asignar tarifa",
        "Car_type_ID": "Identificación del tipo de coche",
        "App_Display_Name": "nombre App",
        "Affiliate_car_type": "Tipo de vehículo afiliado",
        "Regular_rate": "Tarifa regular",
        "Zone": "Zona",
        "Rate": "Calificar",
        "Zone_Rate": "Zona - Tarifa",
        "Flat_Fare": "Tarifa Plana",
        "Flat_Delivery": "Delivery flat",
        "Select_flat_rate": "Seleccionar tarifa plana",
        "Hourly_daily_rate": "Horas/días",
        "Dispatch_To": "Envío a",
        "Normal_Fare": "Tarifa normal",
        "Upload_an_image": "Subir una imagen",
        "DETAIL_CAR": "Detalle de vehículo",
        "DELETE_MULTI_CAR": "Eliminar vehículos seleccionados",
        "DELETE_MULTI_CAR_CONFIRM": "Desea eliminar estos vehículos?. Sólo pueden ser eliminados vehículos en situación de Inactivos",
        "DETAIL_CAR_MAKE": "Marca de vehículo",
        "Car_Make": "Marca de auto",
        "Create_car_success": "Nuevo coche ha sido creado con éxito",
        "Update_car_success": "el vehículo ha sido actualizado correctamente",
        "Update_car_fail": "error al actualizar vehículo",
        "Create_car_fail": "Los errores ocurrieron al crear el auto",
        "Load_car_fail": "Los errores ocurrieron al cargar el auto",
        "Delete_car_fail": "Los errores ocurrieron al eliminar el auto",
        "Delete_car_success": "El coche se ha eliminado correctamente",
        "Change_cars_status_success": "Todos los estados de los coches se han cambiado con éxito",
        "Change_cars_status_fail": "Los errores ocurrieron al cambiar los estados del automóvil.",
        "Delete_cars_success": "Todos los autos seleccionados se han eliminado con éxito",
        "Delete_cars_fail": "Los errores ocurrieron al eliminar autos",
        "Update_car_type_success": "El tipo de coche se ha actualizado correctamente",
        "Update_car_type_fail": "Los errores ocurrieron al actualizar el tipo de automóvil.",
        "Load_car_type_details_fail": "No se pudieron cargar los detalles del tipo de auto",
        "Update_car_make_fail": "Los errores ocurrieron al actualizar la marca del automóvil.",
        "Update_car_make_success": "La marca del coche se ha actualizado correctamente",
        "Create_car_make_fail": "Los errores ocurrieron al crear la marca del automóvil.",
        "Create_car_make_success": "La nueva marca de automóvil se ha creado con éxito",
        "Update_car_model_fail": "Los errores ocurrieron al actualizar el modelo de automóvil.",
        "Update_car_model_success": "El modelo de coche se ha actualizado con éxito.",
        "Create_car_model_fail": "Los errores ocurrieron al crear el modelo de automóvil.",
        "Create_car_model_success": "El nuevo modelo de coche se ha creado con éxito.",
        "Loading_car_model_fail": "Los errores ocurrieron al cargar el modelo de automóvil.",
        "Delete_car_make_success": "La marca del coche se ha eliminado correctamente",
        "Delete_cat_make_fail": "Los errores ocurrieron al eliminar la marca del automóvil.",
        "Delete_regular_rate_success": "Tarifa regular eliminada correctamente",
        "Delete_regular_rate_fail": "Error al eliminar tarifa regular",
        "Update_status_error": "Error actualización del estatus",
        "Case_number": "número de expediente",
        "Phone_Number": "Número de teléfono",
        "ERROR_INPUT_VALIDPHONE": "Por favor ingrese un número de teléfono válido.",
        "Car_type_filter": "Filtro de tipo de coche",
        "No": "No",
        "Intercity_Rate": "Intercity Rate",
        "Select_intercity_rate": "Select intercity rate",
        "Delivery_Rate": "Delivery Rate",
        "Select_delivery_rate": "Select delivery rate",
        "vehicleOwner": "Name of vehicle’s owner",
        "EDIT_CAR_COLOR": "Edit car color",
        "DETAIL_CAR_COLOR": "Car color detail",
        "CREATE_CAR_COLOR": "Add car color",
        "Car_Color": "Car color",
        "Update_car_color_fail": "The errors occurred while updating car color.",
        "Update_car_color_success": "Car color has been updated successfully.",
        "Create_car_color_fail": "The errors occurred while creating car color.",
        "Create_car_color_success": "New car color has been created successfully",
        "Delete_car_color_success": "Car color has been deleted successfully.",
        "Delete_car_color_fail": "The errors occurred while deleting car color.",
        "DELETE_CAR_COLOR": "Delete car color",
        "DELETE_CONFIRM_CAR_COLOR": "Do you want to delete this car color?",
        "sharing_Rate": "Street Sharing Rate",
        "bookingAffiliate": "Affiliate Booking.com car type",
        "streetSharing": "Street Sharing"
    },
    "operationSetting": {
        "Driver": "Conductor",
        "Search_driver_name": "Buscar nombre del controlador",
        "Company": "Compañía",
        "License_plate": "Placa",
        "Select_license_plate": "Seleccionar matrícula",
        "Shift": "Turno",
        "Zone": "Zona",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "No": "No.",
        "Car_Type": "Tipo de carro",
        "Plate_Number": "Número de placa",
        "Unassign": "Desasignar",
        "Please_choose_driver": "Por favor elija conductor.",
        "Please_choose_company": "Por favor seleccione empresa",
        "Please_license_plate": "Por favor, elija la matrícula.",
        "Please_choose_shift": "Por favor, elija turno.",
        "Please_choose_zone": "Por favor elija zona.",
        "CONFIRM_UNASSIGN": "¿Desea anular la asignación de este controlador?",
        "Assign_success": "La operación ha sido asignada con éxito",
        "Unassign_success": "El controlador se ha desasignado con éxito."
    },
    "paymentMethod": {
        "cash": "Efectivo",
        "easyPaisa": "Easy Paisa",
        "jazzCash": "Jazz Cash",
        "cashBySender": "Efectivo por remitente",
        "cashByRecipient": "Efectivo por remitente",
        "personalCard": "Tarjeta personal",
        "corporateCard": "Tarjeta corporativa",
        "qrCodePayment": "Código QR",
        "directBilling": "Facturación Directa",
        "externalCard": "tarjeta externa",
        "mDispatcherCard": "tarjeta de socio",
        "corporatePrepaid": "prepago corporativo",
        "fleetCard": "tarjeta externa",
        "applePay": "Apple Pay",
        "creditExternalCard": "Tarjeta de crédito (externa)",
        "googlePay": "Google Pay",
        "wallet": "Cartera",
        "BankOfGeorgia": "Bank Of Georgia",
        "tnGeWalletlet": "Monedero electrónico TnG",
        "paymentMethod_1": "Efectivo",
        "paymentMethod_2": "Tarjeta personal",
        "paymentMethod_3": "tarjeta de socio",
        "paymentMethod_4": "Tarjeta corporativa",
        "paymentMethod_5": "Facturación Directa",
        "paymentMethod_6": "tarjeta externa",
        "paymentMethod_7": "prepago corporativo",
        "paymentMethod_8": "Código QR",
        "paymentMethod_9": "Apple Pay",
        "paymentMethod_11": "pago aéreo",
        "paymentMethod_12": "Tarjeta de crédito (externa)",
        "paymentMethod_13": "Cartera",
        "paymentMethod_14": "Monedero electrónico TnG",
        "paymentMethod_16": "Efectivo por remitente",
        "paymentMethod_17": "Efectivo por destinatario",
        "paymentMethod_19": "Boost eWallet",
        "paymentMethod_20": "Vipps",
        "paymentMethod_21": "ZainCash",
        "paymentMethod_23": "Google Pay",
        "paymentMethod_25": "Easy Paisa",
        "paymentMethod_26": "Jazz Cash",
        "vippseWallet": "Vipps",
        "paymentMethod_credit": "Personal card",
        "paymentMethod_razerPay": "RazerPay",
        "paymentMethod_gCash": "GCash",
        "paymentMethod_zainCash": "ZainCash",
        "paymentMethod_xenDit": "Xendit",
        "paymentMethod_FIB": "FIB",
        "paymentMethod_SantimPay": "SantimPay",
        "paymentMethod_ksher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_fawry": "Fawry",
        "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
        "paymentLink": "Payment link ",
        "paymentMethod_xendit": "Xendit",
        "paymentMethod_easyPaisa": "Easy Paisa",
        "paymentMethod_jazzCash": "Jazz Cash",
        "paymentMethod_flooz": "Flooz",
        "paymentMethod_tmoney": "Tmoney",
        "paymentMethod_corporateCard": "Corporate card"
    },
    "changePassword": {
        "Change_password": "Cambia la contraseña",
        "Current_password": "Contraseña actual",
        "New_password": "Nueva contraseña",
        "Confirm_password": "Confirmar contraseña",
        "ERROR_INPUT_PASSWORD": "Por favor ingrese la contraseña",
        "ERROR_INPUT_VALID_PASSWORD": "La contraseña debe contener al menos 8 caracteres, un dígito numérico y un carácter especial",
        "ERROR_INPUT_MATCH_PASSWORD": "Las contraseñas no coinciden.  Inténtalo de nuevo",
        "Save": "Ahorrar",
        "Cancel": "Cancelar"
    },
    "userProfile": {
        "My_account": "Mi cuenta",
        "Username": "nombre de usuario",
        "First_name": "Nombre de pila",
        "Last_name": "Apellido",
        "Phone_number": "Número de teléfono",
        "Email_address": "Dirección de correo electrónico",
        "Address": "Habla a",
        "Save": "Ahorrar",
        "Edit_profile": "Editar perfil",
        "Change_password": "Cambia la contraseña",
        "ERROR_INPUT_USERNAME": "Por favor ingrese el nombre de usuario.",
        "ERROR_INPUT_FIRSTNAME": "Por favor ingrese el primer nombre.",
        "ERROR_INPUT_VALID_EMAIL": "Por favor introduzca un correo electrónico válido.",
        "Required_fields": "Campos requeridos"
    },
    "General": {
        "searchQueuingDriver": "Buscar: nombre del conductor, número de teléfono",
        "search": "Buscar",
        "User": "Usuario",
        "My_Account": "Mi cuenta",
        "Sign_out": "desconectar",
        "My_site": "Mi sitio",
        "Command_center": "Centro de comando",
        "Notifications": "Notificaciones",
        "Show_all": "Mostrar todo",
        "SOS_Notifications": "Notificaciones emergencia",
        "You_dont_have_any_message": "no tienes ningun mensaje",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Casarse",
        "Thu": "Jue",
        "Fri": "Vie",
        "Sat": "Se sentó",
        "Sun": "Sol",
        "Now": "Ahora",
        "NowComplete": "Now (Acttual Pickup Time)",
        "hrs": "horas",
        "patmentMethod_1": "Dinero en efectivo",
        "patmentMethod_2": "Tarjeta personal",
        "patmentMethod_3": "tarjeta de socio",
        "patmentMethod_4": "Tarjeta corporativa",
        "patmentMethod_5": "facturación directa",
        "patmentMethod_6": "tarjeta externa",
        "patmentMethod_7": "Prepago Corporativo",
        "patmentMethod_8": "Código QR",
        "patmentMethod_9": "pago de manzana",
        "patmentMethod_10": "Apple Pay",
        "patmentMethod_12": "Tarjeta de crédito (externa)",
        "patmentMethod_13": "Cartera",
        "patmentMethod_14": "Monedero electrónico TnG",
        "patmentMethod_16": "Cash by sender",
        "patmentMethod_17": "Cash by recipient",
        "patmentMethod_19": "Boost",
        "patmentMethod_20": "Vipps",
        "patmentMethod_23": "Google Play",
        "patmentMethod_24": "Payment Link",
        "patmentMethod_25": "Easy Paisa",
        "patmentMethod_26": "Jazz Cash",
        "patmentMethod_ZainCash": "ZainCash",
        "patmentMethod_Xendit": "Xendit",
        "patmentMethod_FIB": "FIB",
        "patmentMethod_SantimPay": "SantimPay",
        "patmentMethod_GCash": "GCash",
        "patmentMethod_cash": "Cash",
        "patmentMethod_credit": "Personal card",
        "patmentMethod_mDispatcher": "Partner card",
        "patmentMethod_corpCredit": "Corporate Card",
        "patmentMethod_directBilling": "Direct invoicing",
        "patmentMethod_fleetCard": "External card",
        "patmentMethod_prepaid": "Corporate Prepaid",
        "patmentMethod_QRCode": "QR Code",
        "patmentMethod_applePay": "Apple Pay",
        "patmentMethod_easyPaisa": "Easy Paisa",
        "patmentMethod_jazzCash": "Jazz Cash",
        "patmentMethod_prePaid": "Corporate Prepaid",
        "patmentMethod_GooglePay": "Google Pay",
        "patmentMethod_creditCardExternal": "Credit Card",
        "patmentMethod_PaxWallet": "Wallet",
        "patmentMethod_TnGeWallet": "TnG eWallet",
        "patmentMethod_boosteWallet": "Boost",
        "patmentMethod_Vipps": "Vipps",
        "patmentMethod_exZainCash": "ZainCash",
        "patmentMethod_exGCash": "GCash",
        "patmentMethod_exPayTM": "PayTM",
        "patmentMethod_exFlooz": "Flooz",
        "patmentMethod_exTmoney": "Tmoney",
        "patmentMethod_exTelebirr": "GCash",
        "paymentMethod_exzainCash": "ZainCash",
        "paymentMethod_exXendit": "Xendit",
        "paymentMethod_exFIB": "FIB",
        "paymentMethod_exSantimPay": "SantimPay",
        "patmentMethod_exKsher": "Ksher",
        "paymentMethod_paydunya": "PayDunya",
        "paymentMethod_exfawry": "Fawry",
        "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
        "patmentMethod_exPayDunya": "PayDunya",
        "patmentMethod_21": "ZainCash",
        "patmentMethod_paymentLink": "Payment Link",
        "patmentMethod_card": "Personal card",
        "patmentMethod_corporateCard": "Corporate card",
        "patmentMethod_paxWallet": "Wallet",
        "patmentMethod_externalWallet": "External Wallet",
        "patmentMethod_googlePay": "Google Pay",
        "patmentAirlineMethod_5": "Direct invoicing",
        "patmentAirlineMethod_4": "Airline Card",
        "patmentAirlineMethod_7": "Airline Prepaid",
        "Name": "Nombre",
        "Delete": "Borrar",
        "App_SOS_dialog_form_message_1": "- Emergencia SOS",
        "App_SOS_dialog_form_message_2": "necesito ayuda en",
        "App_SOS_dialog_form_message_3": "Reserva: , conductor: , pasajero: , matrícula:",
        "mins": "minutos",
        "Credit_Card": "Tarjeta de crédito",
        "ALL": "Todo",
        "NotFound": "la página solicitad no se ha encontrado",
        "ServerError": "Ocurrió un error",
        "AccessDenied": "No tienes permiso para acceder a este área",
        "Retry": "Intentar de nuevo",
        "Can_not_get_data": "no puedo obtener datos"
    },
    "shiftSettings": {
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "Name": "Nombre",
        "Time_Range": "Intervalo de tiempo",
        "Actions": "Acciones",
        "show_pages": "_ de __ mostrados",
        "Edit": "Editar",
        "Delete": "Borrar",
        "EDIT_SHIFT_SETTING": "Editar ajuste de turno",
        "ADD_SHIFT_SETTING": "Añadir ajuste de turno",
        "DETAIL_SHIFT_SETTING": "Ajuste de cambio de detalle",
        "DELETE_SHIFT_SETTING": "Eliminar ajuste de turno",
        "Delete_confirm_shift_setting": "¿Quieres eliminar este artículo?",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "From": "Desde",
        "To": "A",
        "Edit_shift_template": "Editar plantilla de turno",
        "Add_shift_template": "Agregar plantilla de turno",
        "Detail_shift_template": "Plantilla de cambio de detalle",
        "Delete_shift_template": "Eliminar plantilla turnos",
        "Delete_confirm_shift_template": "¿Quieres eliminar este artículo?",
        "Working_time": "Tiempo de trabajo",
        "Create_shift_settings_success": "La nueva configuración de turno se ha creado con éxito",
        "Create_shift_settings_fail": "Los errores ocurrieron al crear la configuración de turno",
        "Update_shift_settings_success": "La configuración de turnos se ha actualizado correctamente",
        "Update_shift_settings_fail": "Los errores ocurrieron al actualizar la configuración de turnos",
        "Delete_shift_settings_success": "La configuración de turno se ha eliminado correctamente",
        "Delete_shift_settings_fail": "Los errores ocurrieron al eliminar la configuración de turno",
        "Create_shift_template_success": "La nueva plantilla de turno se ha creado con éxito",
        "Create_shift_template_fail": "Los errores ocurrieron al crear la plantilla de turno",
        "Update_shift_template_success": "La plantilla de turno se ha actualizado correctamente",
        "Update_shift_template_fail": "Los errores ocurrieron al actualizar la plantilla de turno",
        "Delete_shift_template_success": "La plantilla de turno se ha eliminado correctamente",
        "Delete_shift_template_fail": "Los errores ocurrieron al eliminar la plantilla de turno",
        "Yes": "Yes",
        "No": "No"
    },
    "promotionSettings": {
        "Detail_campaign": "Detalle Campaña",
        "Edit_campaign": "Editar campaña",
        "Add_campaign": "Agregar campaña",
        "Delete_campaign": "Eliminar campaña",
        "Delete_confirm_campaign": "¿Desea eliminar la campaña seleccionada?",
        "Add": "Agregar",
        "Corporate_list": "Corporate list",
        "permittedCorporate": "Permitted corporate list",
        "CorporateCustomers": "Corporate Customers",
        "auto_add_corporate": "Auto add to Corporate User's promotion list",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "Name": "Nombre",
        "Actions": "Acciones",
        "Private": "Privado",
        "show_pages": "_ de __ mostrados",
        "Edit": "Editar",
        "Delete": "Borrar",
        "Send_to_inbox": "Enviar a Bandeja de Entrada",
        "Save": "Ahorrar",
        "Duplicate": "Duplicar",
        "Cancel": "Cancelar",
        "Required_fields": "Campos requeridos",
        "Promotion_code": "El código de promoción",
        "Promotion_type": "Tipo de promoción",
        "Campaign": "Campaña",
        "Type": "Tipo",
        "Value": "Valor",
        "keepMinFee": "Total fare after promo must greater than minimum fare",
        "Zone": "Zones",
        "paymentMethodsApply": "Payment methods",
        "schedules": "Schedules",
        "minimumEstFareApply": "Minimum estimate total fare to apply code",
        "quantityLimitPerYear": "Maximum uses/user/year",
        "termAndCondition": "Term & condition",
        "Valid_from": "Válida desde",
        "Valid_to": "Válido hasta",
        "Total_uses_user": "Usos Totales/Usuario",
        "Released_quantity": "Cantidad liberada",
        "Number_of_sending_inbox": "Número de bandeja de entrada de envío",
        "Status": "Estado",
        "Activate": "Activar",
        "Deactivate": "Desactivar",
        "Add_promotion_code": "Añadir código de promoción",
        "Edit_promotion_code": "Editar código de promoción",
        "Detail_promotion_code": "Detalle código de promoción",
        "Delete_promotion_code": "Eliminar código de promoción",
        "Delete_confirm_promotion_code": "¿Desea eliminar el código de promoción seleccionado?",
        "New_customer": "Nuevos clientes",
        "Budget_per_user": "Budget/user (for whole promotion time period)",
        "Budget_per_user_day": "Budget/user/day",
        "Budget_per_user_month": "Budget/user/month",
        "Budget_per_user_year": "Budget/user/year",
        "Unlimited": "Ilimitado",
        "Limited": "Limitado",
        "Total_uses_per_user": "Total usos/usuario",
        "Send_an_in_box_automatically_to_new_customers": "Enviar una bandeja de entrada automáticamente a nuevos clientes",
        "Notes": "Notas",
        "Public": "Público",
        "Create_campaign_success": "La nueva campaña se ha creado con éxito",
        "Create_campaign_fail": "Los errores ocurrieron al crear la campaña.",
        "Update_campaign_success": "La campaña ha sido actualizada con éxito",
        "Update_campaign_fail": "Los errores ocurrieron al actualizar la campaña.",
        "Delete_campaign_success": "Campaña eliminada correctamente",
        "Delete_campaign_fail": "Error al eliminar la campaña",
        "Loading_campaign_fail": "Los errores ocurrieron al cargar la campaña.",
        "Update_campaign_status_success": "El estado de la campaña se ha actualizado correctamente",
        "Update_campaign_status_fail": "Los errores ocurrieron al actualizar el estado de la campaña.",
        "Loading_promotion_code_fail": "Error al cargar el código promocional",
        "Select_campaign": "Seleccionar campaña",
        "Create_promotion_code_success": "El nuevo código de promoción se ha creado con éxito",
        "Create_promotion_code_fail": "Los errores ocurrieron al crear el código de promoción",
        "Update_promotion_code_status_success": "El estado del código de promoción se ha actualizado correctamente",
        "Update_promotion_code_status_fail": "Los errores ocurrieron al actualizar el estado del código de promoción",
        "Delete_promotion_code_confirmation": "¿Quieres eliminar estos códigos de promoción?",
        "Just_delete_inactive": "** Solo se puede eliminar el código de promoción en estado inactivo.",
        "Send_inbox_confirmation": "Está seguro de querer enviar este código promocional a la bandeja de entrada de los usuarios?",
        "Send_inbox_success": "Código promocional enviado correctamente",
        "Send_inbox_fail": "Error al enviar código promocional. Inténtelo de nuevo",
        "Confirmation": "Confirmación",
        "Delete_promotion_codes_success": "Código promocional eliminado correctamente",
        "Delete_promotion_codes_fail": "Error al eliminar código promocional",
        "Delete_multiple_promotion_codes_success": "Los códigos de promoción se han eliminado correctamente",
        "Delete_multiple_promotion_codes_fail": "Error al eliminar códigos promocionales",
        "Delete_promotion_code_success": "Código promocional eliminado correctamente",
        "Delete_promotion_code_fail": "Error al eliminar código promocional",
        "Update_multiple_promotion_code_status_success": "Los estados del código de promoción se han actualizado correctamente",
        "Update_multiple_promotion_code_status_fail": "Error al eliminar situación código promocional",
        "Search_here": "Busca aquí",
        "Please_input_campaign_name": "Ingrese el nombre de la campaña",
        "Please_input_promotion_code": "Por favor ingrese el código de promoción",
        "Please_input_promotion_code_at_least_4_characters": "Introduzca al menos 4 caracteres",
        "Please_select_campaign": "Por favor seleccione campaña",
        "Please_input_value": "Por favor ingrese el valor",
        "Please_select_date": "Por favor seleccione la fecha",
        "Please_add_schedules": "Please add schedule",
        "Please_add_corporate": "Please add corporate",
        "Please_choose_at_least_one_customer": "Elija al menos un cliente.",
        "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "Este nombre de campaña ha existido en el sistema.  Por favor ingrese otro.",
        "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "Este código de promoción ha existido en el sistema.  Por favor ingrese otro.",
        "Invalid_file": "Archivo no válido",
        "File_size_larger_than_5Mb": "Tamaño de archivo es superior a 5 Mb",
        "Import_promotions_success": "Archivo importado correctamente",
        "Import_Promotions": "Importar documento",
        "Number_successful_imports": "Documentos importados",
        "Number_skipped": "Saltar documento",
        "File": "Archivo",
        "Phone_Numbers": "Número(s) de teléfono",
        "Import_Promotion_code_success": "Código promocional __ importado correctamente",
        "Import_Promotion_code_fail": "Error al importar código promocional. Inténtelo de nuevo",
        "Customer_type": "Tipo de cliente",
        "All": "Todo",
        "Referred_customers": "Clientes referidos",
        "Common_customers": "Clientes generales",
        "Operation_zone": "Zonas de funcionamiento",
        "totalUsesLimitPerDay": "Usos máximos/usuario/día",
        "quantityLimitPerDay": "Cantidad máxima/día",
        "quantityLimitPerMonth": "Cantidad máxima/usuario/mes",
        "applyVerifiedCustomers": "Aplicar solo para clientes de Malasia verificados",
        "applyWhenBudgetLimit": "User can apply code till budget limit is finished",
        "applyVerifiedCustomersTooltip": "Esta promoción se aplica solo a los malayos que hayan verificado su identificación nacional y hayan subido su tarjeta de identificación al sistema.",
        "valueLimitPerUse": "Maximum value/use (Apply for app v48 and above)",
        "Promotion_Services": "Service",
        "transport": "Transport",
        "shuttle": "Shuttle",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "intercity": "Intercity",
        "addSchedule": "Add Schedule",
        "editSchedule": "Edit Schedule",
        "date": "Date",
        "Time_range": "Time range",
        "startTime": "Start time",
        "endTime": "End time",
        "applyForOtherDays": "Apply for other days",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "schedule_startDate_greater_than_endDate": "The start date is greater than the end date",
        "scheduleTimeDuplicate": "Schedule time must not duplicate with each others",
        "promoIcon": "Promo icon",
        "bannerImage": "Banner image",
        "Upload_an_image": "Upload an image",
        "Click_here_to_view": "Click here to view."
    },
    "quest_event": {
        "name": "Nombre",
        "userType": "Tipo de Usuario",
        "carType": "Car type",
        "operationZones": "Operation zone",
        "type": "Tipo de evento",
        "isLuckyDraw": "Sorteo de la suerte",
        "start_date": "Fecha de inicio",
        "end_date": "Fecha final",
        "numOfQualify": "Número de Calificados",
        "event_progress": "Progreso del evento",
        "isActive": "Estado",
        "Actions": "Acción",
        "passenger": "Customer",
        "driver": "Driver",
        "first_will_win": "First will win",
        "largest_will_win": "Largest will win (leaderboard)",
        "not_start": "Not start",
        "active": "Active",
        "closed": "Closed",
        "event_details": "Quest Details",
        "add_event": "Add Quest",
        "limited": "Limited",
        "unlimited": "Unlimited",
        "eventImage": "Event Image",
        "sharedImage": "Shared Image",
        "qualifiedImage": "Qualified Image",
        "expiredImage": "Expired Image",
        "luckyDrawImage": "Lucky Draw Image",
        "Upload_an_image": "Upload an image",
        "Required_Image": "Image Extensions (.jpg, .png, .gif)",
        "ok": "OK",
        "eventId": "Event ID",
        "passengerMetric": {
            "booking_distance": "Booking distance",
            "booking_time": "Booking time",
            "booking_amount_spent": "Booking amount spent",
            "num_of_ondemand": "Number of on-demand",
            "num_of_reservation": "Number of reservation",
            "num_of_intercity": "Number of intercity",
            "num_of_delivery": "Number of delivery",
            "num_of_referral": "Number of referral",
            "num_of_sharing": "Number of sharing",
            "topup_wallet_amount": "Topup wallet amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "number_of_complete_booking": "Number of complete booking",
            "number_of_food_mart": "Number of food / mart"
        },
        "driverMetric": {
            "booking_distance": "Job distance",
            "booking_time": "Job time",
            "num_of_referral": "Number of referral",
            "num_of_complete": "Number of complete booking (unique customer)",
            "num_of_referral_driver": "Number of referral (driver)",
            "num_of_accept": "Number of accept booking",
            "num_of_receive": "Number of receive booking",
            "num_of_cancel": "Number of cancel booking by driver",
            "sum_of_rating": "Total number of rating star",
            "total_rating": "Total number of rating times",
            "earnings_amount": "Earnings Amount",
            "num_of_complete_wallet": "Number of complete booking (wallet)",
            "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
            "available_time": "Online time"
        },
        "Click_here_to_view": "Click here to view.",
        "view": "Click here to view.",
        "metricType": "Type",
        "task": "Task",
        "value": "Value",
        "quantity": "Quantity",
        "quests_list": "Quests",
        "rankImages": "Rank image (point)",
        "qualifiedPrizeOrder": "Qualified Prizes",
        "luckyDrawPrizeOrder": "Lucky Draw Prizes",
        "reward": "Item",
        "from": "From",
        "to": "To",
        "image": "Image",
        "Add_Prize": "Add",
        "bannerText": "Banner text",
        "buttonText": "Button text",
        "bannerLink": "Banner link",
        "shortLink": "Short link",
        "buttonLinkDesktop": "Button link (Desktop)",
        "buttonLinkAndroid": "Button link (Android)",
        "buttonLinkiOS": "Button link (iOS)",
        "termsURL": "Terms & Conditions Link",
        "metadataTitle": "Metadata title",
        "metadataDescription": "Metadata description",
        "isDarkMode": "Dark Mode",
        "isActive_details": "Active",
        "Required_fields": "Required fields",
        "change": "Change",
        "Please_input_name": "Please input name",
        "Please_select_date": "Please select date",
        "endDate_invalid": "End Date invalid",
        "startDate_greater_than_endDate": "The start date is greater than the end date",
        "Please_input_termsURL": "Please input Terms & Conditions Link",
        "Please_input_metadataTitle": "Please input metadata title",
        "Please_input_metadataDescription": "Please input metadata description",
        "Please_input_bannerText": "Please input banner text",
        "Please_input_buttonText": "Please input button text",
        "Please_input_bannerLink": "Please input banner link",
        "Please_input_shortLink": "Please input short link",
        "Please_input_buttonLinkDesktop": "Please input button link (Desktop)",
        "Please_input_buttonLinkAndroid": "Please input button link (Android)",
        "Please_input_buttonLinkiOS": "Please input button link (iOS) ",
        "the_range_1_to_5": "Please input value in the range 1 to 5 for %{name}!",
        "the_range_1_to_100": "Please input value in the range 1 to 100 for %{name}!",
        "task_is_empty": "Please input task for %{name}!",
        "task_limit_50_characters": "Task maximum length is 50 characters for %{name}!",
        "please_input_points": "Please input points for %{name}!",
        "please_input_value": "Please input value for %{name}!",
        "value_is_invalid": "%{name}, value must be a number",
        "please_upload_image": "Please upload image for item %{name}!",
        "rank_invalid": "Rank %{name} is invalid!",
        "rank_invalid_to": "Rank \"To\" must be a number",
        "deepLink": "Deeplink",
        "point_per_task": "Point per task",
        "points": "Points",
        "points_per_some_think": "Points per %{unit}",
        "criterias": "Criteria (optional)",
        "criterias_list": "Criteria",
        "criteriaType": "Type",
        "criteriaValue": "Value",
        "acceptance_rating": "Acceptance Rating (greater than or equal)",
        "cancellation_rating": "Cancellation Rating (less than or equal)",
        "driver_rating": "Driver Rating (greater than or equal)",
        "please_upload_image_rank": "Please upload image for rank %{name}!",
        "Update_event_success": "Quest has been updated successfully",
        "Update_event_fail": "The errors occurred while updating quest",
        "Create_event_success": "New quest has been created successfully",
        "Create_event_fail": "The errors occurred while creating quest",
        "suspend_list": "Suspend List"
    },
    "voucherSettings": {
        "Search_here": "Search here",
        "Voucher_code": "Code #",
        "Campaign": "Campaign",
        "Type": "Type",
        "Value": "Value",
        "Valid_from": "Valid From",
        "Valid_to": "Valid To",
        "isActive": "Status",
        "Activate": "Activate",
        "Deactivate": "Deactivate",
        "Actions": "Actions",
        "Active": "Active",
        "Inactive": "Inactive",
        "redeemed": "Redeemed",
        "expired": "Expired",
        "Add_Voucher_code": "Add code",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "Name": "Name",
        "show_pages": "{0} - {1} of {2} shown",
        "Edit": "Edit",
        "Delete": "Delete",
        "Save": "Save",
        "Duplicate": "Duplicate",
        "Cancel": "Cancel",
        "Required_fields": "Required fields",
        "Detail_voucher_code": "Detail Voucher Code",
        "Delete_voucher_code": "Delete Voucher Code",
        "Confirmation": "Confirmation",
        "Delete_confirm_voucher_code": "Do you want to delete selected voucher code?",
        "Delete_voucher_code_confirmation": "Do you want to delete these voucher codes?",
        "Just_delete_inactive": "** Just only voucher code in the inactive status can be deleted.",
        "Loading_voucher_code_fail": "The errors occurred while loading voucher code",
        "Select_campaign": "Select Campaign",
        "Create_voucher_code_success": "New voucher code has been created successfully",
        "Create_voucher_code_fail": "The errors occurred while creating voucher code",
        "Update_voucher_code_status_success": "Voucher code status has been updated successfully",
        "Update_voucher_code_status_fail": "The errors occurred while updating voucher code status",
        "Delete_voucher_codes_success": "Voucher code has been deleted successfully",
        "Delete_voucher_codes_fail": "The errors occurred while deleting voucher code",
        "Delete_multiple_voucher_codes_success": "Voucher codes has been deleted successfully",
        "Delete_multiple_voucher_codes_fail": "The errors occurred while deleting voucher codes",
        "Delete_voucher_code_success": "Voucher code has been deleted successfully",
        "Delete_voucher_code_fail": "The errors occurred while deleting voucher code",
        "Update_multiple_voucher_code_status_success": "Voucher code statuses has been updated successfully",
        "Update_multiple_voucher_code_status_fail": "The errors occurred while deleting voucher code statuses",
        "Please_input_voucher_code": "Please input voucher code",
        "Please_input_voucher_code_at_least_4_characters": "Please enter at least 4 characters.",
        "Please_select_campaign": "Please select campaign",
        "Please_input_value": "Please input value",
        "Please_select_date": "Please select date",
        "Invalid_file": "File content is invalid",
        "File_size_larger_than_5Mb": "File size is larger than 5Mb",
        "Import_vouchers_success": "File has been imported successfully",
        "Import_vouchers": "Import File",
        "Number_successful_imports": "Imported items",
        "Number_skipped": "Skip items",
        "File": "File",
        "Phone_Numbers": "phone number(s)",
        "Import_voucher_code_success": "Voucher code <b>{0}</b> has been imported successfully",
        "Import_voucher_code_fail": "Voucher code <b>{0}</b> has been failed to import. Please try again",
        "All": "All"
    },
    "pointConfig": {
        "point_config": "Point config",
        "Convert_spent_amount_to_points": "Convert spent amount to points",
        "Convert_topup_amount_to_points": "Convert topup amount to points",
        "Redeem_points_to_voucher": "Redeem points to voucher",
        "others": "Other",
        "Online_Payment": "Online payment",
        "transport": "Transport",
        "parcel": "Parcel",
        "food": "Food",
        "mart": "Mart",
        "delivery": "Delivery",
        "intercity": "Intercity",
        "applyCash": "Apply for cash",
        "point": "Point",
        "vourcherName": "Name",
        "pointsToRedeem": "Points to redeem",
        "vourcherValue": "Voucher value",
        "validTime": "Valid time",
        "month": "Month(s)",
        "day": "Day(s)",
        "termAndCondition": "Terms & conditions",
        "Actions": "Actions",
        "add_voucher": "Add",
        "instructionURL": "Instruction link",
        "displayName": "Show name on super app",
        "isActive": "Active",
        "EDIT_VOUCHER": "Update Voucher",
        "DETAIL_VOUCHER": "Voucher Details",
        "CREATE_VOUCHER": "Add Voucher",
        "update_successfully": "Point config setting has been updated successfully",
        "Create_voucher_successfully": "New voucher has been created successfully",
        "Create_voucher_fail": "The errors occurred while creating voucher",
        "Update_voucher_successfully": "Voucher has been updated successfully",
        "Update_voucher_fail": "The errors occurred while updating voucher",
        "Delete_voucher_successfully": "Voucher has been deleted successfully",
        "Delete_voucher_fail": "The errors occurred while deleting voucher",
        "Confirmation": "Confirmation",
        "Delete_voucher_confirmation": "Do you want to delete this voucher?",
        "Please_enter_value_least_one": "Conversion rate is required! Set up spent amount or top-up amount to continue.",
        "Please_enter_value_is_number_spentAmount": "Please enter valid amount at \"Convert spent amount\" service \"{0}\".",
        "Please_enter_value_is_number_topUp": "Please enter valid amount at \"Convert topup amount\" method \"{0}\"."
    },
    "resetPassword": {
        "New_Password": "Nueva contraseña",
        "New_Comfirm_Password": "Nuevo Confirmar contraseña",
        "Save": "Ahorrar",
        "INFO_SUCCESS": "Se ha enviado un enlace para restablecer la contraseña a su correo electrónico",
        "TOKEN_EXPIRED": "El token para restablecer la contraseña ha caducado",
        "Back_To_Login_Page": "Volver a página de inicio"
    },
    "permission": {
        "editBooking": "Edit farm-out bookings",
        "companyOperator": "Company Operator",
        "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
        "Third_Party_Integration_HolidayTaxis": "3rd party Integration (HolidayTaxis)",
        "supplierPermission": "Supplier permission",
        "Hint_edit_booking": "Allow companies/suppliers to edit bookings farmed out by the fleet owner.",
        "View": "Vista",
        "Actions": "Acciones",
        "EditFare": "Edit Fare",
        "EditDriverEarning": "Edit Driver Earning",
        "EditTotal": "Editar total",
        "ImportBookings": "Import Transport Bookings",
        "ImportDeliveryBookings": "Import Delivery Bookings",
        "ShowFare": "Show Fare",
        "delete_permission": "Eliminar permiso",
        "DELETE_CONFIRM": "¿Está seguro de que desea eliminar el permiso seleccionado?",
        "Yes": "Sí",
        "No": "No",
        "Cancel": "Cancelar",
        "DRIVERS": "Conductores",
        "Driver_list": "Lista de conductores",
        "Enter_to_search": "Entrar para buscar",
        "Permitted_driver_list": "Lista de conductores permitidos",
        "Save": "Ahorrar",
        "CAR": "Vehículo",
        "Car_list": "Lista de carros",
        "Permitted_car_list": "Lista de autos permitidos",
        "Edit_permission": "Editar permiso",
        "Add_permission": "Agregar permiso",
        "Detail_permission": "Permiso de detalle",
        "Name": "Nombre",
        "Driver_Car": "Conductor/vehículo",
        "All": "Todo",
        "Company": "Compañía",
        "By_specific": "Individual",
        "Drivers": "Conductores",
        "Cars": "Coches",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "_ de __ mostrados",
        "Delete": "Borrar",
        "Edit": "Editar",
        "Status": "Estado",
        "Action": "Acción",
        "Inactive": "Inactivo",
        "Active": "Activo",
        "Update_status_error": "Error actualización del estatus",
        "All_tooltip": "Todos los conductores se muestran en Mapa, Panel de reservas, Conductores, Operaciones e Informes. Todos los vehículos se mostrarán en Detalle de vehículos",
        "Company_tooltip": "Los conductores permitidos se muestran en Mapa, Panel de reservas, Conductores, Operaciones e Informes. Los vehículos permitidos se mostrarán en Detalle de vehículos",
        "By_specific_tooltip": "Los conductores permitidos se muestran en Conductores, Operaciones e Informes. Los vehículos permitidos se mostrarán en Detalle de vehículos",
        "Full_permissions": "Permiso completo",
        "ChangeBalance": "Cambiar saldo",
        "ChangeCashBalance": "Change Cash Balance",
        "TotalBalance": "Balance total",
        "WriteOffDebt": "Cancelar deuda",
        "topupPaxWallet": "Change Balance",
        "SeFleetCommission": "Establecer comisión de flota",
        "EditFleetCommission": "Edit fleet commission",
        "EditBalance": "Edit Balance",
        "ViewHeatMap": "Ver mapas de calor",
        "Delete_permission_success": "el permiso ha sido eliminado correctamente",
        "Delete_permission_fail": "Error al eliminar permiso",
        "Create_permission_fail": "Error al crear nuevo permiso",
        "Create_permission_success": "Nuevo permiso ha sido creado correctamente",
        "Update_permission_fail": "Error al actualizar permiso",
        "Update_permission_success": "Permiso actualizado correctamente",
        "No_action_selected": "Marque al menos una acción en la lista de acciones",
        "Required_fields": "Campos requeridos",
        "sendSMS": "Send Message (SMS)",
        "sendInbox": "Send Message (Inbox)",
        "PayToDriver": "View 'Pay to Driver'",
        "PayoutHistory": "View 'Payout History'",
        "Pay": "Pay driver",
        "ActivateDriver": "Activate Driver",
        "Export": "Export",
        "Export_csv": "Export .csv",
        "Street-sharing_rate": "Street sharing rate"
    },
    "thirdPartySettings": {
        "category": "Categoría",
        "Not_over_limit_subs": "No puede crear más de 10 sububicaciones",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "_ de __ mostrados",
        "Delete": "Borrar",
        "Edit": "Editar",
        "Status": "Estatus",
        "Actions": "Acciones",
        "Action": "Acción",
        "Inactive": "Inactivo",
        "Active": "Activo",
        "website": "Website",
        "Check_Google_Address": "Verifique la dirección de Google de latitud y longitud",
        "Delete_Category_success": "La categoría ha sido eliminada con éxito",
        "Delete_category_fail": "Los errores ocurrieron al eliminar la categoría",
        "availableTime": "Available time",
        "Please_choose_at_least_one_category": "Por favor elige al menos una categoría",
        "Please_input_category_name": "Ingrese el nombre de la categoría",
        "Create_category_success": "La categoría ha sido creada con éxito",
        "Create_category_fail": "Los errores ocurrieron al crear la categoría.",
        "Update_third_party_success": "La ubicación ha sido actualizada con éxito",
        "Update_category_fail": "Los errores ocurrieron al actualizar la categoría.",
        "All_Category": "toda la compañía",
        "Delete_Category": "Editar categoria",
        "Edit_Category": "Editar categoria",
        "Add_Category": "Añadir categoría",
        "Add_Third_Party_location": "Agregar",
        "Add": "Agregar",
        "Edit_Third_Party_location": "Editar ubicación de terceros",
        "Location_Not_Found": "Ubicación no encontrada",
        "Create_third_party_success": "Ubicación no encontrada",
        "Create_third_party_fail": "Error al crear ubicación",
        "Update_third_party_fail": "Los errores ocurrieron al actualizar la ubicación",
        "Alias": "Alias",
        "Address": "Habla a",
        "Latitude": "Latitud",
        "Longitude": "Longitud",
        "Please_input_address": "Por favor ingrese la dirección",
        "Please_input_name": "Ingrese el nombre de la ubicación",
        "Please_input_latitude": "Por favor ingrese la latitud",
        "Please_input_longitude": "Por favor ingrese la longitud",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "Required_fields": "Campos requeridos",
        "Last_update": "última actualización",
        "Username": "nombre de usuario",
        "locationType": "LocationType",
        "single": "Ubicación única",
        "multiple": "Múltiples ubicaciones",
        "AddMore": "Añadir más",
        "locationList": "Lista de ubicaciones",
        "instructionLink": "Enlace de instrucciones",
        "location": "Ubicación",
        "checkOnMap": "Consultar en el mapa",
        "Remove": "Eliminar",
        "placeType": "Tipo",
        "all": "Todo",
        "PU": "Levantar",
        "DO": "Dejar",
        "namePlaceholder": "e.j. Terminal 1",
        "instructionLinkPlaceholder": "Página HTML con todas las instrucciones",
        "instructionLinkTooltip": "Introducir un enlace para la página de instrucciones. El pasajero puede seguir las instrucciones en este enlace para encontrar el punto de recogida",
        "longitudePlaceholder": "108.1837969",
        "latitudePlaceholder": "16.0393381",
        "Error_message_lat_in_range": "La latitud debe estar entre -90,0 y 90,0",
        "Error_message_lng_in_range": "La longitud debe estar entre -180,0 y 180,0.",
        "Delete_third_party_success": "La ubicación se ha eliminado correctamente",
        "Delete_third_party_fail": "Los errores ocurrieron al eliminar la ubicación",
        "Delete_location": "Eliminar ubicación",
        "Note_guide": "Nota: al buscar en el mapa, su ubicación se marcará rápidamente con un pin."
    },
    "city": {
        "DELETE_CITY": "Eliminar ciudad",
        "DELETE_CONFIRM": "¿Está seguro de que desea eliminar la ciudad seleccionada?",
        "Yes": "Sí",
        "No": "No",
        "Cancel": "Cancelar",
        "EDIT_CITY": "editar ciudad",
        "ADD_CITY": "Añadir ciudad",
        "Name": "Nombre",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "_ de __ mostrados",
        "Delete": "Eliminar",
        "Edit": "Editar",
        "DETAIL_CITY": "Detalle ciudad",
        "Update_city_success": "Ciudad actualizada correctamente",
        "Create_city_success": "Nueva ciudad creada correctamente",
        "Delete_city_success": "Ciudad eliminada correctamente",
        "cityName": "City name",
        "Service": "Servicio",
        "serviceTransportation": "Transportation",
        "serviceAssistant": "Assistant",
        "serviceDelivery": "Delivery",
        "isShowDriverSignUp": "Show in provider signup",
        "note_isShowDriverSignUp": "(Apply for new signup module from 4.6.46 and above)"
    },
    "jobTab": {
        "DELETE_JOBTAB": "Delete Jobs Tab",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Jobs Tab?",
        "Yes": "Yes",
        "No": "No",
        "Cancel": "Cancel",
        "EDIT_JOBTAB": "Edit Job Tab",
        "ADD_JOBTAB": "Add Jobs Tab",
        "Name": "Name",
        "Required_fields": "Required fields",
        "Save": "Save",
        "Add": "Add",
        "Show_per_page": "Items per page",
        "Page": "Page",
        "show_pages": "{0} - {1} of {2} shown",
        "Delete": "Delete",
        "Edit": "Edit",
        "DETAIL_JOBTAB": "Detail Jobs Tab",
        "Update_JOBTAB_success": "The Jobs Tab has been updated successfully",
        "Create_JOBTAB_success": "New Jobs Tab has been created successfully",
        "Delete_JOBTAB_success": "The Jobs Tab has been deleted successfully",
        "JOBTABName": "Jobs Tab name",
        "jobsLink": "Link"
    },
    "driverNote": {
        "name": "Driver note",
        "DELETE": "Delete Driver Note",
        "DELETE_CONFIRM": "Are you sure you want to delete the selected Driver Note?",
        "EDIT": "Edit Driver Note",
        "ADD": "Add Driver Note",
        "DETAIL": "Detail Driver Note",
        "Update_success": "The Driver Note has been updated successfully",
        "Create_success": "New Driver Note has been created successfully",
        "Delete_success": "The Driver Note has been deleted successfully",
        "Delete_fail": "The errors occurred while deleting driver note",
        "Load_fail": "The errors occurred while loading driver note",
        "Update_fail": "The errors occurred while updating driver note",
        "Create_fail": "The errors occurred while creating driver note"
    },
    "flightIntegration": {
        "Username": "nombre de usuario",
        "provider": "Flight Provider",
        "Key": "API Key",
        "apiId": "API Id",
        "Save": "Ahorrar",
        "Update_flight_integration_success": "La integración de vuelos se ha actualizado con éxito.",
        "Update_flight_integration_fail": "Los errores ocurrieron al actualizar la integración de vuelos."
    },
    "smsIntegration": {
        "provider": "SMS Provider",
        "apiToken": "API Token",
        "subAccountId": "Sub Account ID",
        "senderIdSource": "Sender ID (source):",
        "apiKey": "API Key",
        "username": "Username",
        "shortCode": "Short Code",
        "key": "Key",
        "webService": "Web service",
        "bearerToken": "Bearer token",
        "keyId": "Key ID",
        "email": "Email",
        "appKey": "App key",
        "sender": "Sender",
        "password": "Password",
        "maskedPhone": "Masked phone number",
        "senderId": "Sender ID",
        "webservice": "Web service",
        "brandname": "Brand name",
        "appSecret": "App secret",
        "consumerKey": "Consumer key",
        "requestUrl": "Request Url",
        "authId": "Auth ID",
        "authToken": "Auth Token",
        "authKey": "Auth Key",
        "templateId": "Template Id",
        "templateKey": "Template Key",
        "phloId": "PHLO ID",
        "dlcNumber": "10DLC Number",
        "app_key": "App key",
        "api_key": "API key",
        "accountSID": "Account SID",
        "applicationId": "Application ID",
        "phoneNumber": "SMS number 1",
        "phoneNumber2": "SMS number 2",
        "SenderID_1": "SenderID 1",
        "SenderID_2": "SenderID 2",
        "smsMarketingNumber": "SMS makerting phone number",
        "updateSMSSuccess": "The sms integration has been updated successfully",
        "Deleted_success": "The SMS has been deleted successfully",
        "receiverCountries": "Receiver Countries",
        "error": {
            "ERROR_CREATE": "The errors occurred while creating sms",
            "COUNTRY_EXISTED": "Country already exist on system",
            "ERROR_DELETE": "Failed to delete SMS",
            "ERROR_UPDATE": "Failed to update SMS"
        },
        "identifierId": "Identifier ID"
    },
    "mDispatcher": {
        "mDispatchers": "Socios",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Deactivate": "Desactivar",
        "Activate": "Activar",
        "Delete": "Borrar",
        "Save": "Ahorrar",
        "Edit": "Editar",
        "ADD_MDISPATCHER": "Agregar socio",
        "EDIT_MDISPATCHER": "Editar socio",
        "Phone_number": "Número de teléfono",
        "first_name": "Nombre de pila",
        "last_name": "Apellido",
        "email": "Correo electrónico",
        "address": "Habla a",
        "mDispatcher_type": "tipo de socio",
        "mDispatcher_type_toolTip": "Para agregar más tipos de socios, vaya a Configuración > Tipo de socio",
        "queuing_area_toolTip": "Para agregar más áreas de espera, vaya al menú Área de espera.",
        "mDispatcher": "Compañero",
        "card_management": "Gestión de tarjeta",
        "Select_mDispatcher_type": "Seleccionar tipo de socio",
        "Amount": "Monto",
        "Percent": "Por ciento",
        "Yes": "Sí",
        "Required_fields": "Campos requeridos",
        "Cancel": "Cancelar",
        "commission_type": "Tipo de comisión",
        "commission_value": "Valor de la comisión",
        "Name": "Nombre",
        "Phone_Number": "Número de teléfono",
        "queuing_area": "Área de espera",
        "mdispatcher_type": "tipo de socio",
        "registered_from": "Registrado desde",
        "registration_date": "Fecha de Registro",
        "status": "Estado",
        "actions": "Acciones",
        "Select_queue_area": "Seleccione el área de la cola",
        "for_new_mDipatcher_account_password_default_is_password": "Para la nueva cuenta de socio, la contraseña predeterminada es \"contraseña\"",
        "Create_mDispatcher_success": "El nuevo socio se ha creado con éxito",
        "Create_mDispatcher_fail": "Los errores ocurrieron al crear el socio",
        "Load_mDispatcher_fail": "Los errores ocurrieron al cargar el socio",
        "Update_mDispatcher_success": "El socio se ha actualizado correctamente",
        "Update_mDispatcher_fail": "Los errores ocurrieron al actualizar el socio",
        "Update_mDispatcher_status_success": "El estado del socio se ha actualizado correctamente",
        "Update_mDispatcher_status_fail": "Los errores ocurrieron al actualizar el estado del socio",
        "Update_mDispatcher_statuses_success": "Los estados de los socios se han actualizado correctamente",
        "Update_mDispatcher_statuses_fail": "Los errores ocurrieron al actualizar los estados de los socios",
        "Delete_mDispatcher_success": "El socio se ha eliminado correctamente",
        "Delete_mDispatcher_fail": "Los errores ocurrieron al eliminar al socio",
        "Delete_mDispatchers_success": "Los socios se han eliminado correctamente",
        "Delete_mDispatchers_fail": "Los errores ocurrieron al eliminar socios",
        "Do_you_want_to_delete_these_mDipatchers": "¿Desea eliminar estos socios?",
        "Only_mDistcher_in_the_inactive_status_can_be_deleted": "Sólo pueden ser eliminados los colaboradores en situación inactiva",
        "No_card": "Tarjeta no encontrada",
        "Reset_Password": "Restablecer la contraseña",
        "Reset_password_success": "La contraseña de socio se ha restablecido correctamente",
        "Reset_password_fail": "Los errores ocurrieron al restablecer la contraseña de los socios",
        "Do_you_want_to_reset_password": "Desea restablecer la contraseña?",
        "ERROR_EXISTED_PHONE": "Este teléfono ha existido en el sistema.  Por favor ingrese otro",
        "ERROR_INPUT_VALIDPHONE": "Por favor ingrese un número de teléfono válido.",
        "Partner_detail": "Detalles del socio",
        "ALERT_SELECT_ACTIVATE": "Seleccione socios para activar.",
        "ALERT_SELECT_DEACTIVATE": "Seleccione socios para desactivar.",
        "CONFIRM_DEACTIVATE_MULTI": "¿Desea desactivar estos socios?",
        "CONFIRM_ACTIVATE_MULTI": "¿Quieres activar estos socios?",
        "ERROR_COMMISSION_NUMBERS": "por favor ingrese un número valido",
        "No_Support": "La nueva tarjeta introducidad no es válida en esta zona",
        "Add": "Agregar",
        "Default_zone": "Zona por defecto",
        "required_email_payment": "Se requiere el correo electrónico del socio.  Por favor, actualice el perfil para continuar.",
        "titleGuestQR": "Partner Guest-QR Link",
        "questQRContent": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
        "View_Guest_QR": "View Guest QR"
    },
    "mDispatcherType": {
        "Name": "Nombre",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "No": "No",
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Page": "Página",
        "show_pages": "_ de __ mostrados",
        "Delete": "Eliminar",
        "Edit": "Editar",
        "DELETE_MDISPATCHER_TYPE": "Eliminar tipo de socio",
        "DELETE_CONFIRM": "¿Está seguro de eliminar el tipo de socio?",
        "Yes": "Sí",
        "EDIT_MDISPATCHER_TYPE": "Editar tipo de socio",
        "ADD_MDISPATCHER_TYPE": "Agregar tipo de socio",
        "DETAIL_MDISPATCHER_TYPE": "Detalle tipo de socio",
        "Actions": "Acciones",
        "Delete_mDispatcherType_success": "El tipo de socio se ha eliminado correctamente",
        "Delete_mDispatcherType_fail": "Los errores ocurrieron al eliminar el tipo de socio",
        "Load_mDispatcherType_fail": "Los errores ocurrieron al cargar el tipo de socios",
        "Create_mDispatcherType_success": "El nuevo tipo de socio se ha creado correctamente",
        "Create_mDispatcherType_fail": "Los errores ocurrieron al crear el tipo de socios",
        "Update_mDispatcherType_success": "El tipo de socio se ha actualizado correctamente",
        "Update_mDispatcherType_fail": "Los errores ocurrieron al actualizar el tipo de socios"
    },
    "queuingArea": {
        "queuing_area_info": "Información del área de cola",
        "queuingDrivers": "Conductores activos",
        "Name": "Nombre",
        "Required_fields": "Campos requeridos",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "Yes": "Sí",
        "No": "No",
        "Add": "Agregar",
        "Show_per_page": "elementos por página",
        "Enter_to_search": "Intro para buscar",
        "Page": "Página",
        "show_pages": "_ de __ mostrados",
        "Delete": "Borrar",
        "Edit": "Editar",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Deactivate": "Desactivar",
        "Activate": "Activar",
        "drivers": {
            "order": "Pedido",
            "Driver_name": "Nombre del conductor",
            "Phone_Number": "Número de teléfono",
            "driverLicenseNumber": "Placa",
            "Car_Type": "Tipo de carro",
            "checkInTime": "Hora de entrada",
            "duration": "Duración",
            "timestampScore": "Puntuación de marca de tiempo",
            "changeOrderDriver": "Cambia el orden",
            "detailOrderDriver": "Orden del conductor",
            "Yes": "Yes",
            "No": "No",
            "insert": "Insertar",
            "refresh": "Refrescar",
            "insertDriver": "Insertar un controlador en la cola",
            "orderDriver": "Orden #",
            "reason": "Razón",
            "removeDriver": "Eliminar controlador de la cola",
            "removeDriverConfirm": "¿Desea eliminar este controlador de la cola?",
            "driverNotStay": "El conductor no está en el área de la cola. Por favor, inténtelo de nuevo más tarde.",
            "overOrder": "El número de # pedido no puede ser mayor que el número total de conductores en la cola.",
            "noDriverFound": "No se encontró ningún controlador",
            "driverUpdateErrRs": "El conductor no está en el área de la cola.  Por favor, inténtelo de nuevo más tarde.",
            "orderUpdateErrRs": "El número de # pedido no puede ser mayor que el número total de conductores en la cola.",
            "removeDriverSuccess": "El controlador se ha eliminado correctamente del área de la cola.",
            "removeDriverFail": "Se produjo un error al eliminar el controlador del área de la cola.",
            "operationInsertFailRs": "El controlador no existe o no está disponible.",
            "versionInsertFailRs": "Para aplicar esta función, el controlador debe actualizar su aplicación de controlador a la última versión",
            "queueInsertFailRs": "La cola no existe o el conductor no está en el área de la cola.  Por favor, inténtelo de nuevo más tarde.",
            "vehicleInsertFailRs": "La cola no admite el tipo de vehículo del conductor.",
            "orderInsertFailRs": "El número # de pedido no puede ser mayor que el número total de conductores en la cola.",
            "positionInsertFailRs": "Ocurrió un error al insertar el controlador en el área de la cola.",
            "insertDriverSuccess": "El controlador se ha insertado correctamente en el área de la cola.",
            "changeOrderSuccess": "La posición del conductor se ha cambiado con éxito.",
            "insertDriverFail": "Ocurrió un error al insertar el controlador desde el área de la cola.",
            "changeOrderFail": "Ocurrió un error al cambiar la posición del conductor desde el área de la cola.",
            "selectToInsert": "Buscar: nombre del conductor, número de teléfono",
            "updateBy": "Actualizado por el operador",
            "changeOrder": "Cambia el orden",
            "remove": "Eliminar"
        },
        "keepDriverPlaceCancelNoShow": "Mantener el lugar del conductor cuando la reserva se cancela / no se presenta",
        "keepDriverPlaceUnavailable": "Mantenga el lugar del conductor cuando el conductor no esté disponible o esté desconectado",
        "range": "rango de cola",
        "queueRangeTooltip": "Así es como los conductores verán su número de posición en la cola en la aplicación del conductor. Por ejemplo:\nEstablecer el rango de la cola en 1: los conductores verán su posición en la cola como un solo número: 1, 2, 3, 4, etc.\nEstablecer el rango de la cola en 5: los conductores verán su posición en la cola como un rango: 1-5, 6-10, 11-15, 16-20, etc.\nEstablecer el rango de la cola en 10: los conductores verán su posición en la cola como un rango: 1-10, 11-20, 21-30, 31-40, etc. ",
        "queueCanceledTooltip": "1. Cuando el pasajero cancele o no se presente, el conductor permanecerá en la posición superior si el conductor vuelve a la cola dentro del tiempo establecido.\n2. La posición del conductor tendrá alta prioridad si el conductor vuelve a la cola dentro del tiempo establecido después de completar una reserva de viaje corto ",
        "DELETE_QUEUING_AREA": "Eliminar área de espera",
        "DELETE_CONFIRM": "Desea eliminar área de espera?",
        "EDIT_QUEUING_AREA": "Editar área de espera",
        "ADD_QUEUING_AREA": "Agregar área de espera",
        "DETAIL_QUEUING_AREA": "Detalle zona de colas",
        "Queuing_area": "zona de cola",
        "Address": "Dirección",
        "Enter_a_location": "Introduce una ubicación",
        "Entered_Address_is_incorrect": "El sistema no puede determinar su ubicación.  Utilice la dirección sugerida.",
        "Not_supported_zone": "Esta área de cola no está en la zona admitida",
        "Active_mDispatcher_list": "Lista de socios activos",
        "Queue_list": "lista de colas",
        "Allow_radius": "Permitir radio",
        "Min_drivers_required": "# mínimo de conductores necesarios",
        "Open_queuing_area": "Zona de cola abierta",
        "Auto_dispatch": "Envío automático a los conductores en cola (no es necesario usar la aplicación Partner)",
        "Active_car_list": "Lista de autos activos",
        "mDispatcher": "Compañero",
        "Google_Address": "Dirección de Google",
        "Status": "Estado",
        "Update_status_error": "Error actualización del estatus",
        "driver_checkin_area": "Área de registro del conductor",
        "EDIT_POLYGONS": "Editar polígonos",
        "pax_pickup_area": "Área de recogida de pasajeros",
        "pickup_area": "Área de recogida",
        "your_chosen_polygon": "El polígono elegido",
        "other_queues": "Otras colas",
        "operation_zones": "Zonas de operación",
        "delete_pickup_area_confirm": "¿Estás seguro de que deseas eliminar esta área de recogida?",
        "delete_pickup_area": "Elija el área de registro del conductor.",
        "please_choose_driver_check_in_area": "Por favor, elija el área de recogida.",
        "please_choose_pick_up_area": "Por favor, elija el área de recogida.",
        "Load_queuing_area_fail": "Error al cargar el área de espera",
        "Create_queuingArea_success": "Área de espera creada correctamente",
        "Update_queuingArea_success": "Área de espera actualizada correctamente",
        "Delete_queuingArea_success": "El área de espera ha sido eliminada exitosamente",
        "Delete_queuingArea_fail": "Los errores ocurrieron al eliminar el área de espera",
        "Create_queuingArea_fail": "Los errores ocurrieron al crear el área de espera",
        "Load_queuingArea_fail": "Los errores ocurrieron al cargar el área de espera",
        "Update_queuingArea_fail": "Los errores ocurrieron al actualizar el área de espera"
    },
    "appBanner": {
        "Save": "Ahorrar",
        "Pax_app": "App de pasajero",
        "Apply_to": "Aplica a",
        "Please_select_view": "Selecciones vista",
        "Select_Type": "Por favor seleccione el tipo de banner",
        "Home_view": "Vista Principal",
        "Other_view": "Otra vista",
        "Banner_1": "Pancarta #1",
        "Upload_an_image": "Ninguna imagen seleccionada",
        "Action": "Acción",
        "NA": "No aplica",
        "Link_to_website": "Enlace a web",
        "Make_a_call": "realizar una llamada",
        "Send_an_email": "Enviar un correo electrónico",
        "Banner_2": "Pancarta #2",
        "Banner_3": "Pancarta #3",
        "Driver_app": "App conductor",
        "pax": "Aplicación Pasajero",
        "driver": "Aplicación de conductor",
        "Name": "Nombre",
        "viewType": "Tipo",
        "status": "Estado",
        "image": "Imagen",
        "html": "HTML",
        "Delete_Banner": "Eliminar pancarta",
        "Delete_confirm_banner": "¿Estás seguro de que deseas eliminar el banner seleccionado?",
        "Delete_banner_success": "El banner se ha eliminado correctamente",
        "Delete_banner_fail": "Los errores ocurrieron al eliminar el banner",
        "edit_banner": "Editar pancarta",
        "detail_banner": "Banner de detalle",
        "create_banner": "Agregar pancarta",
        "note_1": "*La extensión del archivo debe ser .jpg, .jpeg, .png y menos de 5MB.",
        "note_2": "*Las imágenes deben tener una relación de aspecto de 16:9.",
        "note_3": "Otras vistas incluye Bandeja de Entrada, amigos referidos, Recibos, Mis reservas, código promocional, agregar tarjeta/método pago, perfil, esperando asignación/reserva completada, vista completa",
        "Update_app_banner_success": "Banner en App actualizado correctamente",
        "Update_app_banner_fail": "Error al actualizar banner de App",
        "Create_app_banner_success": "El banner de la aplicación se ha creado correctamente",
        "Create_app_banner_fail": "¡No se pudo crear el banner de la aplicación!",
        "Note": "Utilice el archivo en formato .png (fondo transparente, tamaño 400:200px) para un mejor rendimiento de la aplicación",
        "Limit_size_upload": "El tamaño del archivo debe ser inferior a 8Mb.",
        "display_duration": "Display duration",
        "playback_settings": "Playback settings",
        "display_banner_again_after": "Display banners again after",
        "stop_display_banner_after": "Stop display banner after",
        "enable_playback": "Enable playback",
        "Update_playback_success": "Playback settings has been updated successfully",
        "Update_playback_fail": "Failed to update the playback settings!",
        "language": "Language",
        "single": "Single Language",
        "multi": "Multi Languages",
        "es-ES": "Spanish",
        "en-US": "English",
        "fr-FR": "French",
        "id-ID": "Bahasa indonesia",
        "vi-VN": "Vietnamese",
        "ur-PK": "Urdu",
        "ms-MY": "Malaysia",
        "ar-AR": "Arabic",
        "nb-NO": "Norwegian",
        "en-GB": "English (United Kingdom)",
        "am-ET": "Amharic",
        "ro-RO": "Romanian",
        "version": "App Version",
        "comparisonVersionApp": {
            "is": "Is",
            "is_not": "Is not",
            "is_one_of": "Is one of",
            "is_not_one_of": "Is not one of",
            "greater_or_equal": "Greater or equal",
            "less_or_equal": "Less or equal"
        },
        "platform": "Platform",
        "platformItem": {
            "all": "All",
            "iOS": "iOS",
            "android": "Android"
        },
        "userStatus": "User status",
        "Active": "Active",
        "Inactive": "Inactive",
        "in_review_completed": "In review (profile completed)",
        "in_review_incomplete": "In review (profile incomplete)",
        "in_review_inProgress": "In review (profile in progress)",
        "zones": "Zones"
    },
    "reservation": {
        "name": "Reserva",
        "company": "Empresa",
        "carType": "Categoría de vehículo",
        "licensePlate": "Matrícula:",
        "driver": "Conductor:",
        "showAllDrivers": "Mostrar todos los conductores",
        "available": "Disponible",
        "reserved": "Reservado",
        "search_holder": "Empresa, nombre del conductor, tipo de vehículo, matrícula",
        "all": "Todos",
        "today": "Hoy",
        "thisWeek": "Esta semana",
        "day": "A diario",
        "week": "Semanalmente",
        "bookingID": "ID reserva",
        "tripId": "Trip ID",
        "pickupTime": "Hora de recogida",
        "pickupLocation": "Dirección de recogida",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "sunday": "Domingo",
        "overlapped": "Solapado",
        "noData": "No se han encontrado datos",
        "bookingDetails": "Detalles de reserva",
        "reservedHint": "Las reservas están confirmadas",
        "availableHint": "No existen reservas confirmadas",
        "overlappedHint": "Dos o más reservas se encuentran solapadas"
    },
    "dashboard": {
        "API": "API",
        "cc": "Central Reservas",
        "dmc": "Panel de reservas",
        "streetSharing": "Street-Sharing",
        "kiosk": "terminal",
        "mDispatcher": "Compañero",
        "paxApp": "App pasajero",
        "webBooking": "Reservas Web",
        "carHailing": "Coche-Hailing",
        "acceptance": "Acceptance",
        "booking_com": "Booking.com",
        "completed": "Completado",
        "noShow": "No presentarse",
        "canceled": "Cancelado",
        "incident": "Incidencia",
        "rate1": "1 estrella",
        "rate2": "2 estrellas",
        "rate3": "3 estrellas",
        "rate4": "4 estrellas",
        "rate5": "5 estrellas",
        "Export_to_Excel": "Exportar a excel",
        "Profit": "Lucro",
        "day_change": "Cambio diario",
        "week_change": "Cambio semanal",
        "month_change": "Cambio mensual",
        "Booking_requests": "Peticiones de reservas",
        "Complete_bookings": "Reservas completadas",
        "New_customers": "Nuevo cliente",
        "Booking_dashboard": "Panel de reservas",
        "others": "Otros",
        "Revenue_booking": "Beneficio & reservas finalizadas",
        "Profit_finish_booking": "Beneficio & reservas finalizadas",
        "Booking_request": "Petición de reserva",
        "Finished_booking": "Reserva finalizada",
        "failed": "Failed",
        "partialCompleted": "Partial Completed",
        "Finished_booking_char_header": "Reserva finalizada",
        "Bookings": "Reservas finalizadas",
        "Ratings": "Evaluaciones",
        "Daily": "Diario",
        "Weekly": "Semanal",
        "Monthly": "Mensual",
        "Total_customer": "Total cliente",
        "ProfitToolTips": "Importe obtenido por la flota excluyendo todos los gastos",
        "RevenueToolTips": "Importe obtenido por la flota en el periodo indicado, incluyendo promociones, descuento conductores, impuestos, etc.",
        "ProfitChangeToolTips": "Porcentaje de variación en beneficio de la flota comparado con periodo anterior",
        "BookingRequestsToolTips": "Número de peticiones a tiempo real y reservas creadas en el periodo indicado",
        "PercentageOfAcceptanceToolTips": "Porcentaje de reservas aceptadas durante el periodo indicado",
        "BookingChangeHint": "Porcentaje de variación en reservas creadas comparado con periodo anterior",
        "CompletedBookingHint": "Número de peticiones a tiempo real y reservas que han sido finalizadas en el periodo indicado",
        "PercentageOfSatisfactionHint": "Porcentaje de satisfacción durante el periodo indicado",
        "CompleteBookingDailyChangeHint": "Porcentaje de variación en reservas finalizadas comparado con periodo anterior",
        "NewCustomersHint": "Número de nuevos clientes registrados durante el periodo indicado",
        "CustomerChangeHint": "Número y porcentaje de variación de clientes durante el periodo indicado",
        "TotalCustomer": "Número de clientes en el sistema",
        "ProfitAndFinishedBookingHint": "Beneficio & reservas finalizadas durante el periodo indicado",
        "BookingRequestHint": "Número de peticiones a tiempo real y reservas creadas en el periodo indicado",
        "FinishedBookingChartsHint": "Número de peticiones a tiempo real y reservas que han sido cerradas en el periodo indicado, incluyendo canceladas, No Show, Incidencias y finalizadas",
        "Customer_count": "Recuento clientes",
        "Revenue": "Ventas"
    },
    "referral": {
        "first_package": "Primer Plan (p. ej. la primera reserva finalizada del pasajero)",
        "driver_refers_to_pax": "Conductor recomienda a pasajeros",
        "driver_will_get": "Conductor lo obtendrá",
        "money": "Dinero",
        "amount": "Monto",
        "percent": "Percent",
        "settlement": "Comisión (% subtotal de cada reserva completada)",
        "pax_will_get": "Pasajero obtendrá",
        "promotion_code": "Código promocional",
        "select_a_promotion": "Seleccionar código promocional",
        "maximum": "Máximo",
        "second_package": "Segundo Plan",
        "from": "Desde",
        "to": "a",
        "After_2nd_package": "Después segundo Plan",
        "from_end_value_of_above_package": "Del valor del último paquete anterior + 1",
        "Expiration_date": "Fecha caducidad",
        "Never": "Nunca",
        "Customized_date": "Fecha personalizada",
        "limited": "Limitado",
        "unlimited": "Ilimitado",
        "Valid_from": "Valid From",
        "Valid_to": "Válido hasta",
        "Invitation_limit": "Límite de árbitro/conductor",
        "Status": "Estatus",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "note": "Una vez creado, cualquier actualización creará nuevos valores de referencia con el mismo código de referencia. El crédito del cliente existente se acumula durante la activación del programa",
        "Required_fields": "Campos requeridos",
        "refferal_update_successfully": "Configuración de referencia actualizada correctamente",
        "distributeIncentiveToDriver": "Distribute incentive to driver",
        "Immediate": "Immediate",
        "Periodic": "Periodic (Manually)",
        "Period": "Period",
        "startDate": "Start date",
        "nextDistributionDate": "Next distribution date",
        "oneMonth": "1 month",
        "threeMonth": "3 months",
        "sixMonth": "6 months",
        "passenger_refers_to_pax": "Passenger refers to Passengers",
        "referer_will_get": "Referer will get",
        "referee_will_get": "Referee will get",
        "promo": "Promotion",
        "Pax_invitation_limit": "Invitation limit/user",
        "promoPaidBy": "Promotion paid by",
        "fleet": "Fleet",
        "driver": "Driver",
        "promoCode": "Promotion code",
        "isSendInbox": "Send inbox",
        "isDefaultCode": "Auto apply on app",
        "autoApplyOnWeb": "Auto apply on web",
        "isDefaultCode_hint": "Add promotion code to customer's list and auto apply to booking when customer creates order",
        "Actions": "Actions",
        "add_promoCode": "Add",
        "Send_an_in_box_automatically_to_new_customers": "Send inbox to customer",
        "set_default_promo_code": "Auto apply on app",
        "EDIT_PROMO_CODE": "Edit promotion code",
        "DETAIL_PROMO_CODE": "Promotion code details",
        "CREATE_PROMO_CODE": "Create promotion code",
        "DELETE_PROMO_CODE": "Delete promotion code",
        "DELETE_CONFIRM_PROMO_CODE": "Do you want to delete this promotion code?",
        "ERROR_INPUT_SOURCE": "Please choose at least one."
    },
    "upload_document": {
        "NO_FILE_CHOSEN": "Archivo no seleccionado",
        "Limit_size_upload": "El tamaño del archivo supera el límite permitido {0}Mb."
    },
    "payment_info": {
        "member_since": "Miembro desde",
        "package_name": "Nombre del Plan",
        "last_payment": "Último pago",
        "credit_card": "Tarjeta de crédito",
        "last_digits": "Últimos 4 dígitos",
        "default": "Por defecto",
        "add_new_card": "Agregar nueva tarjeta",
        "history": "Historial",
        "transaction_date": "Fecha de transacción",
        "transaction_id": "ID de transacción",
        "billing_cycle": "Ciclo de facturación",
        "amount": "Importe",
        "payment_history": "Historial de facturación",
        "card_information": "Información de tarjeta",
        "card_holder": "Titular de la tarjeta",
        "card_number": "Número de tarjeta",
        "expiration_date": "Fecha caducidad",
        "cvv": "CVV"
    },
    "search_by": {
        "group_id": "Group ID",
        "booking_id": "Reserva #",
        "recurring_id": "Recurring #",
        "ExternalID": "External #",
        "tripId": "Trip #",
        "driver_name": "Nombre del conductor",
        "driver_phone_number": "Número de teléfono del conductor",
        "license_plate": "Placa",
        "vehicle": "Vehículo",
        "operator": "Operador",
        "passenger_email": "E-mail del pasajero",
        "passenger_name": "Nombre del pasajero",
        "passenger_phone_number": "Número de teléfono del pasajero",
        "destination": "Destino",
        "pick_up": "Levantar",
        "flight_number": "Vuelo",
        "book_from": "reserva desde",
        "note": "Notas",
        "email": "Correo electrónico",
        "phone": "Teléfono"
    },
    "withdrawal": {
        "transferToEWallet": "Transferred to external e-wallet *{0}",
        "channelCode": "Channel code",
        "channelName": "Channel name",
        "reversed": "Reversed",
        "requestTime": "Tiempo requerido",
        "referenceId": "Referencia #",
        "driverName": "Nombre del conductor",
        "phoneNumber": "Número de teléfono",
        "company": "Compañía",
        "description": "Descripción",
        "requestAmount": "Cantidad de la solicitud",
        "transferAmount": "Monto de la transferencia",
        "currentBalance": "Saldo actual",
        "currency": "Divisa",
        "action": "Acción",
        "approve": "Aprobar",
        "reject": "Rechazar",
        "transferToBankAccount": "Transferido a la cuenta bancaria *{0}",
        "transferToWingBank": "Transferred to Wing bank ID *{0}",
        "transferToCreditWallet": "Transferido a la billetera de crédito",
        "reason": "Razón",
        "search": "Nombre del conductor, teléfono, compañía, ID de transacción, número de referencia",
        "message": {
            "approve": "¿Está seguro de que desea pagarle a este controlador una cantidad de {0}?",
            "approveMulti": "¿Está seguro de que desea pagar a los conductores seleccionados?",
            "approveSuccess": "La solicitud seleccionada ha sido pagada",
            "approveFail": "La solicitud seleccionada no tuvo éxito.  Inténtalo de nuevo.",
            "approveMultiSuccess": "Se han pagado {0} de {1} solicitudes seleccionadas",
            "approveMultiFail": "{0} de {1} solicitud (s) seleccionada (s) no tuvo éxito. Inténtalo de nuevo",
            "buttonConfirmTitle": "Yes",
            "closeButtonText": "No",
            "pleaseInputReason": "Por favor ingrese el motivo",
            "rejectSuccess": "La solicitud del conductor ha sido rechazada",
            "rejectFail": "Error al rechazar la solicitud seleccionada."
        },
        "getDataFailure": "no puedo obtener datos",
        "rejectRequest": "Rechazar solicitud"
    },
    "dynamicSurcharge": {
        "operationZone": "Zona de operación",
        "activeSurcharge": "Recargo activo",
        "surcharge": "Recargo",
        "surchargeName": "Nombre del recargo",
        "parameter": "Parámetro",
        "pickupPoint": "Pickup zone",
        "dropOffPoint": "Drop-off zone",
        "delete_surcharge_success": "El recargo se ha eliminado correctamente",
        "delete_surcharge_fail": "Los errores ocurrieron al eliminar el recargo",
        "update_surcharge_fail": "Los errores ocurrieron al actualizar el recargo",
        "update_surcharge_success": "El recargo se ha actualizado correctamente",
        "create_surcharge_fail": "Los errores ocurrieron al crear el recargo",
        "create_surcharge_success": "El recargo se ha creado correctamente",
        "active_surcharge_fail": "Los errores ocurrieron al activar el recargo",
        "active_surcharge_success": "El recargo se ha activado correctamente",
        "deactive_surcharge_fail": "Los errores ocurrieron al desactivar el recargo",
        "deactive_surcharge_success": "El recargo se ha desactivado correctamente",
        "delete_surcharge": "Eliminar recargo",
        "delete_confirm_message": "¿Está seguro de que desea eliminar el recargo seleccionado?",
        "note": "*Nota: esto es por lo que se multiplicará su tarifa básica.  Ejemplo: una tarifa de $20 sería $30 cuando el parámetro es 1.5x"
    },
    "dynamicFare": {
        "operationZone": "Zona de operación",
        "activeFare": "tarifa activa",
        "fare": "Tarifa",
        "fareName": "Nombre de la tarifa",
        "factor": "Multiply by factor",
        "fix_amount": "Fixed amount",
        "parameter": "Parámetro",
        "delete_fare_success": "La tarifa se ha eliminado correctamente",
        "delete_fare_fail": "Los errores ocurrieron al eliminar la tarifa",
        "update_fare_fail": "Los errores ocurrieron al actualizar la tarifa",
        "update_fare_success": "La tarifa ha sido actualizada con éxito",
        "create_fare_fail": "Los errores ocurrieron al crear la tarifa.",
        "create_fare_success": "La tarifa se ha creado correctamente",
        "active_fare_fail": "Los errores ocurrieron al activar la tarifa",
        "active_fare_success": "La tarifa se ha activado con éxito",
        "deactive_fare_fail": "Los errores ocurrieron al desactivar la tarifa",
        "deactive_fare_success": "La tarifa se ha desactivado con éxito",
        "delete_fare": "Eliminar tarifa",
        "delete_confirm_message": "¿Está seguro de que desea eliminar la tarifa seleccionada?",
        "note": "*Nota: esto es por lo que se multiplicará su tarifa básica.  Ejemplo: una tarifa de $20 sería $30 cuando el parámetro es 1.5x",
        "note_amount": "*Note: this is how much your basic fare will be added. Example: a fare of $20 would be $25 when set amount is $5"
    },
    "notifications": {
        "search": "Buscar ID reserva, external ID",
        "searchProviderName": "Search provider name",
        "statusItem": {
            "all": "Todas las notificaciones",
            "canceledByDriver": "Cancelado por el conductor",
            "canceledByPartner": "Canceled by partner",
            "reservationReminder": "Recordatorio de reserva",
            "localPending": "Local - Pendiente",
            "reservationIsNotStarted": "Reserva no ha comenzado",
            "partnerRequestsCars": "Vehículo solicitado por el partner",
            "timeout": "Se acabó el tiempo",
            "noShow": "Afiliado - No show",
            "affiliatePending": "Afiliación - Pendiente",
            "affiliatePaymentFailed": "Afiliación - Pago rechazado",
            "affiliateTimeout": "Afiliación - Fuera de tiempo",
            "flightApi": "API de vuelo",
            "DebtWriteOff": "Deuda cancelada",
            "driverCancelTrip": "Driver cancels trip",
            "minimumSeatRequired": "Minimum seats required",
            "newFarmInJobFrom3rd": "Booking.com - New booking",
            "canceledJobBy3rd": "Booking.com - Canceled",
            "updatedJobBy3rd": "Booking.com - Updated",
            "newFarmInJobFromHolidayTaxis": "HolidayTaxis - New booking",
            "canceledJobByHolidayTaxis": "HolidayTaxis - Canceled",
            "updatedJobByHolidayTaxis": "HolidayTaxis - Updated",
            "newFarmInJobFromKarhoo": "Karhoo - New booking",
            "canceledJobByKarhoo": "Karhoo - Canceled",
            "updatedJobByKarhoo": "Karhoo - Updated"
        },
        "sos": {
            "sosSearch": "Buscar ID de reserva, nombre del pasajero, nombre del conductor",
            "statusItem": {
                "all": "Todo",
                "driver": "Conductores",
                "passenger": "Pasajeros",
                "crew": "Crew"
            }
        }
    },
    "smartData": {
        "No": "No.",
        "driverName": "Nombre",
        "phone": "Teléfono",
        "rating": "Clasificación",
        "completed": "Terminado",
        "noShow": "no presentarse",
        "incident": "Incidente",
        "canceled": "Cancelado por el conductor",
        "accepted": "Aceptado",
        "reject": "Rechazar",
        "ignore": "Ignorar",
        "earning": "Ganador",
        "query": {
            "top20": "20 mejores",
            "top50": "50 mejores",
            "top100": "100 mejores",
            "bottom20": "20 inferiores",
            "bottom50": "50 inferiores",
            "bottom100": "100 inferiores"
        },
        "status": {
            "canceled": "Cancelado por el conductor",
            "noShow": "no presentarse",
            "incident": "Incidente",
            "completed": "Terminado",
            "earning": "Ganador",
            "reject": "Rechazar",
            "ignore": "Ignorar",
            "accepted": "Aceptado"
        }
    },
    "Reject": "No presentarse",
    "Do not allow Driver to start reservat": "Afiliados",
    "intercityRate": {
        "Delete_intercity_rate": "Delete intercity rate",
        "edit_intercity_rate": "Edit intercity rate",
        "view_intercity_rate": "View intercity rate",
        "add_intercity_rate": "Add intercity rate",
        "Delete_confirm_intercity_rate": "Do you want to delete this item?",
        "Update_intercity_rate_success": "Intercity rate has been updated successfully",
        "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
        "Created_intercity_rate_success": "Intercity rate has been created successfully",
        "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
        "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
        "Update_intercity_rate_route_fail": "The errors occurred while updating intercity route",
        "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
        "Created_intercity_rate_route_fail": "The errors occurred while creating intercity route",
        "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
        "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
        "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
        "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate"
    },
    "intercityRoute": {
        "alias": "Alias",
        "routeNameHint": "To change route name, please go to Settings > Zone > Intercity and change name of city zones accordingly.",
        "pricePerSeat": "Price per seat (tax excluded)",
        "distance": "Distance",
        "duration": "Duration",
        "cancellationPenalty": "Penalty amount per seat",
        "noShow": "Pax/partner no show",
        "penalty": "Penalty amount per seat",
        "no_show_tooltip": "The amount customer will be charged per seat. In addition to the penalty customer will pay tax or tech fee if applied",
        "firstLocation": "First location",
        "secondLocation": "Second location",
        "routeOneSetup": "Setup route 1",
        "routetwoSetup": "Setup route 2",
        "schedules": "Schedules",
        "date": "Date",
        "time": "Time",
        "editSchedule": "Edit schedule",
        "addSchedule": "Add schedule",
        "requestTimeEnable": "Allow customer to set request time",
        "extraFee": "Extra fee",
        "hideRouteEnable": "Hide this route",
        "timeRange": "Time range",
        "workingTime": "Working time",
        "applyForOtherDays": "Apply for other days",
        "limitSeatLuggage": "Limit seat / luggage",
        "warningMinimumSeats": "Minimum seats required",
        "warningMinimumSeatsHint": "Minimum number of seats required to make a trip happen",
        "allowStartLessMinRequiredSeats": "Allow driver to start trip regardless of the minimum seat requirement",
        "allowStartLessMinRequiredSeatsHint": "Driver can start a trip even if requested seats less than minimum seat required",
        "freeLuggagePerSeat": "Free luggage per seat",
        "freeLuggagePerSeatHint": "Number free of luggage included per seat",
        "allowToAddExtraLuggage": "Allow to add additional luggage",
        "allowToAddExtraLuggageHint": "Passenger can request to add more luggage",
        "feePerExtraLuggage": "Fee per extra luggage",
        "Delete_intercity_route": "Delete intercity route",
        "Delete_confirm_intercity_route": "Do you want to delete this route?",
        "Update_intercity_route_fail": "Intercity route has been updated successfully",
        "Update_intercity_route_success": "Intercity route has been updated successfully",
        "created_intercity_route_success": "Intercity route has been created successfully",
        "created_intercity_route_fail": "The errors occurred while creating intercity route",
        "Delete_intercity_route_success": "Intercity route has been deleted successfully",
        "Delete_intercity_route_fail": "The errors occurred while deleting intercity route",
        "penalty_less_than_or_equal_to_price": "Please enter a value less than or equal to price per seat value.",
        "scheduleTimeOverlap": "Schedule time must not overlap with each others",
        "allows_adding_extra": "Allows adding extra charge when customer request time which isn't scheduled by fleet.",
        "this_is_to_limit_users": "This is to limit users (i.e. customer, driver, operator) from creating new booking or trip on this route. Existing bookings or trips are remained as reserved"
    },
    "dayOfWeek": {
        "0": "Sunday",
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday"
    },
    "intercityBooking": {
        "route": "Ruta",
        "departureDate": "Fecha de salida",
        "departureTime": "Departure time",
        "Departure": "Departure",
        "Arrival": "Arrival",
        "RefundFare": "Refund fare",
        "intercityZoneNote": "These zones are not operational zone. And they are used for setting intercity rate only.",
        "zoneNameHint": "Please keep your zone name short and clear so that your customers can search and select easily.",
        "zoneUploadNote_2": "*  It is recommended that image resolution should be 1095x360 and file size less than 5MB.",
        "Complete_booking": "Complete Booking",
        "ConfirmCancel": "Confirm cancel"
    },
    "deliveryRate": {
        "Delete_delivery_rate": "Delete delivery rate",
        "edit_delivery_rate": "Edit delivery rate",
        "view_delivery_rate": "View delivery rate",
        "add_delivery_rate": "Add delivery rate",
        "Delete_confirm_delivery_rate": "Do you want to delete this item?",
        "Update_delivery_rate_success": "Delivery rate has been updated successfully",
        "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
        "Created_delivery_rate_success": "Delivery rate has been created successfully",
        "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
        "Update_delivery_rate_route_success": "Delivery rate route has been updated successfully",
        "Update_delivery_rate_route_fail": "The errors occurred while updating delivery route",
        "Created_delivery_rate_route_success": "Delivery rate route has been created successfully",
        "Created_delivery_rate_route_fail": "The errors occurred while creating delivery route",
        "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
        "Delete_delivery_rate_route_fail": "The errors occurred while deleting delivery rate route",
        "Delete_delivery_rate_success": "Delivery rate has been deleted successfully",
        "Delete_delivery_rate_fail": "The errors occurred while deleting delivery rate",
        "distance_km": "Fee per km",
        "distance_mile": "Fee per mil",
        "flat": "Flat Fee",
        "startingFee": "Starting fee",
        "feeFirstDistance": "Fee first distance",
        "feeSecondDistance": "Fee second distance",
        "feeThirdDistance": "Fee third distance",
        "feeAfterThirdDistance": "Fee after third distance",
        "feePerMinute": "Fare per minute",
        "feeMinimum": "Fee minimum",
        "feeFirstStop": "Fee for first stop",
        "feeExtraStop": "Fee for each extra stop"
    },
    "pwaSettings": {
        "pwa": "PWA",
        "iconName": "Icon & name",
        "logoBackgroundColor": "Logo, background & color",
        "longName": "Long name",
        "shortName": "Short name",
        "icon192": "Icon on Home Screen (192x192)",
        "icon512": "Icon on Splash Screen",
        "logo": "Logo (300x300)",
        "logoMenu": "Logo menu (300x300)",
        "backgroundImage": "Background image - Transport (1024x500)",
        "backgroundImageDelivery": "Background image - Delivery (1024x500)",
        "mainColor": "Main color",
        "secondaryColor": "Secondary color",
        "showMainColor": "Show main color instead of background image",
        "success": "PWA setting has been updated successfully",
        "failed": "Failed to update PWA setting. Please try again!",
        "errorUploadFileSize": "The required dimensions are {0}x{0} px.",
        "hideWBInstallAppBtn": "Hide install app button",
        "enableBookNow": "Now",
        "enableGuestCheckout": "Guest checkout",
        "enableBookLater": "Later",
        "enableHideRecipient": "Hide recipient info (Package content, Delivery instructions)",
        "pwaLink": "PWA for Passenger",
        "partnerLink": "PWA for Partner",
        "pwaDriverLink": "PWA for Driver",
        "providerSignUp": "Driver sign-up link",
        "AdsTracking": "Ads Tracking",
        "googleId": "Google Tag ID",
        "placeholdergoogleId": "Enter Google Tag ID",
        "metaId": "Meta Pixel ID",
        "placeholdermetaId": "Enter Meta Pixel ID"
    },
    "emailConfig": {
        "emailServer": "Email server",
        "default": "Default",
        "customized": "Customized",
        "fromName": "From name",
        "email": "From email",
        "replyTo": "Reply to",
        "userAuth": "Username",
        "password": "Password",
        "smtpServer": "SMTP server",
        "port": "SMTP port",
        "isAuthenticated": "Authenticated",
        "option": "Enable Option",
        "none": "None",
        "tls": "TLS",
        "ssl": "SSL",
        "save": "Save",
        "cancel": "Cancel",
        "success": "Update email server success",
        "fail": "Update email server fail",
        "loadFail": "Load email server detail fail"
    },
    "driverFields": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_FIELDS": "Edit Driver Fields",
        "DETAIL_DRIVER_FIELDS": "Detail Driver Fields",
        "ADD_DRIVER_FIELDS": "Add Driver Fields",
        "fieldKey": "Fields key",
        "fieldName": "Fields name",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "availableCompanyCar": "Company Car - Transport drivers",
        "deliveryCompany": "Company Car - Delivery drivers",
        "availableIndividualCar": "Individual Car - Transport drivers",
        "deliveryIndividual": "Individual Car - Delivery drivers",
        "required": "Required",
        "Update_fail": "The errors occurred while updating driver fields",
        "Update_success": "The Driver Fields has been updated successfully",
        "FieldKey": "Fields Key",
        "FieldName": "Fields Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions",
        "fieldType": "Fields Type",
        "default": "Default",
        "customized": "Customized"
    },
    "driverDocument": {
        "Load_fail": "",
        "Edit": "Edit",
        "View": "View",
        "Cancel": "Cancel",
        "show": "Show",
        "hide": "Hide",
        "EDIT_DRIVER_DOCUMENT": "Edit Driver Document",
        "DETAIL_DRIVER_DOCUMENT": "Detail Driver Document",
        "ADD_DRIVER_DOCUMENT": "Add Driver Document",
        "documentKey": "Document key",
        "documentName": "Document name",
        "documentNameDetail": {
            "zh-HK": "Traditional Chinese",
            "it-IT": "Standard Italian",
            "es-ES": "Spanish",
            "en-US": "American English",
            "fr-FR": "Standard French",
            "zh-CN": "Simplified Chinese",
            "id-ID": "Bahasa Indonesia",
            "vi-VN": "Vietnamese",
            "ur-PK": "Urdu (Islamic Republic of Pakistan)",
            "ms-MY": "Malay (Malaysia)",
            "ar-AR": "Arabic",
            "en-GB": "English (United Kingdom)",
            "nb-NO": "Norwegian",
            "am-ET": "Amharic",
            "ro-RO": "Romanian",
            "th-TH": "Thai",
            "ta-IN": "Tamil",
            "hi-IN": "Hindi",
            "te-IN": "Telugu",
            "ka-GE": "Georgian",
            "ru-RU": "Russian",
            "kn-IN": "Kannada"
        },
        "isIndividual": "Company car - Transport drivers",
        "deliveryCompany": "Company car - Delivery drivers",
        "isOwner": "Individual driver - Transport drivers",
        "deliveryIndividual": "Individual driver - Delivery drivers",
        "availableCC": "Available in Command Center",
        "availableDrvSignUp": "Available in Driver sign up",
        "isRequired": "Required",
        "isExpiredDate": "Expiry date - Command Center",
        "expiredDateForDrvSignUp": "Expiry date - Driver signup",
        "requireExpiredDate": "Require Expiry Date",
        "requireExpiredDateForDrvSignUp": "Require Expiry Date",
        "requireDocumentId": "Require Document ID",
        "documentId": "Document ID - Command Center",
        "documentIdForDrvSignUp": "Document ID - Driver signup",
        "Update_fail": "The errors occurred while updating driver document",
        "Update_success": "The Driver Document has been updated successfully",
        "DocumentKey": "Document Key",
        "DocumentName": "Document Name (American English)",
        "CommandCenter": "Available In Command Center",
        "SignUp": "Available In Driver Sign Up",
        "CompanyTransport": "Company Car - Transport",
        "CompanyDelivery": "Company Car - Delivery",
        "IndividualTransport": "Individual Car - Transport",
        "IndividualDelivery": "Individual Car - Delivery",
        "Require": "Required",
        "Actions": "Actions"
    },
    "holidayTaxis": {
        "title": "HolidayTaxis API Credentials",
        "note": "Communicate with HolidayTaxis's technical team to provide you with api key to connect to their system.",
        "apiKey": "API key",
        "placeHolderApiKey": "Enter api key"
    },
    "trailNotes": {
        "maxLengthFiles": "Maximum 3 files per comment",
        "maxSizeFiles": "Maximum 10MB per file",
        "typeFiles": "File type supported: pdf, doc, docx, csv, xls, xlsx, jpeg, jpg, png, webp, txt, note",
        "maxComments": "Maximum 20 comments per booking",
        "bookingActivity": "Booking Activity",
        "viewAll": "View all",
        "operator": "Operator",
        "driver": "Driver",
        "passenger": "Customer",
        "pHInput": "Add a new comment. You can click save to save the note. You can also drag an drop multiple files here.",
        "addFiles": "Add files",
        "save": "Save",
        "inputNotes": "Please input text",
        "successDeleteTrailNotes": "Delete trail notes successfully",
        "successCreateTrailNotes": "Create trail notes successfully",
        "deleteTrailNote": "Delete a trail note",
        "msgDeleteTrailNote": "Are you sure you want to delete this?"
    },
    "supplier": {
        "permissionRequired": "Operator need to select a permission",
        "offerDetail": "Offer Detail",
        "orderDetail": "Order Detail",
        "bookingNumber": "Booking Number",
        "date": "Date",
        "pickupLocation": "Pick up Location",
        "dropoffLocation": "Drop off Location",
        "passenger": "Passenger",
        "luggage": "Luggage",
        "pickupService": "Pickup Service",
        "pickupSignage": "Pickup Signage",
        "flightNumber": "Flight Number",
        "departure": "Departure",
        "arrival": "Arrival",
        "terminal": "Terminal",
        "gate": "Gate",
        "meetGreet": "Meet & Greet",
        "specialRequests": "Special Requests",
        "additionalServices": "Additional Services",
        "price": "Price",
        "prices": "Prices",
        "guest": "Guest",
        "serviceClass": "Service Class",
        "distance": "Distance",
        "duration": "Duration",
        "acceptOffer": "Accept Offer",
        "updateBooking": "Update Booking",
        "package": "Package",
        "route": "Route",
        "assignedChauffer": "Assigned Chauffeur",
        "assignedVehicle": "Assigned Vehicle",
        "logs": "Logs",
        "link": "Links",
        "desLink": "Click to copy link",
        "trackLink": "Track link",
        "driverLink": "Driver link",
        "close": "Close",
        "links": "Links",
        "linksDes": "Click to copy link",
        "status": "Status",
        "rating": "Rating",
        "permissions": "Permissions",
        "permissionsNote": "Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.",
        "owner": "Owner",
        "ownerNote": "Full Access",
        "manageUsers": "Manage Users",
        "manageUsersNote": "Add/remove new operators",
        "manageDrivers": "Manage Drivers",
        "manageDriversNote": "Add/remove drivers",
        "manageVehicles": "Manage Vehicles",
        "manageVehiclesNote": "Add/remove vehicles",
        "mapView": "Live Map",
        "mapViewNote": "Track drivers and active trips live on the map.",
        "calendarView": "Calendar View",
        "calendarViewNote": "View calendar of all assigned trips.",
        "bookingView": "Booking View",
        "bookingViewNote": "View and manage current bookings, assign drivers, and allocate vehicles.",
        "offersView": "Offers View",
        "offersViewNote": "Accept new job offers.",
        "pricing": "Pricing",
        "pricingNote": "View trip prices",
        "userDetail": "User Detail",
        "addOperator": "Add Operator",
        "updateOperator": "Update Operator",
        "supplier": "Supplier",
        "zone": "Zone",
        "emails": "Emails",
        "email": "Email",
        "phoneNumber": "Phone Number",
        "Deactivate": "Deactivate",
        "Activate": "Activate",
        "ALERT_SELECT_DEACTIVATE": "Please select supplier to deactivate",
        "ALERT_SELECT_ACTIVATE": "Please select supplier to activate",
        "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these suppliers?",
        "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these suppliers?",
        "Update_supplier_user_status_success": "Supplier status has been updated successfully",
        "Update_supplier_user_status_fail": "The errors occurred while updating supplier status",
        "agent": "Agent",
        "Supplier": "Supplier",
        "addSupplier": "Add Supplier",
        "detailSupplier": "Supplier Detail",
        "addUser": "Add User",
        "Add": "Add",
        "ContactInfo": "Contact Info",
        "policy": "Policy",
        "users": "Users",
        "setting": "Setting",
        "zones": "Zones",
        "actions": "Actions",
        "companyName": "Company Name",
        "corporationType": "Corporation Type",
        "country": "Country",
        "ADMIN_INFORMATION": "Admin Information",
        "firstName": "First Name",
        "lastName": "Last Name",
        "commissionValue": "Fleet commission",
        "commissionValueDes": "Set commissions the fleet earns for every complete order.",
        "paymentMethodsApplyDes": "Job specific payment methods that suppliers can process:",
        "bookingSettings": "Booking Settings",
        "bookingSettingsDes": "Configure job visibility, release times, and delays for efficient supplier operations.",
        "advanceBookingVisibilityTooltip": "Specify how many days in advance suppliers can see the orders.",
        "delayBroadcastToolTip": "Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.",
        "releaseTimeToolTip": "Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.",
        "otherSettings": "Other Settings",
        "hideFare": "Driver - Always hide fare",
        "hideFareDes": "Hide the fare for all drivers of this supplier.",
        "supplierJobRequired": "Supplier Job Acceptance Required",
        "supplierJobRequiredDes": "Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.",
        "supplierApproval": "Suppliers Driver/Vehicle Approval",
        "supplierApprovalDes": "Allow supplier operators to activate drivers and vehicles within their fleet.",
        "emailsApply": "Email Notifications",
        "emailsApplyDes": "Emails to send new jobs, booking updates.",
        "address": "Address",
        "zipCode": "Zip/Postal Code",
        "city": "City",
        "state": "State/Province",
        "tax": "Tax Identification Number",
        "businessRegistration": "Business Registration Number",
        "vatNumber": "VAT ID/Number",
        "mobile": "Mobile",
        "editOperator": "Edit Operator",
        "searchEmail": "Search Email",
        "acceptOfferSuccess": "Accept offer successfully",
        "acceptOfferFail": "Booking not found or another supplier has accepted the booking",
        "driverPayout": "Driver payout",
        "payout": "Payout",
        "totalPayout": "Total payout",
        "totalPayoutDes": "The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.",
        "supplierPayout": "Supplier payout",
        "driverTip": "Driver tip",
        "cancel": "Cancel",
        "save": "Save",
        "activeSupplier": "Active supplier",
        "userName": "Username",
        "bookingCompletedView": "Completed Bookings",
        "bookingCompletedViewNote": "View the history of all completed bookings.",
        "driverEarning": "Driver earning"
    },
    "noResultFound": "No results found"
};
