import _ from 'lodash';
export const en = {
  name: 'English',
  flagCode: 'us',
  locale: 'en-en',
  project: {
    name: 'QUp DMC ',
  },
  Sidebar: {
    Setting: 'Settings',
    Map: 'Map',
    Cue: 'Bookings',
    ImportBookings: 'Import Bookings',
    New_booking: 'New Booking',
    Customer: 'Travelers',
    Driver: 'Chauffeurs',
    REPORTS: 'Reports',
    Corporate: 'Clients',
    mDispatcher: 'mDispatcher',
    Reservation: 'Calendar',
    Driver_Settlement: 'Driver Settlement',
    Reports: {
      Booking_details: 'Booking details',
      Financial: 'Financial',
      Financial_Driver: 'Driver',
      Financial_company: 'Supplier',
      Driver_activity: "Driver's activity",
      Revenue_summary: 'Revenue summary',
      Revenue_fleet: 'Fleet',
      Revenue_driver: 'Driver',
      Revenue_monthly: 'Monthly',
      Total_adjustment: 'Total adjustment',
      Promotion: 'Promotion',
      Incident_Cancellation: 'Incident & Cancellation',
      Deletion_Request: 'Incident & Cancellation',
      Driver_Rating: 'Customer rates driver',
      Company_settlement: 'Company settlement',
      Passenger_Rating: 'Driver rates customer',
      Booking_logs: 'Booking logs',
      Rating: 'Rating',
      Operator_logs: 'Operator logs',
      Daily: 'Daily',
      Daily_Driver: 'Daily Driver',
      Daily_Car: 'Daily Vehicle',
      Daily_Driver_Car: 'Daily Driver Vehicle',
      mDispatcher: 'mDispatcher',
      Corporate: 'Client',
      Driver_login_status: "Driver's login status",
      Car_activity: "Vehicle's activity",
      Partner: 'Partner',
      Prepaid_top_up: 'Edit_Balance',
      Affiliation: 'Affiliation',
      Booking_summary: 'Booking summary',
      Cash_Wallet: 'Cash wallet',
      Credit_Wallet: 'Credit wallet',
      Driver_wallet: 'Driver wallet',
      Driver_Deposit: 'Driver deposit',
      Document_expiry: 'Document expiry',
      Settlement: 'Settlement',
      Unapproved_Driver: 'Unapproved driver',
      Approved_Driver: 'Approved driver',
      Pay_to_driver: 'Pay to Supplier/Driver',
      Settlement_history: 'Settlement history',
    },
    Settings: {
      Fleet_info: 'Organization info',
      Permission: 'Permission',
      User: 'Staff',
      General: 'General',
      Regular: 'Regular',
      Flat: 'Flat',
      Hourly: 'Hourly - Daily',
      Company: 'Supplier',
      Dispatch: 'Dispatch',
      Voice_SMS: 'Voice & SMS',
      Rate: 'Rate',
      Car: 'Vehicle',
      Car_type: 'Vehicle type',
      Car_mgmt: 'Vehicle mgmt',
      Car_Make: 'Make & Model',
      Partner_type: 'mDispatcher type',
      Shift_template: 'Shift template',
      Shift_settings: 'Shift settings',
      Operation: 'Operation',
      Promotion_code: 'Promotion code',
      Campaign: 'Campaign',
      Queuing_area: 'Queuing area',
      Referral_code: 'Referral code',
      City: 'City',
      Queuing_area: 'Queuing area',
      '3rd_party_location': '3rd party location',
      Zone: 'Zone',
      App_banner: 'App banner',
    },
  },
  messages: {
    credits: {
      '-1': 'This traveler account has been deactivated. Please contact our administrator.',
      421: 'Card number is not valid.',
      422: 'Expired date is not valid.',
      429: 'Zip code is not valid.',
      430: 'AVS Postal Code does not match',
      431: 'AVS Postal Code not verified',
      432: 'CVV is not valid.',
      433: 'CVV does not match',
      434: 'CVV is not verified',
      435: 'CVV issuer does not participate',
      436: 'Your card was declined',
      // '437': 'An error occurred while processing your card. Try again in a little bit',
      437: 'Insufficient funds. Please check your balance!',
      439: 'CVV not processed',
      407: 'Action failed',
      406: 'Action failed',
      441: 'No CVV data from issuer',
      443: 'No data from issuer/banknet switch',
      445: 'AVS System unable to process',
      446: 'Issuing bank does not support AVS',
      447: 'AVS not supported for your business',
      448: 'This card has been blocked due to repeated unsuccessful payment attempts. Please change your payment method or try again later',
      452: 'Your card is declined. Please enter another credit card that was registered at the same area as your phone number',
      453: 'Payment.gateway-not-support',
      454: 'AVS Address does not match',
      455: 'AVS Both postal code and address do not match',
      458: 'Authentication failed',
      459: 'Bank cards do not supprt',
      461: 'This card is not suppoted',
      462: 'Card holder name invald',
      463: 'Verification code is ivalid',
      464: 'The Bank does not supprt generating and sending the verification code',
      470: "Please enter the traveler's email address before make a booking with credit card",
      474: " Please enter the traveler's name before make a booking with credit card",
      2000: 'Do Not Honor',
      2001: 'Insufficient Funds. The account did not have sufficient funds to cover the transaction amount.',
      2002: 'Limit Exceeded',
      2003: 'Cardholder&#39;s Activity Limit Exceeded',
      2004: 'Expired Card',
      2005: 'Invalid Credit Card Number',
      2006: 'Invalid Expiration Date',
      2007: 'No Account',
      2008: 'Card Account Length Error',
      2009: 'No Such Issuer',
      2010: 'Card Issuer Declined CVV',
      2011: 'Voice Authorization Required. The cardholder&#39;s bank is requesting that the merchant calls to obtain a special authorization code in order to complete this transaction',
      2012: 'Processor Declined - Possible Lost Card',
      2013: 'Processor Declined - Possible Stolen Card',
      2014: 'Processor Declined - Fraud Suspected',
      2015: 'Transaction Not Allowed',
      2016: 'Duplicate Transaction',
      2017: 'Cardholder Stopped Billing',
      2018: 'Cardholder Stopped All Billing',
      2019: 'Invalid Transaction',
      2020: 'Violation',
      2021: 'Security Violation',
      2022: 'Declined - Updated Cardholder Available',
      2023: 'Processor Does Not Support This Feature',
      2024: 'Card Type Not Enabled',
      2025: 'Set Up Error - Merchant',
      2026: 'Invalid Merchant ID',
      2027: 'Set Up Error - Amount',
      2028: 'Set Up Error - Hierarchy',
      2029: 'Set Up Error - Card',
      2030: 'Set Up Error - Terminal',
      2031: 'Encryption Error',
      2032: 'Surcharge Not Permitted',
      2033: 'Inconsistent Data',
      2034: 'No Action Taken',
      2035: 'Partial Approval For Amount In Group III Version',
      2036: 'Authorization could not be found to reverse',
      2037: 'Already Reversed',
      2038: 'Processor Declined. The traveler&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.',
      2039: 'Invalid Authorization Code',
      2040: 'Invalid Store',
      2041: 'Declined - Call For Approval',
      2042: 'Invalid Client ID',
      2043: 'Error - Do Not Retry, Call Issuer',
      2044: 'Declined - Call Issuer',
      2045: 'Invalid Merchant Number',
      2046: 'Declined. The traveler&#39;s bank is unwilling to accept the transaction, need to contact their bank for more details.',
      2047: 'Call Issuer. Pick Up Card. This error indicates that the card has been reported as lost or stolen by the cardholder.',
      2048: 'Invalid Amount',
      2049: 'Invalid SKU Number',
      2050: 'Invalid Credit Plan',
      2051: 'Credit Card Number does not match method of payment',
      2052: 'Invalid Level III Purchase',
      2053: 'Card reported as lost or stolen',
      2054: 'Reversal amount does not match authorization amount',
      2055: 'Invalid Transaction Division Number',
      2056: 'Transaction amount exceeds the transaction division limit',
      2057: 'Issuer or Cardholder has put a restriction on the card',
      2058: 'Merchant not MasterCard SecureCode enabled.',
      2060: 'Address Verification and Card Security Code Failed',
      2061: 'Invalid Transaction Data',
      2062: 'Invalid Tax Amount',
      2064: 'Invalid Currency Code',
      2078: 'Invalid Secure Payment Data',
      2080: 'Invalid user credentials',
      3000: 'Processor Network Unavailable - Try Again',
      4001: 'Settlement Declined',
      4006: 'Capture amount exceeded allowable limit',
      81813:
        'Postal code can only contain letters, numbers, spaces, and hyphens',
      81809:
        'Postal code may contain no more than 9 letter or number characters',
      91826:
        'Postal code is invalid. Postal code must be a string of 5 or 9 digits, optionally separated by a dash or a space.',
      81723: 'Cardholder name is too long. Maximum 175 characters.',
      81703: 'Credit card type is not accepted by this merchant account.',
      81718:
        'Credit card number cannot be updated to an unsupported card type when it is associated to subscriptions.',
      81717: 'Credit card number is not an accepted test number.',
      91723: 'Update Existing Token is invalid',
      91730: 'Verification not supported on this merchant account.',
      91734: 'Credit card type is not accepted by this merchant account.',
      91738: 'Payment method specified is not a credit card.',
      91745: 'Invalid params for credit card update.',
      91722: 'Payment method token is required.',
      81706: 'CVV is required',
      81707: 'CVV must be 3 or 4 digits.',
      81709: 'Expiration date is required.',
      81710: 'Expiration date is invalid.',
      81714: 'Credit card number is required.',
      81715: 'Credit card number is invalid.',
      81716: 'Credit card number must be 12-19 digits.',
    },
    reBookingMsg: {
      304: 'This booking was not found',
      303: 'The time you have selected must be later than the current time!',
      500: 'Some booking info is not valid or missing. Please check and try again!',
    },
    promoMsg: {
      phoneInvalid:
        "Please enter passenger's phone number before applying the promo code",
      304: 'The promo code is not valid',
      413: 'Sorry, this promo code is not available at the moment',
      414: 'The promo code is not valid',
      415: 'The promo code is not valid',
      416: 'This promo code has already been used.',
      419: 'Sorry, this promo code is not available at the moment',
      420: 'Sorry, you have reached your limited promo uses',
      421: 'Sorry, this promo code has reached its released usage limit ',
      422: 'This promo code cannot be used under your account ',
      423: 'Sorry, you have reached your limited promo budget ',
    },
    updateBookingMsg: {
      304: 'This booking was not found',
      303: 'Update booking fail, Please check and try again!',
      pickUpTime:
        'The time you have selected must be later than the current time!',
      pickup: 'There are no booking services available in this area',
      destination:
        'Some booking info is not valid or missing. Please check and try again!',
      dispatchType:
        'Some booking info is not valid or missing. Please check and try again!',
    },

    sessionExpired: {
      403: 'You have been logged out because your account has been logged in from another device or your session has expired',
    },
    not_found: {
      404: 'The page you requested could not be found',
    },
    booking: {
      fare_gt_zero: 'Fare must be greater than 0!',
      edit_total_fail: 'Edit total value fail!',
      complete_fail: 'The booking cannot be completed!',
      cancle_fail:
        'Something went wrong when canceling this booking. Please try again!',
      incident_fail: 'Incident fail',
      phone_invalid: 'Phone number is not valid!',
      add_psg_fail: 'Cannot add new passenger',
      end_booking_leave: 'Leaving this page will end your booking.',
      update_booking_fail: 'Update booking fail',

      area_service_unavailable:
        'There are no booking services available in this area',
      time_invalid:
        'The time you have selected must be later than the current time!',
      psg_inactivate:
        'This traveler account has been deactivated. Please contact our administrator',
      data_incorect:
        'One or more required fields are incorrect. Please check your booking info and try again!',
      preAuthorize:
        'Please check your account as an amount of {0} is required to guarantee your booking',
      booking_limited:
        'Sorry, you have reached the maximum number of bookings per minute/day. Please try again later.',
      minimumBookAhead:
        'Reservation pickup time must be at least {0} hour(s) {1} minute(s) from current time',
      maximumBookAhead:
        'Reservation pickup time cannot be later than {0} day(s) from current time',
      Please_select_or_add_a_card: 'Please select or add a card!',
      304: 'This booking was not found',
      303: 'The time you have selected must be later than the current time!',
      500: 'Some booking info is not valid or missing. Please check and try again!',
      Unable_to_process_by_fare_zero:
        'We are unable to process your request, please change vehicle type or booking type and try again. If you need any assistance, please contact our support team',
      Can_not_connect_to_server: 'Cannot connect to server!',
      Check_connection_or_contact_system_support:
        'Please check your connection or contact system support for help!',
      Create_new_booking_success:
        'A new booking has been created successfully!',
      Update_booking_success: 'This booking has been updated successfully!',
      Booking_drop_of_success: 'Booking drop off Sucessful',
      Cancel_booking_success: 'This booking has been cancelled successfully!',
      Booking_detail_not_found: 'Booking detail is not found!',
      Check_promo_error: 'Check promoCode error!',
      Invalid_format: 'Invalid format',
      Flight_not_found: 'Flight not found!',
      Add_new_credit_error: 'Add new credit error!',
      Socket_server_disconnected: 'Socket server disconnected!',
      Please_reload_page_or_check_your_connection:
        'Please reload page or check your connection!',
      Check_customer_error: 'Check traveler error!',
      Please_select_car_type: 'Please select vehicle type',
      Please_enter_destination_for_ridesharing_booking:
        'Please enter destination for ride-sharing booking',
      Extra_detination_must_be_difference_from_destination:
        'Extra detination must be difference from destination',
      Pickup_address_must_be_different_from_destination:
        'Pickup address must be different from destination',
      Best_price: 'Best price',
      You_may_be_charged_extra_fee_if_your_trip_excceds:
        'You may be charged extra fee if your trip exceeds',
      You_may_be_charged_extra_fee_if_your_trip_excceds_with_params:
        'You may be charged extra fee if your trip exceeds {0} {1}, {2} {3}',
    },
    item_no_found: 'No item was found',
    commonMessages: {
      greater_than_0: 'Please enter a value greater than 0.',
      Required_field: 'This field is required',
      distance_from_equa_or_smaller:
        "The distance 'from' must be smaller than or equal to the distance 'to'",
      must_be_number: 'Please enter valid number',
      equal_or_greater_than_0: 'Please enter a value equal or greater than 0.',
      must_be_integer: 'Please enter integer number',
      invalid_email_format: ' Please enter valid email.',
      greater_or_equa: 'Please enter a value greater than or equal to {0}.',
      Error_message: 'Error!',
      callcenter_newbooking_message:
        "New booking request with phone <span class='bold'>{0}</span>",
      callcenter_existed_booking_message:
        "One active booking <span class='bold'>#{0}</span> exists with phone <span class='bold'>{1}</span>",
      callcenter_close_newbooking_form_warning:
        'Please close/complete your current book before recieve new customer request',
      Emergency_SOS_Title: 'Emergency SOS',
      Integer_from_range: 'Please enter integer number from {0} - {1}',
      Please_enter_a_future_date: 'Please enter a future date',
      Card_holder_is_required: 'Card holder is required',
      Card_number_is_required: 'Card number is required',
      Expired_date_is_required: 'Expired date is required',
      Expired_date_is_invalid:
        'Expiration date is invalid. Please enter expiration date in format mm/yy',
      cvv_is_required: 'CVV is required',
      Card_holder_is_required: 'Card holder is required',
      Address_is_required: 'Address is required',
      Zipcode_is_required: 'Zip code is required',
      Country_is_required: 'Country is required',
      City_is_required: 'City is required',
      State_is_required: 'State is required',
    },
  },
  errors: {
    303: 'user not found',
    // Driver
    3001: 'Phone number existed',
    3002: 'Invalid date of birth',
    3003: 'Invalid driver license expired date',
    3004: 'Driver not found',
    3005: 'Missing driver settlement info (bank address, city, state, zipcode)',
    3006: 'Missing bank account info (nameOfAccount, typeOfAccount, routingNumber, accountNumber, ssn)',
    3007: "You can't edit this driver, who is having a job.",
    3008: 'This driver is currently logging in. Please ask him to log out to edit his information.',
    3009: 'This driver has accepted one or more reservation(s). Please assign the booking(s) to other driver(s) before deactivating the driver.',
    3010: 'Connect dispatching failed.',
    3011: 'This driver must be setup for operation before being activated.',
    3012: 'This driver must be setup for operation before being activated.',
    3013: 'Just only item in the inactive status can be deleted.',
    3014: 'Required fields are missing. Please check.',
    3015: 'Invalid birth year. Please check.',
    3016: 'Invalid zip code. Please check.',
    3017: 'Invalid state/province. Please check.',
    3018: 'Invalid name of account. Please check.',
    3019: 'Invalid routing number. Please check.',
    3020: 'Birthday is required. Please check.',
    3021: 'Invalid ssn number. Please check.',
    3022: "Driver's bank account can't receive funds. Please check (Must be at least 13 years of age to use Stripe).",
    3023: "Logged in drivers can't be operated. Please try again later.",
    3026: 'Driver no longer exists.',
    3027: "The driver's bank account is not set up. Please go to Drivers module to set up his bank account.",
    3028: 'Failed to pay the selected driver(s). Please try again.',
    3029: 'Failed to clear earning data of the driver. Please try again.',
    3032: 'This username already exists on our system. Please enter another one.',
    3033: 'Invalid BSB. The number should be 6 digits in the format xxxxxx.',
    // Vehicle
    4001: 'Your plate number has been existed on system. Please input another one.',
    4002: 'Invalid expired date',
    4003: 'Vehicle is active (must deactive before deleting)',
    4004: 'Vehicle not found',
    4005: 'Vehicle is inactive (and no need to deactive any more)',
    4006: 'Vehicle is in using (cannot delete or deactive)',
    4007: 'Plate number is required',

    // Company
    5001: 'Company existed',
    5004: 'Company not found',

    // Vehicle Model
    6001: 'Vehicle model existed',
    6004: 'Vehicle model not found',

    // Vehicle make
    7001: 'Your vehicle make name has been existed on system. Please input another one.',
    7004: 'Vehicle make not found',

    // Vehicle Type
    8001: 'Vehicle type existed',
    8004: 'Vehicle type not found',

    // Flat Route
    9001: 'Flat route existed',
    9004: 'Flat route not found',

    // Zone
    10001: 'Zone existed',
    10004: 'Zone not found',

    // FZONE
    11001: 'Zone existed',
    11002: 'Zone conflict',
    11003: 'Zone must be a polygon object',
    11004: 'Zone not found',
    11005: 'Zone is in using',

    // Inbox
    12001: 'Inbox existed',
    12002: 'Please config SMS Twilio',
    12004: 'Inbox not found',

    // Promo code
    13001:
      'This promotion code has been existing on the system. Please input another one',
    13002: 'Invalid Date',
    13003: 'Private',
    13004: 'Promotion code not found',
    13005: 'Only New Traveler',
    13006: 'Over Quantity Limit',
    13007: 'Over Budget Limit',
    13008: 'Over Total Uses Limit',
    13011: 'Promoction campaign has been deactivated or deleted',
    13012: 'Date range is invalid',

    // Booking
    14004: 'Booking not found',

    // Fleet
    15004: 'Fleet not found',
    15002: 'Your fleet has been deactivated. Please contact our administrator',

    // Fare flat
    16001: 'Fare name existed',
    16004: 'Fare not found',

    // Package Rate
    17001: 'Name is existed',
    17004: 'Package rate not found',

    // Normal Fare
    18001: 'Name is existed',
    18002: 'Fare in use',
    18004: 'Fare not found',

    // Fare Hourly
    19001: 'Name is existed',
    19002: 'Fare in use',
    19004: 'Fare not found',

    // User
    20001:
      'This username already exists on our system. Please enter another one.',
    20002: 'Your account has been inactive. Please contact our administrator',
    20003:
      'The username or password you entered is incorrect. Please try again',
    20004: 'The username is not registered in system.',
    20006: 'Current password does not match. Please try again.',
    20005: 'This link was expired',
    20007: 'User # has been existed',
    // Custmer
    22001: 'Traveler is existed',
    22004: 'Traveler not found',
    22005: 'Invalid File Format.',
    // Role
    21001:
      'Your permission name has been existed on system. Please input another one.',
    21002:
      'Cannot deactivate this permission as it is being assigned to one or more user(s)',
    21003: 'Role active',
    21004: 'Role not found',
    // Voice & SMS
    24001: 'Phone number invalid',

    // Queuing area
    30001: 'Queuing area not found',
    30002: 'Queuing area in using',

    // System
    500000: 'Internal system error.',
    500001: 'Please config SMS Twilio',
    undefined: 'Something went wrong with our server, please try it again.',

    // Shift
    25001:
      'This name has been existing on the system. Please input another one.',
    25002: 'Invalid time range',
    25004: 'Shift not found',
    26001:
      'This name has been existing on the system. Please input another one.',
    26002:
      'This shift is currently used in operation, please unassigned it before deleting',
    26004: 'Shift template not found',

    // Corporate
    31001:
      'Your company name has been existed on system. Please input another one.',
    31002:
      'This username already exists on our system. Please enter another one.',
    31003: 'Corporate not found.',
    31004: "Corporate's User not found.",
    31005: 'Only corporates who are inactive can be deleted.',
    31006:
      'This phone has been existing on the Corp Users. Please input another one.',
    31007: "Corporate's Traveler not found.",
    31008: 'Card not found.',
    31009:
      'This phone has been existing on the system. Please input another one.',
    31011:
      'This traveller cannot be activated because it belongs to an inactive corporate account',

    // Additional service
    32001:
      'Your service name has been existed on system. Please input another one.',
    32004: 'Service not found',
    33002: 'The times ranges must not overlap with each others',
    // Import file :
    400005: 'File content is invalid',
    400003: 'Permission denied',
    400000: 'Phone number is not valid!',
    // third party location
    600001: 'Location not found',
    600002:
      'This Longitude & Latitude coordinates have existed on the system. Please add another one',
    // mDispatcher
    29002: 'Phone number is existed',
    // mDispatcher Type
    700001: 'Type is inuse',
    700002: 'mDispatcher not found',
    700003:
      'Your mDispatcher type name has been existed on system. Please input another one.',
    // promotion
    27001: 'Campaign is existed',
    27002: 'Campaign is activated',
    27004: 'Campaign not found',
    // Company
    28001: 'City is existed',
    28002: 'City is beeing active',
    28003: 'City is in use',
    28004: 'City not found',
  },
  statusDisplay: {
    supplierAccepted: 'Supplier accepted',
    delivery_supplierAccepted: 'Supplier accepted',
    confirmed: 'Confirmed reservation',
    pending: 'Pending',
    queue: 'Dispatching',
    offered: 'Dispatching',
    booked: 'Driver on the way',
    engaged: 'P.O.B',
    droppedOff: 'Dropped off',
    pre: 'Pending',
    recurrent: 'Recurrent',
    vacant: '',
    vacantTime: '',
    arrived: 'Arrived and waiting',
    completed: 'Completed',
    canceled: 'Canceled',
    noShow: 'No show',
    action: 'Waiting for process',
    incident: 'Incident',
    pickupTimeExceeded: 'Time out',
    canceledByCC: 'Canceled by CC',
    canceledByPassenger: 'Canceled by passenger',
    canceledByPartner: 'Canceled by partner',
    canceledBymDispatcher: 'Canceled by mDispatcher',
    canceledByCorporateAdmin: 'Canceled by Corporate Admin',
    canceledByAPI: 'Canceled by API',
    canceledByWebBooking: 'Canceled by web booking',
    canceledByTimeout: 'Canceled by timeout',
  },
  login: {
    Username: 'Username',
    Password: 'Password',
    Remember_me: 'Remember me',
    Forgot_your_password: 'Forgot your password',
    Login: 'Login',
  },
  cue: {
    Active: 'Active',
    Finished: 'Finished',
    Home_affiliate: 'Home / Affiliate',
    Booking_type: 'Booking type',
    From: 'From',
    To: 'To',
    Status: 'Status',
    Select_Status: 'Select Status',
    Operator: 'Agent',
    Car_types: 'Vehilce types',
    Booking_from: 'Booking from',
    All: 'All',
    Pending: 'Pending',
    Dispatching: 'Dispatching',
    Waiting_for_process: 'Waiting for process',
    Confirmed_reservation: 'Confirmed reservation',
    Driver_on_the_way: 'Driver on the way',
    Arrived_and_waiting: 'Arrived and waiting',
    Passenger_on_board: 'Passenger on board',
    Dropped_off: 'Dropped off',
    combobox_select: 'Select...',
    Search_here: 'Search here',
    Bookingid: 'Booking #',
    Pickup_Drop_Off: 'Departure/Drop Off',
    Pickup_Location_Destination: 'Departure Location/Destination',
    Passenger: 'Traveler',
    Airport_Pickup: 'Airport Departure',
    Payment: 'Payment',
    Vehicle: 'Vehicle',
    Car_type: 'Vehicle type',
    Driver: 'Driver',
    Estimated_Fare: 'Estimated Fare',
    Notes: 'Notes',
    Type: 'Type',
    Home: 'Home',
    Affiliate: 'Affiliate',
    Now: 'Now (Chauffeur)',
    Reservation: 'Reservation (Chauffeur)',
    No_show: 'No show',
    Canceled_by_CC: 'Canceled by CC',
    Canceled_by_passenger: 'Canceled by passenger',
    Canceled_by_partner: 'Canceled by partner',
    Canceled_by_mDispatcher: 'Canceled by mDispatcher',
    Canceled_by_Corporate_Admin: 'Canceled by Corporate Admin',
    Canceled_by_API: 'Canceled by API',
    canceled_By_Web_Booking: 'Canceled by web booking',
    canceled_By_Time_Out: 'Canceled by timeout',
    Completed: 'Completed',
    Incident: 'Incident',
    Payment_Method: 'Payment Method',
    No_Phone: 'No Phone',
    VIP: 'VIP',
    Non_VIP: 'Non-VIP',
    Passenger_type: 'Passenger type',
    Payment: 'Payment',
    Error_load_booking:
      'Cannot load the booking, Please try again or refresh page!',
    Edit_total_success: 'Edit total successful!',
    Edit_total_fail: 'Edit total fail!',
    Time_out: 'Time out',
    Ride_Service: 'Ride-service',
    Regulation: 'Regulation',
    Ride_Sharing: 'Ride-sharing',
    Unmatching: 'Unmatching',
    Matching: 'Matching',
    all: 'All',
    matching: 'Matching',
    unmatching: 'Unmatching',
    regulation: 'Regulation',
    rideSharing: 'Ride-sharing',
    Support_service: 'Support service',
    SOS: 'SOS',
    Non_SOS: 'Non-SOS',
    Corporate: 'Organization',
    Individual: 'Individual',
    On_curb: 'On curb',
    Meet_Greet: 'Meet & Greet',
    Close: 'Close',
    auto_refrest_cue: 'Auto-refresh ({0} seconds)',
    Ride_type: 'Ride type',
    Edit_total_note:
      'Note: subtotal, tech fee, booking fee, tax and tip will be updated when changing the total amount',
    Booking: 'Booking',
    Edit_fare: 'Edit fare',
    Adjust_price: 'Adjust price',
    Total: 'Total',
    Reason: 'Reason',
    Please_enter_reason: 'Please enter reason.',
    Save: 'Save',
    Cancel: 'Cancel',
  },
  newbooking: {
    Pick_up_time: 'Pickup time',
    Drop_off_time: 'Drop off time',
    New_Booking: 'New Booking',
    BOOKING_INFORMATION: 'BOOKING INFORMATION',
    TIP_PROMO: 'TIP - PROMO',
    Pick_up: 'Departure',
    Enter_a_location: 'Enter a location',
    Enter_3rd_party_location: 'Enter 3rd party location',
    Destination: 'Destination',
    Pickup_time: 'Departure time',
    Note: 'Note',
    TRAVELER_INFORMATION: 'TRAVELER INFORMATION',
    Traveler_type: 'Traveler type',
    Individual: 'Individual',
    Corporation: 'Organization',
    Phone_number: 'Phone number',
    First_name: 'First name',
    Last_name: 'Last name',
    Email_address: 'Email address',
    Corp_id: 'Organization id',
    Manager_name: "Manager's name",
    Manager_email: "Manager's email",
    Cost_centre: 'Cost centre',
    Department: 'Department',
    Corp_division: 'Organization division',
    Tip: 'Tip',
    Promo_code: 'Promo code',
    Apply: 'Apply',
    Promo: 'Promo:',
    DISPATCH: 'DISPATCH',
    Auto_dispatch: 'Auto dispatch',
    Assign_driver: 'Assign driver',
    Driver: 'Driver',
    PAYMENT_METHOD: 'PAYMENT METHOD',
    TRIP_ESTIMATE: 'TRIP ESTIMATE',
    Choose_a_stored_card: 'Choose a stored card',
    Use_new_card: 'Use new card',
    Card_holder: 'Card holder',
    Card_number: 'Card number',
    Expired_date: 'Expire date(mm/yyyy)',
    ccv: 'CVV',
    Distance: 'Distance',
    Duration: 'Duration',
    Fare: 'Fare',
    Route: 'Route',
    Create: 'Create',
    Cancel: 'Cancel',
    Meet_driver: 'Meet driver',
    Airline: 'Airline',
    Flight: 'Flight #',
    FLY_INFO: 'FLIGHT INFO',
    Ride_Sharing: 'Ride-Sharing',
    Round_trip: 'Round trip',
    Car_type: 'Vehicle type',
    Address: 'Address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Remove: 'Remove',
    Client_case_matter: 'Client case matter / Trip description',
    Charge_code: 'Charge code / Trip code',
    CANCEL_BOOKING: 'CLOSE BOOKING CONFIRM',
    CANCEL_BOOKING_CONFIRM:
      'Do you want to leave this form? You will lose all the booking data.',
    Search_here: 'Search here',
    No_support_location:
      'Unfortunately, the service is not available in this area',
    Closest_driver_message: 'Closest driver is approximately {0} away',
    No_cars_available: 'No car available within dispatch radius',
    processed_by_affiliate:
      'Your requests will be processed by our affiliate partners',
    FlightExample: 'Flight #, e.g AA1097',
    Yes: 'Yes',
    No: 'No',
    dispatch_3rd: 'Dispatch via 3rd party',
    Company: 'Clients',
    Card_required: 'Please select or add a card!',
    Tip_value: 'N/A',
    Calendar: 'Calendar',
    Extra_Destination: 'Additional Destination',
    Company: 'Clients',
    Driver_credit_limited_balance_warning:
      "This driver's balance is less than or equal limited amount",
    Meet_Greet_option: 'Meet & Greet',
    On_Curb_option: 'On Curb',
    Extra_Services: 'Additional Services',
    Addl_Services: "Add'l services",
    Save: 'Save',
    Cancel: 'Cancel',
    Calendar: 'Calendar',
    Addl_Services: "Add'l Services",
    Please_fill_in_all_the_required_fields:
      'Please fill in all the required fields.',
  },
  bookingDetailLogs: {
    recipient: 'Recipient',
    updatedInfo: 'Updated info',
    updatedField: 'Updated field',
    valueBefore: 'Value before',
    valueAfter: 'Value after',
    location: 'Location',
    etaFare: 'Fare',
    dispatchType: 'Dispatch',
    drvInfo: 'Driver',
    vehicleTypeRequest: 'Vehicle Request',
    clientCaseMatter: 'Trip description',
    chargeCode: 'Trip code',
    driverEarningType: 'Driver Earning Type',
    editedDriverEarning: 'Driver earning',
    reasonEditFare: 'Reason',
    bookingReference: 'External ID',
    tip: 'Tip',
    type: 'Service type',
    promo: 'Promo',
    dispatch3rd: 'Dispatch 3rd',
    rideSharing: 'Ride-Sharing',
    notes: 'Driver notes',
    operatorNote: 'Internal notes',
    welcomeMessage: 'Welcome Message',
    flightNumber: 'Flight Number',
    fullName: 'Full Name',
    email: 'Email',
    cardMask: 'Card mask',
    paymentType: 'Payment method',
    recipients: 'Recipients',
    sender: 'Sender',
    pickupName: 'Pickup Name',
    pickupPhone: 'Pickup Phone',
    pickupEmail: 'Pickup Email',
    supplierEarningType: 'Payout type',
    editedSupplierEarning: 'Payout value',
    route: 'Route name',
    markupDifference: 'Markup Difference',
    selectedDates: 'Selected Dates',
    services: 'Fleet services',
    destinationAddress: 'Destination location',
    pickupAddress: 'Pick-up location',
    tripType: 'Trip Type',
    pickUpTime: 'Pickup time',
    paxNumber: 'Passenger',
    luggageNumber: 'Luggage',
    vehicle: 'Vehicle',
    minutesToArrive: 'Expected driver arrival',
    affiliateNote: 'Affiliate Note',
    packageRateName: 'Package name',
    supplierCompanies: 'Company',
    puPoints: 'Pick-up location',
    doPoints: 'Destination location',
    promoValue: 'Promo value',
    packageInfo: 'Package info',
    orderId: 'Order Id',
    cashOnDelivery: 'Cash on delivery',
    externalId: 'External Id',
    recurring: 'Recurring',
    batchDelivery: 'Batch Delivery',
    payout: 'Payout',
    locationDetails: 'Location Details',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    name: 'Name',
  },
  bookingdetail: {
    Booking_detail: 'Booking detail',
    creditTransactionFee: 'Credit card fees',
    BOOKING_INFORMATION: 'BOOKING INFORMATION',
    TIP_PROMO: 'TIP - PROMO',
    Pick_up: 'Departure*',
    Enter_a_location: 'Enter a location',
    Destination: 'Destination',
    Pickup_time: 'Departure time',
    Notes: 'Notes',
    TRAVELER_INFORMATION: 'TRAVELER INFORMATION',
    Traveler_type: 'Traveler type',
    Individual: 'Individual',
    Corporation: 'Organization',
    Phone_number: 'Phone number',
    First_name: 'First name',
    Last_name: 'Last name',
    Email_address: 'Email address',
    Corp_id: 'Organization id',
    Manager_name: "Manager's name",
    Manager_email: "Manager's email",
    Cost_centre: 'Cost centre',
    Department: 'Department',
    Corp_division: 'Organization division',
    Tip: 'Tip',
    Promo_code: 'Promo code',
    Apply: 'Apply',
    Promo: 'Promo:',
    DISPATCH: 'DISPATCH',
    Auto_dispatch: 'Auto dispatch',
    Assign_driver: 'Assign driver',
    Driver: 'Driver',
    PAYMENT_METHOD: 'PAYMENT METHOD',
    Payment_methods: 'Payment methods',
    TRIP_ESTIMATE: 'TRIP ESTIMATE',
    Choose_a_stored_card: 'Choose a stored card',
    Use_new_card: 'Use new card',
    Card_holder: 'Card holder',
    Card_number: 'Card number',
    Expired_date: 'Expire date(mm/yyyy)',
    ccv: 'CVV',
    Distance: 'Distance',
    Duration: 'Duration',
    Fare: 'Fare',
    Route: 'Route',
    Meet_driver: 'Meet driver',
    Airline: 'Airline',
    Flight: 'Flight #',
    FLY_INFO: 'FLIGHT INFO',
    Ride_Sharing: 'Ride-Sharing',
    Round_trip: 'Round trip',
    Car_type: 'Vehicle type',
    Address: 'Address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Remove: 'Remove',
    New_Booking: 'New Booking',
    Update_Booking: 'Update Booking',
    Cancel_Booking: 'Cancel Booking',
    Complete_with_payment: 'Complete With Payment',
    Incident: 'Incident',
    CONFIRM_CANCEL: 'CONFIRM CANCEL',
    CONFIRM_CANCEL_MESSAGE:
      "Please click 'Ok' to cancel this booking. If you don't want to, click 'Cancel' to close the dialogue box",
    CONFIRM_UPDATE: 'CONFIRM UPDATE',
    CONFIRM_UPDATE_MESSAGE: 'Are you sure you want to update this booking?',
    Close: 'Close',
    Update_info: 'Update info',
    Update_info_dispatch: 'Update info & dispatch',
    BOOKING: 'BOOKING',
    DROPPED_OFF: 'DROPPED OFF',
    Payment_Type: 'Payment Type',
    Total: 'Total',
    Next: 'Next',
    Pay_here: 'Pay here',
    Cancel: 'Cancel',
    Back: 'Back',
    Tax: 'Tax',
    Booking_fees: 'Booking fees',
    Tech_fees: 'Tech fees',
    Subtotal: 'Subtotal',
    Other_fees: 'Other fees',
    Toll_fees: 'Toll fees',
    Heavy_traffic: 'Heavy traffic',
    Rush_hour: 'Surcharge',
    Basic_fare: 'Basic fare',
    Ok: 'Ok',
    Input_reason: 'Input reason',
    Client_case_matter: 'Client case matter / Trip description',
    Charge_code: 'Charge code / Trip code',
    Enter_3rd_party_location: 'Enter 3rd party location',
    Company: 'Clients',
    Check_promo_error: 'Check promoCode error!',
    Add_new_credit_error: 'Add new credit error!',
    Can_not_get_fare_setting: 'Cannot get fare setting!',
    Can_not_get_affilate_fare_setting: 'Cannot get Affiliate fare settings!',
    Can_not_check_commisson: 'cannot check commisson!',
    No_flight_info: 'No flight info!',
    Socket_server_disconnected: 'Socket server disconnected!',
    Please_reload_page_or_check_your_connection:
      'Please reload page or check your connection!',
    View_Logs: 'View logs',
    Bookingid: 'Booking #',
    Calendar: 'Calendar',
    Extra_Destination: 'Additional Destination',
    Company: 'Company',
    Meet_Greet_option: 'Meet & Greet',
    On_Curb_option: 'On Curb',
    Credit_Card: 'Credit card',
    Charge: 'Charge',
    No_charge: 'No charge',
    QR_Code_method_not_allow_error_message:
      'Please select another payment type as QRCode is not available to pay on dashboard!',
    change_payment_method_note:
      'If you want to change payment method, please go back to booking form.',
  },
  map: {
    Enter_a_location: 'Enter a location',
    All_company: 'All suppliers',
    All_cars: 'All vehicles',
    Show_all_cars: 'Show all vehicles',
    Hide_all_cars: 'Hide all vehicles',
    Vacant: 'Available',
    Dispatching: 'Dispatching',
    Driver_on_the_way: 'Driver on the way',
    Arrived_and_waiting: 'Arrived and waiting',
    P_O_B: 'P.O.B',
    Dropped_off: 'Dropped off',
    Waiting_for_process: 'Waiting for process',
    Unavailable: 'Unavailable',
    Total_Drivers: 'Total Drivers',
    Estimate: 'Estimate',
    Destination: 'Destination',
    Pickup: 'Pickup',
    Start_point: 'Start point',
    Passenger: 'Passenger',
    Driver_name: 'Driver name',
    Search_car_here: 'Search vehicle here',
    Error_load_booking:
      'Cannot load the booking, Please try again or refresh page!',
  },
  customer: {
    Customers: 'Travelers',
    ADD_CUSTOMER: 'ADD TRAVELER',
    EDIT_CUSTOMER: 'EDIT TRAVELER',
    DETAIL_CUSTOMER: 'DETAIL TRAVELER',
    Add: 'Add',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Delete: 'Delete',
    Import: 'Import',
    View_Message: 'Message',
    Send_Message: 'Send Message',
    Export_to_excel: 'Export to excel',
    Filter: 'Filter',
    Apply: 'Apply',
    Clear: 'Clear',
    Name: 'Name',
    Phone_Number: 'Phone Number',
    Email: 'Email',
    Vip: 'Vip',
    File: 'File',
    Number_successful_imports: 'Number of successful imports: ',
    Number_skipped: 'Number of skipped: ',
    Number_duplicate_phone_numbers: 'Number of duplicate phone numbers: ',
    Number_wrong_phone_numbers: 'Number of wrong phone numbers: ',
    Corporate_Name: 'Corporation',
    Registered_From: 'Registered From',
    Registration_Date: 'Registration Date',
    Registration_date_from: 'Registration date from',
    Registration_date_to: 'Registration date to',
    IOS_Last_Login_Version: 'IOS Last-Login Version',
    Android_Last_Login_Version: 'Android Last-Login Version',
    Last_Active_Date: 'Last Active Date',
    Days_Since_Last_Active: 'Days Since Last Active',
    Completed_Bookings: 'Completed Bookings',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactive',
    Active: 'Active',
    Edit: 'Edit',
    View: 'View',
    ALERT_SELECT_ACTIVATE: 'Please select traveler to activate.',
    ALERT_SELECT_DEACTIVATE: 'Please select traveler to deactivate.',
    ALERT_SELECT_DELETE: 'Please select traveler to delete.',
    CONFIRM_DELETE: 'Do you want to delete this traveler?',
    CONFIRM_DELETE_MULTI:
      'Do you want to delete these traveler? Only traveler who are inactive and not a ' +
      'registered user via app can be deleted.',
    CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these travelers?',
    CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these travelers?',
    CONFIRM_EXPORT_TO_EXCEL:
      'Are you sure you want to export all data as a .xlsx file?',
    // Add, Edit traveler
    Add_card_top_up_success: 'Prepaid card has been topped up successfully',
    Add_card_success: 'The card has been added successfully!',
    Add_customer_success: 'Add traveler success',
    Update_customer_success: 'Update traveler success',
    Create_customer_fail: 'Create traveler fail',
    Update_customer_fail: 'Update traveler fail',
    ERROR_EXISTED_PHONE:
      'This phone has been existing on the system. Please input another one',
    ERROR_INPUT_FIRSTNAME: 'Please input first name.',
    ERROR_INPUT_PHONE: 'Please input phone number.',
    ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
    ERROR_INPUT_VALIDEMAIL: 'Please enter a valid email address.',
    CONFIRM_DELETE_CARD: 'Are you sure you want to delete this cards?',
    First_name: 'First name',
    Last_name: 'Last name',
    Phone_number: 'Phone number',
    Email_address: 'Email address',
    Vip: 'Vip',
    Notes: 'Notes',
    Account: ' Account',
    Card_management: 'Card management',
    Home: 'Home',
    Affiliate: 'Affiliate',
    SHOW_PERSONAL_CARD: 'SHOW PERSONAL CARD',
    ADD_PERSONAL_CARD: 'ADD PERSONAL CARD',
    SHOW_mDISPATCHER_CARD: 'SHOW mDISPATCHER CARD',
    ADD_mDISPATCHER_CARD: 'ADD mDISPATCHER CARD',
    Personal_card: 'Personal card',
    mDispatcher_card: 'mDispatcher card',
    Card_holder: 'Card holder ',
    Card_number: 'Card number',
    Expired_date: 'Expired date ',
    CVV: 'CVV',
    Address: 'Address',
    Country: 'Country',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Required_fields: 'Required fields',
    Cancel: 'Cancel',
    Save: 'Save',
    // Add, Delete Card
    ERROR_INPUT_CARD_HOLDER: 'Please input card holder',
    ERROR_INPUT_CARD_NUMBER: 'Please input card number',
    ERROR_INPUT_EXPIRED_DATE: 'Please input expired date',
    ERROR_VAILD_EXPIRED_DATE: 'Expired date is not valid',
    ERROR_INPUT_CVV: 'Please input CVV',
    ERROR_INPUT_ADDRESS: 'Please input address',
    ERROR_INPUT_CITY: 'Please input city',
    ERROR_INPUT_ZIPCODE: 'Please input zip code',
    ERROR_INPUT_COUNTRY: 'Please select country',
    Info_edit_error: 'Error!',
    Gender: 'Gender',
    Select_Gender: 'Select',
    Male: 'Male',
    Female: 'Female',
    Date_of_birth: 'Date of birth',
    Street_address: 'Street address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Country: 'Country',
    Select_Zone: 'Select Zone',
    ERROR_GET_GATEWAYS: 'ERROR !!! Get Gateways',
    Require_email: 'Please enter the email before adding new card',
    Require_lastName: 'Please enter the last name before adding new card',
    Require_phone_number:
      "Please enter the traveler's phone number before make a booking with credit card",
    IMPORT_CUSTOMER_SUCCESS: 'import customers successfully',
    No_card: 'No card found',
    No_Support: 'New card adding is currently not supported in this area',
    Card_Management: 'Card Management',
    DETAIL_CUSTOMER: 'Customer Details',
    Select_Country: 'Select country',
  },
  table_header: {
    Items_per_page: 'Items per page',
    Page: 'Page',
    Search_here: 'Search here',
    Shown_Active: '{0} - {1} of {2} shown ',
    Active: '{0} Active ',
  },
  driver: {
    Drivers: 'Drivers',
    Add: 'Add',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Delete: 'Delete',
    View_Message: 'Message',
    Send_Message: 'Send Message',
    Driver_Name: 'Driver Name',
    Driver_ID: 'ID# / National IC#',
    Username: 'Username',
    Phone_Number: 'Phone Number',
    Driver_Type: 'Driver Type',
    TOP: 'TOP',
    Company: 'Supplier',
    Driver_License_Number: 'Driver License Number',
    License_Plate: 'License Plate',
    Car_Type: 'Vehicle Type',
    Zone: 'Zone',
    Registered_From: 'Registered From',
    Registration_Date: 'Registration Date',
    IOS_Last_Login_Version: 'IOS Last-Login Version',
    Android_Last_Login_Version: 'Android Last-Login Version',
    Last_Active_Date: 'Last Active Date',
    Days_Since_Last_Active: 'Days Since Last Active',
    Completed_bookings: 'Completed bookings',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactive',
    Active: 'Active',
    Edit: 'Edit',
    View: 'View',
    ALERT_SELECT_ACTIVATE: 'Please select driver to activate.',
    ALERT_SELECT_DEACTIVATE: 'Please select driver to deactivate.',
    ALERT_SELECT_DELETE: 'Please select driver to delete.',
    CONFIRM_DELETE: 'Do you want to delete this driver?',
    CONFIRM_DEACTIVATE_MULTI:
      'Are you sure you want to deactivate the selected driver(s)? Their connected' +
      'hardware meter(s) will be also disabled. Only driver(s) who are having no current trip or reservation can be deactivated.',
    CONFIRM_ACTIVATE_MULTI:
      'Do you want to activate these drivers? Only driver(s) who are completely setup for operation can be activated.',
    CONFIRM_DELETE_MULTI:
      'Do you want to delete these drivers? Just only driver in the inactive status can be deleted.',
    // Add, Edit Driver
    ADD_DRIVER: 'ADD DRIVER',
    Avatar: 'Avatar',
    Individual_Driver: 'Individual Driver',
    Owner_Operator: 'Owner Operator',
    CONTACT_INFO: 'CONTACT INFO',
    Home_address: 'Home address',
    Driver_Type: 'Driver Type',
    First_name: 'First name',
    Last_name: 'Last name',
    Phone_number: 'Phone number',
    Email_address: 'Email address',
    City: 'City',
    Select_city: 'Select city',
    State: 'State',
    Zip_code: 'Zip code',
    Date_of_birth: 'Date of birth',
    City_driver_want_to_drive: 'City driver want to drive',
    Driver_license_number: 'Driver license number',
    Driver_license_state: 'Driver license state/Province',
    Driver_license_expiry: 'Driver license expiry',
    FOR_INSPECTOR: 'FOR INSPECTOR',
    Document: 'Document',
    Link: 'Link',
    Notes: 'Notes',
    UPLOAD_DOCUMENT: 'UPLOAD DOCUMENT',
    Click_here_to_download: 'Click here to download.',
    EXTENSION_REQUIREMENT:
      'The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip and less than 5Mb.',
    Invalid_file: 'File content is invalid',
    File_size_larger_than_5Mb: 'File size larger than 5Mb',
    // Bank Account
    BANK_ACCOUNT: 'BANK ACCOUNT',
    Name_of_bank: 'Name of bank',
    Name_of_account: "Account holder's name",
    First_M_Last: 'First M. Last',
    RTBnumber: 'Routing/Transit/Branch number',
    Account_number: 'Account number',
    IBAN_number: 'IBAN number',
    Check_number: 'Check number',
    Swift_code_number: 'Swift code number',
    SSNNI: 'SSN/ NI',
    Verification_document: 'Verification document',
    OPERATION: 'OPERATION',
    CAR_INSURANCE: 'VEHICLE INSURANCE',
    Policy_number: 'Policy number',
    Effective_date: 'Effective date',
    Expired_date: 'Expired date',
    Select_company: 'Select supplier',
    Car: 'Vehicle',
    Existing_car: 'Existing vehicle',
    New_car: 'New vehicle',
    License_plate: 'License plate',
    Select_license_plate: 'Select license plate',
    Car_Type: 'Vehicle Type',
    Select_Car_Type: 'Select Vehicle Type',
    Make: 'Make',
    Select_Car_Make: 'Select Vehicle Make',
    Model: 'Model',
    Select_Car_Model: 'Select Vehicle Model',
    Year: 'Year',
    Select_Year: 'Select Year',
    Color: 'Color',
    Passengers: 'Passengers',
    Luggage: 'Luggage',
    Phone_Number: 'Phone number',
    License_expiry: 'License expiry',
    Shift: 'Shift',
    Select_Shift_Template: 'Select Shift Template',
    Fleet_commission: 'Supplier commission (report)',
    Percentage: 'Percentage',
    Amount: 'Amount',
    transaction: 'Transaction',
    Terminal_ID: 'Terminal ID',
    Auth_key: 'Auth key',
    CREDIT_DEPOSIT: 'Credit wallet',
    Credit_Balance: 'Credit Balance',
    Balance: 'Balance',
    Change_balance: 'Change balance',
    Select_balance: 'Select balance',
    Adjust_amount: 'Adjust amount',
    Adjust_value: 'Adjust value',
    New_point: 'New point',
    New_balance: 'New balance',
    Enter_reason: 'Enter reason',
    ERROR_INPUT_BALANCE: 'Please select balance.',
    ERROR_INPUT_AMOUNT: 'Please input this value.',
    ERROR_INPUT_REASON: 'Please enter reason.',
    ERROR_INPUT_VALID_AMOUNT:
      'Please enter a value greater than or equal to 0.',
    INFO_ADD_BALANCE_SUCCESS: 'Driver balance has been updated successfully',
    INFO_UPDATE_POINT_SUCCESS: 'Passenger point has been updated successfully',
    Force_meter: 'Force meter',
    Force_meter_tooltip:
      "Driver app must be connected to the meter before operating. Applied for Pulsar & Centrodyne's meters",
    ERROR_EXISTED_PHONE:
      'This phone has been existing on the system. Please input another one',
    TOP_driver: 'TOP driver',
    Ride_sharing: 'Ride-sharing',
    Note_password:
      'Note: For new driver account, the default password is "password"',
    Required_fields: 'Required fields',
    Cancel: 'Cancel',
    Save: 'Save',
    ERROR_INPUT_USERNAME: 'Please input username.',
    ERROR_INPUT_VALID_USERNAME:
      'Username may only contain lower letters & numbers and must be between 3 and 20 characters long',
    ERROR_INPUT_PHONE: 'Please specify a valid phone number.',
    ERROR_INPUT_VALID_EMAIL: ' Please enter valid email.',
    ERROR_INPUT_FRISTNAME: 'Please input first name.',
    ERROR_INPUT_LASTNAME: 'Please input last name.',
    ERROR_INPUT_HOME_ADDRESS: 'Please input home address.',
    ERROR_INPUT_CITY: 'Please input city.',
    ERROR_INPUT_STATE: 'Please input state.',
    ERROR_INPUT_ZIPCODE: 'Please input zipcode.',
    ERROR_INPUT_BIRTHDAY: 'Please input birthday.',
    ERROR_INPUT_NAME_OF_BANK: 'Please input name of bank.',
    ERROR_INPUT_NAME_OF_ACCOUNT: "Please input account holder's name.",
    ERROR_INPUT_ROUTING_NUMBER: 'Please input routing number.',
    ERROR_INPUT_ACCOUNT_NUMBER: 'Please input account number.',
    ERROR_INPUT_CHECK_NUMBER: 'Please input check number.',
    ERROR_INPUT_SSN: 'Please input SSN.',
    ERROR_INPUT_DocumentId: 'Please input Document ID.',
    ERROR_INPUT_COMPANY: 'Please select supplier to activate.',
    ERROR_INPUT_LICENSE_PLATE: 'Please choose license plate.',
    ERROR_INPUT_ZONE: 'Please select zone to activate.',
    ERROR_INPUT_SHIFT: 'Please select shift to activate.',
    ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS: 'Must contain only numbers.',
    ERROR_SSN_ONLY_NUMBERS: 'Must contain only numbers.',
    ERROR_SSN_LENGTH: 'SSN must be at least 4 characters',
    ERROR_COMMISSION_NUMBERS: 'Please enter a valid number',
    INFO_DEACTIVAED_SUCCESS: 'The driver has been deactivated successfully.',
    INFO_ACTIVAED_SUCCESS: 'The driver has been activated successfully.',
    INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE:
      'The driver has been deactivated but the integrated hardware meter is cannot be turned off for some unexpected reasons.',
    INFO_MULTIDEACTIVAED_SUCCESS:
      'The {0} selected drivers have been successfully deactivated.',
    INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS:
      'The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled.',
    INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE:
      'The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled. {2} were not due to some unexpected reasons.',
    INFO_MULTIACTIVAED_SUCCESS:
      'The {0} selected drivers have been successfully activated.',
    DRIVER_UPDATE_SUCCESS: 'Driver has been updated successfully.',
    DRIVER_CREATE_SUCCESS: 'Driver info has been successfully added',
    Expiry_Date: 'Expiry Date',
    Case_number: 'Case number',
    Export_to_csv: 'Export to CSV',
    CONFIRM_EXPORT_TO_CSV:
      'Are you sure you want to export all data as a .csv file?',
    Ride_sharing_tooltip:
      'Ride-sharing is an advanced feature, please contact our support Team to get more info',
  },
  corporate: {
    Corporates: 'Clients',
    Add: 'Add',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Delete: 'Delete',
    Company_Name: 'Client name',
    Admin_Name: 'Admin Name',
    Username: 'Username',
    Phone_Number: 'Phone Number',
    Email: 'Email',
    Registered_From: 'Registered From',
    Registration_Date: 'Registration Date',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactive',
    Active: 'Active',
    In_Review: 'In Review',
    Edit: 'Edit',
    View: 'View',
    ADD_CORPORATE_CARD: 'ADD CLIENT CARD',
    CONFIRM_DELETE_CARD: 'Are you sure you want to delete this cards?',
    ALERT_SELECT_ACTIVATE: 'Please select client to activate.',
    ALERT_SELECT_DEACTIVATE: 'Please select client to deactivate.',
    ALERT_SELECT_DELETE: 'Please select client to delete.',
    CONFIRM_DELETE: 'Do you want to delete this client?',
    CONFIRM_DELETE_USER: 'Do you want to delete this client user?',
    CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these clients?',
    CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these clients?',
    CONFIRM_DELETE_MULTI:
      'Do you want to delete these clients?\n ** Only clients who are inactive can be deleted.',
    // add
    ERROR_INPUT_PHONE: 'Please specify a valid phone number.',
    ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
    ERROR_EXISTED_PHONE:
      'This phone has been existing on the system. Please input another one',
    Account: 'Account',
    Corp_traveler: 'Tripper',
    Payment_methods: 'Payment methods',
    Corp_operators: 'Client operators',
    Pricing: 'Pricing',
    COMPANY_INFORMATION: 'CLIENT INFORMATION',
    Company_name: 'Client name',
    Company_ID: 'Client ID (for client traveler signup)',
    Company_address: 'Client address',
    Invoice_email: 'Invoice email',
    Confirmation_email: 'Confirmation email',
    ADMIN_ACCOUNT: 'ADMIN ACCOUNT',
    First_name: 'First name',
    Last_name: 'Last name',
    Commission: 'Commission',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Reset_password: 'Reset password',
    Employee_Name: 'Name',
    Created_Date: '	Created Date',
    Add_corporate_card: 'Add client card',
    Corp_Users: 'Client Users',
    Pricing_Default: 'Default (same pricing plan as normal pax)',
    Pricing_Discount:
      'Discount by percentage (applied for client traveler only)',
    Pricing_Add_different_rates: 'Add different rates',
    ERROR_INPUT_COMPANYNAME: 'Please input client name.',
    ERROR_INPUT_USERNAME: 'Please input username.',
    ERROR_INPUT_FIRSTNAME: 'Please input first name.',
    ERROR_INPUT_EMAIL: 'Please input email.',
    ERROR_INPUT_VALID_EMAIL: 'Please enter a valid email address.',
    ERROR_INPUT_VALID_NUMBER: 'Please enter valid number',
    ERROR_INPUT_VALID_RANGE_AMOUNT: 'Please enter a value between {0} and {1}.',
    INFO_ADD_CORPORATE_SUCCESS:
      'Account {0} has been created successfully. Please check the email {1} for login credentials details.',
    INFO_UPDATE_CORPORATE_SUCCESS: 'Client has been updated successfully.',
    INFO_UPDATE_PRICING_SUCCESS: 'Update Pricing Successful.',
    INFO_RESET_PASSWORD_SUCCESS:
      'Account {0} has been reset successfully. Please check the email {1} for login credentials details.',
    COMFIRM_RESET_PASSWORD: 'Do you want to reset password?',
    Traveler_signature: 'Traveler signature',
    Tracking_log: 'Tracking log',
    Rating: 'Rating',
    // Corp Users
    Email_address: 'Email address',
    Assistant_email: 'Assistant email',
    Corp_PO: 'Client PO',
    Cost_centre: 'Cost centre',
    Corp_division: 'Client division',
    Corp_id: 'Client id',
    Department: 'Department',
    Manager_email: "Manager's email",
    Manager_name: "Manager's name",
    Corporate_card: 'Client card',
    Direct_invoicing: 'Direct invoicing',
    Corporate_Prepaid: 'Client Prepaid',
    Cash: 'Cash',
    Car_Type_ID: 'Vehicle Type ID',
    App_Display_Name: 'App Display Name',
    Normal_Fare: 'Normal Fare',
    Flat_Fare: 'Flat Fare',
    Hourly_Daily_Rate: 'Hourly/ Daily Rate',
    Assign_rate: 'Assign rate',
    Zone: 'Zone',
    Rate: 'Rate',
    Zone_Rate: 'Zone - Rate',
    Please_select_rate: 'Please select rate',
    Paid_by: 'Paid by',
    Enter_new_credit_card: ' Enter new credit card ',
    TOP_UP: 'TOP UP',
    Top_up: 'Top up',
    Topup_amount: 'Topup amount',
    Currency: 'Currency',
    ERROR_INPUT_AMOUNT: 'Please input valid amount.',
    VAT_number: 'VAT number',
    Gender: 'Gender',
    Select_Gender: 'Select',
    Male: 'Male',
    Female: 'Female',
    Date_of_birth: 'Date of birth',
    Street_address: 'Street address',
    City: 'City',
    State: 'State',
    Zip_code: 'Zip code',
    Country: 'Country',
    adding_new_card:
      'You are adding a new card to use service in %{zoneName} area',
    No_Support: 'New card adding is currently not supported in this area',
    Username: 'Username',
  },
  message: {
    Add: 'Add',
    Delete: 'Delete',
    Items_per_page: 'Items per page',
    Page: 'Page',
    Search_here: 'Search here',
    Shown_Active: '{0} - {1} of {2} shown',
    Date: 'Date',
    Subject: 'Subject',
    To: 'To',
    Type: 'Type',
    Result: 'Result',
    Actions: 'Actions',
    New_message: 'New message',
    MESSAGE_DETAILS: 'MESSAGE DETAILS',
    All: 'All',
    Company: 'Company',
    Active: 'Active',
    In_Active: 'Inactive',
    In_Review: 'In Review',
    Send_to: 'Send to',
    All_drivers: 'All drivers',
    All_customers: 'All travelers',
    Customized_driver: 'Customized driver',
    Customized_customer: 'Customized traveler',
    Receiver_list: 'Receiver list',
    Content: 'Content',
    Required_fields: 'Required fields',
    Search_driver_name_driver_phone_number:
      'Search driver name, driver phone number',
    Search_customer_name_customer_phone_number:
      'Search traveler name, traveler phone number',
    Search_car_type: 'Search vehicle type',
    Searching: 'Searching...',
    No_results: 'No results',
    Send: 'Send',
    Cancel: 'Cancel',
    SMS_failed_Inbox_succeeded: 'SMS failed, Inbox succeeded',
    Failed: 'Failed',
    Succeeded: 'Succeeded',
    CONFIRM_DELETE: 'Do you want to delete this message?',
    CONFIRM_DELETE_MULTI: 'Do you want to delete these messages?',
    ALERT_SELECT_DELETE: 'Please select message to delete.',
    ERROR_INPUT_RECEIVERLIST:
      'Please choose at least one driver to send message',
    ERROR_INPUT_SUBJECT: 'Please input subject',
    ERROR_INPUT_CONTENT: 'Please input content',
    import_fail_success:
      '%{success} driver(s) has been imported successfully, %{fail} failed.',
    CONFIRM_SEND_SUCCESS:
      'Your message has been sent. This may take several minute. Please wait while the system is processing your request.',
    Load_message_and_sms_fail:
      'The errors occurred while loading message and SMS details',
    Update_message_and_sms_fail:
      'The errors occurred while updating message and SMS details',
    Update_message_and_sms_success:
      'Message and SMS details has been updated successfully',
    Driver_List: 'Driver List',
    Car_Type_List: 'Vehicle type list',
    Search_vehicle_type_name: 'Search Vehicle Type Name',
    ALERT_PROCESSING_DELETE:
      'Your request is being processed. This could take several seconds',
  },
  fleetInfo: {
    Fleet_name: 'Organization name',
    Phone_number: 'Phone number',
    Email_address: 'Email address',
    Country: 'Country',
    Time_zone: 'Time zone',
    Address: 'Address',
    Website: 'Website',
    Currency: 'Currency',
    Distance_unit: 'Distance unit',
    Load_fleet_info_fail: 'The errors occurred while loading fleet info',
  },
  generalSetting: {
    alertNotifications: {
      title: 'Alert notifications',
      emails: 'Emails',
      emailsPlaceholder: 'Enter email',
      slack: 'Slack Channel (Instructions)',
      slackPlaceholder: 'Slack POST URL',
      discord: 'Discord Channel (Instructions)',
      discordPlaceholder: 'Enter Discord Channel',
      telegram: 'Telegram Channel (Instructions)',
      telegramPlaceholder: 'Enter Telegram Channel',
    },
    AM: 'AM',
    PM: 'PM',
    RUSH_HOUR: 'Surcharge',
    Name: 'Name',
    Date: 'Date',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Time_range: 'Time range',
    From: 'From',
    To: 'To',
    Rush_hour_time_calculation: 'Surcharge time calculation',
    Rush_hour_surcharge: 'Surcharge',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    MDISPATCHER_CANCELLATION: 'Traveler cancellation',
    Book_now: 'Book now',
    Duration: 'Duration',
    Times: 'Times',
    Book_later: 'Book later',
    MDISPATCHER_NO_SHOW: 'TRAVELER NO SHOW',
    AIRPORT_FEE: 'AIRPORT FEE',
    From_airport: 'From airport',
    To_airport: 'To airport',
    MEET_DRIVER: 'MEET DRIVER',
    On_curb: 'On curb',
    Meet_Greet: 'Meet & Greet',
    Add: 'Add',
    Edit: 'Edit',
    DELETE_SERVICE: 'Delete service',
    DELETE_CONFIRM: 'Do you want to delete this item?',
    HEAVY_TRAFFIC: 'HEAVY TRAFFIC',
    Heavy_traffic_surcharge: 'Heavy traffic surcharge',
    TOLL_FEE: 'Toll fee',
    parkingFee: 'Parking fee',
    gasFee: 'Gas fee',
    OTHER_FEES: 'OTHER FEES',
    Can_edit: 'Able to edit',
    Can_add_note: 'Able to add note',
    Other_fees_amount: 'Other fees amount',
    TAX: 'TAX',
    Tax_surcharge: 'Tax surcharge',
    TIP: 'TIP',
    Default_tip: 'Default tip',
    RESERVATION_REMINDER: 'RESERVATION REMINDER',
    Driver_app_SMS_alert_before: 'Driver app - SMS & alert before',
    Pax_app_alert_before: 'Pax app - alert before',
    Sound_notification: 'Sound notification',
    Advance_information: 'Advance information corporate users',
    Load_general_fail: 'The errors occurred while loading general setting',
    Update_general_success: 'General setting has been updated successfully',
    Update_general_fail: 'The errors occurred while updating general setting',
    Time_over_lap: 'The times ranges must not overlap with each others',
    Allow_passenger_to_register_as_corp_traveler:
      'Allow passenger to register as corp users',
    Not_Allow_Make_Booking_Without_Assign_Fare:
      'Do not allow pax to make booking if basic fare = 0.00',
    Disable_Direct_Invoicing_Receipt_Email:
      'Disable direct invoicing receipt email',
    Additional_services: 'Additional Services',
    Edit_additional_services: 'Edit Additional Services',
    Service_Fee_By_Currencies: 'Service fee',
    Service_type: 'Service Type',
    Optional: 'Optional',
    Compulsory: 'Compulsory',
    Vehicle_type: 'Vehicle Type',
    Adding_additional_service_success: 'Add service success',
    Update_additional_service_success: 'Update service success',
    Adding_additional_service_fail: 'Add Service fail',
    Update_additional_service_fail: 'Update service fail',
    Other_settings: 'Other settings',
    Operator_assign_error: 'Operator assign errors',
    Create_surcharge_success: 'Create surcharge success',
    Update_surcharge_success: 'Update surcharge success',
    Delete_surcharge_success: 'Delete surcharge success',
    Create_surcharge_fail: 'Create surcharge failed',
    Update_surcharge_fail: 'Update surcharge failed',
    Delete_surcharge_fail: 'Delete surcharge failed',
    Get_surcharge_detail_fail: 'Get surcharge detail failed',
    Repeat: 'Repeat',
    DayOfWeek: 'Day of week',
    SingleDay: 'Single day',
    Yearly: 'Yearly',
    Backup_phone_number: 'Backup phone number',
    Backup_phone_number_hint:
      'Direct to this number when driver and passenger are out of operation zones',
    Apply_countdown_clock: 'Apply countdown clock',
    No_show_duration_tooltip:
      'Set up time to display No Show button after driver tap on Arrived button on Driver App',
    Invalid_period_of_time: 'Invalid period of time',
    hide_Provider_Filter_From_Corporate_Board:
      'Hide provider filter from corporate board',
    customer_email_is_optional: "Customer's email is optional on passenger app",
    disable_hourly_rate:
      'Disable hourly rate when both hourly and flat rate are available',
  },
  user: {
    Show_per_page: 'Items per page',
    Page: 'Page',
    Search_here: 'Search here',
    show_pages: '{0} - {1} of {2} shown',
    Username: 'Staff name',
    Name: 'Name',
    Phone_number: 'Phone Number',
    Email: 'Email',
    Permission: 'Permission',
    Status: 'Status',
    Actions: 'Actions',
    Active: 'Active',
    Inactive: 'Inactive',
    Edit: 'Edit',
    Deactivate: 'Deactivate',
    Delete: 'Delete',
    Add: 'Add',
    ADD_USER: 'ADD STAFF',
    First_name: 'First name',
    Last_name: 'Last name',
    AgendId: 'Staff #',
    Email_address: 'Email address',
    Address: 'Address',
    Permission: 'Permission',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    EDIT_USER: 'EDIT STAFF',
    Yes: 'Yes',
    Cancel: 'Cancel',
    DELETE_USER: 'DELETE STAFF',
    DETAIL_USER: 'DETAIL STAFF',
    DELETE_CONFIRM: 'Are you sure to delete Staff?',
    Update_user_fail: 'The errors occurred while updating staff',
    Update_user_success: 'Staff has been updated successfully',
    Create_user_fail: 'The errors occurred while creating staff',
    Create_user_success:
      'New staff {0} has been created successfully. Please check the email {1} for login credentials details.',
    Load_user_fail: 'The errors occurred while loading staff',
    Update_user_status_fail: 'The errors occurred while updating staff status',
    Delete_user_fail: 'The errors occurred while deleting staff',
    Delete_user_success: 'Staff has been deleted successfully',
    CONFIRM_RESET_PASSWORD: 'Do you want to reset password?',
    RESET_PASSWORD: 'Do you want to reset password?',
    Reset_password: 'Reset password',
    Select_permission: 'Select Permission',
    Reset_password_success: 'Reset password successful',
  },
  report: {
    query: {
      errorMessage: {
        bookFrom: 'Please select booking from',
        paymentMethod: 'Please select payment method',
        bookingType: 'Please select booking type',
        ratingType: 'Please select rating type',
        stars: 'Please select rating type',
        transactionType: 'Please select transaction type',
        serviceType: 'Please select service type',
        rideService: 'Please select ride service',
        invalidEmail: 'Please enter a valid email address.',
      },
      search: {
        bookingDetails:
          'Search: Booking ID, Operator, Traveler phone, Traveler name, Driver name, ID# / National IC#, License plate, Queuing area, Note',
        financial: 'Search: Driver name, Phone',
        financialDriver: 'Search: Booking ID, Driver name, ID# / National IC#',
        carActivity: 'Search: License plate',
        affiliation: 'Search: Booking ID',
        prepaidTopUp: 'Search: Supplier name, Top up ID, Transaction ID',
        operatorLogs: 'Search: Operator name, User name, Description',
        corporate:
          'Search: Booking ID, Driver name, Client traveler, Client case matter, Charge code',
        bookingLogs:
          'Search: Booking ID, Traveler name, Driver name, ID# / National IC#',
        rating: 'Search: Booking ID, Traveler phone, Traveler name',
        mDispatcher: 'Search: Booking ID, mDispatcher name',
        totalAdjustment: 'Search: Booking ID',
        incidentCancellation:
          'Search: Booking ID, Traveler phone, Traveler name',
        revenueDriver: 'Search: Driver name',
        dailyDriver: 'Search:  Driver phone, Driver name, ID# / National IC#',
        dailyCar: 'Search: License plate, Permission',
        dailyDriverCar:
          'Search:  Driver phone, Driver name, ID# / National IC#, License plate',
        driverLoginStatus:
          'Search:  Driver phone, Driver name, ID# / National IC#, License plate',
        bookingSummary: 'Search: Operator name, User name, Booking ID',
        creditWallet:
          'Search: Supplier name, Driver name, Booking ID, Operator',
        settlement: 'Search:  Driver name, ID# / National IC#',
        driverRating: 'Search: Booking ID, Traveler phone, Traveler name',
      },
      common: {
        all: 'All',
      },
      today: 'Today',
      thisMonth: 'This month',
      lastMonth: 'Last month',
      past30Days: 'Past 30 days',
      thisYear: 'This year',
      custom: 'Custom',
      fromDate: 'From date',
      toDate: 'To date',
      driver: 'Driver',
      company: 'Supplier',
      operator: 'Operator',
      farmType: 'Farm type',
      carLicensePlate: 'Vehicle license plate',
      bookingService: 'Booking service',
      currency: 'Currency',
      bookingFrom: 'Booking from',
      paymentMethod: 'Payment method',
      paymentStatus: 'Payment status',
      bookingType: 'Booking type',
      ratingType: 'Rating type',
      stars: 'Rating',
      mDispatcherType: 'mDispatcher type',
      mDispatcher: 'mDispatcher name',
      campaign: 'Campaign',
      promocode: 'Promo code',
      canceledBy: 'Canceled by',
      timezone: 'Timezone',
      month: 'Month',
      year: 'Year',
      view: 'View',
      action: 'Action',
      status: 'Status',
      module: 'Module',
      driverType: 'Type',
      vehicleType: 'Vehicle type',
      serviceType: 'Service type',
      expireDate: 'Expiry date',
      rideSharing: 'Ride sharing',
      rideService: 'Ride service',
      settlement: 'Settlement',
      rideServiceItem: {
        all: 'All',
        regulation: 'Regulation',
        rideSharing: 'Ride-sharing',
        Support_service: 'Support service',
        SOS: 'SOS',
        Non_SOS: 'Non-SOS',
        Corporate: 'Organization',
        Individual: 'Individual',
        On_curb: 'On curb',
        Meet_Greet: 'Meet & Greet',
        Close: 'Close',
        auto_refrest_cue: 'Auto-refresh ({0} seconds)',
        Ride_type: 'Ride type',
        Edit_total_note: 'Note: subtotal, tech fee, booking fee, tax and tip will be updated when changing the total amount',
        Booking: 'Booking',
        Edit_fare: 'Edit fare',
        Adjust_price: 'Adjust price',
        Total: 'Total',
        Reason: 'Reason',
        Please_enter_reason: 'Please enter reason.',
        Save: 'Save',
        Cancel: 'Cancel'
      },
      newbooking: {
        Pick_up_time: 'Pickup time',
        Drop_off_time: 'Drop off time',
        New_Booking: 'New Booking',
        GoogleMapRoute: 'Google Map',
        BOOKING_INFORMATION: 'BOOKING INFORMATION',
        TIP_PROMO: 'TIP - PROMO',
        Pick_up: 'Departure',
        Enter_a_location: 'Enter a location',
        Enter_3rd_party_location: 'Enter 3rd party location',
        Destination: 'Destination',
        Pickup_time: 'Departure time',
        Note: 'Note',
        TRAVELER_INFORMATION: 'TRAVELER INFORMATION',
        Traveler_type: 'Traveler type',
        Individual: 'Individual',
        Corporation: 'Organization',
        Phone_number: 'Phone number',
        First_name: 'First name',
        Last_name: 'Last name',
        Email_address: 'Email address',
        Corp_id: 'Organization id',
        Manager_name: 'Manager\'s name',
        Manager_email: 'Manager\'s email',
        Cost_centre: 'Cost centre',
        Department: 'Department',
        Corp_division: 'Organization division',
        Tip: 'Tip',
        Promo_code: 'Promo code',
        Apply: 'Apply',
        Promo: 'Promo:',
        DISPATCH: 'DISPATCH',
        Auto_dispatch: 'Auto dispatch',
        Assign_driver: 'Assign driver',
        Driver: 'Driver',
        PAYMENT_METHOD: 'PAYMENT METHOD',
        TRIP_ESTIMATE: 'TRIP ESTIMATE',
        Choose_a_stored_card: 'Choose a stored card',
        Use_new_card: 'Use new card',
        Card_holder: 'Card holder',
        Card_number: 'Card number',
        Expired_date: 'Expire date(mm/yyyy)',
        ccv: 'CVV',
        Distance: 'Distance',
        Duration: 'Duration',
        Fare: 'Fare',
        Route: 'Route',
        Create: 'Create',
        Cancel: 'Cancel',
        Meet_driver: 'Meet driver',
        Airline: 'Airline',
        Flight: 'Flight #',
        FLY_INFO: 'FLIGHT INFO',
        Ride_Sharing: 'Ride-Sharing',
        Round_trip: 'Round trip',
        Car_type: 'Vehicle type',
        Address: 'Address',
        City: 'City',
        State: 'State',
        Zip_code: 'Zip code',
        Remove: 'Remove',
        Client_case_matter: 'Client case matter / Trip description',
        Charge_code: 'Charge code / Trip code',
        CANCEL_BOOKING: 'CLOSE BOOKING CONFIRM',
        CANCEL_BOOKING_CONFIRM: 'Do you want to leave this form? You will lose all the booking data.',
        Search_here: 'Search here',
        No_support_location: 'Unfortunately, the service is not available in this area',
        Closest_driver_message: 'Closest driver is approximately {0} away',
        No_cars_available: 'No car available within dispatch radius',
        processed_by_affiliate: 'Your requests will be processed by our affiliate partners',
        FlightExample: 'Flight #, e.g AA1097',
        Yes: 'Yes',
        No: 'No',
        dispatch_3rd: 'Dispatch via 3rd party',
        Company: 'Clients',
        Card_required: 'Please select or add a card!',
        Tip_value: 'N/A',
        Calendar: 'Calendar',
        Extra_Destination: 'Additional Destination',
        Company: 'Clients',
        Driver_credit_limited_balance_warning: 'This driver\'s balance is less than or equal limited amount',
        Meet_Greet_option: 'Meet & Greet',
        On_Curb_option: 'On Curb',
        Extra_Services: 'Additional Services',
        Addl_Services: "Add'l services",
        Save: 'Save',
        Cancel: 'Cancel',
        Calendar: 'Calendar',
        Addl_Services: "Add'l Services",
        Please_fill_in_all_the_required_fields: 'Please fill in all the required fields.'
      },
      bookingdetail: {
        Booking_detail: 'Booking detail',
        creditTransactionFee: 'Credit card fees',
        BOOKING_INFORMATION: 'BOOKING INFORMATION',
        TIP_PROMO: 'TIP - PROMO',
        Pick_up: 'Departure*',
        Enter_a_location: 'Enter a location',
        Destination: 'Destination',
        Pickup_time: 'Departure time',
        Notes: 'Notes',
        TRAVELER_INFORMATION: 'TRAVELER INFORMATION',
        Traveler_type: 'Traveler type',
        Individual: 'Individual',
        Corporation: 'Organization',
        Phone_number: 'Phone number',
        First_name: 'First name',
        Last_name: 'Last name',
        Email_address: 'Email address',
        Corp_id: 'Organization id',
        Manager_name: 'Manager\'s name',
        Manager_email: 'Manager\'s email',
        Cost_centre: 'Cost centre',
        Department: 'Department',
        Corp_division: 'Organization division',
        Tip: 'Tip',
        Promo_code: 'Promo code',
        Apply: 'Apply',
        Promo: 'Promo:',
        DISPATCH: 'DISPATCH',
        Auto_dispatch: 'Auto dispatch',
        Assign_driver: 'Assign driver',
        Driver: 'Driver',
        PAYMENT_METHOD: 'PAYMENT METHOD',
        Payment_methods: 'Payment methods',
        TRIP_ESTIMATE: 'TRIP ESTIMATE',
        Choose_a_stored_card: 'Choose a stored card',
        Use_new_card: 'Use new card',
        Card_holder: 'Card holder',
        Card_number: 'Card number',
        Expired_date: 'Expire date(mm/yyyy)',
        ccv: 'CVV',
        Distance: 'Distance',
        Duration: 'Duration',
        Fare: 'Fare',
        Route: 'Route',
        Meet_driver: 'Meet driver',
        Airline: 'Airline',
        Flight: 'Flight #',
        FLY_INFO: 'FLIGHT INFO',
        Ride_Sharing: 'Ride-Sharing',
        Round_trip: 'Round trip',
        Car_type: 'Vehicle type',
        Address: 'Address',
        City: 'City',
        State: 'State',
        Zip_code: 'Zip code',
        Remove: 'Remove',
        New_Booking: 'New Booking',
        Update_Booking: 'Update Booking',
        Cancel_Booking: 'Cancel Booking',
        Complete_with_payment: 'Complete With Payment',
        Incident: 'Incident',
        CONFIRM_CANCEL: 'CONFIRM CANCEL',
        CONFIRM_CANCEL_MESSAGE: 'Please click \'Ok\' to cancel this booking. If you don\'t want to, click \'Cancel\' to close the dialogue box',
        CONFIRM_UPDATE: 'CONFIRM UPDATE',
        CONFIRM_UPDATE_MESSAGE: 'Are you sure you want to update this booking?',
        Close: 'Close',
        Update_info: 'Update info',
        Update_info_dispatch: 'Update info & dispatch',
        BOOKING: 'BOOKING',
        DROPPED_OFF: 'DROPPED OFF',
        Payment_Type: 'Payment Type',
        Total: 'Total',
        Next: 'Next',
        Pay_here: 'Pay here',
        Cancel: 'Cancel',
        Back: 'Back',
        Tax: 'Tax',
        Booking_fees: 'Booking fees',
        Tech_fees: 'Tech fees',
        Subtotal: 'Subtotal',
        Other_fees: 'Other fees',
        Toll_fees: 'Toll fees',
        Heavy_traffic: 'Heavy traffic',
        Rush_hour: 'Surcharge',
        Basic_fare: 'Basic fare',
        Ok: 'Ok',
        Input_reason: 'Input reason',
        Client_case_matter: 'Client case matter / Trip description',
        Charge_code: 'Charge code / Trip code',
        Enter_3rd_party_location: 'Enter 3rd party location',
        Company: 'Clients',
        Check_promo_error: 'Check promoCode error!',
        Add_new_credit_error: 'Add new credit error!',
        Can_not_get_fare_setting: 'Cannot get fare setting!',
        Can_not_get_affilate_fare_setting: 'Cannot get Affiliate fare settings!',
        Can_not_check_commisson: 'cannot check commisson!',
        No_flight_info: 'No flight info!',
        Socket_server_disconnected: 'Socket server disconnected!',
        Please_reload_page_or_check_your_connection: 'Please reload page or check your connection!',
        View_Logs: 'View logs',
        Bookingid: 'Booking #',
        Calendar: 'Calendar',
        Extra_Destination: 'Additional Destination',
        Company: 'Company',
        Meet_Greet_option: 'Meet & Greet',
        On_Curb_option: 'On Curb',
        Credit_Card: 'Credit card',
        Charge: 'Charge',
        No_charge: 'No charge',
        QR_Code_method_not_allow_error_message: 'Please select another payment type as QRCode is not available to pay on dashboard!',
        change_payment_method_note: 'If you want to change payment method, please go back to booking form.'
      },
      map: {
        Enter_a_location: 'Enter a location',
        All_company: 'All suppliers',
        All_cars: 'All vehicles',
        Show_all_cars: 'Show all vehicles',
        Hide_all_cars: 'Hide all vehicles',
        Vacant: 'Available',
        Dispatching: 'Dispatching',
        Driver_on_the_way: 'Driver on the way',
        Arrived_and_waiting: 'Arrived and waiting',
        P_O_B: 'P.O.B',
        Dropped_off: 'Dropped off',
        Waiting_for_process: 'Waiting for process',
        Unavailable: 'Unavailable',
        Total_Drivers: 'Total Drivers',
        Estimate: 'Estimate',
        Destination: 'Destination',
        Pickup: 'Pickup',
        Start_point: 'Start point',
        Passenger: 'Passenger',
        Driver_name: 'Driver name',
        Search_car_here: 'Search vehicle here',
        Error_load_booking: 'Cannot load the booking, Please try again or refresh page!',
      },
      customer: {
        Customers: 'Travelers',
        ADD_CUSTOMER: 'ADD TRAVELER',
        EDIT_CUSTOMER: 'EDIT TRAVELER',
        DETAIL_CUSTOMER: 'DETAIL TRAVELER',
        Add: 'Add',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        Delete: 'Delete',
        Import: 'Import',
        View_Message: 'Message',
        Send_Message: 'Send Message',
        Export_to_excel: 'Export to excel',
        Filter: 'Filter',
        Apply: 'Apply',
        Clear: 'Clear',
        Name: 'Name',
        Phone_Number: 'Phone Number',
        Email: 'Email',
        Vip: 'Vip',
        File: 'File',
        Number_successful_imports: 'Number of successful imports: ',
        Number_skipped: 'Number of skipped: ',
        Number_duplicate_phone_numbers: 'Number of duplicate phone numbers: ',
        Number_wrong_phone_numbers: 'Number of wrong phone numbers: ',
        Corporate_Name: 'Corporation',
        Registered_From: 'Registered From',
        Registration_Date: 'Registration Date',
        Registration_date_from: 'Registration date from',
        Registration_date_to: 'Registration date to',
        IOS_Last_Login_Version: 'IOS Last-Login Version',
        Android_Last_Login_Version: 'Android Last-Login Version',
        Last_Active_Date: 'Last Active Date',
        Days_Since_Last_Active: 'Days Since Last Active',
        Completed_Bookings: 'Completed Bookings',
        Status: 'Status',
        Actions: 'Actions',
        Action: 'Action',
        Inactive: 'Inactive',
        Active: 'Active',
        Edit: 'Edit',
        View: 'View',
        ALERT_SELECT_ACTIVATE: 'Please select traveler to activate.',
        ALERT_SELECT_DEACTIVATE: 'Please select traveler to deactivate.',
        ALERT_SELECT_DELETE: 'Please select traveler to delete.',
        CONFIRM_DELETE: 'Do you want to delete this traveler?',
        CONFIRM_DELETE_MULTI: 'Do you want to delete these traveler? Only traveler who are inactive and not a '
          + 'registered user via app can be deleted.',
        CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these travelers?',
        CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these travelers?',
        CONFIRM_EXPORT_TO_EXCEL: 'Are you sure you want to export all data as a .xlsx file?',
        // Add, Edit traveler
        Add_card_top_up_success: 'Prepaid card has been topped up successfully',
        Add_card_success: 'The card has been added successfully!',
        Add_customer_success: 'Add traveler success',
        Update_customer_success: 'Update traveler success',
        Create_customer_fail: 'Create traveler fail',
        Update_customer_fail: 'Update traveler fail',
        ERROR_EXISTED_PHONE: 'This phone has been existing on the system. Please input another one',
        ERROR_INPUT_FIRSTNAME: 'Please input first name.',
        ERROR_INPUT_PHONE: 'Please input phone number.',
        ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
        ERROR_INPUT_VALIDEMAIL: 'Please enter a valid email address.',
        CONFIRM_DELETE_CARD: 'Are you sure you want to delete this cards?',
        First_name: 'First name',
        Last_name: 'Last name',
        Phone_number: 'Phone number',
        Email_address: 'Email address',
        Vip: 'Vip',
        Notes: 'Notes',
        Account: ' Account',
        Card_management: 'Card management',
        Home: 'Home',
        Affiliate: 'Affiliate',
        SHOW_PERSONAL_CARD: 'SHOW PERSONAL CARD',
        ADD_PERSONAL_CARD: 'ADD PERSONAL CARD',
        SHOW_mDISPATCHER_CARD: 'SHOW mDISPATCHER CARD',
        ADD_mDISPATCHER_CARD: 'ADD mDISPATCHER CARD',
        Personal_card: 'Personal card',
        mDispatcher_card: 'mDispatcher card',
        Card_holder: 'Card holder ',
        Card_number: 'Card number',
        Expired_date: 'Expired date ',
        CVV: 'CVV',
        Address: 'Address',
        Country: 'Country',
        City: 'City',
        State: 'State',
        Zip_code: 'Zip code',
        Required_fields: 'Required fields',
        Cancel: 'Cancel',
        Save: 'Save',
        // Add, Delete Card
        ERROR_INPUT_CARD_HOLDER: 'Please input card holder',
        ERROR_INPUT_CARD_NUMBER: 'Please input card number',
        ERROR_INPUT_EXPIRED_DATE: 'Please input expired date',
        ERROR_VAILD_EXPIRED_DATE: 'Expired date is not valid',
        ERROR_INPUT_CVV: 'Please input CVV',
        ERROR_INPUT_ADDRESS: 'Please input address',
        ERROR_INPUT_CITY: 'Please input city',
        ERROR_INPUT_ZIPCODE: 'Please input zip code',
        ERROR_INPUT_COUNTRY: 'Please select country',
        Info_edit_error: 'Error!',
        Gender: 'Gender',
        Select_Gender: 'Select',
        Male: 'Male',
        Female: 'Female',
        Date_of_birth: 'Date of birth',
        Street_address: 'Street address',
        City: 'City',
        State: 'State',
        Zip_code: 'Zip code',
        Country: 'Country',
        Select_Zone: 'Select Zone',
        ERROR_GET_GATEWAYS: 'ERROR !!! Get Gateways',
        Require_email: 'Please enter the email before adding new card',
        Require_lastName: 'Please enter the last name before adding new card',
        Require_phone_number: 'Please enter the traveler\'s phone number before make a booking with credit card',
        IMPORT_CUSTOMER_SUCCESS: 'import customers successfully',
        No_card: 'No card found',
        No_Support: 'New card adding is currently not supported in this area',
        Card_Management: 'Card Management',
        DETAIL_CUSTOMER: 'Customer Details',
        Select_Country: 'Select country'
      },
      table_header: {
        Items_per_page: 'Items per page',
        Page: 'Page',
        Search_here: 'Search here',
        Shown_Active: '{0} - {1} of {2} shown ',
        Active: '{0} Active ',
      },
      driver: {
        Drivers: 'Drivers',
        Add: 'Add',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        Delete: 'Delete',
        View_Message: 'Message',
        Send_Message: 'Send Message',
        Driver_Name: 'Driver Name',
        Driver_ID: 'ID# / National IC#',
        Username: 'Username',
        Phone_Number: 'Phone Number',
        Driver_Type: 'Driver Type',
        TOP: 'TOP',
        Company: 'Supplier',
        Driver_License_Number: 'Driver License Number',
        License_Plate: 'License Plate',
        Car_Type: 'Vehicle Type',
        Zone: 'Zone',
        Registered_From: 'Registered From',
        Registration_Date: 'Registration Date',
        IOS_Last_Login_Version: 'IOS Last-Login Version',
        Android_Last_Login_Version: 'Android Last-Login Version',
        Last_Active_Date: 'Last Active Date',
        Days_Since_Last_Active: 'Days Since Last Active',
        Completed_bookings: 'Completed bookings',
        Status: 'Status',
        Actions: 'Actions',
        Action: 'Action',
        Inactive: 'Inactive',
        Active: 'Active',
        Edit: 'Edit',
        View: 'View',
        ALERT_SELECT_ACTIVATE: 'Please select driver to activate.',
        ALERT_SELECT_DEACTIVATE: 'Please select driver to deactivate.',
        ALERT_SELECT_DELETE: 'Please select driver to delete.',
        CONFIRM_DELETE: 'Do you want to delete this driver?',
        CONFIRM_DEACTIVATE_MULTI: 'Are you sure you want to deactivate the selected driver(s)? Their connected'
          + 'hardware meter(s) will be also disabled. Only driver(s) who are having no current trip or reservation can be deactivated.',
        CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these drivers? Only driver(s) who are completely setup for operation can be activated.',
        CONFIRM_DELETE_MULTI: 'Do you want to delete these drivers? Just only driver in the inactive status can be deleted.',
        // Add, Edit Driver
        ADD_DRIVER: 'ADD DRIVER',
        Avatar: 'Avatar',
        Individual_Driver: 'Individual Driver',
        Owner_Operator: 'Owner Operator',
        CONTACT_INFO: 'CONTACT INFO',
        Home_address: 'Home address',
        Driver_Type: 'Driver Type',
        First_name: 'First name',
        Last_name: 'Last name',
        Phone_number: 'Phone number',
        Email_address: 'Email address',
        City: 'City',
        Select_city: 'Select city',
        State: 'State',
        Zip_code: 'Zip code',
        Date_of_birth: 'Date of birth',
        City_driver_want_to_drive: 'City driver want to drive',
        Driver_license_number: 'Driver license number',
        Driver_license_state: 'Driver license state/Province',
        Driver_license_expiry: 'Driver license expiry',
        FOR_INSPECTOR: 'FOR INSPECTOR',
        Document: 'Document',
        Link: 'Link',
        Notes: 'Notes',
        UPLOAD_DOCUMENT: 'UPLOAD DOCUMENT',
        Click_here_to_download: 'Click here to download.',
        EXTENSION_REQUIREMENT: 'The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip and less than 5Mb.',
        Invalid_file: 'File content is invalid',
        File_size_larger_than_5Mb: 'File size larger than 5Mb',
        // Bank Account
        BANK_ACCOUNT: 'BANK ACCOUNT',
        Name_of_bank: 'Name of bank',
        Name_of_account: 'Account holder\'s name',
        First_M_Last: 'First M. Last',
        RTBnumber: 'Routing/Transit/Branch number',
        Account_number: 'Account number',
        IBAN_number: 'IBAN number',
        Check_number: 'Check number',
        Swift_code_number: 'Swift code number',
        SSNNI: 'SSN/ NI',
        Verification_document: 'Verification document',
        OPERATION: 'OPERATION',
        CAR_INSURANCE: 'VEHICLE INSURANCE',
        Policy_number: 'Policy number',
        Effective_date: 'Effective date',
        Expired_date: 'Expired date',
        Select_company: 'Select supplier',
        Car: 'Vehicle',
        Existing_car: 'Existing vehicle',
        New_car: 'New vehicle',
        License_plate: 'License plate',
        Select_license_plate: 'Select license plate',
        Car_Type: 'Vehicle Type',
        Select_Car_Type: 'Select Vehicle Type',
        Make: 'Make',
        Select_Car_Make: 'Select Vehicle Make',
        Model: 'Model',
        Select_Car_Model: 'Select Vehicle Model',
        Year: 'Year',
        Select_Year: 'Select Year',
        Color: 'Color',
        Passengers: 'Passengers',
        Luggage: 'Luggage',
        Phone_Number: 'Phone number',
        License_expiry: 'License expiry',
        Shift: 'Shift',
        Select_Shift_Template: 'Select Shift Template',
        Fleet_commission: 'Supplier commission (report)',
        Percentage: 'Percentage',
        Amount: 'Amount',
        transaction: 'Transaction',
        Terminal_ID: 'Terminal ID',
        Auth_key: 'Auth key',
        CREDIT_DEPOSIT: 'Credit wallet',
        Credit_Balance: 'Credit Balance',
        Balance: 'Balance',
        Change_balance: 'Change balance',
        Select_balance: 'Select balance',
        Adjust_amount: 'Adjust amount',
        Adjust_value: 'Adjust value',
        New_point: 'New point',
        New_balance: 'New balance',
        Enter_reason: 'Enter reason',
        ERROR_INPUT_BALANCE: 'Please select balance.',
        ERROR_INPUT_AMOUNT: 'Please input this value.',
        ERROR_INPUT_REASON: 'Please enter reason.',
        ERROR_INPUT_VALID_AMOUNT: 'Please enter a value greater than or equal to 0.',
        INFO_ADD_BALANCE_SUCCESS: 'Driver balance has been updated successfully',
        INFO_UPDATE_POINT_SUCCESS: 'Passenger point has been updated successfully',
        Force_meter: 'Force meter',
        Force_meter_tooltip: 'Driver app must be connected to the meter before operating. Applied for Pulsar & Centrodyne\'s meters',
        ERROR_EXISTED_PHONE: 'This phone has been existing on the system. Please input another one',
        TOP_driver: 'TOP driver',
        Ride_sharing: 'Ride-sharing',
        Note_password: 'Note: For new driver account, the default password is "password"',
        Required_fields: 'Required fields',
        Cancel: 'Cancel',
        Save: 'Save',
        ERROR_INPUT_USERNAME: 'Please input username.',
        ERROR_INPUT_VALID_USERNAME: 'Username may only contain lower letters & numbers and must be between 3 and 20 characters long',
        ERROR_INPUT_PHONE: 'Please specify a valid phone number.',
        ERROR_INPUT_VALID_EMAIL: ' Please enter valid email.',
        ERROR_INPUT_FRISTNAME: 'Please input first name.',
        ERROR_INPUT_LASTNAME: 'Please input last name.',
        ERROR_INPUT_HOME_ADDRESS: 'Please input home address.',
        ERROR_INPUT_CITY: 'Please input city.',
        ERROR_INPUT_STATE: 'Please input state.',
        ERROR_INPUT_ZIPCODE: 'Please input zipcode.',
        ERROR_INPUT_BIRTHDAY: 'Please input birthday.',
        ERROR_INPUT_NAME_OF_BANK: 'Please input name of bank.',
        ERROR_INPUT_NAME_OF_ACCOUNT: 'Please input account holder\'s name.',
        ERROR_INPUT_ROUTING_NUMBER: 'Please input routing number.',
        ERROR_INPUT_ACCOUNT_NUMBER: 'Please input account number.',
        ERROR_INPUT_CHECK_NUMBER: 'Please input check number.',
        ERROR_INPUT_SSN: 'Please input SSN.',
    ERROR_INPUT_DocumentId: 'Please input Document ID.',
        ERROR_INPUT_COMPANY: 'Please select supplier to activate.',
        ERROR_INPUT_LICENSE_PLATE: 'Please choose license plate.',
        ERROR_INPUT_ZONE: 'Please select zone to activate.',
        ERROR_INPUT_SHIFT: 'Please select shift to activate.',
        ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS: 'Must contain only numbers.',
        ERROR_SSN_ONLY_NUMBERS: 'Must contain only numbers.',
        ERROR_SSN_LENGTH: 'SSN must be at least 4 characters',
        ERROR_COMMISSION_NUMBERS: 'Please enter a valid number',
        INFO_DEACTIVAED_SUCCESS: 'The driver has been deactivated successfully.',
        INFO_ACTIVAED_SUCCESS: 'The driver has been activated successfully.',
        INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE: 'The driver has been deactivated but the integrated hardware meter is cannot be turned off for some unexpected reasons.',
        INFO_MULTIDEACTIVAED_SUCCESS: 'The {0} selected drivers have been successfully deactivated.',
        INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS: 'The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled.',
        INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE: 'The {0} selected drivers have been successfully deactivated. {1} connected hardware meter(s) were disabled. {2} were not due to some unexpected reasons.',
        INFO_MULTIACTIVAED_SUCCESS: 'The {0} selected drivers have been successfully activated.',
        DRIVER_UPDATE_SUCCESS: 'Driver has been updated successfully.',
        DRIVER_CREATE_SUCCESS: 'Driver info has been successfully added',
        Expiry_Date: 'Expiry Date',
        Case_number: 'Case number',
        Export_to_csv: 'Export to CSV',
        CONFIRM_EXPORT_TO_CSV: 'Are you sure you want to export all data as a .csv file?',
        Ride_sharing_tooltip: 'Ride-sharing is an advanced feature, please contact our support Team to get more info'
      },
      corporate: {
        Corporates: 'Clients',
        Add: 'Add',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        Delete: 'Delete',
        Company_Name: 'Client name',
        Admin_Name: 'Admin Name',
        Username: 'Username',
        Phone_Number: 'Phone Number',
        Email: 'Email',
        Registered_From: 'Registered From',
        Registration_Date: 'Registration Date',
        Status: 'Status',
        Actions: 'Actions',
        Action: 'Action',
        Inactive: 'Inactive',
        Active: 'Active',
        In_Review: 'In Review',
        Edit: 'Edit',
        View: 'View',
        ADD_CORPORATE_CARD: 'ADD CLIENT CARD',
        CONFIRM_DELETE_CARD: 'Are you sure you want to delete this cards?',
        ALERT_SELECT_ACTIVATE: 'Please select client to activate.',
        ALERT_SELECT_DEACTIVATE: 'Please select client to deactivate.',
        ALERT_SELECT_DELETE: 'Please select client to delete.',
        CONFIRM_DELETE: 'Do you want to delete this client?',
        CONFIRM_DELETE_USER: 'Do you want to delete this client user?',
        CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these clients?',
        CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these clients?',
        CONFIRM_DELETE_MULTI: 'Do you want to delete these clients?\n ** Only clients who are inactive can be deleted.',
        // add
        ERROR_INPUT_PHONE: 'Please specify a valid phone number.',
        ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
        ERROR_EXISTED_PHONE: 'This phone has been existing on the system. Please input another one',
        Account: 'Account',
        Corp_traveler: 'Tripper',
        Payment_methods: 'Payment methods',
        Corp_operators: 'Client operators',
        Pricing: 'Pricing',
        COMPANY_INFORMATION: 'CLIENT INFORMATION',
        Company_name: 'Client name',
        Company_ID: 'Client ID (for client traveler signup)',
        Company_address: 'Client address',
        Invoice_email: 'Invoice email',
        Confirmation_email: 'Confirmation email',
        ADMIN_ACCOUNT: 'ADMIN ACCOUNT',
        First_name: 'First name',
        Last_name: 'Last name',
        Commission: 'Commission',
        Required_fields: 'Required fields',
        Save: 'Save',
        Cancel: 'Cancel',
        Reset_password: 'Reset password',
        Employee_Name: 'Employee Name',
        Created_Date: '	Created Date',
        Add_corporate_card: 'Add client card',
        Corp_Users: 'Client Users',
        Pricing_Default: 'Default (same pricing plan as normal pax)',
        Pricing_Discount: 'Discount by percentage (applied for client traveler only)',
        Pricing_Add_different_rates: 'Add different rates',
        ERROR_INPUT_COMPANYNAME: 'Please input client name.',
        ERROR_INPUT_USERNAME: 'Please input username.',
        ERROR_INPUT_FIRSTNAME: 'Please input first name.',
        ERROR_INPUT_EMAIL: 'Please input email.',
        ERROR_INPUT_VALID_EMAIL: 'Please enter a valid email address.',
        ERROR_INPUT_VALID_NUMBER: 'Please enter valid number',
        ERROR_INPUT_VALID_RANGE_AMOUNT: 'Please enter a value between {0} and {1}.',
        INFO_ADD_CORPORATE_SUCCESS: 'Account {0} has been created successfully. Please check the email {1} for login credentials details.',
        INFO_UPDATE_CORPORATE_SUCCESS: 'Client has been updated successfully.',
        INFO_UPDATE_PRICING_SUCCESS: 'Update Pricing Successful.',
        INFO_RESET_PASSWORD_SUCCESS: 'Account {0} has been reset successfully. Please check the email {1} for login credentials details.',
        COMFIRM_RESET_PASSWORD: 'Do you want to reset password?',
        Traveler_signature: 'Traveler signature',
        Tracking_log: 'Tracking log',
        Rating: 'Rating',
        // corp traveler
        Email_address: 'Email address',
        Assistant_email: 'Assistant email',
        Corp_PO: 'Client PO',
        Cost_centre: 'Cost centre',
        Corp_division: 'Client division',
        Corp_id: 'Client id',
        Department: 'Department',
        Manager_email: 'Manager\'s email',
        Manager_name: 'Manager\'s name',
        Corporate_card: 'Client card',
        Direct_invoicing: 'Direct invoicing',
        Corporate_Prepaid: 'Client Prepaid',
        Cash: 'Cash',
        Car_Type_ID: 'Vehicle Type ID',
        App_Display_Name: 'App Display Name',
        Normal_Fare: 'Normal Fare',
        Flat_Fare: 'Flat Fare',
        Hourly_Daily_Rate: 'Hourly/ Daily Rate',
        Assign_rate: 'Assign rate',
        Zone: 'Zone',
        Rate: 'Rate',
        Zone_Rate: 'Zone - Rate',
        Please_select_rate: 'Please select rate',
        Paid_by: 'Paid by',
        Enter_new_credit_card: ' Enter new credit card ',
        TOP_UP: 'TOP UP',
        Top_up: 'Top up',
        Topup_amount: 'Topup amount',
        Currency: 'Currency',
        ERROR_INPUT_AMOUNT: 'Please input valid amount.',
        VAT_number: 'VAT number',
        Gender: 'Gender',
        Select_Gender: 'Select',
        Male: 'Male',
        Female: 'Female',
        Date_of_birth: 'Date of birth',
        Street_address: 'Street address',
        City: 'City',
        State: 'State',
        Zip_code: 'Zip code',
        Country: 'Country',
        adding_new_card: 'You are adding a new card to use service in %{zoneName} area',
        No_Support: 'New card adding is currently not supported in this area',
        Username: 'Username'
      },
      message: {
        Add: 'Add',
        Delete: 'Delete',
        Items_per_page: 'Items per page',
        Page: 'Page',
        Search_here: 'Search here',
        Shown_Active: '{0} - {1} of {2} shown',
        Date: 'Date',
        Subject: 'Subject',
        To: 'To',
        Type: 'Type',
        Result: 'Result',
        Actions: 'Actions',
        New_message: 'New message',
        MESSAGE_DETAILS: 'MESSAGE DETAILS',
        All: 'All',
        Company: 'Company',
        Active: 'Active',
        In_Active: 'Inactive',
        In_Review: 'In Review',
        Send_to: 'Send to',
        All_drivers: 'All drivers',
        All_customers: 'All travelers',
        Customized_driver: 'Customized driver',
        Customized_customer: 'Customized traveler',
        Receiver_list: 'Receiver list',
        Content: 'Content',
        Required_fields: 'Required fields',
        Search_driver_name_driver_phone_number: 'Search driver name, driver phone number',
        Search_customer_name_customer_phone_number: 'Search traveler name, traveler phone number',
        Search_car_type: 'Search vehicle type',
        Searching: 'Searching...',
        No_results: 'No results',
        Send: 'Send',
        Cancel: 'Cancel',
        SMS_failed_Inbox_succeeded: 'SMS failed, Inbox succeeded',
        Failed: 'Failed',
        Succeeded: 'Succeeded',
        CONFIRM_DELETE: 'Do you want to delete this message?',
        CONFIRM_DELETE_MULTI: 'Do you want to delete these messages?',
        ALERT_SELECT_DELETE: 'Please select message to delete.',
        ERROR_INPUT_RECEIVERLIST: 'Please choose at least one driver to send message',
        ERROR_INPUT_SUBJECT: 'Please input subject',
        ERROR_INPUT_CONTENT: 'Please input content',
        import_fail_success: '%{success} driver(s) has been imported successfully, %{fail} failed.',
        CONFIRM_SEND_SUCCESS: 'Your message has been sent. This may take several minute. Please wait while the system is processing your request.',
        Load_message_and_sms_fail: 'The errors occurred while loading message and SMS details',
        Update_message_and_sms_fail: 'The errors occurred while updating message and SMS details',
        Update_message_and_sms_success: 'Message and SMS details has been updated successfully',
        Driver_List: 'Driver List',
        Car_Type_List: 'Vehicle type list',
        Search_vehicle_type_name: 'Search Vehicle Type Name',
        ALERT_PROCESSING_DELETE: 'Your request is being processed. This could take several seconds',
      },
      fleetInfo: {
        Fleet_name: 'Organization name',
        Phone_number: 'Phone number',
        Email_address: 'Email address',
        Country: 'Country',
        Time_zone: 'Time zone',
        Address: 'Address',
        Website: 'Website',
        Currency: 'Currency',
        Distance_unit: 'Distance unit',
        Load_fleet_info_fail: 'The errors occurred while loading fleet info'
      },
      generalSetting: {
        alertNotifications: {
          title: 'Alert notifications',
          emails: 'Emails',
          emailsPlaceholder: 'Enter email',
          slack: 'Slack Channel (Instructions)',
          slackPlaceholder: 'Slack POST URL',
          discord: 'Discord Channel (Instructions)',
          discordPlaceholder: 'Enter Discord Channel',
          telegram: 'Telegram Channel (Instructions)',
          telegramPlaceholder: 'Enter Telegram Channel',
        },
        AM: 'AM',
        PM: 'PM',
        RUSH_HOUR: 'Surcharge',
        Name: 'Name',
        Date: 'Date',
        Mon: 'Mon',
        Tue: 'Tue',
        Wed: 'Wed',
        Thu: 'Thu',
        Fri: 'Fri',
        Sat: 'Sat',
        Sun: 'Sun',
        Time_range: 'Time range',
        From: 'From',
        To: 'To',
        Rush_hour_time_calculation: 'Surcharge time calculation',
        Rush_hour_surcharge: 'Surcharge',
        Required_fields: 'Required fields',
        Save: 'Save',
        Cancel: 'Cancel',
        MDISPATCHER_CANCELLATION: 'Traveler cancellation',
        Book_now: 'Book now',
        Duration: 'Duration',
        Times: 'Times',
        Book_later: 'Book later',
        MDISPATCHER_NO_SHOW: 'TRAVELER NO SHOW',
        AIRPORT_FEE: 'AIRPORT FEE',
        From_airport: 'From airport',
        To_airport: 'To airport',
        MEET_DRIVER: 'MEET DRIVER',
        On_curb: 'On curb',
        Meet_Greet: 'Meet & Greet',
        Add: 'Add',
        Edit: 'Edit',
        DELETE_SERVICE: 'Delete service',
        DELETE_CONFIRM: 'Do you want to delete this item?',
        HEAVY_TRAFFIC: 'HEAVY TRAFFIC',
        Heavy_traffic_surcharge: 'Heavy traffic surcharge',
        TOLL_FEE: 'Toll fee',
    parkingFee: 'Parking fee',
    gasFee: 'Gas fee',
        OTHER_FEES: 'OTHER FEES',
        Can_edit: 'Able to edit',
        Can_add_note: 'Able to add note',
        Other_fees_amount: 'Other fees amount',
        TAX: 'TAX',
        Tax_surcharge: 'Tax surcharge',
        TIP: 'TIP',
        Default_tip: 'Default tip',
        RESERVATION_REMINDER: 'RESERVATION REMINDER',
        Driver_app_SMS_alert_before: 'Driver app - SMS & alert before',
        Pax_app_alert_before: 'Pax app - alert before',
        Sound_notification: 'Sound notification',
        Advance_information: 'Advance information corporate traveller',
        Load_general_fail: 'The errors occurred while loading general setting',
        Update_general_success: 'General setting has been updated successfully',
        Update_general_fail: 'The errors occurred while updating general setting',
        Time_over_lap: 'The times ranges must not overlap with each others',
        Allow_passenger_to_register_as_corp_traveler: 'Allow passenger to register as corp traveler',
        Not_Allow_Make_Booking_Without_Assign_Fare: 'Do not allow pax to make booking if basic fare = 0.00',
        Disable_Direct_Invoicing_Receipt_Email: 'Disable direct invoicing receipt email',
        Additional_services: 'Additional Services',
        Edit_additional_services: 'Edit Additional Services',
        Service_Fee_By_Currencies: 'Service fee',
        Service_type: 'Service Type',
        Optional: 'Optional',
        Compulsory: 'Compulsory',
        Vehicle_type: 'Vehicle Type',
        Adding_additional_service_success: 'Add service success',
        Update_additional_service_success: 'Update service success',
        Adding_additional_service_fail: 'Add Service fail',
        Update_additional_service_fail: 'Update service fail',
        Other_settings: 'Other settings',
        Operator_assign_error: 'Operator assign errors',
        Create_surcharge_success: 'Create surcharge success',
        Update_surcharge_success: 'Update surcharge success',
        Delete_surcharge_success: 'Delete surcharge success',
        Create_surcharge_fail: 'Create surcharge failed',
        Update_surcharge_fail: 'Update surcharge failed',
        Delete_surcharge_fail: 'Delete surcharge failed',
        Get_surcharge_detail_fail: 'Get surcharge detail failed',
        Repeat: 'Repeat',
        DayOfWeek: 'Day of week',
        SingleDay: 'Single day',
        Yearly: 'Yearly',
        Backup_phone_number: 'Backup phone number',
        Backup_phone_number_hint: 'Direct to this number when driver and passenger are out of operation zones',
        Apply_countdown_clock: 'Apply countdown clock',
        No_show_duration_tooltip: 'Set up time to display No Show button after driver tap on Arrived button on Driver App',
        Invalid_period_of_time: 'Invalid period of time',
        hide_Provider_Filter_From_Corporate_Board: 'Hide provider filter from corporate board',
        customer_email_is_optional: 'Customer\'s email is optional on passenger app',
        disable_hourly_rate: 'Disable hourly rate when both hourly and flat rate are available'
      },
      user: {
        Show_per_page: 'Items per page',
        Page: 'Page',
        Search_here: 'Search here',
        show_pages: '{0} - {1} of {2} shown',
        Username: 'Staff name',
        Name: 'Name',
        Phone_number: 'Phone Number',
        Email: 'Email',
        Permission: 'Permission',
        Status: 'Status',
        Actions: 'Actions',
        Active: 'Active',
        Inactive: 'Inactive',
        Edit: 'Edit',
        Deactivate: 'Deactivate',
        Delete: 'Delete',
        Add: 'Add',
        ADD_USER: 'ADD STAFF',
        First_name: 'First name',
        Last_name: 'Last name',
        AgendId: 'Staff #',
        Email_address: 'Email address',
        Address: 'Address',
        Permission: 'Permission',
        Required_fields: 'Required fields',
        Save: 'Save',
        Cancel: 'Cancel',
        EDIT_USER: 'EDIT STAFF',
        Yes: 'Yes',
        Cancel: 'Cancel',
        DELETE_USER: 'DELETE STAFF',
        DETAIL_USER: 'DETAIL STAFF',
        DELETE_CONFIRM: 'Are you sure to delete Staff?',
        Update_user_fail: 'The errors occurred while updating staff',
        Update_user_success: 'Staff has been updated successfully',
        Create_user_fail: 'The errors occurred while creating staff',
        Create_user_success: 'New staff {0} has been created successfully. Please check the email {1} for login credentials details.',
        Load_user_fail: 'The errors occurred while loading staff',
        Update_user_status_fail: 'The errors occurred while updating staff status',
        Delete_user_fail: 'The errors occurred while deleting staff',
        Delete_user_success: 'Staff has been deleted successfully',
        CONFIRM_RESET_PASSWORD: 'Do you want to reset password?',
        RESET_PASSWORD: 'Do you want to reset password?',
        Reset_password: 'Reset password',
        Select_permission: 'Select Permission',
        Reset_password_success: 'Reset password successful'
      },
      report: {
        query: {
          errorMessage: {
            bookFrom: 'Please select booking from',
            paymentMethod: 'Please select payment method',
            bookingType: 'Please select booking type',
            ratingType: 'Please select rating type',
            stars: 'Please select rating type',
            transactionType: 'Please select transaction type',
            serviceType: 'Please select service type',
            rideService: 'Please select ride service',
            invalidEmail: 'Please enter a valid email address.',
          },
          search: {
            bookingDetails: 'Search: Booking ID, Operator, Traveler phone, Traveler name, Driver name, ID# / National IC#, License plate, Queuing area, Note',
            financial: 'Search: Driver name, Phone',
            financialDriver: 'Search: Booking ID, Driver name, ID# / National IC#',
            carActivity: 'Search: License plate',
            affiliation: 'Search: Booking ID',
            prepaidTopUp: 'Search: Supplier name, Top up ID, Transaction ID',
            operatorLogs: 'Search: Operator name, User name, Description',
            corporate: 'Search: Booking ID, Driver name, Client traveler, Client case matter, Charge code',
            bookingLogs: 'Search: Booking ID, Traveler name, Driver name, ID# / National IC#',
            rating: 'Search: Booking ID, Traveler phone, Traveler name',
            mDispatcher: 'Search: Booking ID, mDispatcher name',
            totalAdjustment: 'Search: Booking ID',
            incidentCancellation: 'Search: Booking ID, Traveler phone, Traveler name',
            revenueDriver: 'Search: Driver name',
            dailyDriver: 'Search:  Driver phone, Driver name, ID# / National IC#',
            dailyCar: 'Search: License plate, Permission',
            dailyDriverCar: 'Search:  Driver phone, Driver name, ID# / National IC#, License plate',
            driverLoginStatus: 'Search:  Driver phone, Driver name, ID# / National IC#, License plate',
            bookingSummary: 'Search: Operator name, User name, Booking ID',
            creditWallet: 'Search: Supplier name, Driver name, Booking ID, Operator',
            settlement: 'Search:  Driver name, ID# / National IC#',
            driverRating: 'Search: Booking ID, Traveler phone, Traveler name'
          },
          common: {
            all: 'All',
          },
          today: 'Today',
          thisMonth: 'This month',
          lastMonth: 'Last month',
          past30Days: 'Past 30 days',
          thisYear: 'This year',
          custom: 'Custom',
          fromDate: 'From date',
          toDate: 'To date',
          driver: 'Driver',
          company: 'Supplier',
          operator: 'Operator',
          farmType: 'Farm type',
          carLicensePlate: 'Vehicle license plate',
          bookingService: 'Booking service',
          currency: 'Currency',
          bookingFrom: 'Booking from',
          paymentMethod: 'Payment method',
          paymentStatus: 'Payment status',
          bookingType: 'Booking type',
          ratingType: 'Rating type',
          stars: 'Rating',
          mDispatcherType: 'mDispatcher type',
          mDispatcher: 'mDispatcher name',
          campaign: 'Campaign',
          promocode: 'Promo code',
          canceledBy: 'Canceled by',
          timezone: 'Timezone',
          month: 'Month',
          year: 'Year',
          view: 'View',
          action: 'Action',
          status: 'Status',
          module: 'Module',
          driverType: 'Type',
          vehicleType: 'Vehicle type',
          serviceType: 'Service type',
          expireDate: 'Expiry date',
          rideSharing: 'Ride sharing',
          rideService: 'Ride service',
          rideServiceItem: {
            all: 'All',
            regulation: 'Regulation',
            rideSharing: 'Ride-sharing'
          },
          actionItem: {
            all: 'All',
            login: 'Log in',
            logout: 'Log out',
            bookIn: 'Available',
            bookOff: 'Not available',
            deny: 'Reject',
            ignore: 'Ignore',
            accept: 'Accept',
            startTrip: 'On my way',
            updateBooking: 'Update',
            arrive: 'Arrive',
            pickup: 'Pickup',
            drop: 'Drop off',
            complete: 'Complete',
            noShow: 'No show',
            rqJob: 'Dispatching',
            cancel: 'Cancel',
          },
          loginStatusItem: {
            all: 'All',
            online: 'Logging in',
            offline: 'Unlogged in'
          },
          farmTypeItem: {
            all: 'All',
            farmIn: 'Farm in',
            farmOut: 'Roaming',
            affiliationOwner: 'Affiliation owner',
            farmInAndAffiliationOwner: 'Farm in and affiliation owner',
            farmOutAndAffiliationOwner: 'Farm out and affiliation owner',
          },
          settlementDriverTypeItem: {
            all: 'All driver',
            individual: 'Individual driver'
          },
          paymentMethodItem: {
            B2BTerminal: 'B2BTerminal',
            webBooking: 'Web booking',
            pendingPayment: 'Pending payment',
            all: 'All',
            cash: 'Cash',
            personalCard: 'Personal card',
            corporateCard: 'Client card',
            qrCodePayment: 'QR code payment',
            directBilling: 'Direct invoicing',
            externalCard: 'External card',
            mDispatcherCard: 'mDispatcher card',
            corporatePrepaid: 'Client prepaid',
            canceled: 'Canceled',
            noShow: 'No Show',
            incident: 'Incident',
            fleetCard: 'External card',
            applePay: 'Apple pay'
          },
          paymentStatusItem: {
            all: 'All',
            fullPayment: 'Full payment',
            partialPayment: 'Partial payment',
            pendingPayment: 'Pending payment'
          },
          companySettlementTypeItem: {
            all: 'All',
            fleetOweCompany: 'Fleet owe company',
            companyOweFleet: 'Company owe fleet'
          },
          bookingFromItem: {
            all: 'All',
            paxApp: 'Pax app',
            mDispatcher: 'mDispatcher',
            kiosk: 'Kiosk',
            cc: 'Command Center',
            webBooking: 'Web booking',
            carHailing: 'Car-hailing',
          },
          bookingTypeItem: {
        batchDelivery: 'Batch Delivery',
            all: 'All',
            now: 'Now',
            reservation: 'Reservation',
          },
          bookingServiceItem: {
            all: 'All',
            homeFleet: 'Home booking',
            localBooking: 'Local booking',
            farmIn: 'Provide affiliate service',
            farmInAndAffiliationOwner: 'Farm in & affiliation owner',
            provideService: 'Provide affiliate service'
          },
          ratingTypeItem: {
            all: 'All',
            like: 'Like',
            dislike: 'Dislike'
          },
          starsItem: {
            all: 'All',
            star1: '1 Star',
            star2: '2 Stars',
            star3: '3 Stars',
            star4: '4 Stars',
            star5: '5 Stars'
          },
          incidentCanceledTypes: {
            all: 'All',
            incident: 'Incident',
            canceled: 'Canceled'
          },
          canceledByItem: {
            all: 'All',
            CorpAD: 'Client Admin',
            passenger: 'Passenger',
            CC: 'CC',
            mDispatcher: 'mDispatcher',
            Partner: 'Partner',
            webBooking: 'Web booking',
            timeout: 'Timeout'
          },
          bookingSummaryActionItem: {
            all: 'All',
            create: 'Create',
            update: 'Update',
            cancel: 'Cancel',
            complete: 'Complete',
          },
          transactionTypeItem: {
            bankAccount: 'Transferred to bank account',
            creditWallet: 'Transferred to credit wallet',
            netEarning: 'Net Earning',
            referralEarning: 'Referral Earning',
            all: 'All',
            topUp: 'Top up',
            editBalance: 'Edit balance',
            bookingDeduction: 'Booking deduction',
          },
          moduleItem: {
            all: 'All',
            bookings: 'Bookings',
            customers: 'Travelers',
            dispatch: 'Dispatch',
            general: 'General',
            promotion: 'Promotion',
            rate: 'Rate',
            car: 'Vehicle',
            city: 'City',
            company: 'Supplier',
            drivers: 'Drivers',
            driverSettlement: 'Driver settlement',
            login: 'Login',
            logout: 'Logout',
            mDispatcher: 'mDispatcher',
            mDispatcherType: 'mDispatcher type',
            operation: 'Operation',
            partners: 'Partners',
            permission: 'Permission',
            queuingArea: 'Queuing area',
            report: 'Report',
            shift: 'Shift',
            user: 'User',
            voiceSms: 'Voice & SMS',
          },
          serviceTypeItem: {
            all: 'All',
            oneWay: 'One way',
            fromAirport: 'From airport',
            toAirport: 'To airport',
            hourly: 'Hourly/daily',
            roundTrip: 'Round trip',
            fromAirportHourlyDaily: 'From airport - hourly/daily',
            fromAirportRoundTrip: 'From airport - round trip',
            toAirportHourlyDaily: 'To airport - hourly/daily',
            toAirportRoundTrip: 'To airport - round trip'
          }
        },
        error: {
          23005:
            'For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range',
        },
        result: {
          noResultFound: 'No result found',
          page: 'Page',
          itemsPerPage: 'Items per page',
          showPages: 'Shown %{from} - %{to} of %{total}',
          exportToExcel: 'Export to excel',
          pay: 'Pay',
          bookingDetails: {
            bookingId: 'Booking #',
            bookFrom: 'Booking from',
            bookingService: 'Booking service',
            bookingType: 'Booking type',
            serviceType: 'Service type',
            operator: 'Operator',
            customerPhone: 'Passenger phone number',
            customerName: 'Passenger name',
            corpDivision: 'Division',
            corpDept: 'Dept',
            rideSharing: 'Ride-sharing',
            queueName: 'Queuing area',
            bookingTime: 'Booking time',
            pickupTime: 'Departure time',
            dispatchTime: 'Dispatch time',
            onBoardTime: 'On board time',
            droppedOffTime: 'Drop off time',
            onBoardDuration: 'On board duration',
            dispatch: 'Dispatch #',
            completedTime: 'Completed Time',
            driverName: 'Driver name',
            driverIdPermit: 'ID# / National IC#',
            driverLicenseNumber: 'Driver license number',
            companyName: 'Supplier',
            carType: 'Vehicle Type',
            licensePlate: 'License plate',
            pickupLocation: 'Departure location',
            geoPickup: 'Departure location lat/long',
            destination: 'Destination',
            geoDestination: 'Destination lat/long',
            pickupFrom: 'Departure location from',
            distanceTour: 'P.O.B distance',
            notes: 'Notes',
            basicFareCalculator: 'Basic fare calculator',
            basicFare: 'Basic fare',
            editFare: 'Edit basic fare',
            oldBasicFare: 'Old basic fare',
            airportFee: 'Airport fee',
            meetDriver: 'Meet driver',
            rushHour: 'Surcharge',
            heavyTraffic: 'Heavy traffic',
            tollFee: 'Toll fee',
            otherFees: 'Other fees',
            subtotal: 'Subtotal',
            techFee: 'Tech fee',
            serviceFee: "Add'l services",
            bookingFee: 'Booking fee',
            salesTax: 'Sales tax',
            tipAmt: 'Tip amt',
            promoCode: 'Promo code',
            promoAmt: 'Promo amt',
            requestedTotalAmount: 'Requested total amount',
            total: 'Total amt',
            actualReceived: 'Actual received',
            differenceAmt: 'Difference (received)',
        customerDebt: 'Customer Debt',
            markupDifference: 'Markup difference',
            requestedPaymentMethod: 'Requested payment method',
            paidBy: 'Paid by',
            instructions: 'Instructions',
            additionalServiceName: 'Name',
            additionalServiceValue: 'Value',
            sendReceiptHeader: '',
            sendReceipt: 'Send receipt',
            send: 'Send',
            cancel: 'Cancel',
            sentReceiptSuccess: 'The receipt has been sent successfully',
            sentReceiptFailed: 'The receipt cannot be sent right now. Please try again.',
            coRider: 'Co-rider',
            coriderName: 'Name',
            stopAddress: 'Additional Destination',
            stopGeo: 'Lat/long'
          },
          financial: {
            driverName: 'Driver name',
            driverPhone: 'Phone number',
            driverUserName: 'Username',
            companyName: 'Supplier',
            bookingList: 'Booking list',
            totalFare: 'Total fare w/o promo',
            netEarning: 'Net Earning',
            showBookingList: 'Show'
          },
          financialDriver: {
            date: 'Date',
            bookingId: 'Booking ID',
            paidBy: 'Paid by',
            bookFrom: 'Booking from',
            bookingType: 'Booking type',
            companyName: 'Supplier',
            driverName: 'Driver',
            driverIdPermit: 'ID# / National IC#',
            totalFare: 'Total fare without promo',
            subtotal: 'Subtotal',
            transactionFee: 'Transaction fee',
            techFee: 'Tech fee',
            salesTax: 'Sales tax',
            tipAmt: 'Tip amt',
            bookingFee: 'Booking fee',
            commission: 'Commission',
            deduction: 'Deduction',
            promoAmt: 'Promo amt',
            ridePayment: 'Ride payment',
            grossEarning: 'Gross earning',
            driverDeduction: 'Driver deduction',
            netEarning: 'Net Earning',
          },
          financialCompany: {
            companyName: 'Supplier ',
            homeFleetNetEarning: 'Home fleet Net Earning',
            affiliateNetEarning: 'Affiliate Net Earning',
            totalNetEarning: 'Total Driver Net Earning',
            status: 'Status',
            companyAmount: 'Company profit',
          },
          companySettlement: {
            companyName: 'Supplier',
            pendingSettlement: 'Pending settlement',
            viewDetails: 'View details',
            settlementType: 'Type',
          },
          companySettlementDetails: {
            bookId: "Booking ID",
            dateTime: "Date time",
            originalPaymentStatus: "Original payment status",
            subTotal: "Subtotal",
            fleetAmount: "Fleet amount",
            driverAmount: "Driver amount",
            companyAmount: "Company amount",
            companyOweFleet: "Company owe fleet",
            fleetOweCompany: "Fleet owe company",
            notes: "Notes"
          },
          promotion: {
            date: 'Date',
            promoCode: 'Promo code',
            users: 'Users',
            uses: 'Uses',
            totalPromoAmount: 'Total promo amount',
            totalReceipt: 'Total receipt'
          },
          carActivity: {
            plateNumber: 'Vehicle license plate',
            vehicleType: 'Vehicle type',
            companyName: 'Supplier',
            day1st: '1st',
            day2nd: '2nd',
            day3rd: '3rd',
            day4th: '4th',
            day5th: '5th',
            day6th: '6th',
            day7th: '7th',
            day8th: '8th',
            day9th: '9th',
            day10th: '10th',
            day11th: '11th',
            day12th: '12th',
            day13th: '13th',
            day14th: '14th',
            day15th: '15th',
            day16th: '16th',
            day17th: '17th',
            day18th: '18th',
            day19th: '19th',
            day20th: '20th',
            day21st: '21st',
            day22nd: '22nd',
            day23rd: '23rd',
            day24th: '24th',
            day25th: '25th',
            day26th: '26th',
            day27th: '27th',
            day28th: '28th',
            day29th: '29th',
            day30th: '30th',
            day31st: '31st',
            operationDays: 'Operation days',
            activeCars: 'Total active vehicle:'
          },
          carActivityLogsTitle: 'Logs',
          carActivityLogs: {
            licensePlate: 'Vehicle license plate',
            companyName: 'Supplier',
            bookingId: 'Booking ID',
            driverName: 'Driver name',
            driverIDNumber: 'ID# / National IC#',
            date: 'Accepted time',
            bookFrom: 'Booking from',
          },
          affiliation: {
            date: 'Pickup time',
            bookingId: 'Booking ID',
            bookingType: 'Booking type',
            homeFleet: 'Home fleet',
            providerFleet: 'Provider fleet',
            driver: 'Driver',
            passenger: 'Passenger',
            paidBy: 'Paid by',
            airportFee: 'Airport fee',
            meetDriver: 'Meet driver',
            rushHour: 'Rush hour',
            tollFee: 'Toll fee',
            heavyTraffic: 'Heavy traffic',
            otherFees: 'Other fees',
            provider: 'Provider',
            basicFareProvider: 'Basic fare provider',
            subTotalProvider: 'Sub total provider',
            techFeeProvider: 'Tech fee provider',
            bookingFeeProvider: 'Booking fee provider',
            taxValueProvider: 'Tax value provider',
            tipProvider: 'Tip provider',
            totalFareProvider: 'Total fare provider',
            homeFleet: 'Home fleet',
            basicFare: 'Basic fare',
            subtotal: 'Subtotal',
            techFee: 'Tech fee',
            bookingFee: 'Booking fee',
            tax: 'Tax',
            tip: 'Tip',
            totalFare: 'Total fare',
            payoutInLocalCurrency: 'Payout in local currency',
            payoutInUSD: 'Payout in USD',
            fleetCommInUSD: 'Fleet comm in USD',
            bookingFeeInUSD: 'Booking fee in USD',
            totalPayoutInUSD: 'Total payout in USD',
          },
          prepaidTopUp: {
            date: 'Date & time',
            topUpId: 'Top up ID',
            transactionId: 'Transaction ID',
            name: 'Name',
            company: 'Supplier',
            paidBy: 'Paid by',
            amount: 'Amount'
          },
          operatorLogs: {
            date: 'Date',
            operator: 'Operator',
            username: 'Username',
            module: 'Module',
            description: 'Description'
          },
          corporate: {
            date: 'Date',
            bookingId: 'Booking ID',
            corporateName: 'Supplier',
            corpTraveler: 'Client traveler',
            corpId: 'Client id',
            corpDivision: 'Client division',
            costCentre: 'Cost centre',
            corpPO: 'Client PO',
            managerName: 'Manager\'s name',
            managerEmail: 'Manager\'s email',
            title: 'Title',
            department: 'Department',
            clientCaseMatter: 'Client case matter / Trip description',
            chargeCode: 'Charge code / Trip code',
            driverName: 'Driver',
            pickupLocation: 'Pickup location',
            destination: 'Destination',
            total: 'Total amt',
            subtotal: 'Subtotal',
            commission: 'Commission',
            paidBy: 'Paid by',
            statusDisplay: 'Status',
          },
          bookingLogs: {
            bookingId: 'Booking ID',
            bookingService: 'Booking service',
            bookingType: 'Booking type',
            driverName: 'Driver',
            driverIdPermit: 'ID# / National IC#',
            customerName: 'Passenger',
            pickupTime: 'Pickup time',
            onBoardTime: 'On board time',
            droppedOffTime: 'Drop off time',
            pickupLocation: 'Pickup location',
            destination: 'Destination',
            dispatchHistory: 'Dispatch history',
            distanceTour: 'Distance',
            duration: 'Duration',
            paidBy: 'Paid by',
            bookFrom: 'Booking From',
            map: 'Map',
            showDispatchHistory: 'Show',
            showMap: 'Show'
          },
          rating: {
            date: 'Date',
            bookingId: 'Booking ID',
            driverName: 'Driver',
            driverIdPermit: 'ID# / National IC#',
            customerName: 'Traveler name',
            customerPhone: 'Traveler phone',
            like: 'Like',
            dislike: 'Dislike',
            notes: 'Comment',
            stars: 'Rating'
          },
          mDispatcher: {
            date: 'Date',
            bookingId: 'Booking ID',
            mDispatcherName: 'mDispatcher name',
            mDispatcherType: 'mDispatcher type',
            subtotal: 'Subtotal',
            commission: 'Commission',
            paidBy: 'Paid by',
          },
          totalAdjustment: {
            date: 'Date',
            bookingId: 'Booking ID',
            oldAmount: 'Total',
            type: 'Type',
            markupDifference: 'Markup difference',
            newAmount: 'Edited total/Markup',
            username: 'Operator',
            reason: 'Reason'
          },
          incidentCancellation: {
            date: 'Date',
            bookingId: 'Booking ID',
            customerName: 'Traveler name',
            customerPhone: 'Traveler phone',
            bookFrom: 'Booking from',
            canceledBy: 'Canceled by',
            type: 'Type',
            reason: 'Reason',
            canceller: 'Canceller'
          },
          revenueFleet: {
            date: 'Date',
            fareRevenue: 'Fare revenue',
            trip: 'Trip',
            avgFareTrip: 'Avg fare/trip',
            drivers: '# Drivers',
            avgTripPerDriver: 'Avg trip/driver',
            dispatcher: '# Dispatcher',
          },
          revenueDriver: {
            date: 'Date',
            driverName: 'Driver',
            fareRevenue: 'Fare revenue',
            trip: '# Trip',
            avgFareTrip: 'Avg fare/trip'
          },
          revenueMonthly: {
            month: 'Month',
            trip: 'Finished trips',
            subtotal: 'Subtotal',
            tipAmt: 'Tip',
            techFee: 'Tech fee',
            salesTax: 'Tax',
            partnerCommission: 'Partner commission/ ODD',
            totalFare: 'Total fare without promo',
            netEarning: 'Net Earning',
          },
          dailyDriver: {
            driverName: 'Driver',
            driverIDPermit: 'ID# / National IC#',
            driverPhone: 'Phone',

            workingHoursOnline: 'Online (hrs)',
            workingHoursAvailable: 'Available (hrs)',
            workingHoursNotAvailable: 'Not available (hrs)',
            workingHoursPob: 'P.O.B (hrs)',
            workingHoursAccepted: 'Accepted',
            workingHoursReject: 'Reject',
            workingHoursIgnore: 'Ignore',
            workingHoursCancel: 'Cancel',

            completedTripsTotal: 'Total',
            completedTripsCc: 'From CC',
            completedTripsCarHailing: 'From car-hailing',
            completedTripsPaxApp: 'From pax app',
            completedTripsWebBooking: 'From web booking',
            completedTripsMDispatcher: 'From mDispatcher',
            completedTripsKiosk: 'From Kiosk',
            completedTripsApi: 'From API',

            ccActivityDispatchedTrips: 'Dispatched trips',
            ccActivityAccepted: 'Accepted',
            ccActivityReject: 'Reject',
            ccActivityIgnore: 'Ignore',
            ccActivityCancelByPassenger: 'Canceled by passenger ',
            ccActivityCancelByDriver: 'Canceled by driver',
            ccActivityCancelByCc: 'Canceled by CC',
            ccActivityCancelByAPI: 'Canceled by API ',
            ccActivityNoShow: 'No show',

            fareSummaryTotalCash: 'Cash',
            fareSummaryTotalCreditCard: 'Credit card',
            fareSummaryTotalExternalCard: 'External card',
            fareSummaryTotal: 'Total',

            ratingNotes: 'No. likes',
            ratingDislike: 'No. dislikes',
            ratingLike: 'No. notes',

            fareDetailsBasicFare: 'Basic fare',
            fareDetailsAirportFee: 'Airport fee',
            fareDetailsRushHour: 'Surcharge',
            fareDetailsHeavyTraffic: 'Heavy traffic',
            fareDetailsOtherFees: 'Other fees',
            fareDetailsSubTotal: 'Subtotal',
            fareDetailsTechFee: 'Tech fee',
            fareDetailsBookingFee: 'Booking fee',
            fareDetailsSalesTax: 'Sales tax',
            fareDetailsTip: 'Tip amt',
            fareDetailsPromoAmount: 'Promo amt',
            fareDetailsTotalAmt: 'Total amt',
          },
          dailyCar: {
            carInfo: 'Vehicle info',
            fareDetails: 'Fare details',
            completedTrips: 'Completed trips',

            carInfoCompanyName: 'Supplier',
            carInfoPermission: 'Permission',
            carInfoVehiclePlateNumber: 'License plate',
            carInfoVhcId: 'Vehicle #',
            carInfoCarType: 'Vehicle type',
            completedTripsTotal: 'Total',
            completedTripsCc: 'From CC',
            completedTripsCarHailing: 'From car-hailing',
            completedTripsPaxApp: 'From pax app',
            completedTripsWebBooking: 'From web booking',
            completedTripsMDispatcher: 'From mDispatcher',
            completedTripsKiosk: 'From Kiosk',
            completedTripsAPI: 'From API',
            fareDetailsBasicFare: 'Basic fare',
            fareDetailsAirportFee: 'Airport fee',
            fareDetailsMeetDriver: 'Meet driver',
            fareDetailsRushHour: 'Surcharge',
            fareDetailsHeavyTraffic: 'Heavy traffic',
            fareDetailsTollFee: 'Toll fee',
            fareDetailsOtherFees: 'Other fees',
            fareDetailsSubTotal: 'Sub total',
            fareDetailsTechFee: 'Tech fee',
            fareDetailsBookingFee: 'Booking fee',
            fareDetailsSalesTax: 'Sales tax',
            fareDetailsTip: 'Tip',
            fareDetailsPromoAmount: 'Promo amount',
            fareDetailsTotalAmt: 'Total amt',
          },
          dailyDriverCar: {
            driverInfo: 'Driver info',
            driverName: 'Driver name',
            driverIDPermit: 'ID# / National IC#',
            driverPhone: 'Phone number',
            companyName: 'Supplier',
            actionInfo: 'Actions',
            date: 'Date',
            action: 'Action',
            bookingId: 'Booking ID',
            carInfo: 'Vehicle info',
            permission: 'Permission',
            licensePlate: 'License plate',
            carId: 'Vehicle #',
            carType: 'Vehicle type',
          },
          driverLoginStatus: {
            driver: 'Driver',
            driverIdPermit: 'ID# / National IC#',
            phone: 'Phone number	',
            username: 'Username',
            company: 'Supplier',
            carType: 'Vehicle type	',
            licensePlate: 'License plate	',
            status: 'Status',
          },
          bookingSummary: {
            date: 'Date',
            operator: 'Operator',
            username: 'Username',
            action: 'Action',
            bookingId: 'Booking ID',
          },
          creditWallet: {
            dateAndTime: 'Date & time',
            driverName: 'Driver name',
            phoneNumber: 'Phone number',
            userName: 'Username',
            company: 'Supplier',
            type: 'Type',
            bookingId: 'Booking #',
            operator: 'Operator',
            reason: 'Reason',
            amount: 'Changed amount',
            newAmount: 'New balance',
            currency: 'Currency',
          },
          dispatchHistory: {
            performer: 'Performer',
            name: 'Name',
            action: 'Action',
            date: 'Date',
            location: 'Driver\'s location',
            estimate: 'Vehicle-to-pickup location estimate',
          },
          payToDriver: {
            driverName: 'Driver',
            driverPhone: 'Phone number',
            companyName: 'Company',
            totalAvailablePayout: 'Available payout',
            totalAmountTransferredToCreditWallet: 'Amount transferred to credit wallet',
            totalEarningAmount: 'Earning amount',
            period: 'Period',
            totalRides: 'Total rides',
            actions: 'Actions',
            pay: 'Pay',
            remove: 'Remove',
            clearSuccess: 'The earning of selected driver was successfully cleared.',
            clearFailed: 'Failed to clear earning data of the driver. Please try again.',
            selectDriver: {
              title: '',
              body: 'Please select driver to pay.',
              buttonTitle: 'OK'
            },
            confirmClear: {
              title: '',
              body: 'Are you sure you want to clear earning data of this driver? This action cannot be undone.',
              buttonTitle: 'OK'
            },
            paymentSuccessfully: 'Payment Successfully',
          },
          settlementHistory: {
            date: 'Date',
            driverName: 'Driver name',
            dateRange: 'Date range',
            totalTransaction: 'Total settled transactions',
            paidAmount: 'Total settled amount',
          },
          settlement: {
            driverName: 'Driver',
            driverIdPermit: 'ID# / National IC#',
            totalBookings: 'Total booking',
            total: 'Total amt',
            approved: 'Approved',
            acct: 'ACCT',
            gross: 'GROSS',
            percent: '3.0%',
            net: 'NET',
          },
          documentExpiry: {
            driverName: 'Driver name',
            driverPhone: 'Driver phone',
            company: 'Supplier',
            documentName: 'Document name',
            expiredDate: 'Expired date',
            status: 'Status',
            statusStr: {
              NA: 'N/A',
              expired: 'Expired',
              active: 'Active'
            }
          }
        }
      },
      zoneSetting: {
        Add: 'Add',
        Import: 'Import',
        Name: 'Name',
        Status: 'Status',
        Active: 'Active',
        Inactive: 'Inactive',
        Deactivate: 'Deactivate',
        Activate: 'Activate',
        Delete: 'Delete',
        Cancel: 'Cancel',
        Input_zone_name: 'Input zone name',
        Save_zone: 'Save zone',
        Clear_draw: 'Clear draw',
        Edit: 'Edit',
        Delete_zone_success: 'Zone hase been deleted successfully',
        Delete_zone_fail: 'The errors occurred while deleting zone',
        Update_zone_fail: 'The errors occurred while updating zone',
        Update_zone_success: 'Zone hase been updated successfully',
        Create_zone_fail: 'The errors occurred while creating zone',
        Create_zone_success: 'New zone hase been created successfully',
        Draw_zone: 'Please draw zone on map',
        Import_zones_successful: 'Import zones successful',
        Import_zones_fail: 'Import zones fail',
        DELETE_ZONE: 'Delete Zone',
        ZONE_CONFIRM: 'Do you want to delete zone ?',
        Name_zone_required: 'Name and Zone are required!'
      },
      companySetting: {
        DELETE_COMPANY: 'DELETE SUPPLIER',
        DELETE_CONFIRM: 'Do you want to delete supplier!',
        DETAIL_COMPANY: 'DETAIL SUPPLIER',
        Yes: 'Yes',
        Cancel: 'Cancel',
        EDIT_COMPANY: 'EDIT SUPPLIER',
        ADD_COMPANY: 'ADD SUPPLIER',
        Name: 'Name',
        Required_fields: 'Required fields',
        Save: 'Save',
        Cancel: 'Cancel',
        Actions: 'Actions',
        Edit: 'Edit',
        Add: 'Add',
        Show_per_page: 'Items per page',
        Page: 'Page',
        show_pages: '{0} - {1} of {2} shown',
        Create_company_success: 'New supplier has been created successfully',
        Create_company_fail: 'The errors occurred while creating supplier',
        Update_company_success: 'Supplier has been updated successfully',
        Update_company_fail: 'The errors occurred while updating supplier',
        Load_company_fail: 'The errors occurred while loading supplier',
        Delete_company_success: 'Supplier has been deleted successfully',
        Delete_company_fail: 'The errors occurred while deleting supplier',
      },
      dispatchSetting: {
        GENERAL: 'GENERAL',
        Offer_interval: 'Offer interval',
        Seconds: 'Seconds',
        Auto_re_dispatch_interval: 'Auto re-dispatch interval',
        Maximum_auto_dispatch_duration: 'Maximum auto-dispatch duration',
        Time_out: 'Time out',
        mins: 'mins',
        minTimeBeforeReservation: 'Do not allow Driver to start reservation before',
        IN_ADVANCE: 'IN ADVANCE',
        Assign_reservation_to_driver: 'Assign reservation to driver',
        Dispatcher_confirms_job_for_driver: 'Dispatcher confirms job for driver',
        Minimum_book_ahead: 'Minimum book ahead',
        Maximum_book_ahead: 'Maximum book ahead - single order',
        Week: 'Week',
        Month: 'Month',
        hrs: 'hrs',
        Driver_cancellation_duration: 'Driver cancellation duration',
        Dispatch_mode: 'Dispatch mode',
        Manual: 'Manual',
        Immediate_auto_dispatch: 'Immediate auto-dispatch',
        Delayed_auto_dispatch: 'Delayed auto-dispatch',
        Auto_dispatch_before_pickup_time: 'Auto dispatch before pickup time',
        Required_fields: 'Required fields',
        Save: 'Save',
        Process_in_advance: 'Process in advance',
        Save_dispatch_success: 'Dispatch has been updated successfully',
        Save_dispatch_fail: 'The errors occurred while updating dispatch',
        Dispatch_setting_general_tooltip: 'Apply to booking on demand, booking in advance with delayed auto-dispatch',
        Dispatch_setting_Assign_reservation_to_driver_tooltip: 'Reservation is assigned and accepted for driver automatically when this option is enable',
        Dispatch_setting_Driver_cancellation_duration_tooltip: ' Duration is from the time when the pickup time to driver cancels the booking',
        Dispatch_setting_Dispatch_mode_tooltip: '- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.',

      },
      voiceSMSSetting: {
        ACCOUNT_SETTINGS: 'ACCOUNT SETTINGS',
        Account_SID: 'Account SID',
        Auth_Token: 'Auth Token',
        Application_ID: 'Application ID',
        SMS_number_1: 'SMS number 1',
        SMS_number_2: 'SMS number 2',
        SMS_SETTINGS: 'SMS SETTINGS',
        MASKED_PHONE_SETTINGS: 'MASKED PHONE SETTINGS',
        SMS_MARKETING_SETTINGS: 'SMS MARKETING SETTINGS',
        Phone_number: 'Phone number',
        Save: 'Save'
      },
      regularSetting: {
        Name: 'Name',
        Last_Update: 'Last Update',
        Status: 'Status',
        Required_fields: 'Required fields',
        Yes: 'Yes',
        Cancel: 'Cancel',
        Save: 'Save',
        Actions: 'Actions',
        Edit: 'Edit',
        Add: 'Add',
        Show_per_page: 'Items per page',
        Page: 'Page',
        show_pages: '{0} - {1} of {2} shown',
        DELETE_CONFIRM: 'Do you want to delete?',
        ADD_REGULAR_RATE: 'ADD REGULAR RATE',
        EDIT_REGULAR_RATE: 'EDIT REGULAR RATE',
        DETAIL_REGULAR_RATE: 'DETAIL REGULAR RATE',
        DELETE_REGULAR_RATE: 'DELETE REGULAR RATE',
        Delete_confirm_regular_rate: 'Do you want to delete this item?',
        STARTING: 'STARTING',
        Starting_Now: 'Starting (Now)',
        Starting_Reservation: 'Starting (Reservation)',
        FIRST_DISTANCE_FEE: 'FIRST DISTANCE FEE',
        First_distance_fee: 'First distance',
        From: 'From',
        To: 'To',
        Fee_for_first_distance: 'Fee for first distance',
        SECOND_DISTANCE_FEE: 'SECOND DISTANCE FEE',
        Second_distance_fee: 'Second-distance',
        AFTER_SECOND_DISTANCE: 'AFTER SECOND DISTANCE',
        Fee_after_second_distance: 'Fee after second distance',
        PER_MINUTE: 'PER MINUTE',
        Fee_per_minute: 'Fare per minute',
        mi: 'mi',
        hour: 'hour',
        MINIMUM: 'MINIMUM',
        Minimum_Now: 'Minimum (Now)',
        Minimum_Reservation: 'Minimum (Reservation)',
        PAX_MDISPATCHER_CANCELLATION: 'Traveler cancellation',
        On_demand: 'On demand',
        Penalty: 'Penalty',
        In_advance: 'In advance',
        PAX_MDISPATCHER_NO_SHOW: 'TRAVELER NO SHOW',
        Assign_car_type: 'Assign vehicle type',
        Select_car_type: 'Select vehicle type',
        Active: 'Active',
        Inactive: 'Inactive',
        Deactivate: 'Deactivate',
        DELETE_FLATE_RATE: 'DELETE FLATE RATE',
        Delete_confirm_flat_rate: 'Do you want to delete this item?',
        EDIT_FLAT_RATE: 'EDIT FLAT RATE',
        ADD_FLAT_RATE: 'ADD FLAT RATE',
        DELETE_FLAT_RATE: 'DELETE FLAT RATE',
        DELETE_HOURLY_RATE: 'DELETE HOURLY/ DAILY RATE',
        Delete_confirm_hour_rate: 'Do you want to delete this item?',
        EDIT_HOURLY_RATE: 'EDIT HOURLY/ DAILY RATE',
        ADD_HOURLY_RATE: 'ADD HOURLY/ DAILY RATE',
        DELETE_FLAT_ROUTE: 'DELETE_FLAT_ROUTE',
        DELETE_ROUTE_CONFIRM: 'Are you sure to delete route?',
        EDIT_ROUTE: 'EDIT ROUTE',
        ADD_ROUTE: 'ADD ROUTE',
        Route_name: 'Route name',
        Route_type: 'Route Type',
        Zone_to_zone: 'Zone to zone',
        Zipcode_to_zipcode: 'Zipcode to zipcode',
        First_location: 'First location',
        Second_location: 'Second location',
        First_location_zip_note: '*Notes: You can enter many zip codes at the same time by using \',\' symbol. For consecutive codes, please use \'->\' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)',
        Second_location_zip_note: '*Notes: You can enter many zip codes at the same time by using \',\' symbol. For consecutive codes, please use \'->\' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)',
        SINGLE_TRIP_SETUP: 'SINGLE TRIP SETUP',
        Route_1: 'Route 1 (First location -> Second location)',
        Route_2: 'Route 2 (Second location -> First location)',
        Limitation: 'Limitation',
        Unlimited: 'Unlimited',
        Limited: 'Limited',
        Time_coverage: 'Time coverage',
        Extra_time: 'Extra time',
        ROUND_TRIP_SETUP: 'ROUND TRIP SETUP',
        Round_trip: 'Round trip',
        Time_coverage: 'Time coverage',
        ROUTES: 'ROUTES',
        Route_1_Fare: 'Route 1 Fare',
        Route_2_Fare: 'Route 2 Fare',
        Round_Trip_Fare: 'Round Trip Fare',
        Last_Update: 'Last Update',
        Select_a_zone: 'Select a zone',
        minute: 'minute',
        VIEW_REGULAR_RATE: 'VIEW DETAIL REGULAR RATE',
        VIEW_FLAT_RATE: 'VIEW FLAT RATE',
        VIEW_HOURLY_RATE: 'VIEW HOURLY RATE',
        HOURLY_PACKAGE_RATE: 'HOURLY PACKAGE RATE',
        Package_name: 'Package name',
        Package_duration: 'Package duration',
        Base_rate: 'Base rate',
        Distance_coverage: 'Distance coverage',
        Extra_duration: 'Extra duration',
        Extra_distance: 'Extra distance',
        DELETE_HOURLY_PACKAGE: 'DELETE HOURLY PACKAGE',
        DELETE_PACKAGE_CONFIRM: 'Do you want to delete package rate?',
        EDIT_PACKAGE: 'EDIT PACKAGE',
        VIEW_PACKAGE: 'VIEW PACKAGE DETAIL',
        ADD_PACKAGE: 'ADD PACKAGE',
        Fee_per_extra_duration: 'Fee per extra duration',
        Fee_per_extra_distance: 'Fee per extra distance',
        hours: 'hour(s)',
        days: 'day(s)',
        Update_flat_rate_success: 'Flat rate has been updated successfully',
        Update_flat_rate_fail: 'The errors occurred while updating flat rate',
        Created_flat_rate_success: 'Flat rate has been created successfully',
        Created_flat_rate_fail: 'The errors occurred while creating flat rate',
        Update_flat_rate_route_success: 'Flat rate route has been updated successfully',
        Update_flat_rate_route_fail: 'The errors occurred while updating flat rate route',
        Created_flat_rate_route_success: 'Flat rate route has been created successfully',
        Created_flat_rate_route_fail: 'The errors occurred while creating flat rate route',
        Loading_flat_rate_fail: 'The errors occurred while loading flat rate detail',
        Delete_flat_rate_route_fail: 'The errors occurred while deleting flat rate route',
        Delete_flat_rate_success: 'Flat rate has been deleted successfully',
        Delete_flat_rate_fail: 'The errors occurred while deleting flat rate',
        Update_regular_rate_success: 'Regular rate has been updated successfully',
        Update_regular_rate_fail: 'The errors occurred while updating regular rate',
        Create_regular_rate_success: 'Regular rate has been created successfully',
        Create_regular_rate_fail: 'The errors occurred while creating regular rate',
        Update_hourly_rate_success: 'Hourly rate has been updated successfully',
        Update_hourly_rate_fail: 'The errors occurred while updating hourly rate',
        Create_hourly_rate_success: 'Hourly rate has been created successfully',
        Create_hourly_rate_fail: 'The errors occurred while creating hourly rate',
        Update_hourly_rate_package_success: 'Hourly rate package has been updated successfully',
        Update_hourly_rate_package_fail: 'The errors occurred while updating hourly rate package',
        Create_hourly_rate_package_success: 'Hourly rate package has been created successfully',
        Create_hourly_rate_package_fail: 'The errors occurred while creating hourly rate package',
        Load_hourly_rate_fail: 'The errors occurred while loading hourly rate details',
        Update_hourly_status: 'The errors occurred while updating hourly rate status',
        Delete_hourly_fail: 'The errors occurred while deleting hourly rate',
        Delete_hourly_success: 'Hourly rate has been deleted successfully',
        Loading_regular_rate_fail: 'The errors occurred while loading regular rate',
        Update_regular_status_fail: 'The errors occurred while updating regular rate status',
        Delete_regular_rate_success: 'Regular rate has been deleted successfully',
        Delete_regular_rate_fail: 'The errors occurred while deleting regular rate',
        Please_go_to_Car_Type_settings: 'Please go to Vehicle Type settings to assign this rate to vehicle types.',
        Car_type_filter: 'Vehicle Type Filter',
        Delete_package_error: 'Delete package error!',
        Pax_mDispatcher_cancellation_tooltip: 'Cancellation policy applied for passengers',
        Penalty_tooltip: 'Charged amount',
        Pax_mDispatcher_no_show_tooltip: 'No show policy applied for passengers. This fee will be charged if drivers tap "No show" when they do not see passengers at the pickup location.'
      },
      carSetting: {
        DELETE_CAR: 'DELETE VEHICLE',
        DELETE_CONFIRM: 'Do you want to delete selected vehicle?',
        Yes: 'Yes',
        Cancel: 'Cancel',
        EDIT_CAR: 'EDIT VEHICLE',
        CREATE_CAR: 'CREATE VEHICLE',
        Plate_Number: 'Plate Number',
        Car_Type: 'Vehicle Type',
        Select_car_type: 'Select vehicle type',
        Make: 'Make',
        Select_car_make: 'Select vehicle make',
        Model: 'Model',
        Select_a_car_model: 'Select a vehicle model',
        Year: 'Year',
        Select_year: 'Select year',
        Hardware_Meter: 'Hardware Meter',
        VIN: 'Vehicle identification number (VIN)',
        Company: 'Supplier',
        Select_company: 'Select supplier',
        Max_Passengers: 'Max Passengers',
        Max_Luggage: 'Max Luggage',
        Color: 'Color',
        License_expiry: 'License expiry',
        Required_fields: 'Required fields',
        Save: 'Save',
        Cancel: 'Cancel',
        Add: 'Add',
        Plate_Number: 'Plate Number',
        Car_Type: 'Vehicle Type',
        Hardware_Meter: 'Hardware Meter',
        Make: 'Make',
        Car_Model: 'Vehicle Model',
        Created_Date: 'Created Date',
        Status: 'Status',
        Actions: 'Actions',
        Add: 'Add',
        Show_per_page: 'Items per page',
        Page: 'Page',
        show_pages: '{0} - {1} of {2} shown',
        Active: 'Active',
        Inactive: 'Inactive',
        Edit: 'Edit',
        Deactivate: 'Deactivate',
        Activate: 'Activate',
        Delete: 'Delete',
        Name: 'Name',
        MODEL_LIST: 'MODEL LIST',
        Model_Name: 'Model name',
        Add_Model: 'Add model',
        DELETE_CAR_MODEL: 'DELETE VEHICLE MODEL',
        DELETE_CONFIRM_MODEL: 'Do you want to delete vehicle model?',
        EDIT_CAR_MAKE: 'EDIT VEHICLE MAKE',
        CREATE_CAR_MAKE: 'ADD VEHICLE MAKE',
        Assign_Rate: 'Assign Rate',
        ASSIGN_RATE: 'ASSIGN RATE',
        Car_type_ID: 'Vehicle type ID',
        App_Display_Name: 'App Display Name',
        Affiliate_car_type: 'Affiliate Vehicle Type',
        Regular_rate: 'Regular rate',
        Zone: 'Zone',
        Rate: 'Rate',
        Zone_Rate: 'Zone Rate',
        Flat_Fare: 'Flat Fare',
        Select_flat_rate: 'Select flat rate',
        Hourly_daily_rate: 'Hourly / daily rate',
        Dispatch_To: 'Dispatch To',
        Normal_Fare: 'Normal Fare',
        Upload_an_image: 'Upload an image',
        DETAIL_CAR: 'VEHICLE DETAIL',
        DELETE_MULTI_CAR: 'Delete selected cars',
        DELETE_MULTI_CAR_CONFIRM: 'Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?',
        DETAIL_CAR_MAKE: 'Vehicle Make',
        Car_Make: 'Vehicle Make',
        Create_car_success: 'New vehicle has been created successfully',
        Update_car_success: 'This vehicle has been updated successfully.',
        Update_car_fail: 'The errors occurred while updating vehicle',
        Create_car_fail: 'The errors occurred while creating vehicle',
        Load_car_fail: 'The errors occurred while loading vehicle',
        Delete_car_fail: 'The errors occurred while deleting vehicle',
        Delete_car_success: 'Vehicle has been deleted successfully',
        Change_cars_status_success: 'All vehicle statuses have been changed successfully',
        Change_cars_status_fail: 'The errors occurred while changing vehicle statuses',
        Delete_cars_success: 'All selected cars have been deleted successfully',
        Delete_cars_fail: 'The errors occurred while deleting cars',
        Update_car_type_success: 'Vehicle type has been updated successfully',
        Update_car_type_fail: 'The errors occurred while updating vehicle type',
        Load_car_type_details_fail: 'Could not load vehicle type details',
        Update_car_make_fail: 'The errors occurred while updating vehicle make',
        Update_car_make_success: 'Vehicle make has been updated successfully',
        Create_car_make_fail: 'The errors occurred while creating vehicle make',
        Create_car_make_success: 'New vehicle make has been created successfully',
        Update_car_model_fail: 'The errors occurred while updating vehicle model',
        Update_car_model_success: 'Vehicle model has been updated successfully',
        Create_car_model_fail: 'The errors occurred while creating vehicle model',
        Create_car_model_success: 'New vehicle model has been created successfully',
        Loading_car_model_fail: 'The errors occurred while loading vehicle model',
        Delete_car_make_success: 'Vehicle make has been deleted successfully',
        Delete_cat_make_fail: 'The errors occurred while deleting vehicle make',
        Delete_regular_rate_success: 'Delete Regular rate successfull',
        Delete_regular_rate_fail: 'Delete Regular rate error',
        Update_status_error: 'Update status error',
        Case_number: 'Case number',
        Car_type_filter: 'Vehicle Type Filter',
        Phone_Number: 'Phone Number',
        ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.'
      },
      operationSetting: {
        Driver: 'Driver',
        Search_driver_name: 'Search driver name',
        Company: 'Supplier',
        License_plate: 'License plate',
        Select_license_plate: 'Select license plate',
        Shift: 'Shift',
        Zone: 'Zone',
        Required_fields: 'Required fields',
        Save: 'Save',
        No: 'No.',
        Car_Type: 'Vehicle Type',
        Plate_Number: 'Plate Number',
        Unassign: 'Unassign',
        Please_choose_driver: 'Please choose driver.',
        Please_choose_company: 'Please choose supplier.',
        Please_license_plate: 'Please choose license plate.',
        Please_choose_shift: 'Please choose shift.',
        Please_choose_zone: 'Please choose zone.',
        CONFIRM_UNASSIGN: 'Do you want to unassigned this driver?',
        Assign_success: 'Operation has been assigned successfully',
        Unassign_success: 'Driver has been unassigned successfully.'
      },
      paymentMethod: {
        cash: 'Cash',
        personalCard: 'Personal card',
        corporateCard: 'Client card',
        qrCodePayment: 'QR code payment',
        directBilling: 'Direct invoicing',
        externalCard: 'External card',
        mDispatcherCard: 'mDispatcher card',
        corporatePrepaid: 'Client prepaid',
        canceled: 'Canceled',
        noShow: 'No Show',
        incident: 'Incident',
        fleetCard: 'External card',
        applePay: 'Apple pay',
      },
      paymentStatusItem: {
        all: 'All',
        fullPayment: 'Full payment',
        partialPayment: 'Partial payment',
        pendingPayment: 'Pending payment',
      },
      bookingFromItem: {
        all: 'All',
        paxApp: 'Pax app',
        mDispatcher: 'mDispatcher',
        kiosk: 'Kiosk',
        cc: 'Command Center',
        webBooking: 'Web booking',
        carHailing: 'Car-hailing',
      },
      bookingTypeItem: {
        batchDelivery: 'Batch Delivery',
        all: 'All',
        now: 'Now',
        reservation: 'Reservation',
      },
      bookingServiceItem: {
        all: 'All',
        homeFleet: 'Home booking',
        localBooking: 'In-house',
        farmIn: 'Farm in',
        farmInAndAffiliationOwner: 'Farm in & affiliation owner',
        provideService: 'Farm in',
      },
      ratingTypeItem: {
        all: 'All',
        like: 'Like',
        dislike: 'Dislike',
      },
      starsItem: {
        all: 'All',
        star1: '1 Star',
        star2: '2 Stars',
        star3: '3 Stars',
        star4: '4 Stars',
        star5: '5 Stars',
      },
      incidentCanceledTypes: {
        all: 'All',
        incident: 'Incident',
        canceled: 'Canceled',
      },
      canceledByItem: {
        all: 'All',
        CorpAD: 'Client Admin',
        passenger: 'Passenger',
        CC: 'CC',
        mDispatcher: 'mDispatcher',
        Partner: 'Partner',
        webBooking: 'Web booking',
        timeout: 'Timeout',
      },
      bookingSummaryActionItem: {
        all: 'All',
        create: 'Create',
        update: 'Update',
        cancel: 'Cancel',
        complete: 'Complete',
      },
      transactionTypeItem: {
        bankAccount: 'Transferred to bank account',
        creditWallet: 'Transferred to credit wallet',
        netEarning: 'Net Earning',
        referralEarning: 'Referral Earning',
        all: 'All',
        topUp: 'Top up',
        editBalance: 'Edit balance',
        bookingDeduction: 'Booking deduction',
      },
      moduleItem: {
        all: 'All',
        bookings: 'Bookings',
        customers: 'Travelers',
        dispatch: 'Dispatch',
        general: 'General',
        promotion: 'Promotion',
        rate: 'Rate',
        car: 'Vehicle',
        city: 'City',
        company: 'Supplier',
        drivers: 'Drivers',
        driverSettlement: 'Driver settlement',
        login: 'Login',
        logout: 'Logout',
        mDispatcher: 'mDispatcher',
        mDispatcherType: 'mDispatcher type',
        operation: 'Operation',
        partners: 'Partners',
        permission: 'Permission',
        queuingArea: 'Queuing area',
        report: 'Report',
        shift: 'Shift',
        user: 'User',
        voiceSms: 'Voice & SMS',
      },
      serviceTypeItem: {
        all: 'All',
        oneWay: 'One way',
        fromAirport: 'From airport',
        toAirport: 'To airport',
        hourly: 'Hourly/daily',
        roundTrip: 'Round trip',
        fromAirportHourlyDaily: 'From airport - hourly/daily',
        fromAirportRoundTrip: 'From airport - round trip',
        toAirportHourlyDaily: 'To airport - hourly/daily',
        toAirportRoundTrip: 'To airport - round trip',
      },
    },
    error: {
      23005:
        'For system performance you may only view reports in %{interval} %{unitLabel}, please adjust your date-range',
    },
    result: {
      noResultFound: 'No result found',
      page: 'Page',
      itemsPerPage: 'Items per page',
      showPages: 'Shown %{from} - %{to} of %{total}',
      exportToExcel: 'Export to excel',
      pay: 'Pay',
      bookingDetails: {
        transactionId: 'Transaction ID',
        bookingId: 'Booking #',
        bookFrom: 'Booking from',
        bookingService: 'Booking service',
        bookingType: 'Booking type',
        serviceType: 'Service type',
        operator: 'Operator',
        customerPhone: 'Passenger phone number',
        customerName: 'Passenger name',
        corpDivision: 'Division',
        corpDept: 'Dept',
        rideSharing: 'Ride-sharing',
        queueName: 'Queuing area',
        bookingTime: 'Creation time',
        pickupTime: 'Departure time',
        dispatchTime: 'Dispatch time',
        onBoardTime: 'On board time',
        droppedOffTime: 'Drop off time',
        onBoardDuration: 'On board duration',
        dispatch: 'Dispatch #',
        completedTime: 'Completed Time',
        driverName: 'Driver name',
        driverIdPermit: 'ID# / National IC#',
        driverLicenseNumber: 'Driver license number',
        companyName: 'Supplier',
        carType: 'Vehicle Type',
        licensePlate: 'License plate',
        pickupLocation: 'Departure location',
        geoPickup: 'Departure location lat/long',
        destination: 'Destination',
        geoDestination: 'Destination lat/long',
        pickupFrom: 'Departure location from',
        distanceTour: 'P.O.B distance',
        notes: 'Notes',
        basicFareCalculator: 'Basic fare calculator',
        basicFare: 'Basic fare',
        editFare: 'Edit basic fare',
        oldBasicFare: 'Old basic fare',
        airportFee: 'Airport fee',
        meetDriver: 'Meet driver',
        rushHour: 'Surcharge',
        heavyTraffic: 'Heavy traffic',
        tollFee: 'Toll fee',
        otherFees: 'Other fees',
        subtotal: 'Subtotal',
        techFee: 'Tech fee',
        serviceFee: "Add'l services",
        bookingFee: 'Booking fee',
        salesTax: 'Sales tax',
        tipAmt: 'Tip amt',
        promoCode: 'Promo code',
        promoAmt: 'Promo amt',
        total: 'Total amt',
        actualReceived: 'Actual received',
        differenceAmt: 'Difference (received)',
        customerDebt: 'Customer Debt',
        markupDifference: 'Markup difference',
        requestedPaymentMethod: 'Requested payment method',
        paidBy: 'Paid by',
        instructions: 'Instructions',
        additionalServiceName: 'Name',
        additionalServiceValue: 'Value',
        sendReceiptHeader: '',
        sendReceipt: 'Send receipt',
        send: 'Send',
        cancel: 'Cancel',
        sentReceiptSuccess: 'The receipt has been sent successfully',
        sentReceiptFailed:
          'The receipt cannot be sent right now. Please try again.',
        coRider: 'Co-rider',
        coriderName: 'Name',
        stopAddress: 'Additional Destination',
        stopGeo: 'Lat/long',
      },
      financial: {
        driverName: 'Driver name',
        driverPhone: 'Phone number',
        driverUserName: 'Username',
        companyName: 'Supplier',
        bookingList: 'Booking list',
        totalFare: 'Total fare w/o promo',
        netEarning: 'Net Earning',
        showBookingList: 'Show',
      },
      financialDriver: {
        payoutType: 'Payout type',
        date: 'Date',
        bookingId: 'Booking ID',
        paidBy: 'Paid by',
        bookFrom: 'Booking from',
        bookingType: 'Booking type',
        companyName: 'Supplier',
        driverName: 'Driver',
        driverIdPermit: 'ID# / National IC#',
        totalFare: 'Total fare without promo',
        subtotal: 'Subtotal',
        transactionFee: 'Transaction fee',
        techFee: 'Tech fee',
        salesTax: 'Sales tax',
        tipAmt: 'Tip amt',
        bookingFee: 'Booking fee',
        commission: 'Commission',
        deduction: 'Deduction',
        promoAmt: 'Promo amt',
        ridePayment: 'Ride payment',
        grossEarning: 'Gross earning',
        driverDeduction: 'Driver deduction',
        netEarning: 'Net Earning',
      },
      financialCompany: {
        companyName: 'Supplier ',
        homeFleetNetEarning: 'Home fleet Net Earning',
        affiliateNetEarning: 'Affiliate Net Earning',
        totalNetEarning: 'Total Driver Net Earning',
        status: 'Status',
        companyAmount: 'Company profit',
      },
      promotion: {
        date: 'Date',
        promoCode: 'Promo code',
        users: 'Users',
        uses: 'Uses',
        totalPromoAmount: 'Total promo amount',
        totalReceipt: 'Total receipt',
      },
      carActivity: {
        plateNumber: 'Vehicle license plate',
        vehicleType: 'Vehicle type',
        companyName: 'Supplier',
        day1st: '1st',
        day2nd: '2nd',
        day3rd: '3rd',
        day4th: '4th',
        day5th: '5th',
        day6th: '6th',
        day7th: '7th',
        day8th: '8th',
        day9th: '9th',
        day10th: '10th',
        day11th: '11th',
        day12th: '12th',
        day13th: '13th',
        day14th: '14th',
        day15th: '15th',
        day16th: '16th',
        day17th: '17th',
        day18th: '18th',
        day19th: '19th',
        day20th: '20th',
        day21st: '21st',
        day22nd: '22nd',
        day23rd: '23rd',
        day24th: '24th',
        day25th: '25th',
        day26th: '26th',
        day27th: '27th',
        day28th: '28th',
        day29th: '29th',
        day30th: '30th',
        day31st: '31st',
        operationDays: 'Operation days',
        activeCars: 'Total active vehicle:',
      },
      carActivityLogsTitle: 'Logs',
      carActivityLogs: {
        licensePlate: 'Vehicle license plate',
        companyName: 'Supplier',
        bookingId: 'Booking ID',
        driverName: 'Driver name',
        driverIDNumber: 'ID# / National IC#',
        date: 'Accepted time',
        bookFrom: 'Booking from',
      },
      affiliation: {
        date: 'Date',
        bookingId: 'Booking ID',
        bookingType: 'Booking type',
        homeFleet: 'Home fleet',
        providerFleet: 'Provider fleet',
        driver: 'Driver',
        passenger: 'Passenger',
        paidBy: 'Paid by',
        airportFee: 'Airport fee',
        meetDriver: 'Meet driver',
        rushHour: 'Rush hour',
        tollFee: 'Toll fee',
        heavyTraffic: 'Heavy traffic',
        otherFees: 'Other fees',
        provider: 'Provider',
        basicFareProvider: 'Basic fare provider',
        subTotalProvider: 'Sub total provider',
        techFeeProvider: 'Tech fee provider',
        bookingFeeProvider: 'Booking fee provider',
        taxValueProvider: 'Tax value provider',
        tipProvider: 'Tip provider',
        totalFareProvider: 'Total fare provider',
        homeFleet: 'Home fleet',
        basicFare: 'Basic fare',
        subtotal: 'Subtotal',
        techFee: 'Tech fee',
        bookingFee: 'Booking fee',
        tax: 'Tax',
        tip: 'Tip',
        totalFare: 'Total fare',
        payoutInLocalCurrency: 'Payout in local currency',
        payoutInUSD: 'Payout in USD',
        fleetCommInUSD: 'Fleet comm in USD',
        bookingFeeInUSD: 'Booking fee in USD',
        totalPayoutInUSD: 'Total payout in USD',
        vehicle: 'Vehicle',
        customerPhone: 'Customer phone number',
        driverPhone: 'Driver phone number',
        total: 'Total',
        totalInUSD: 'Total (USD)',
        supplierPayout: 'Supplier payout',
        supplierPayoutInUSD: 'Supplier payout (USD)',
        buyerPayout: 'Buyer commission',
        buyerPayoutInUSD: 'Buyer commission (USD)',
      },
      prepaidTopUp: {
        date: 'Date & time',
        topUpId: 'Top up ID',
        transactionId: 'Transaction ID',
        name: 'Name',
        company: 'Supplier',
        paidBy: 'Paid by',
        amount: 'Amount',
      },
      operatorLogs: {
        date: 'Date',
        operator: 'Operator',
        username: 'Username',
        module: 'Module',
        description: 'Description',
      },
      corporate: {
        date: 'Date',
        bookingId: 'Booking ID',
        corporateName: 'Supplier',
        corpTraveler: 'Client traveler',
        corpId: 'Client id',
        corpDivision: 'Client division',
        costCentre: 'Cost centre',
        corpPO: 'Client PO',
        managerName: "Manager's name",
        managerEmail: "Manager's email",
        title: 'Title',
        department: 'Department',
        clientCaseMatter: 'Client case matter / Trip description',
        chargeCode: 'Charge code / Trip code',
        driverName: 'Driver',
        pickupLocation: 'Pickup location',
        destination: 'Destination',
        total: 'Total amt',
        subtotal: 'Subtotal',
        commission: 'Commission',
        paidBy: 'Paid by',
        statusDisplay: 'Status',
      },
      bookingLogs: {
        bookingId: 'Booking ID',
        bookingService: 'Booking service',
        bookingType: 'Booking type',
        driverName: 'Driver',
        driverIdPermit: 'ID# / National IC#',
        customerName: 'Passenger',
        pickupTime: 'Pickup time',
        onBoardTime: 'On board time',
        droppedOffTime: 'Drop off time',
        pickupLocation: 'Pickup location',
        destination: 'Destination',
        dispatchHistory: 'Dispatch history',
        distanceTour: 'Distance',
        duration: 'Duration',
        paidBy: 'Paid by',
        bookFrom: 'Booking From',
        map: 'Map',
        showDispatchHistory: 'Show',
        showMap: 'Show',
        paymentActivities: 'Payment activities',
      },
      rating: {
        date: 'Date',
        bookingId: 'Booking ID',
        driverName: 'Driver',
        driverIdPermit: 'ID# / National IC#',
        customerName: 'Traveler name',
        customerPhone: 'Traveler phone',
        like: 'Like',
        dislike: 'Dislike',
        notes: 'Comment',
        stars: 'Rating',
      },
      mDispatcher: {
        date: 'Date',
        bookingId: 'Booking ID',
        mDispatcherName: 'mDispatcher name',
        mDispatcherType: 'mDispatcher type',
        subtotal: 'Subtotal',
        commission: 'Commission',
        paidBy: 'Paid by',
      },
      totalAdjustment: {
        date: 'Date',
        bookingId: 'Booking ID',
        oldAmount: 'Total',
        type: 'Type',
        markupDifference: 'Markup difference',
        newAmount: 'Edited total/Markup',
        username: 'Operator',
        reason: 'Reason',
      },
      incidentCancellation: {
        date: 'Date',
        bookingId: 'Booking ID',
        customerName: 'Traveler name',
        customerPhone: 'Traveler phone',
        bookFrom: 'Booking from',
        canceledBy: 'Canceled by',
        type: 'Type',
        reason: 'Reason',
        canceller: 'Canceller',
      },
      revenueFleet: {
        date: 'Date',
        fareRevenue: 'Fare revenue',
        trip: 'Trip',
        avgFareTrip: 'Avg fare/trip',
        drivers: '# Drivers',
        avgTripPerDriver: 'Avg trip/driver',
        dispatcher: '# Dispatcher',
      },
      revenueDriver: {
        date: 'Date',
        driverName: 'Driver',
        fareRevenue: 'Fare revenue',
        trip: '# Trip',
        avgFareTrip: 'Avg fare/trip',
      },
      revenueMonthly: {
        month: 'Month',
        trip: 'Finished trips',
        subtotal: 'Subtotal',
        tipAmt: 'Tip',
        techFee: 'Tech fee',
        salesTax: 'Tax',
        partnerCommission: 'Partner commission/ ODD',
        totalFare: 'Total fare without promo',
        netEarning: 'Net Earning',
      },
      dailyDriver: {
        driverName: 'Driver',
        driverIDPermit: 'ID# / National IC#',
        driverPhone: 'Phone',

        workingHoursOnline: 'Online (hrs)',
        workingHoursAvailable: 'Available (hrs)',
        workingHoursNotAvailable: 'Not available (hrs)',
        workingHoursPob: 'P.O.B (hrs)',
        workingHoursAccepted: 'Accepted',
        workingHoursReject: 'Reject',
        workingHoursIgnore: 'Ignore',
        workingHoursCancel: 'Cancel',

        completedTripsTotal: 'Total',
        completedTripsCc: 'From CC',
        completedTripsCarHailing: 'From car-hailing',
        completedTripsPaxApp: 'From pax app',
        completedTripsWebBooking: 'From web booking',
        completedTripsMDispatcher: 'From mDispatcher',
        completedTripsKiosk: 'From Kiosk',
        completedTripsApi: 'From API',

        ccActivityDispatchedTrips: 'Dispatched trips',
        ccActivityAccepted: 'Accepted',
        ccActivityReject: 'Reject',
        ccActivityIgnore: 'Ignore',
        ccActivityCancelByPassenger: 'Canceled by passenger ',
        ccActivityCancelByDriver: 'Canceled by driver',
        ccActivityCancelByCc: 'Canceled by CC',
        ccActivityCancelByAPI: 'Canceled by API ',
        ccActivityNoShow: 'No show',

        fareSummaryTotalCash: 'Cash',
        fareSummaryTotalCreditCard: 'Credit card',
        fareSummaryTotalExternalCard: 'External card',
        fareSummaryTotal: 'Total',

        ratingNotes: 'No. likes',
        ratingDislike: 'No. dislikes',
        ratingLike: 'No. notes',

        fareDetailsBasicFare: 'Basic fare',
        fareDetailsAirportFee: 'Airport fee',
        fareDetailsRushHour: 'Surcharge',
        fareDetailsHeavyTraffic: 'Heavy traffic',
        fareDetailsOtherFees: 'Other fees',
        fareDetailsSubTotal: 'Subtotal',
        fareDetailsTechFee: 'Tech fee',
        fareDetailsBookingFee: 'Booking fee',
        fareDetailsSalesTax: 'Sales tax',
        fareDetailsTip: 'Tip amt',
        fareDetailsPromoAmount: 'Promo amt',
        fareDetailsTotalAmt: 'Total amt',
      },
      dailyCar: {
        carInfo: 'Vehicle info',
        fareDetails: 'Fare details',
        completedTrips: 'Completed trips',

        carInfoCompanyName: 'Supplier',
        carInfoPermission: 'Permission',
        carInfoVehiclePlateNumber: 'License plate',
        carInfoVhcId: 'Vehicle #',
        carInfoCarType: 'Vehicle type',
        completedTripsTotal: 'Total',
        completedTripsCc: 'From CC',
        completedTripsCarHailing: 'From car-hailing',
        completedTripsPaxApp: 'From pax app',
        completedTripsWebBooking: 'From web booking',
        completedTripsMDispatcher: 'From mDispatcher',
        completedTripsKiosk: 'From Kiosk',
        completedTripsAPI: 'From API',
        fareDetailsBasicFare: 'Basic fare',
        fareDetailsAirportFee: 'Airport fee',
        fareDetailsMeetDriver: 'Meet driver',
        fareDetailsRushHour: 'Surcharge',
        fareDetailsHeavyTraffic: 'Heavy traffic',
        fareDetailsTollFee: 'Toll fee',
        fareDetailsOtherFees: 'Other fees',
        fareDetailsSubTotal: 'Sub total',
        fareDetailsTechFee: 'Tech fee',
        fareDetailsBookingFee: 'Booking fee',
        fareDetailsSalesTax: 'Sales tax',
        fareDetailsTip: 'Tip',
        fareDetailsPromoAmount: 'Promo amount',
        fareDetailsTotalAmt: 'Total amt',
      },
      dailyDriverCar: {
        driverInfo: 'Driver info',
        driverName: 'Driver name',
        driverIDPermit: 'ID# / National IC#',
        driverPhone: 'Phone number',
        companyName: 'Supplier',
        actionInfo: 'Actions',
        date: 'Date',
        action: 'Action',
        bookingId: 'Booking ID',
        carInfo: 'Vehicle info',
        permission: 'Permission',
        licensePlate: 'License plate',
        carId: 'Vehicle #',
        carType: 'Vehicle type',
      },
      driverLoginStatus: {
        driver: 'Driver',
        driverIdPermit: 'ID# / National IC#',
        phone: 'Phone number	',
        username: 'Username',
        company: 'Supplier',
        carType: 'Vehicle type	',
        licensePlate: 'License plate	',
        status: 'Status',
      },
      bookingSummary: {
        date: 'Date',
        operator: 'Operator',
        username: 'Username',
        action: 'Action',
        bookingId: 'Booking ID',
      },
      creditWallet: {
        dateAndTime: 'Date & time',
        driverName: 'Driver name',
        phoneNumber: 'Phone number',
        userName: 'Username',
        company: 'Supplier',
        type: 'Type',
        bookingId: 'Booking #',
        operator: 'Operator',
        reason: 'Reason',
        amount: 'Changed amount',
        newAmount: 'New balance',
        currency: 'Currency',
      },
      dispatchHistory: {
        performer: 'Performer',
        name: 'Name',
        action: 'Action',
        date: 'Date',
        location: "Driver's location",
        estimate: 'Vehicle-to-pickup location estimate',
      },
      payToDriver: {
        driverName: 'Driver',
        driverPhone: 'Phone number',
        companyName: 'Company',
        totalAvailablePayout: 'Available payout',
        totalAmountTransferredToCreditWallet:
          'Amount transferred to credit wallet',
        totalEarningAmount: 'Earning amount',
        period: 'Period',
        totalRides: 'Total rides',
        actions: 'Actions',
        pay: 'Pay',
        remove: 'Remove',
        clearSuccess:
          'The earning of selected driver was successfully cleared.',
        clearFailed:
          'Failed to clear earning data of the driver. Please try again.',
        selectDriver: {
          title: '',
          body: 'Please select driver to pay.',
          buttonTitle: 'OK',
        },
        confirmClear: {
          title: '',
          body: 'Are you sure you want to clear earning data of this driver? This action cannot be undone.',
          buttonTitle: 'OK',
        },
        paymentSuccessfully: 'Payment Successfully',
      },
      settlementHistory: {
        date: 'Date',
        driverName: 'Driver name',
        dateRange: 'Date range',
        totalTransaction: 'Total settled transactions',
        paidAmount: 'Total settled amount',
      },
      settlement: {
        driverName: 'Driver',
        driverIdPermit: 'ID# / National IC#',
        totalBookings: 'Total booking',
        total: 'Total amt',
        approved: 'Approved',
        acct: 'ACCT',
        gross: 'GROSS',
        percent: '3.0%',
        net: 'NET',
      },
      documentExpiry: {
        driverName: 'Driver name',
        driverPhone: 'Driver phone',
        company: 'Supplier',
        documentName: 'Document name',
        expiredDate: 'Expired date',
        status: 'Status',
        statusStr: {
          NA: 'N/A',
          expired: 'Expired',
          active: 'Active',
        },
      },
    },
  },
  zoneSetting: {
    Add: 'Add',
    Import: 'Import',
    Name: 'Name',
    Status: 'Status',
    Active: 'Active',
    Inactive: 'Inactive',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    Delete: 'Delete',
    Cancel: 'Cancel',
    Input_zone_name: 'Input zone name',
    Save_zone: 'Save zone',
    Clear_draw: 'Clear draw',
    Edit: 'Edit',
    Delete_zone_success: 'Zone hase been deleted successfully',
    Delete_zone_fail: 'The errors occurred while deleting zone',
    Update_zone_fail: 'The errors occurred while updating zone',
    Update_zone_success: 'Zone hase been updated successfully',
    Create_zone_fail: 'The errors occurred while creating zone',
    Create_zone_success: 'New zone hase been created successfully',
    Draw_zone: 'Please draw zone on map',
    Import_zones_successful: 'Import zones successful',
    Import_zones_fail: 'Import zones fail',
    DELETE_ZONE: 'Delete Zone',
    ZONE_CONFIRM: 'Do you want to delete zone ?',
    Name_zone_required: 'Name and Zone are required!',
  },
  companySetting: {
    DELETE_COMPANY: 'DELETE SUPPLIER',
    DELETE_CONFIRM: 'Do you want to delete supplier!',
    DETAIL_COMPANY: 'DETAIL SUPPLIER',
    Yes: 'Yes',
    Cancel: 'Cancel',
    EDIT_COMPANY: 'EDIT AGENT',
    ADD_COMPANY: 'ADD AGENT',
    Name: 'Name',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Actions: 'Actions',
    Edit: 'Edit',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Create_company_success: 'New supplier has been created successfully',
    Create_company_fail: 'The errors occurred while creating supplier',
    Update_company_success: 'Supplier has been updated successfully',
    Update_company_fail: 'The errors occurred while updating supplier',
    Load_company_fail: 'The errors occurred while loading supplier',
    Delete_company_success: 'Supplier has been deleted successfully',
    Delete_company_fail: 'The errors occurred while deleting supplier',
  },
  dispatchSetting: {
    GENERAL: 'GENERAL',
    Offer_interval: 'Offer interval',
    Seconds: 'Seconds',
    Auto_re_dispatch_interval: 'Auto re-dispatch interval',
    Maximum_auto_dispatch_duration: 'Maximum auto-dispatch duration',
    Time_out: 'Time out',
    mins: 'mins',
    minTimeBeforeReservation: 'Do not allow Driver to start reservation before',
    IN_ADVANCE: 'IN ADVANCE',
    Assign_reservation_to_driver: 'Assign reservation to driver',
    Dispatcher_confirms_job_for_driver: 'Dispatcher confirms job for driver',
    Minimum_book_ahead: 'Minimum book ahead',
    Maximum_book_ahead: 'Maximum book ahead - single order',
    Week: 'Week',
    Month: 'Month',
    hrs: 'hrs',
    Driver_cancellation_duration: 'Driver cancellation duration',
    Dispatch_mode: 'Dispatch mode',
    Manual: 'Manual',
    Immediate_auto_dispatch: 'Immediate auto-dispatch',
    Delayed_auto_dispatch: 'Delayed auto-dispatch',
    Auto_dispatch_before_pickup_time: 'Auto dispatch before pickup time',
    Required_fields: 'Required fields',
    Save: 'Save',
    Process_in_advance: 'Process in advance',
    Save_dispatch_success: 'Dispatch has been updated successfully',
    Save_dispatch_fail: 'The errors occurred while updating dispatch',
    Dispatch_setting_general_tooltip:
      'Apply to booking on demand, booking in advance with delayed auto-dispatch',
    Dispatch_setting_Assign_reservation_to_driver_tooltip:
      'Reservation is assigned and accepted for driver automatically when this option is enable',
    Dispatch_setting_Driver_cancellation_duration_tooltip:
      ' Duration is from the time when the pickup time to driver cancels the booking',
    Dispatch_setting_Dispatch_mode_tooltip:
      '- Manual mode: Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. After clicking on “Update booking” at the booking detail, reservation will be dispatched to your assigned drivers or to all drivers in case of no driver assigning. It will be integrated into driver device calendar and reminder will be available. <br>- Delayed mode:Reservation will be saved and stayed on Cue of your Command Center under “Pending” status once reservation is created. Reservation will be automatically dispatched before “time value at “Process in advance”” from pick up time to the assigned driver or the nearest available one in case of no driver assigning. <br>- Immediate auto-dispatch mode: Reservation will be automatically dispatched to all drivers once reservation is created. It will be integrated into driver device calendar and reminder will be available.',
  },
  voiceSMSSetting: {
    ACCOUNT_SETTINGS: 'ACCOUNT SETTINGS',
    Account_SID: 'Account SID',
    Auth_Token: 'Auth Token',
    Application_ID: 'Application ID',
    SMS_number_1: 'SMS number 1',
    SMS_number_2: 'SMS number 2',
    SMS_SETTINGS: 'SMS SETTINGS',
    MASKED_PHONE_SETTINGS: 'MASKED PHONE SETTINGS',
    SMS_MARKETING_SETTINGS: 'SMS MARKETING SETTINGS',
    Phone_number: 'Phone number',
    Save: 'Save',
    bookingSMS: 'Message template',
    bookingSMSTemplate: {
      name: "Name",
      content: "Content",
      action: "Action",
    },
    bookingSMSModal: {
      title: 'Add SMS template',
      save: 'Save',
      cancel: 'Cancel'
    },
  },
  regularSetting: {
    Name: 'Name',
    Last_Update: 'Last Update',
    Status: 'Status',
    Required_fields: 'Required fields',
    Yes: 'Yes',
    Cancel: 'Cancel',
    Save: 'Save',
    Actions: 'Actions',
    Edit: 'Edit',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    DELETE_CONFIRM: 'Do you want to delete?',
    ADD_REGULAR_RATE: 'ADD REGULAR RATE',
    EDIT_REGULAR_RATE: 'EDIT REGULAR RATE',
    DETAIL_REGULAR_RATE: 'DETAIL REGULAR RATE',
    DELETE_REGULAR_RATE: 'DELETE REGULAR RATE',
    Delete_confirm_regular_rate: 'Do you want to delete this item?',
    STARTING: 'STARTING',
    Starting_Now: 'Starting (Now)',
    Starting_Reservation: 'Starting (Reservation)',
    FIRST_DISTANCE_FEE: 'FIRST DISTANCE FEE',
    First_distance_fee: 'First distance',
    From: 'From',
    To: 'To',
    Fee_for_first_distance: 'Fee for first distance',
    SECOND_DISTANCE_FEE: 'SECOND DISTANCE FEE',
    Second_distance_fee: 'Second-distance',
    AFTER_SECOND_DISTANCE: 'AFTER SECOND DISTANCE',
    Fee_after_second_distance: 'Fee after second distance',
    PER_MINUTE: 'PER MINUTE',
    Fee_per_minute: 'Fare per minute',
    mi: 'mi',
    hour: 'hour',
    MINIMUM: 'MINIMUM',
    Minimum_Now: 'Minimum (Now)',
    Minimum_Reservation: 'Minimum (Reservation)',
    PAX_MDISPATCHER_CANCELLATION: 'Traveler cancellation',
    On_demand: 'On demand',
    Penalty: 'Penalty',
    In_advance: 'In advance',
    PAX_MDISPATCHER_NO_SHOW: 'TRAVELER NO SHOW',
    Assign_car_type: 'Assign vehicle type',
    Select_car_type: 'Select vehicle type',
    Active: 'Active',
    Inactive: 'Inactive',
    Deactivate: 'Deactivate',
    DELETE_FLATE_RATE: 'DELETE FLATE RATE',
    Delete_confirm_flat_rate: 'Do you want to delete this item?',
    EDIT_FLAT_RATE: 'EDIT FLAT RATE',
    ADD_FLAT_RATE: 'ADD FLAT RATE',
    DELETE_FLAT_RATE: 'DELETE FLAT RATE',
    DELETE_HOURLY_RATE: 'DELETE HOURLY/ DAILY RATE',
    Delete_confirm_hour_rate: 'Do you want to delete this item?',
    EDIT_HOURLY_RATE: 'EDIT HOURLY/ DAILY RATE',
    ADD_HOURLY_RATE: 'ADD HOURLY/ DAILY RATE',
    DELETE_FLAT_ROUTE: 'DELETE_FLAT_ROUTE',
    DELETE_ROUTE_CONFIRM: 'Are you sure to delete route?',
    EDIT_ROUTE: 'EDIT ROUTE',
    ADD_ROUTE: 'ADD ROUTE',
    Route_name: 'Route name',
    Route_type: 'Route Type',
    Zone_to_zone: 'Zone to zone',
    Zipcode_to_zipcode: 'Zipcode to zipcode',
    First_location: 'First location',
    Second_location: 'Second location',
    First_location_zip_note:
      "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
    Second_location_zip_note:
      "*Notes: You can enter many zip codes at the same time by using ',' symbol. For consecutive codes, please use '->' symbol instead of inputting one by one ( ex: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
    SINGLE_TRIP_SETUP: 'SINGLE TRIP SETUP',
    Route_1: 'Route 1 (First location -> Second location)',
    Route_2: 'Route 2 (Second location -> First location)',
    Limitation: 'Limitation',
    Unlimited: 'Unlimited',
    Limited: 'Limited',
    Time_coverage: 'Time coverage',
    Extra_time: 'Extra time',
    ROUND_TRIP_SETUP: 'ROUND TRIP SETUP',
    Round_trip: 'Round trip',
    Time_coverage: 'Time coverage',
    ROUTES: 'ROUTES',
    Route_1_Fare: 'Route 1 Fare',
    Route_2_Fare: 'Route 2 Fare',
    Round_Trip_Fare: 'Round Trip Fare',
    Last_Update: 'Last Update',
    Select_a_zone: 'Select a zone',
    minute: 'minute',
    VIEW_REGULAR_RATE: 'VIEW DETAIL REGULAR RATE',
    VIEW_FLAT_RATE: 'VIEW FLAT RATE',
    VIEW_HOURLY_RATE: 'VIEW HOURLY RATE',
    HOURLY_PACKAGE_RATE: 'HOURLY PACKAGE RATE',
    Package_name: 'Package name',
    Package_duration: 'Package duration',
    Base_rate: 'Base rate',
    Distance_coverage: 'Distance coverage',
    Extra_duration: 'Extra duration',
    Extra_distance: 'Extra distance',
    DELETE_HOURLY_PACKAGE: 'DELETE HOURLY PACKAGE',
    DELETE_PACKAGE_CONFIRM: 'Do you want to delete package rate?',
    EDIT_PACKAGE: 'EDIT PACKAGE',
    VIEW_PACKAGE: 'VIEW PACKAGE DETAIL',
    ADD_PACKAGE: 'ADD PACKAGE',
    Fee_per_extra_duration: 'Fee per extra duration',
    Fee_per_extra_distance: 'Fee per extra distance',
    hours: 'hour(s)',
    days: 'day(s)',
    Update_flat_rate_success: 'Flat rate has been updated successfully',
    Update_flat_rate_fail: 'The errors occurred while updating flat rate',
    Created_flat_rate_success: 'Flat rate has been created successfully',
    Created_flat_rate_fail: 'The errors occurred while creating flat rate',
    Update_flat_rate_route_success:
      'Flat rate route has been updated successfully',
    Update_flat_rate_route_fail:
      'The errors occurred while updating flat rate route',
    Created_flat_rate_route_success:
      'Flat rate route has been created successfully',
    Created_flat_rate_route_fail:
      'The errors occurred while creating flat rate route',
    Loading_flat_rate_fail:
      'The errors occurred while loading flat rate detail',
    Delete_flat_rate_route_fail:
      'The errors occurred while deleting flat rate route',
    Delete_flat_rate_success: 'Flat rate has been deleted successfully',
    Delete_flat_rate_fail: 'The errors occurred while deleting flat rate',
    Update_regular_rate_success: 'Regular rate has been updated successfully',
    Update_regular_rate_fail: 'The errors occurred while updating regular rate',
    Create_regular_rate_success: 'Regular rate has been created successfully',
    Create_regular_rate_fail: 'The errors occurred while creating regular rate',
    Update_hourly_rate_success: 'Hourly rate has been updated successfully',
    Update_hourly_rate_fail: 'The errors occurred while updating hourly rate',
    Create_hourly_rate_success: 'Hourly rate has been created successfully',
    Create_hourly_rate_fail: 'The errors occurred while creating hourly rate',
    Update_hourly_rate_package_success:
      'Hourly rate package has been updated successfully',
    Update_hourly_rate_package_fail:
      'The errors occurred while updating hourly rate package',
    Create_hourly_rate_package_success:
      'Hourly rate package has been created successfully',
    Create_hourly_rate_package_fail:
      'The errors occurred while creating hourly rate package',
    Load_hourly_rate_fail:
      'The errors occurred while loading hourly rate details',
    Update_hourly_status:
      'The errors occurred while updating hourly rate status',
    Delete_hourly_fail: 'The errors occurred while deleting hourly rate',
    Delete_hourly_success: 'Hourly rate has been deleted successfully',
    Loading_regular_rate_fail: 'The errors occurred while loading regular rate',
    Update_regular_status_fail:
      'The errors occurred while updating regular rate status',
    Delete_regular_rate_success: 'Regular rate has been deleted successfully',
    Delete_regular_rate_fail: 'The errors occurred while deleting regular rate',
    Please_go_to_Car_Type_settings:
      'Please go to Vehicle Type settings to assign this rate to vehicle types.',
    Car_type_filter: 'Vehicle Type Filter',
    Delete_package_error: 'Delete package error!',
    Pax_mDispatcher_cancellation_tooltip:
      'Cancellation policy applied for passengers',
    Penalty_tooltip: 'Charged amount',
    Pax_mDispatcher_no_show_tooltip:
      'No show policy applied for passengers. This fee will be charged if drivers tap "No show" when they do not see passengers at the pickup location.',
  },
  carTypeSetting: {
    newService: 'New Service',
    editService: 'Edit Service',
    serviceInfo: 'Service Info',
    name: 'Name',
    namePH: 'Car-type name',
    enterNumber: 'Enter a number',
    appDisplayName: 'App display name',
    shortDescription: 'Short description',
    maxPassengers: 'Max passengers',
    maxLuggage: 'Max luggages',
    vehiclePhoto: 'Vehicle Photos',
    iconShowList: 'Icon to show in list',
    imageShowWeb: 'Image to show on web',
    carPinOnMap: 'Car PIN on map',
    recommendedSize: 'Recommended size:',
    serviceSetting: 'Service Settings',
    serviceType: 'Service type',
    reservation: 'Reservation',
    onDemand: 'On-demand',
    destinationSetting: 'Destination setting',
    requireDestination: 'Require destination',
    extraDestination: 'Extra destination',
    calculatorFareMode: 'Calculate fare mode',
    hideDestinationOnDemand: 'On-demand',    meteredFare: 'Metered fare',
    quotedFare: 'Quoted fare',
    availableZones: 'Available Zones',
    dispatchAssociation: 'Dispatch Association',
    Save: 'Save',
    Edit: 'Edit',
    Cancel: 'Cancel',
    validateName: 'Please input name',
    validateAppDisplayName: 'Please input app display name',
    validateSelectImage: 'Please select an image',
    updateSuccess: 'Update successfully',
    createSuccess: 'Create successfully',
    failApi: 'Something went wrong!',
    validateRequire: 'Please specify passenger count and double-check luggage.',
  },
  carSetting: {
    Details: 'Details',
    vehicleInfo: 'Vehicle Info',
    Documents: 'Documents',
    vehicle: 'Vehicle',
    addVehicle: 'Add Vehicle',
    detailVehicle: 'Vehicle Details',
    additionalVehicleTitle: 'Additional Vehicle Information',
    Phone_Number_Tooltip: 'Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.',
    discardChange: 'Discard changes',
    DELETE_CAR: 'DELETE VEHICLE',
    DELETE_CONFIRM: 'Do you want to delete selected vehicle?',
    Yes: 'Yes',
    Cancel: 'Cancel',
    EDIT_CAR: 'EDIT VEHICLE',
    CREATE_CAR: 'CREATE VEHICLE',
    Plate_Number: 'Plate Number',
    Car_Type: 'Vehicle Type',
    Select_car_type: 'Select vehicle type',
    Make: 'Make',
    Select_car_make: 'Select vehicle make',
    Model: 'Model',
    Select_a_car_model: 'Select a vehicle model',
    Year: 'Production Year',
    Select_year: 'Select year',
    Hardware_Meter: 'Hardware Meter',
    VIN: 'Vehicle identification number (VIN)',
    Company: 'Supplier',
    Select_company: 'Select supplier',
    Max_Passengers: 'Max Passengers',
    Max_Luggage: 'Max Luggage',
    Color: 'Color',
    License_expiry: 'License expiry',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Add: 'Add',
    Plate_Number: 'Plate Number',
    Car_Type: 'Vehicle Type',
    Hardware_Meter: 'Hardware Meter',
    Make: 'Make',
    Car_Model: 'Vehicle Model',
    Created_Date: 'Created Date',
    Status: 'Status',
    Actions: 'Actions',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Active: 'Active',
    Inactive: 'Inactive',
    Edit: 'Edit',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    Delete: 'Delete',
    Name: 'Name',
    MODEL_LIST: 'MODEL LIST',
    Model_Name: 'Model name',
    Add_Model: 'Add model',
    DELETE_CAR_MODEL: 'DELETE VEHICLE MODEL',
    DELETE_CONFIRM_MODEL: 'Do you want to delete vehicle model?',
    EDIT_CAR_MAKE: 'EDIT VEHICLE MAKE',
    CREATE_CAR_MAKE: 'ADD VEHICLE MAKE',
    Assign_Rate: 'Assign Rate',
    ASSIGN_RATE: 'ASSIGN RATE',
    Car_type_ID: 'Vehicle type ID',
    App_Display_Name: 'App Display Name',
    Affiliate_car_type: 'Affiliate Vehicle Type',
    Regular_rate: 'Regular rate',
    Zone: 'Zone',
    Rate: 'Rate',
    Zone_Rate: 'Zone Rate',
    Flat_Fare: 'Flat Fare',
    Select_flat_rate: 'Select flat rate',
    Hourly_daily_rate: 'Hourly / daily rate',
    Dispatch_To: 'Dispatch To',
    Normal_Fare: 'Normal Fare',
    Upload_an_image: 'Upload an image',
    DETAIL_CAR: 'VEHICLE DETAIL',
    DELETE_MULTI_CAR: 'Delete selected cars',
    DELETE_MULTI_CAR_CONFIRM:
      'Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?',
    DETAIL_CAR_MAKE: 'Vehicle Make',
    Car_Make: 'Vehicle Make',
    Create_car_success: 'New vehicle has been created successfully',
    Update_car_success: 'This vehicle has been updated successfully.',
    Update_car_fail: 'The errors occurred while updating vehicle',
    Create_car_fail: 'The errors occurred while creating vehicle',
    Load_car_fail: 'The errors occurred while loading vehicle',
    Delete_car_fail: 'The errors occurred while deleting vehicle',
    Delete_car_success: 'Vehicle has been deleted successfully',
    Change_cars_status_success:
      'All vehicle statuses have been changed successfully',
    Change_cars_status_fail:
      'The errors occurred while changing vehicle statuses',
    Delete_cars_success: 'All selected cars have been deleted successfully',
    Delete_cars_fail: 'The errors occurred while deleting cars',
    Update_car_type_success: 'Vehicle type has been updated successfully',
    Update_car_type_fail: 'The errors occurred while updating vehicle type',
    Load_car_type_details_fail: 'Could not load vehicle type details',
    Update_car_make_fail: 'The errors occurred while updating vehicle make',
    Update_car_make_success: 'Vehicle make has been updated successfully',
    Create_car_make_fail: 'The errors occurred while creating vehicle make',
    Create_car_make_success: 'New vehicle make has been created successfully',
    Update_car_model_fail: 'The errors occurred while updating vehicle model',
    Update_car_model_success: 'Vehicle model has been updated successfully',
    Create_car_model_fail: 'The errors occurred while creating vehicle model',
    Create_car_model_success: 'New vehicle model has been created successfully',
    Loading_car_model_fail: 'The errors occurred while loading vehicle model',
    Delete_car_make_success: 'Vehicle make has been deleted successfully',
    Delete_cat_make_fail: 'The errors occurred while deleting vehicle make',
    Delete_regular_rate_success: 'Delete Regular rate successfull',
    Delete_regular_rate_fail: 'Delete Regular rate error',
    Update_status_error: 'Update status error',
    Case_number: 'Case number',
    Car_type_filter: 'Vehicle Type Filter',
    Phone_Number: 'Phone Number',
    ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
  },
  operationSetting: {
    Driver: 'Driver',
    Search_driver_name: 'Search driver name',
    Company: 'Supplier',
    License_plate: 'License plate',
    Select_license_plate: 'Select license plate',
    Shift: 'Shift',
    Zone: 'Zone',
    Required_fields: 'Required fields',
    Save: 'Save',
    No: 'No.',
    Car_Type: 'Vehicle Type',
    Plate_Number: 'Plate Number',
    Unassign: 'Unassign',
    Please_choose_driver: 'Please choose driver.',
    Please_choose_company: 'Please choose supplier.',
    Please_license_plate: 'Please choose license plate.',
    Please_choose_shift: 'Please choose shift.',
    Please_choose_zone: 'Please choose zone.',
    CONFIRM_UNASSIGN: 'Do you want to unassigned this driver?',
    Assign_success: 'Operation has been assigned successfully',
    Unassign_success: 'Driver has been unassigned successfully.',
  },
  paymentMethod: {
    cash: 'Cash',
    personalCard: 'Personal card',
    corporateCard: 'Client card',
    qrCodePayment: 'QR code payment',
    directBilling: 'Direct Invoicing',
    externalCard: 'External card',
    mDispatcherCard: 'mDispatcher card',
    corporatePrepaid: 'Client prepaid',
    fleetCard: 'External card',
    applePay: 'Apple pay',
    googlePay: 'Google pay'
  },
  changePassword: {
    Change_password: 'Change password',
    Current_password: 'Current password',
    New_password: 'New password',
    Confirm_password: 'Confirm password',
    ERROR_INPUT_PASSWORD: 'Please input password',
    ERROR_INPUT_VALID_PASSWORD:
      'Password must contain at least 8 characters, one numeric digit and a special character',
    ERROR_INPUT_MATCH_PASSWORD: 'Password does not match. Please try again',
    Save: 'Save',
    Cancel: 'Cancel',
  },
  userProfile: {
    My_account: 'My account',
    Username: 'Username',
    First_name: 'Firstname',
    Last_name: 'Last name',
    Phone_number: 'Phone number',
    Email_address: 'Email address',
    Address: 'Address',
    Save: 'Save',
    Edit_profile: 'Edit profile',
    Change_password: 'Change password',
    ERROR_INPUT_USERNAME: 'Please input username.',
    ERROR_INPUT_FIRSTNAME: 'Please input first name.',
    ERROR_INPUT_VALID_EMAIL: ' Please enter valid email.',
    Required_fields: 'Required fields',
  },

  General: {
    searchQueuingDriver: 'Search: Driver Name, Phone Number',
    search: 'Search',
    User: 'User',
    My_Account: 'My Account',
    Sign_out: 'Sign out',
    My_site: 'My site',
    Command_center: 'Command Center',
    Notifications: 'Notifications',
    You_dont_have_any_message: 'You do not have any message',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Now: 'Now',
NowComplete: 'Now (Acttual Pickup Time)',
    hrs: 'hrs',
    patmentMethod_1: 'Cash',
    patmentMethod_2: 'Personal card',
    patmentMethod_3: 'mDispatch card',
    patmentMethod_4: 'Client Card',
    patmentMethod_5: 'Direct invoicing',
    patmentMethod_6: 'External card',
    patmentMethod_7: 'Client Prepaid',
    patmentMethod_8: 'QR Code',
    patmentMethod_9: 'Apple Pay',
    patmentMethod_10: 'Apple Pay',
    Name: 'Name',
    Delete: 'Delete',
    App_SOS_dialog_form_message_1: '{0} Emergency SOS!',
    App_SOS_dialog_form_message_2: 'I need help at ',
    App_SOS_dialog_form_message_3:
      'Booking: {0}, Driver: {1}, Passenger: {2}, License Plate: {3} ({4})',
    mins: 'mins',
  },
  shiftSettings: {
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    Name: 'Name',
    Time_Range: 'Time Range',
    Actions: 'Actions',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Edit',
    Delete: 'Delete',
    EDIT_SHIFT_SETTING: 'EDIT SHIFT SETTING',
    ADD_SHIFT_SETTING: 'ADD SHIFT SETTING',
    DETAIL_SHIFT_SETTING: 'DETAIL SHIFT SETTING',
    DELETE_SHIFT_SETTING: 'DELETE SHIFT SETTING',
    Delete_confirm_shift_setting: 'Do you want to delete this item?',
    Name: 'Name',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    From: 'From',
    To: 'To',
    Edit_shift_template: 'Edit Shift Template',
    Add_shift_template: 'Add Shift Template',
    Detail_shift_template: 'Detail Shift Template',
    Delete_shift_template: 'Delete Shift Template',
    Delete_confirm_shift_template: 'Do you want to delete this item?',
    Working_time: 'Working Time',
    Create_shift_settings_success:
      'New shift setting has been created successfully',
    Create_shift_settings_fail:
      'The errors occurred while creating shift setting',
    Update_shift_settings_success:
      'Shift setting has been updated successfully',
    Update_shift_settings_fail:
      'The errors occurred while updating shift setting',
    Delete_shift_settings_success:
      'Shift setting has been deleted successfully',
    Delete_shift_settings_fail:
      'The errors occurred while deleting shift setting',
    Create_shift_template_success:
      'New shift template has been created successfully',
    Create_shift_template_fail:
      'The errors occurred while creating shift template',
    Update_shift_template_success:
      'Shift template has been updated successfully',
    Update_shift_template_fail:
      'The errors occurred while updating shift template',
    Delete_shift_template_success:
      'Shift template has been deleted successfully',
    Delete_shift_template_fail:
      'The errors occurred while deleting shift template',
  },
  promotionSettings: {
    Detail_campaign: 'Detail Campaign',
    Edit_campaign: 'Edit Campaign',
    Add_campaign: 'Add Campaign',
    Delete_campaign: 'Delete Campaign',
    Delete_confirm_campaign: 'Do you want to delete selected campaign?',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    Name: 'Name',
    Actions: 'Actions',
    show_pages: '{0} - {1} of {2} shown',
    Edit: 'Edit',
    Delete: 'Delete',
    Send_to_inbox: 'Send to inbox',
    Save: 'Save',
    Cancel: 'Cancel',
    Required_fields: 'Required fields',
    Promotion_code: 'Promotion Code',
    Promotion_type: 'Promotion Type',
    Campaign: 'Campaign',
    Type: 'Type',
    Value: 'Value',
    Valid_from: 'Valid From',
    Valid_to: 'Valid To',
    Total_uses_user: 'Total Uses/User',
    Released_quantity: 'Released Quantity',
    Number_of_sending_inbox: 'Number Of Sending Inbox',
    Status: 'Status',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Add_promotion_code: 'Add Promotion Code',
    Edit_promotion_code: 'Edit Promotion Code',
    Detail_promotion_code: 'Detail Promotion Code',
    Delete_promotion_code: 'Delete Promotion Code',
    Delete_confirm_promotion_code:
      'Do you want to delete selected promotion code?',
    New_customer: 'New traveler',
    Budget_per_user: 'Budget/user',
    Unlimited: 'Unlimited',
    Limited: 'Limited',
    Total_uses_per_user: 'Total uses/user',
    Send_an_in_box_automatically_to_new_customers:
      'Send an inbox automatically to new travelers',
    Notes: 'Notes',
    Private: 'Private',
    Public: 'Public',
    Create_campaign_success: 'New campaign has been created successfully',
    Create_campaign_fail: 'The errors occurred while creating campaign',
    Update_campaign_success: 'Campaign has been updated successfully',
    Update_campaign_fail: 'The errors occurred while updating campaign',
    Delete_campaign_success: 'Campaign has been deleted successfully',
    Delete_campaign_fail: 'The errors occurred while deleting campaign',
    Loading_campaign_fail: 'The errors occurred while loading campaign',
    Update_campaign_status_success:
      'Campaign status has been updated successfully',
    Update_campaign_status_fail:
      'The errors occurred while updating campaign status',
    Loading_promotion_code_fail:
      'The errors occurred while loading promotion code',
    Select_campaign: 'Select Campaign',
    Create_promotion_code_success:
      'New promotion code has been created successfully',
    Create_promotion_code_fail:
      'The errors occurred while creating promotion code',
    Update_promotion_code_status_success:
      'Promotion code status has been updated successfully',
    Update_promotion_code_status_fail:
      'The errors occurred while updating promotion code status',
    Delete_promotion_code_confirmation:
      'Do you want to delete these promotion codes?',
    Just_delete_inactive:
      '** Just only promotion code in the inactive status can be deleted.',
    Send_inbox_confirmation:
      'Are you sure you want to send this promo code to passenger inbox?',
    Send_inbox_success: 'Promotion code has been sent successfully.',
    Send_inbox_fail: 'Promotion code has been sent failed. Please try again!',
    Confirmation: 'Confirmation',
    Delete_promotion_codes_success:
      'Promotion code has been deleted successfully',
    Delete_promotion_codes_fail:
      'The errors occurred while deleting promotion code',
    Delete_multiple_promotion_codes_success:
      'Promotion codes has been deleted successfully',
    Delete_multiple_promotion_codes_fail:
      'The errors occurred while deleting promotion codes',
    Delete_promotion_code_success:
      'Promotion code has been deleted successfully',
    Delete_promotion_code_fail:
      'The errors occurred while deleting promotion code',
    Update_multiple_promotion_code_status_success:
      'Promotion code statuses has been updated successfully',
    Update_multiple_promotion_code_status_fail:
      'The errors occurred while deleting promotion code statuses',
    Search_here: 'Search here',
    Please_input_campaign_name: 'Please input campaign name',
    Please_input_promotion_code: 'Please input promotion code',
    Please_input_promotion_code_at_least_4_characters:
      'Please enter at least 4 characters.',
    Please_select_campaign: 'Please select campaign',
    Please_input_value: 'Please input value',
    Please_select_date: 'Please select date',
    Please_choose_at_least_one_customer: 'Please choose at least one traveler.',
    This_campaign_name_has_been_existing_on_the_system_Please_input_another_one:
      'This campaign name has been existing on the system. Please input another one.',
    This_promotion_code_has_been_existing_on_the_system_Please_input_another_one:
      'This promotion code has been existing on the system. Please input another one.',
    Invalid_file: 'File content is invalid',
    File_size_larger_than_5Mb: 'File size is larger than 5Mb',
    Import_promotions_success: 'File has been imported successfully',
    Import_Promotions: 'Import File',
    Number_successful_imports: 'Imported items',
    Number_skipped: 'Skip items',
    File: 'File',
    Phone_Numbers: 'phone number(s)',
    Import_Promotion_code_success:
      'Promotion code <b>{0}</b> has been imported successfully',
    Import_Promotion_code_fail:
      'Promotion code <b>{0}</b> has been failed to import. Please try again',
  },
  resetPassword: {
    New_Password: 'New Password',
    New_Comfirm_Password: 'New Confirm Password',
    Save: 'Save',
    INFO_SUCCESS: 'A reset password link has been sent to your email',
    TOKEN_EXPIRED: 'Your reset password token has been expired',
    Back_To_Login_Page: 'Back To Login Page',
  },
  permission: {
    View: 'View',
    Actions: 'Actions',
    EditTotal: 'Actions',
    delete_permission: 'Delete Permission',
    DELETE_CONFIRM: 'Do you want to delete permission!',
    Yes: 'Yes',
    Cancel: 'Cancel',
    DRIVERS: 'DRIVERS',
    Driver_list: 'Driver list',
    Enter_to_search: 'Enter to search',
    Permitted_driver_list: 'Permitted driver list',
    Save: 'Save',
    CAR: 'VEHICLE',
    Car_list: 'Vehicle list',
    Permitted_car_list: 'Permitted vehicle list',
    Edit_permission: 'Edit permission',
    Add_permission: 'Add permission',
    Detail_permission: 'Detail permission',
    Name: 'Name',
    Driver_Car: 'Driver/Vehicle',
    All: 'All',
    Company: 'Supplier',
    By_specific: 'By specific',
    Drivers: 'Drivers',
    Cars: 'Cars',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Delete',
    Edit: 'Edit',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactive',
    Active: 'Active',
    Update_status_error: 'Update status error',
    All_tooltip:
      'All driver(s) will be displayed on Map, Cue, Drivers, Operation and Reports. All vehicle(s) will be displayed on Vehicle mgmt',
    Company_tooltip:
      'Permitted driver(s) in this supplier will be displayed on Map, Cue, Drivers, Operation and Reports. Permitted vehicle(s) in this supplier will be displayed on Vehicle mgmt',
    By_specific_tooltip:
      'Permitted driver(s) in this list will be displayed on Drivers, Operation. Permitted vehicle(s) in this list will be displayed on Vehicle mgmt',
    Full_permissions: 'Full permissions',
    ChangeBalance: 'Change Balance',
  },
  thirdPartySettings: {
    Not_over_limit_subs: 'You can not create more than 10 sub-locations',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Delete',
    Edit: 'Edit',
    Status: 'Status',
    Actions: 'Actions',
    Action: 'Action',
    Inactive: 'Inactive',
    Active: 'Active',
    Check_Google_Address: 'Check Google address from latitude & longitude',
    Add_Third_Party_location: 'Add',
    Add: 'Add',
    Edit_Third_Party_location: 'Edit Third Party Location',
    Location_Not_Found: 'Location not found',
    Create_third_party_success: 'Location has been created successfully',
    Create_third_party_fail: 'The errors occurred while creating location',
    Update_third_party_success: 'Location has been updated successfully',
    Update_third_party_fail: 'The errors occurred while updating location',
    Alias: 'Alias',
    Address: 'Address',
    Latitude: 'Latitude',
    Longitude: 'Longitude',
    Please_input_address: 'Please input address',
    Please_input_latitude: 'Please input latitude',
    Please_input_longitude: 'Please input longitude',
    Save: 'Save',
    Cancel: 'Cancel',
    Required_fields: 'Required fields',
    Last_update: 'Last Update',
    Username: 'Username',
    Error_message_lat_in_range: 'The latitude must be between -90.0 and 90.0',
    Error_message_lng_in_range:
      'The longitude must be between -180.0 and 180.0.',
    Delete_third_party_success: 'Location has been deleted successfully',
    Delete_third_party_fail: 'The errors occurred while deleting location',
  },
  city: {
    DELETE_CITY: 'Delete city',
    DELETE_CONFIRM: 'Do you want to delete city?',
    Yes: 'Yes',
    Cancel: 'Cancel',
    EDIT_CITY: 'Edit city',
    ADD_CITY: 'Add City',
    Name: 'Name',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Delete',
    Edit: 'Edit',
    DETAIL_CITY: 'Detail city',
  },
  flightIntegration: {
    Username: 'Username',
    provider: 'Flight Provider',
    Key: 'API Key',
    apiId: 'API Id',
    Save: 'Save',
  },
  smsIntegration: {
    provider: 'SMS Provider',
    apiToken: 'API Token',
    subAccountId: 'Sub Account ID',
    senderIdSource: 'Sender ID (source):',
    apiKey: 'API Key',
    username: 'Username',
    shortCode: 'Short Code',
    key: 'Key',
    webService: 'Web service',
    bearerToken: 'Bearer token',
    keyId: 'Key ID',
    email: 'Email',
    appKey: 'App key',
    sender: 'Sender',
    password: 'Password',
    maskedPhone: 'Masked phone number',
    senderId: 'Sender ID',
    webservice: 'Web service',
    brandname: 'Brand name',
    appSecret: 'App secret',
    consumerKey: 'Consumer key',
    requestUrl: 'Request Url',
    authId: 'Auth ID',
    authToken: 'Auth Token',
    authKey: 'Auth Key',
    phloId: 'PHLO ID',
    dlcNumber: '10DLC Number',
    app_key: 'App key',
    api_key: 'API key',
    accountSID: 'Account SID',
    applicationId: 'Application ID',
    phoneNumber: 'SMS number 1',
    phoneNumber2: 'SMS number 2',
    SenderID_1: 'SenderID 1',
    SenderID_2: 'SenderID 2',
    smsMarketingNumber: 'SMS makerting phone number',
    updateSMSSuccess: 'The sms integration has been updated successfully'
  },
  mDispatcher: {
    mDispatchers: 'mDispatchers',
    Active: 'Active',
    Inactive: 'Inactive',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    Delete: 'Delete',
    Save: 'Save',
    Edit: 'Edit',
    ADD_MDISPATCHER: 'Add mDispatcher',
    EDIT_MDISPATCHER: 'Edit mDispatcher',
    Phone_number: 'Phone Number',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    address: 'Address',
    mDispatcher_type: 'mDispatcher type',
    mDispatcher: 'mDispatcher',
    card_management: 'Card Management',
    Select_mDispatcher_type: 'Select mDispatcher Type',
    Amount: 'Amount',
    Percent: 'Percent',
    Yes: 'Yes',
    Required_fields: 'Required fields',
    Cancel: 'Cancel',
    commission_type: 'Commission Type',
    commission_value: 'Commission Value',
    Name: 'Name',
    Phone_Number: 'Phone Number',
    queuing_area: 'Queuing Area',
    mdispatcher_type: 'Mdispatcher Type',
    registered_from: 'Registered From',
    registration_date: 'Registration Date',
    status: 'Status',
    actions: 'Actions',
    Select_queue_area: 'Select queue area',
    for_new_mDipatcher_account_password_default_is_password:
      'For new mDispatcher account, the default password is "password"',
    Create_mDispatcher_success: 'New mDipatcher has been created successfully',
    Create_mDispatcher_fail: 'The errors occurred while creating mDispatcher',
    Load_mDispatcher_fail: 'The errors occurred while loading mDispatcher',
    Update_mDispatcher_success: 'mDipatcher has been updated successfully',
    Update_mDispatcher_fail: 'The errors occurred while updating mDispatcher',
    Update_mDispatcher_status_success:
      'mDipatcher status has been updated successfully',
    Update_mDispatcher_status_fail:
      'The errors occurred while updating mDispatcher status',
    Update_mDispatcher_statuses_success:
      'mDipatcher statuses has been updated successfully',
    Update_mDispatcher_statuses_fail:
      'The errors occurred while updating mDispatcher statuses',
    Delete_mDispatcher_success: 'mDipatcher has been deleted successfully',
    Delete_mDispatcher_fail: 'The errors occurred while deleting mDispatcher',
    Delete_mDispatchers_success: 'mDipatchers has been deleted successfully',
    Delete_mDispatchers_fail: 'The errors occurred while deleting mDispatchers',
    Do_you_want_to_delete_these_mDipatchers:
      'Do you want to delete these mDipatchers?',
    Only_mDistcher_in_the_inactive_status_can_be_deleted:
      '***Just only mDistcher in the inactive status can be deleted',
    No_card: 'No card found',
    Reset_Password: 'Reset Password',
    Reset_password_success: 'mDispatcher password has been reset successfully',
    Reset_password_fail:
      'The errors occurred while reseting mDispatchers password',
    Do_you_want_to_reset_password: 'Do you want to reset password ?',
    ERROR_EXISTED_PHONE:
      'This phone has been existing on the system. Please input another one',
    ERROR_INPUT_VALIDPHONE: 'Please enter a valid phone number.',
    DETAIL_MDISPATCHER_TYPE: 'DETAIL MDISPATCHER TYPE',
    ALERT_SELECT_ACTIVATE: 'Please select mDispatchers to activate.',
    ALERT_SELECT_DEACTIVATE: 'Please select mDispatchers to deactivate.',
    CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these mDispatchers?',
    CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these mDispatchers?',
    ERROR_COMMISSION_NUMBERS: 'Please enter a valid number',
    No_Support: 'New card adding is currently not supported in this area',
    Add: 'Add',
  },
  mDispatcherType: {
    Name: 'Name',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Delete',
    Edit: 'Edit',
    DELETE_MDISPATCHER_TYPE: 'Delete mDispatcher Type',
    DELETE_CONFIRM: 'Are you sure to delete mDispatcher Type?',
    Yes: 'Yes',
    EDIT_MDISPATCHER_TYPE: 'Edit mDispatcher Type',
    ADD_MDISPATCHER_TYPE: 'Add mDispatcher Type',
    DETAIL_MDISPATCHER_TYPE: 'Detail mDispatcher Type',
    Actions: 'Actions',
    Delete_mDispatcherType_success:
      'mDispatcher Type has been deleted successfully',
    Delete_mDispatcherType_fail:
      'The errors occurred while deleting mDispatchers type',
    Load_mDispatcherType_fail:
      'The errors occurred while loading mDispatchers type',
    Create_mDispatcherType_success:
      'New mDispatcher Type has been created successfully',
    Create_mDispatcherType_fail:
      'The errors occurred while creating mDispatchers type',
    Update_mDispatcherType_success:
      'mDispatcher Type has been updated successfully',
    Update_mDispatcherType_fail:
      'The errors occurred while updating mDispatchers type',
  },
  queuingArea: {
    queuing_area_info: 'Queue area info',
    drivers: 'Queuing drivers',
    Name: 'Name',
    Required_fields: 'Required fields',
    Save: 'Save',
    Cancel: 'Cancel',
    Yes: 'Yes',
    Add: 'Add',
    Show_per_page: 'Items per page',
    Page: 'Page',
    show_pages: '{0} - {1} of {2} shown',
    Delete: 'Delete',
    Edit: 'Edit',
    Active: 'Active',
    Inactive: 'Inactive',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    Delete: 'Delete',
    keepDriverPlaceCancelNoShow:
      "Keep driver's place when booking is canceled / no-show",
    keepDriverPlaceUnavailable:
      "Keep driver's place when driver is unavailable",
    range: 'Queue range',
    queueRangeTooltip: `This is how drivers will see their queuing position number on driver app. For example:
       - Setting queue range at 1: drivers will see their position in queue as a single number: 1, 2, 3, 4, etc.
       - Setting queue range at 5: drivers will see their position in queue as a range: 1-5, 6-10, 11-15, 16-20, etc.
       - Setting queue range at 10: drivers will see their position in queue as a range: 1-10, 11-20, 21-30, 31-40, etc.`,
    DELETE_QUEUING_AREA: 'Delete Queuing Area',
    DELETE_CONFIRM: 'Do you want to delete Queuing Area?',
    EDIT_QUEUING_AREA: 'Edit Queuing Area',
    ADD_QUEUING_AREA: 'Add Queuing Area',
    DETAIL_QUEUING_AREA: 'Detail queuing area',
    Queuing_area: 'Queuing area',
    Address: 'Address',
    Enter_a_location: 'Enter a location',
    Entered_Address_is_incorrect:
      'The system cannot determine your location. Please use the suggested address.',
    Not_supported_zone: 'This queue area is not in the supported zone',
    Active_mDispatcher_list: 'Active mDispatcher list',
    Queue_list: 'Queue list',
    Allow_radius: 'Allow radius',
    Min_drivers_required: '# Min Drivers Required',
    Open_queuing_area: 'Open queuing area',
    Active_car_list: 'Active vehicle list',
    Queue_list: 'Queue list',
    mDispatcher: 'mDispatcher',
    Google_Address: 'Google Address',
    Status: 'Status',
    Update_status_error: 'Update status error',
    Load_queuing_area_fail: 'The errors occurred while loading queuing area',
    Create_queuingArea_success: 'Queuing area has been created successfully',
    Update_queuingArea_success: 'Queuing area has been updated successfully',
  },
  appBanner: {
    Save: 'Save',
    Pax_app: 'Pax app',
    Apply_to: 'Apply to',
    Please_select_view: 'Please select view',
    Home_view: 'Home view',
    Other_view: 'Other view',
    Banner_1: 'Banner #1',
    Upload_an_image: 'No image selected',
    Action: 'Action',
    NA: 'N/A',
    Link_to_website: 'Link to website',
    Make_a_call: 'Make a call',
    Send_an_email: 'Send an email',
    Banner_2: 'Banner #2',
    Banner_3: 'Banner #3',
    Driver_app: 'Driver app',
    note_1:
      '*The file extension must be .jpg, .jpeg, .png, .gif and less than 5MB.',
    note_2: '*Images should have a 16:9 aspect ratio.',
    note_3:
      '*Other views including Inbox, Refer friend, Receipt, My booking, Promo code, Add Credit card/Payment methods, Profile, Waiting for process/Confirmed reservation, Completed view.',
  },
  reservation: {
    name: 'Reservation',
    company: 'Company',
    carType: 'Car type',
    licensePlate: 'License Plate',
    driver: 'Driver',
    showAllDrivers: 'Show All Drivers',
    available: 'Available',
    reserved: 'Reserved',
    search_holder: 'Company, Driver Name, Car Type, License Plate',
    all: 'All',
    today: 'Today',
    thisWeek: 'This Week',
    day: 'Day',
    week: 'Week',
    bookingID: 'Booking ID',
    pickupTime: 'Pickup time',
    pickupLocation: 'Pickup location',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    reserved: 'Reserved',
    overlapped: 'Overlapped',
    available: 'Available',
    noData: 'Sorry! Nothing to show',
    bookingDetails: 'Reservation Details',
    reservedHint: 'Reservation bookings are confirmed',
    availableHint: "Don't have any confirmed reservation bookings",
    overlappedHint: 'Two or more confirmed reservation bookings overlap',
  },
};
